<div class="flex h-full flex-col">
    <div class="min-h-0 grow">
        <ng-container *ngTemplateOutlet="hasFetchedClients ? page : loadingPageTemplate"></ng-container>
    </div>
    <app-paginator [shouldBeHidden]="dataSource.filteredData.length < 10" [pageSize]="20" [pageSizeOptions]="[10, 20, 30]" #paginator>
    </app-paginator>
</div>

<ng-template #page>
    @if (dataSource.data.length > 0) {
        <div class="flex h-full flex-col">
            <div class="flex w-full items-center gap-x-4 px-7.5 pb-4 sm:px-[20px]">
                <p class="w-36 whitespace-nowrap md:hidden md:!text-[0.9375rem]">
                    <span class="malou-text-section-title malou-color-text-1">
                        {{ 'clients.clients' | translate }}
                    </span>
                    <i class="malou-text-18--regular malou-color-text-1 italic">({{ dataSource.filteredData.length }})</i>
                </p>
                <div class="grow">
                    <app-search
                        [debounceTime]="0"
                        [placeholder]="'common.search' | translate"
                        (searchChange)="applyFilter($event)"></app-search>
                </div>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matTooltip]="(selection.selected.length ? 'common.duplicate' : 'clients.bulk.duplicate') | translate"
                    [disabled]="!selection.selected.length"
                    (click)="duplicateClients()">
                    <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                </button>
                <button
                    class="malou-btn-icon--secondary btn-xl"
                    mat-icon-button
                    [matTooltip]="(selection.selected.length ? 'clients.delete' : 'clients.bulk.delete') | translate"
                    [disabled]="!selection.selected.length"
                    (click)="deleteSelectedClients()">
                    <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
                </button>
                <button class="malou-btn-raised--primary btn-xl !h-12.5 md:!hidden" mat-raised-button [matMenuTriggerFor]="actionsMenu">
                    {{ 'clients.add_clients' | translate }}
                </button>
                <button
                    class="malou-btn-icon--primary btn-xl !hidden md:!flex md:!h-full"
                    mat-icon-button
                    (click)="editCreateManualClient()">
                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ADD"></mat-icon>
                </button>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="editCreateManualClient()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'clients.manually' | translate }}</span>
                    </button>
                    <button class="flex gap-x-3" mat-menu-item (click)="importClientsFile()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.IMPORT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'clients.import_file' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
            <div class="min-h-0 grow overflow-y-auto px-7.5 sm:px-[20px]">
                <div class="hidden md:mt-2 md:block">
                    <app-sort-by-filters
                        [sortOptions]="SORT_OPTIONS"
                        [sortBy]="'lastVisitedAt'"
                        [sortOrder]="1"
                        (changeSortBy)="onSortByChange($event)"
                        (toggleSortOrder)="onSortOrderChange()">
                    </app-sort-by-filters>
                </div>
                @if (dataSource.filteredData.length) {
                    <mat-table class="malou-mat-table" matSort [dataSource]="dataSource" #table="matTable">
                        <ng-container matColumnDef="select">
                            <mat-header-cell *matHeaderCellDef class="items-end">
                                <app-noop-mat-checkbox
                                    classInput="malou-text-13--regular"
                                    color="primary"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    (click)="$event ? toggleAllFiltered() : null">
                                    <span class="malou-text-13--semibold ml-5 hidden text-malou-color-text-2 sm:block">
                                        {{ 'clients.select_all_clients' | translate }}
                                    </span>
                                </app-noop-mat-checkbox>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table; let i = index">
                                <app-noop-mat-checkbox
                                    classInput="malou-text-13--regular"
                                    color="primary"
                                    [checked]="selection.isSelected(client)">
                                </app-noop-mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                <div class="flex flex-col">
                                    {{ 'clients.lastname' | translate }}
                                </div>
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let client; table: table"
                                class="font-semibold !text-malou-color-text-1"
                                [matTooltip]="client.lastName && client.lastName.length > 20 ? client.lastName : ''">
                                {{ (client.lastName | shortText: 20) || '-' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="firstName">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'clients.firstname' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let client; table: table"
                                [matTooltip]="client.firstName && client.firstName.length > 20 ? client.firstName : ''">
                                {{ (client.firstName | shortText: 20) || '-' }}
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lastVisitedAt">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'clients.last_visit' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table">
                                <div class="flex gap-1">
                                    <span class="malou-text-13--semibold hidden sm:block"> {{ 'clients.last_visit' | translate }} : </span>
                                    <span>
                                        {{ (client.lastVisitedAt | formatDate: 'shortDate') || '-' }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="source">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'clients.source' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table" class="!min-w-fit">
                                <div class="flex flex-col gap-1">
                                    <span class="malou-text-13--semibold hidden sm:block">
                                        {{ 'clients.source' | translate }}
                                    </span>
                                    @switch (client.source) {
                                        @case (ClientsSource.MALOU) {
                                            <div class="malou-chip malou-chip--purple-light flex gap-2.5">
                                                <app-platform-logo
                                                    imgClasses="w-3.5"
                                                    [logo]="ClientsSource.MALOU"
                                                    [withLazyLoading]="true"></app-platform-logo>
                                                <span class="xl:hidden">{{ 'clients.malou_file' | translate }}</span>
                                            </div>
                                        }
                                        @case (ClientsSource.LAFOURCHETTE) {
                                            <div class="malou-chip malou-chip--green flex gap-2.5">
                                                <app-platform-logo
                                                    imgClasses="w-3.5"
                                                    [logo]="ClientsSource.LAFOURCHETTE"
                                                    [withLazyLoading]="true"></app-platform-logo>
                                                <span class="xl:hidden">{{ ClientsSource.LAFOURCHETTE | platformName }}</span>
                                            </div>
                                        }
                                        @case (ClientsSource.ZENCHEF) {
                                            <div class="malou-chip malou-chip--warn flex gap-2.5">
                                                <app-platform-logo
                                                    imgClasses="w-3.5"
                                                    [logo]="ClientsSource.ZENCHEF"
                                                    [withLazyLoading]="true"></app-platform-logo>
                                                <span class="xl:hidden">{{ ClientsSource.ZENCHEF | platformName }}</span>
                                            </div>
                                        }
                                        @case (ClientsSource.WHEEL_OF_FORTUNE) {
                                            <div class="malou-chip malou-chip--purple flex gap-2.5">
                                                <app-platform-logo
                                                    imgClasses="w-3.5"
                                                    [logo]="ClientsSource.WHEEL_OF_FORTUNE"
                                                    [withLazyLoading]="true"></app-platform-logo>
                                                <span class="xl:hidden">{{ 'clients.wheel_of_fortune' | translate }}</span>
                                            </div>
                                        }
                                        @default {
                                            <div class="malou-chip malou-chip--pink-light flex gap-2.5">
                                                <app-platform-logo
                                                    imgClasses="w-3.5"
                                                    [logo]="ClientsSource.MALOU"
                                                    [withLazyLoading]="true"></app-platform-logo>
                                                <span class="xl:hidden">{{ 'clients.import_manual' | translate }}</span>
                                            </div>
                                        }
                                    }
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="mailsNb">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'campaigns.report.sent_mails' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table" class="justify-center">
                                <div class="flex gap-1">
                                    <span class="malou-text-13--semibold hidden sm:block">
                                        {{ 'campaigns.report.sent_mails' | translate }} :
                                    </span>
                                    <span>
                                        {{ client.sentEmails }}
                                    </span>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="ratings">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'clients.given_stars' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table">
                                <div class="flex flex-col">
                                    <span class="malou-text-13--semibold hidden sm:block">
                                        {{ 'clients.given_stars_responsive' | translate }}
                                    </span>
                                    <div class="flex gap-1 md:min-h-[40px]">
                                        @for (rating of client.givenStarsDetails | slice: 0 : 2; track rating) {
                                            <div
                                                class="flex items-center rounded bg-malou-chip-background-primary px-2 py-1"
                                                matTooltip="{{
                                                    'campaigns.infos'
                                                        | translate
                                                            : {
                                                                  name: rating.campaignName,
                                                                  date: rating.campaignDate | formatDate: 'shortDate',
                                                                  platform: rating.campaignPlatform,
                                                              }
                                                }}">
                                                <span class="malou-text-17--medium mr-0.5 mt-[3px] text-malou-color-primary">
                                                    {{ 'rating_filters' | pluralTranslate: rating.star }}
                                                </span>
                                                <mat-icon
                                                    class="!h-4 !w-4 text-malou-color-primary"
                                                    [svgIcon]="SvgIcon.STAR_BORDER"></mat-icon>
                                            </div>
                                        }
                                        @if (client.givenStarsDetails.length > 2) {
                                            <span class="malou-text-14--medium my-auto text-malou-color-primary"
                                                >+{{ client.givenStarsDetails.length }}</span
                                            >
                                        }
                                    </div>
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="isUnsubscribed">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-sort-header>
                                {{ 'clients.unsubscription' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table">
                                <div class="flex flex-col gap-1">
                                    <span class="malou-text-13--semibold hidden sm:block">
                                        {{ 'clients.unsubscription' | translate }}
                                    </span>
                                    @if (client.isUnsubscribed) {
                                        <div class="malou-chip malou-chip--pink-light">
                                            <span>{{ 'clients.yes' | translate }}</span>
                                        </div>
                                    }
                                    @if (!client.isUnsubscribed) {
                                        <div class="malou-chip malou-chip--green">
                                            <span>{{ 'clients.no' | translate }}</span>
                                        </div>
                                    }
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                            <mat-header-cell *matHeaderCellDef class="items-end" mat-header-cell></mat-header-cell>
                            <mat-cell *matCellDef="let client; table: table" (click)="$event.stopPropagation()">
                                <button class="malou-btn-icon" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                                    <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                                </button>
                                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                                    <button class="flex gap-x-3" mat-menu-item (click)="editCreateManualClient(client)">
                                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.EDIT"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'common.edit' | translate }}</span>
                                    </button>
                                    <button class="flex gap-x-3" mat-menu-item (click)="duplicateClients(client)">
                                        <mat-icon class="!h-4" color="primary" [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'clients.duplicate_other_restaurants' | translate }}</span>
                                    </button>
                                    <button class="flex gap-x-3" mat-menu-item (click)="deleteOneClient(client._id)">
                                        <mat-icon class="!h-4" color="warn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                        <span class="malou-text-14--regular">{{ 'common.delete' | translate }}</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                        <mat-row
                            *matRowDef="let client; columns: DISPLAYED_COLUMNS; table: table; let i = index"
                            [class.selected]="selection.isSelected(client)"
                            (click)="selection.toggle(client)"></mat-row>
                    </mat-table>
                } @else {
                    <app-no-results
                        [details]="['clients.no_match_details' | translate]"
                        [title]="'clients.no_match' | translate"
                        [picture]="Illustration.Goggles | illustrationPathResolver">
                    </app-no-results>
                }
            </div>
        </div>
    } @else {
        <div class="flex h-full w-full flex-1 items-center justify-center">
            <div class="flex flex-col items-center">
                <img class="h-32" loading="lazy" [lazyLoad]="Illustration.Cutlery | illustrationPathResolver" />
                <p class="malou-text-14--bold mb-4 mt-9 text-center">
                    {{ 'clients.no_results' | translate }}
                </p>
                <span class="malou-text-10--regular mb-6 text-center">{{ 'clients.no_results_details' | translate }}</span>
                <button class="malou-btn-raised--primary !h-12 min-md:!hidden" mat-raised-button (click)="editCreateManualClient()">
                    {{ 'clients.add_clients' | translate }}
                </button>
                <button class="malou-btn-raised--primary !h-12 md:!hidden" mat-raised-button [matMenuTriggerFor]="actionsMenu">
                    {{ 'clients.add_clients' | translate }}
                </button>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-xl" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="editCreateManualClient()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'clients.manually' | translate }}</span>
                    </button>
                    <button class="flex gap-x-3" mat-menu-item (click)="importClientsFile()">
                        <mat-icon color="primary" [svgIcon]="SvgIcon.IMPORT"></mat-icon>
                        <span class="malou-text-14--regular">{{ 'clients.import_file' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    }
</ng-template>

<ng-template #loadingPageTemplate>
    <div class="h-full overflow-y-auto px-7.5">
        <div class="w-full">
            <app-skeleton skeletonClass="w-full h-[50px] secondary-bg"></app-skeleton>
            <div class="mb-2 mt-7">
                <app-skeleton
                    flexDirection="flex-row"
                    justifyContent="justify-around"
                    skeletonClass="w-full h-[30px] w-[100px] secondary-bg"
                    [count]="4"></app-skeleton>
            </div>
            <app-skeleton gapClass="gap-1" skeletonClass="w-full h-[50px] secondary-bg" [count]="7"></app-skeleton>
        </div>
    </div>
</ng-template>

<ng-template #no_results>
    <app-no-results
        [details]="['clients.no_match_details' | translate]"
        [title]="'clients.no_match' | translate"
        [picture]="Illustration.Goggles | illustrationPathResolver">
    </app-no-results>
</ng-template>
