import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash';
import { DateTime } from 'luxon';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    interval,
    map,
    merge,
    of,
    startWith,
    Subject,
    Subscription,
    switchMap,
    takeUntil,
} from 'rxjs';

import { ClientSource, ClientVariable, isApiResultSuccess, PlatformDefinitions, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { OrganizationsService } from ':core/services/organizations.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { CampaignsHttpErrorPipe } from ':modules/campaigns/pipe/campaigns-http-error.pipe';
import { CampaignsActions } from ':modules/campaigns/store/campaigns.actions';
import { CampaignStatus } from ':modules/campaigns/store/campaigns.interface';
import { ClientsService } from ':modules/clients/clients.service';
import { User } from ':modules/user/user';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { CursorPosition } from ':shared/helpers/text-area-emoji.helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Restaurant } from ':shared/models';
import { Campaign, ClientInteraction, Email } from ':shared/models/campaign';
import { ClientsSource, ContactMode, EnableClient } from ':shared/models/client';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

const EMAIL_DISPLAY_CHARACTERS_LIMIT = {
    desktop: 15,
    mobile: 35,
};

interface CampaignSettingsForm {
    name: string;
    contactMode: ContactMode;
    platformKey: string;
    redirectFrom: string;
}

interface AudienceForm {
    sources: string[];
}

interface CampaignTargetPeopleForm {
    audience: INullableFormGroup<AudienceForm>;
    startDate: Date;
    endDate: Date;
    minDaysFromLastContactedAt: string;
    contactInteractions: ClientInteraction[];
    consent: boolean;
}

interface FromForm {
    name: string;
    email: string;
}

interface ContentForm {
    object: string;
    messageHTML: string;
    from: INullableFormGroup<FromForm>;
}

interface CampaignWriteMessageForm {
    content: INullableFormGroup<ContentForm>;
}

@Component({
    selector: 'app-create-campaign',
    templateUrl: './create-campaign.component.html',
    styleUrls: ['./create-campaign.component.scss'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
        CampaignsHttpErrorPipe,
    ],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        InputDatePickerComponent,
        MalouSpinnerComponent,
        PaginatorComponent,
        PlatformLogoComponent,
        SelectComponent,
        SelectPlatformsComponent,
        SlideToggleComponent,
        TextAreaComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        FormsModule,
        InputTextComponent,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatRippleModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        TranslateModule,
        FormatDatePipe,
        PlatformNamePipe,
        ShortTextPipe,
        IllustrationPathResolverPipe,
    ],
})
@AutoUnsubscribeOnDestroy()
export class CreateCampaignComponent implements OnInit, AfterViewInit, KillSubscriptions {
    @ViewChild('bodySteps', { static: false }) bodySteps: ElementRef<HTMLInputElement>;
    @ViewChild('currentStepBody', { static: false }) currentStepBody: ElementRef<HTMLElement>;

    readonly SvgIcon = SvgIcon;

    restaurant: Restaurant;
    currentStep$ = new BehaviorSubject<number>(1);
    numberOfSteps: number;

    readonly CLIENTS_SOURCE = Object.values(ClientsSource);

    readonly DAYS_RANGE_FOR_CONTACT: string[] = ['10', '20', '30', '45', this.translate.instant('campaigns.new_campaign.never')];

    campaignSettingsForm: INullableFormGroup<CampaignSettingsForm>;
    campaignTargetPeopleForm: INullableFormGroup<CampaignTargetPeopleForm>;
    campaignWriteMessageForm: INullableFormGroup<CampaignWriteMessageForm>;

    displayMessageHTML: string | undefined;
    readonly MAX_LENGTH_MESSAGE = 500;

    readonly NEW_CAMPAIGN_TRANSLATE = this.translate.instant('campaigns.new_campaign');
    platformsForReview: string[];
    contactModesList: string[] = [ContactMode.EMAIL];

    user: User;

    dataSource = new MatTableDataSource<EnableClient>();
    readonly DISPLAYED_COLUMNS: string[] = ['lastName', 'firstName', 'email', 'lastVisitedAt', 'enable'];
    isEmailConfirmed = false;
    warningMessage = this.translate.instant('campaigns.new_campaign.will_need_verif');
    watcherForEmailConfirmationSubscription: Subscription;

    upsertCampaign: (campaign: Campaign) => void;
    removeCampaign: (campaignId: string) => void;

    emailLimitCharactersDisplay = EMAIL_DISPLAY_CHARACTERS_LIMIT.desktop;

    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    textareaElement: HTMLTextAreaElement | null;

    readonly TODAY: Date = DateTime.local().toJSDate();

    readonly isCreatingCampaign = signal<boolean>(false);
    readonly isPhoneScreen = toSignal(this.screenSizeService.isPhoneScreen$, { initialValue: this.screenSizeService.isPhoneScreen });

    displayCloseModal = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            user: User;
            upsertCampaign: (campaign: Campaign) => void;
            removeCampaign: (campaignId: string) => void;
        },
        public readonly screenSizeService: ScreenSizeService,
        private readonly _dialogRef: MatDialogRef<CreateCampaignComponent>,
        public readonly translate: TranslateService,
        private readonly _campaignsService: CampaignsService,
        private readonly _toastService: ToastService,
        private readonly _campaignsHttpErrorPipe: CampaignsHttpErrorPipe,
        private readonly _clientsService: ClientsService,
        private readonly _fb: FormBuilder,
        private readonly _store: Store,
        private readonly _organizationsService: OrganizationsService
    ) {
        this.restaurant = data.restaurant;
        this.user = data.user;
        this.upsertCampaign = this.data.upsertCampaign;
        this.removeCampaign = this.data.removeCampaign;

        this._initCampaignSettingsForm();
        this._initCampaignTargetPeopleForm();
        this._initCampaignWriteMessageForm();
    }

    get object(): string | null | undefined {
        return this.campaignWriteMessageForm.get('content.object')?.value;
    }

    get email(): string | null | undefined {
        return this.campaignWriteMessageForm.get('content.from.email')?.value;
    }

    get name(): string | null | undefined {
        return this.campaignWriteMessageForm.get('content.from.name')?.value;
    }

    get messageHTML(): string | null | undefined {
        return this.campaignWriteMessageForm.get('content.messageHTML')?.value;
    }

    get currentStep(): number {
        return this.currentStep$.value;
    }

    get startDate(): Date | undefined {
        return this.campaignTargetPeopleForm.get('startDate')?.value ?? undefined;
    }

    get endDate(): Date | undefined {
        return this.campaignTargetPeopleForm.get('endDate')?.value ?? undefined;
    }

    get contactInteractions(): ClientInteraction[] | null | undefined {
        return this.campaignTargetPeopleForm.get('contactInteractions')?.value;
    }

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            this.dataSource.sort = sort;
        }
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    displayWithSelectPlatforms = (key: PlatformKey | ClientSource): string => {
        if (key === ClientSource.MANUAL) {
            return this.translate.instant('campaigns.import_manual');
        } else if (key === ClientSource.WHEEL_OF_FORTUNE) {
            return this.translate.instant('campaigns.wheel_of_fortune');
        } else if (key === ClientSource.MALOU) {
            return this.translate.instant('campaigns.new_campaign.sources.malou_file');
        }

        return PlatformDefinitions.getPlatformDefinition(key)?.fullName ?? '';
    };

    ngOnInit(): void {
        this.campaignWriteMessageForm
            .get('content.messageHTML')
            ?.valueChanges.pipe(
                startWith(this.campaignWriteMessageForm.get('content.messageHTML')?.value),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((value) => {
                this.displayMessageHTML = this._mapHTMLOverview(value);
            });

        const connectedPlatformKeys = uniq([PlatformKey.GMB].concat(this.restaurant.access.map((a) => a.platformKey)));
        this.platformsForReview = connectedPlatformKeys.filter(
            (key) => PlatformDefinitions.getPlatformDefinition(key)?.canRedirectToExternalReview
        );

        merge(
            this.campaignTargetPeopleForm.get('startDate')?.valueChanges ?? of(null),
            this.campaignTargetPeopleForm.get('endDate')?.valueChanges ?? of(null),
            this.campaignTargetPeopleForm.get('audience.sources')?.valueChanges ?? of(null),
            this.campaignTargetPeopleForm.get('minDaysFromLastContactedAt')?.valueChanges ?? of(null)
        )
            .pipe(
                startWith(null),
                switchMap(() => {
                    let minDaysFromLastContactedAt = +(this.campaignTargetPeopleForm.get('minDaysFromLastContactedAt')?.value ?? 0);
                    if (isNaN(minDaysFromLastContactedAt)) {
                        minDaysFromLastContactedAt = 0;
                    }
                    const startDate = this.campaignTargetPeopleForm.get('startDate')?.value;
                    const endDate = this.campaignTargetPeopleForm.get('endDate')?.value;
                    const sources = this.campaignTargetPeopleForm.get('audience.sources')?.value;

                    if (startDate && endDate && sources) {
                        return this._clientsService.getClientsBeforeCreatingCampaign(this.restaurant._id, {
                            startDate,
                            endDate,
                            minDaysFromLastContactedAt,
                            accepts: [ContactMode.EMAIL],
                            sources,
                        });
                    } else {
                        return of(null);
                    }
                }),
                filter(Boolean),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((result) => {
                if (result && isApiResultSuccess(result)) {
                    const enabledClients = result.data.map(
                        (client) =>
                            new EnableClient({
                                ...client,
                                enable: true,
                            })
                    );
                    this.dataSource.data = enabledClients;
                    this.campaignTargetPeopleForm
                        .get('contactInteractions')
                        ?.setValue(enabledClients.map((client) => new ClientInteraction({ clientId: client._id })));
                }
            });

        this.campaignWriteMessageForm
            .get('content.from.email')
            ?.valueChanges.pipe(
                startWith(null),
                debounceTime(500),
                filter(() => !!this.email),
                switchMap(() => this._campaignsService.getAWSVerifiedExpeditorEmails({ emails: [this.email as string] })),
                map((res) => res.data),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((awsVerifiedEmails) => {
                this.isEmailConfirmed = awsVerifiedEmails?.[this.email as string];
            });

        const emailControl = this.campaignWriteMessageForm.get('content.from.email') as AbstractControl<string | null, string | null>;

        combineLatest([emailControl.valueChanges.pipe(startWith(this.email)), this.currentStep$])
            .pipe(
                filter(([, step]) => step === 4 && !!this.email),
                distinctUntilChanged(([previousEmail], [currentEmail]) => previousEmail === currentEmail),
                switchMap(() => this._campaignsService.sendVerificationEmail(this.email as string)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe(() => {
                this._startWatcherForEmailConfirmation();
            });

        this.screenSizeService.resize$.pipe(takeUntil(this.killSubscriptions$)).subscribe((elt) => {
            if (elt.size === ScreenSize.IsSmallScreen) {
                this.emailLimitCharactersDisplay = EMAIL_DISPLAY_CHARACTERS_LIMIT.mobile;
            } else {
                this.emailLimitCharactersDisplay = EMAIL_DISPLAY_CHARACTERS_LIMIT.desktop;
            }
        });

        // scroll to top every time the step change
        this.currentStep$.pipe(takeUntil(this.killSubscriptions$)).subscribe(() => {
            if (!this.currentStepBody) {
                return;
            }
            this.currentStepBody.nativeElement.scrollTop = 0;
        });
    }

    ngAfterViewInit(): void {
        this.numberOfSteps = this.bodySteps.nativeElement.childElementCount;
        this.textareaElement = document.querySelector('textarea');
    }

    close(shouldCheckBeforeClose: boolean = false): void {
        if (!this.campaignSettingsForm.dirty && !this.campaignTargetPeopleForm.dirty && !this.campaignWriteMessageForm.dirty) {
            this._dialogRef.close();
            return;
        }
        if (shouldCheckBeforeClose) {
            this.displayCloseModal = true;
        } else {
            this._dialogRef.close();
        }
    }

    next(): void {
        if (this.currentStep$.getValue() < this.numberOfSteps) {
            this.currentStep$.next(this.currentStep$.getValue() + 1);
        }
    }

    previous(): void {
        if (this.currentStep$.getValue() > 1) {
            this.currentStep$.next(this.currentStep$.getValue() - 1);
        }
    }

    displayWith(value: { key: string; translate: string }): string {
        return value.translate.charAt(0).toUpperCase() + value.translate.slice(1);
    }

    displayWithContactMode(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    displayWithPlatformsForReview(value: { key: string; name: string }): string {
        return value.name;
    }

    addChipToMessageHTML(chip: string): void {
        const startPosition = this.textareaElement?.selectionStart;
        const endPosition = this.textareaElement?.selectionEnd;
        if (this.messageHTML && startPosition && endPosition) {
            const cursorPosition: CursorPosition = { startPosition, endPosition };
            const msg = this._replaceTextBetween(this.messageHTML, cursorPosition.startPosition, cursorPosition.endPosition, chip);
            this.campaignWriteMessageForm.get('content.messageHTML')?.patchValue(msg);
        }
    }

    cantGoToStep(step: number): boolean {
        switch (step) {
            case 1:
                return this.campaignSettingsForm.invalid;
            case 2:
                return this.campaignTargetPeopleForm.invalid;
            case 3:
                return this.campaignWriteMessageForm.invalid;
            case 4:
                return !this.isEmailConfirmed;
            default:
                return false;
        }
    }

    goToStep(step: number): void {
        for (let i = 1; i < step; i++) {
            if (this.cantGoToStep(i)) {
                return;
            }
        }
        this.currentStep$.next(step);
    }

    sendTestEmail(): void {
        const emailData = {
            ...this.campaignWriteMessageForm.getRawValue().content,
            messageHTML: this._mapHTMLMessageGeneric(this.messageHTML),
            to: this.user.email,
        };
        const platformKey = this.campaignSettingsForm.get('platformKey')?.value;
        if (platformKey) {
            this._campaignsService.sendTestEmail(this.restaurant._id, platformKey, emailData as Email).subscribe({
                next: () => {
                    this._toastService.openSuccessToast(this.translate.instant('campaigns.new_campaign.email_sent'));
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(this._campaignsHttpErrorPipe.transform(err));
                },
            });
        }
    }

    onToggleEnableClient(client: EnableClient): void {
        const index = this.dataSource.data.findIndex((c) => c._id === client._id);
        this.dataSource.data[index].enable = !this.dataSource.data[index].enable;
        this.dataSource.data = [...this.dataSource.data];
        const enabledContacts = this.dataSource.data.filter((c) => c.enable).map((c) => new ClientInteraction({ clientId: c._id }));
        this.campaignTargetPeopleForm.get('contactInteractions')?.setValue(enabledContacts);
    }

    isLastStep(): boolean {
        return (
            this.currentStep$.getValue() === this.numberOfSteps ||
            (this.currentStep$.getValue() === this.numberOfSteps - 1 && this.isEmailConfirmed)
        );
    }

    createCampaign(): void {
        this.isCreatingCampaign.set(true);
        const campaign = this._mapFormsToCampaign(this.campaignSettingsForm, this.campaignTargetPeopleForm, this.campaignWriteMessageForm);
        if (!campaign) {
            return;
        }
        let createdCampaign: Campaign;
        this._campaignsService
            .createCampaign(campaign)
            .pipe(
                map((res) => res.data),
                switchMap((campaignCreated) => {
                    createdCampaign = campaignCreated;
                    this._handleCampaignCreated(campaignCreated);
                    return this._campaignsService.sendCampaignMails(campaignCreated._id, this.restaurant._id);
                })
            )
            .subscribe({
                next: () => {
                    this._store.dispatch(CampaignsActions.editCampaignStatus({ campaignStatus: CampaignStatus.SENT }));
                },
                error: (error) => {
                    this._handleCampaignError(error, createdCampaign);
                },
            });
    }

    private _initCampaignSettingsForm(): void {
        this.campaignSettingsForm = this._fb.group({
            name: ['', Validators.required],
            contactMode: [ContactMode.EMAIL, Validators.required],
            platformKey: ['', Validators.required],
            redirectFrom: ['', Validators.required],
        });
    }

    private _initCampaignTargetPeopleForm(): void {
        const TODAY = DateTime.now().toJSDate();
        const monthBefore = DateTime.now().minus({ months: 1 }).toJSDate();

        this.campaignTargetPeopleForm = this._fb.group({
            audience: this._fb.group({
                sources: [[] as string[], Validators.required],
            }),
            startDate: [monthBefore, Validators.required],
            endDate: [TODAY, Validators.required],
            minDaysFromLastContactedAt: [this.DAYS_RANGE_FOR_CONTACT[0], Validators.required],
            contactInteractions: [[] as ClientInteraction[], [Validators.required, Validators.minLength(1)]],
            consent: [false, Validators.requiredTrue],
        });
    }

    private _initCampaignWriteMessageForm(): void {
        const defaultMessage = this.translate.instant('campaigns.new_campaign.prefilled_message');

        this.campaignWriteMessageForm = this._fb.group({
            content: this._fb.group({
                object: [this._getDefaultObject(), Validators.required],
                messageHTML: [defaultMessage, Validators.required],
                from: this._fb.group({
                    name: [this._getDefaultFromName(), Validators.required],
                    email: [
                        {
                            value: this.user.email,
                            disabled: true,
                        },
                        [Validators.required, Validators.email],
                    ],
                }),
            }),
        });
    }

    private _getDefaultFromName(): string {
        return `${this.user.name} ${this.NEW_CAMPAIGN_TRANSLATE.from_short.toLowerCase()} ${this.restaurant.name}`;
    }

    private _getDefaultObject(): string {
        return this.translate.instant('campaigns.new_campaign.your_opinion_on', { restaurantName: this.restaurant.name });
    }

    private _mapFormsToCampaign(
        settingsForm: INullableFormGroup<CampaignSettingsForm>,
        targetPeopleForm: INullableFormGroup<CampaignTargetPeopleForm>,
        contentForm: INullableFormGroup<CampaignWriteMessageForm>
    ): Campaign | undefined {
        const settings = settingsForm.getRawValue();
        const targetPeople = targetPeopleForm.getRawValue();
        targetPeople.consent = null;

        const {
            object,
            from: { name, email },
        } = contentForm.getRawValue().content;
        const messageHTML = this._mapHTMLMessageGeneric(contentForm.getRawValue().content.messageHTML);

        if (!object || !name || !email || !messageHTML) {
            return;
        }

        const content = {
            object,
            from: { name, email },
            messageHTML,
        };
        return new Campaign({
            ...this._getSettings(settings),
            ...this._getTargetPeople(targetPeople),
            content,
            restaurantId: this.restaurant._id,
        });
    }

    private _getSettings(settings: {
        name: string | null;
        contactMode: ContactMode | null;
        platformKey: string | null;
        redirectFrom: string | null;
    }): {
        name?: string;
        contactMode?: ContactMode;
        platformKey?: string;
        redirectFrom?: string;
    } {
        return {
            name: settings.name ?? undefined,
            contactMode: settings.contactMode ?? undefined,
            platformKey: settings.platformKey ?? undefined,
            redirectFrom: settings.redirectFrom ?? undefined,
        };
    }

    private _getTargetPeople(targetPeople: {
        audience: {
            sources: string[] | null;
        };
        startDate: Date | null;
        endDate: Date | null;
        minDaysFromLastContactedAt: string | null;
        contactInteractions: ClientInteraction[] | null;
        consent: boolean | null;
    }): {
        audience: {
            sources: string[];
        };
        startDate: Date | undefined;
        endDate: Date | undefined;
        minDaysFromLastContactedAt: string | undefined;
        contactInteractions: ClientInteraction[] | undefined;
        consent: boolean | undefined;
    } {
        return {
            audience: {
                sources: targetPeople.audience.sources ?? [],
            },
            startDate: targetPeople.startDate ?? undefined,
            endDate: targetPeople.endDate ?? undefined,
            minDaysFromLastContactedAt: targetPeople.minDaysFromLastContactedAt ?? undefined,
            contactInteractions: targetPeople.contactInteractions ?? undefined,
            consent: targetPeople.consent === null ? undefined : targetPeople.consent,
        };
    }

    private _startWatcherForEmailConfirmation(): void {
        this.watcherForEmailConfirmationSubscription?.unsubscribe();
        this.watcherForEmailConfirmationSubscription = interval(2000)
            .pipe(
                filter(() => !!this.email),
                switchMap(() => this._campaignsService.getAWSVerifiedExpeditorEmails({ emails: [this.email as string] })),
                map((res) => res.data),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (awsVerifiedEmails) => {
                    const isVerified = awsVerifiedEmails?.[this.email as string];
                    this.isEmailConfirmed = isVerified;
                    if (isVerified) {
                        this._linkVerifiedEmailToOrganization(this.email as string);
                        this.watcherForEmailConfirmationSubscription.unsubscribe();
                    }
                },
            });
    }

    private _linkVerifiedEmailToOrganization(verifiedEmail: string): void {
        if (!this.restaurant.organizationId) {
            return;
        }
        this._organizationsService.linkVerifiedEmailToOrganization(this.restaurant.organizationId, { verifiedEmail }).subscribe({
            error: (err) => console.warn('err :>>', err),
        });
    }

    private _mapHTMLMessageGeneric(message: string | null | undefined): string | undefined {
        const restaurantNameWithLink = this.restaurant.website
            ? `<a style="text-decoration: none; color: #323C47;" \
              href="${this.restaurant.website}"><strong>${this.restaurant.name}</strong></a>`
            : `<span><strong>${this.restaurant.name}</strong></span>`;
        return message
            ?.replace(/\\n/g, '<br>')
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.business_name, 'g'), restaurantNameWithLink)
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.client_firstname, 'g'), ClientVariable.FIRSTNAME)
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.client_lastname, 'g'), ClientVariable.LASTNAME);
    }

    private _mapHTMLOverview(message: string | null | undefined): string | undefined {
        return message
            ?.replace(/\r?\n/g, '<br />')
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.business_name, 'g'), this._addHTMLUnderline(this.restaurant.name))
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.client_firstname, 'g'), this._addHTMLUnderline(this.user?.name))
            .replace(new RegExp(this.NEW_CAMPAIGN_TRANSLATE.client_lastname, 'g'), this._addHTMLUnderline(this.user?.lastname));
    }

    private _addHTMLUnderline(text: string): string {
        return `<u>${text}</u>`;
    }

    private _replaceTextBetween(text: string, startPosition: number, endPosition: number, contentToAdd: string): string {
        const space = startPosition === endPosition ? ' ' : '';
        return text.substring(0, startPosition) + space + contentToAdd + text.substring(endPosition);
    }

    private _handleCampaignCreated(campaign: Campaign): void {
        this.upsertCampaign(campaign);
        this.isCreatingCampaign.set(false);
        this._dialogRef.close();
        this._store.dispatch(CampaignsActions.setFooterVisibility({ isFooterVisible: true }));
        this._store.dispatch(CampaignsActions.editCampaignStatus({ campaignStatus: CampaignStatus.IN_PROGRESS }));
    }

    private _handleCampaignError(error: any, campaign: Campaign): void {
        this.isCreatingCampaign.set(false);
        if (campaign) {
            this.removeCampaign(campaign._id);
        }
        const errorMessage = this._campaignsHttpErrorPipe.transform(error);
        this._store.dispatch(CampaignsActions.editCampaignStatus({ campaignStatus: CampaignStatus.ERROR, error: errorMessage }));
        this._toastService.openErrorToast(errorMessage);
    }
}
