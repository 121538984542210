<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header md:!flex-wrap">
            <span class="order-1 whitespace-nowrap">
                {{ 'information.hours.edit_hours' | translate }}
            </span>
            <div class="order-2 flex w-full justify-end md:order-3 md:mt-2 md:justify-start">
                <mat-checkbox
                    class="malou-text-14--bold malou-color-text-2 mr-5"
                    color="primary"
                    [checked]="isClosedTemporarily"
                    (change)="changeOpenStatus($event)">
                    {{ 'information.hours.close_temporarily' | translate }}
                </mat-checkbox>
            </div>
            <div class="order-3 flex md:order-2">
                <button class="malou-btn-icon" mat-icon-button (click)="close()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        </div>

        <div class="malou-dialog__body" id="hoursModal">
            <mat-tab-group
                animationDuration="5ms"
                mat-align-tabs="start"
                [selectedIndex]="selectedTab"
                (selectedIndexChange)="handleTabChange($event)">
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label> {{ 'information.hours.open' | translate }} </ng-template>
                    <app-business-hours-form
                        [businessHours]="restaurant.regularHours"
                        [isClosedTemporarily]="isClosedTemporarily"
                        (updateHours)="updateRegularHours($event)"
                        (hasError)="handleFormErrors(HoursModalTabs.REGULAR_HOURS, $event)">
                    </app-business-hours-form>
                </mat-tab>
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span [innerHTML]="'information.hours.exceptional' | translate"></span>
                    </ng-template>
                    <app-special-hours-form
                        [rest]="restaurant"
                        [specialHours]="specialHours"
                        [prefilledStartDate]="data.prefilledStartDate"
                        [isClosedTemporarily]="isClosedTemporarily"
                        [showAnteriorHours]="showAnteriorHours"
                        (updateSpecialHours)="updateSpecialHours($event)"
                        (hasError)="handleFormErrors(HoursModalTabs.SPECIAL_HOURS, $event)">
                    </app-special-hours-form>
                </mat-tab>
                <mat-tab>
                    <ng-template class="malou-text-14--regular" mat-tab-label>
                        <span [innerHTML]="'information.hours.other' | translate"></span>
                    </ng-template>
                    <app-other-hours-form
                        [restaurant]="restaurant"
                        (updateHours)="updateOtherHours($event)"
                        (hasError)="handleFormErrors(HoursModalTabs.OTHER_HOURS, $event)">
                    </app-other-hours-form>
                </mat-tab>
            </mat-tab-group>
        </div>

        @if (selectedTab === 1) {
            <div class="malou-dialog__section">
                <button class="malou-btn-flat !p-0" mat-button (click)="showAnteriorHours = !showAnteriorHours">
                    @if (showAnteriorHours) {
                        <span>
                            {{ 'information.special_hours.hide_previous_hours' | translate }}
                        </span>
                    } @else {
                        {{ 'information.special_hours.show_previous_hours' | translate }}
                    }
                </button>
            </div>
        }

        @if (getErrorText(selectedTab).length) {
            <div class="malou-dialog__section">
                <span class="malou-color-state-error malou-text-10--bold text-center">
                    {{ getErrorText(selectedTab) }}
                </span>
            </div>
        }

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            @if (isPlatformsUpdatesReleaseEnabled$ | async) {
                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full"
                    [text]="'common.update' | translate"
                    [disabled]="((doesFormHaveErrors | applyPure: formErrors) && !isClosedTemporarily) || !hasOneDirtyForm"
                    (buttonClick)="save()"></app-button>
            } @else {
                <button
                    class="malou-btn-raised--primary !h-11 md:grow"
                    mat-raised-button
                    [disabled]="((doesFormHaveErrors | applyPure: formErrors) && !isClosedTemporarily) || !hasOneDirtyForm"
                    (click)="save()">
                    {{ 'common.save' | translate }}
                </button>
            }
        </div>
    </div>
</ng-template>
