export enum GiftClaimStartDateOption {
    NOW = 'NOW',
    TOMORROW = 'TOMORROW',
}

export const GiftClaimDurationInDaysOption = [
    2,
    7,
    15,
    30, // 1 month
    60, // 2 months
    182, // 6 months
];
