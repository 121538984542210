import { APP_DEFAULT_LANGUAGE, ApplicationLanguage } from '@malou-io/package-utils';

export namespace LocalStorage {
    export function setLang(lang: ApplicationLanguage): void {
        localStorage.setItem('lang', lang);
    }

    export function getLang(): ApplicationLanguage {
        const lang = localStorage.getItem('lang');
        const availableLangs: string[] = Object.values(ApplicationLanguage);
        return lang && availableLangs.includes(lang) ? (lang as ApplicationLanguage) : APP_DEFAULT_LANGUAGE;
    }
}
