import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, compact, differenceWith } from 'lodash';
import { map } from 'rxjs';

import { PlatformKey } from '@malou-io/package-utils';

import { DEFAULT_CLOSED_TIME_PERIODS } from ':core/constants';
import { OtherHoursService } from ':core/services/other-hours.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { BusinessHoursFormComponent } from ':modules/informations/hours-modal/business-hours-form/business-hours-form.component';
import { INullableFormArray, INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { HoursType, IOtherPeriod, OtherPeriod, Restaurant, TimePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

interface IOtherHoursForm {
    formHours: INullableFormArray<OtherPeriod>;
}

@Component({
    selector: 'app-other-hours-form',
    templateUrl: './other-hours-form.component.html',
    styleUrls: ['./other-hours-form.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatMenuModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        BusinessHoursFormComponent,
        MatExpansionModule,
        MatTooltipModule,
        MatIconModule,
    ],
})
export class OtherHoursFormComponent implements OnInit {
    @Input() restaurant: Restaurant;
    @Output() updateHours = new EventEmitter<{ otherHours: IOtherPeriod[]; availableHoursTypeIds: string[] }>();
    @Output() hasError = new EventEmitter<boolean>();

    readonly SvgIcon = SvgIcon;

    otherHoursForm: INullableFormGroup<IOtherHoursForm>;
    availableHoursTypes: HoursType[] = [];
    hourTypes: HoursType[] = [];
    expandedPanelIndex = -1;
    lang = LocalStorage.getLang();

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _otherHoursService: OtherHoursService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    get formHours(): INullableFormArray<IOtherPeriod> {
        return this.otherHoursForm.get('formHours') as INullableFormArray<IOtherPeriod>;
    }

    ngOnInit(): void {
        this._initForm();

        if (this.restaurant.availableHoursTypes?.length > 0) {
            this.availableHoursTypes = this.restaurant.availableHoursTypes;
            this.hourTypes = differenceWith(
                this.restaurant.availableHoursTypes,
                this.restaurant.otherHours.map((hour) => hour.hoursType),
                (availableHour, otherHour) => availableHour.hoursType === otherHour.hoursType
            );
            this._setForm(this.restaurant.otherHours);
            return;
        }
        this._otherHoursService
            .getHoursTypes(this.restaurant._id, PlatformKey.GMB)
            .pipe(map((res) => res.data))
            .subscribe({
                next: (hourTypes) => {
                    this.availableHoursTypes = hourTypes;
                    this.hourTypes = hourTypes;
                },
                error: (err) => {
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });
    }

    handleFormErrors(hasError: boolean): void {
        this.hasError.emit(hasError);
    }

    updateHoursAtIndex({ regularHours }: { regularHours: TimePeriod[] }, index: number): void {
        this.formHours.controls[index].patchValue({ periods: regularHours }, { emitEvent: false });
        this._emitNewHours();
    }

    addOtherHours(hoursType: HoursType): void {
        const defaultPeriods = cloneDeep([...DEFAULT_CLOSED_TIME_PERIODS]);
        const formGroup: INullableFormGroup<IOtherPeriod> = this._fb.group({
            hoursType: [hoursType],
            periods: [defaultPeriods],
        });
        this.formHours.push(formGroup);
        this.hourTypes = this.hourTypes.filter((hourType) => hourType.hoursType !== hoursType.hoursType);
        setTimeout(() => (this.expandedPanelIndex = this.formHours.controls.length - 1), 50);
    }

    removeHoursAtIndex(index: number): void {
        const hoursType = this.formHours.controls[index].value.hoursType;
        this.formHours.removeAt(index);
        if (hoursType) {
            this.hourTypes.push(hoursType);
        }
        this._emitNewHours();
    }

    private _initForm(): void {
        this._setForm([]);
    }

    private _setForm(otherHours?: OtherPeriod[]): void {
        const formGroups = otherHours?.map((otherHour) =>
            this._fb.group({
                hoursType: [otherHour.hoursType],
                periods: [otherHour.periods],
            })
        );

        this.otherHoursForm = this._fb.group({
            formHours: this._fb.array<FormGroup>(formGroups ?? []) as INullableFormArray<IOtherPeriod>,
        });
    }

    private _emitNewHours(): void {
        this.updateHours.emit({
            otherHours: compact(
                this.otherHoursForm.value.formHours?.map((formHour) =>
                    formHour.hoursType && formHour.periods
                        ? {
                              hoursType: formHour.hoursType,
                              periods: formHour.periods,
                          }
                        : undefined
                ) ?? []
            ),
            availableHoursTypeIds: this.availableHoursTypes.map((hourType) => hourType._id),
        });
    }
}
