import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

@Component({
    selector: 'app-platform-logo',
    templateUrl: './platform-logo.component.html',
    styleUrls: ['./platform-logo.component.scss'],
    standalone: true,
    imports: [NgClass, LazyLoadImageModule, PlatformLogoPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformLogoComponent {
    readonly imgClasses = input<string>('');
    readonly dynamicClasses = input<Record<string, boolean>>({});
    readonly withLazyLoading = input<boolean>(false);
    readonly withBorder = input<boolean>(false);
    readonly testId = input<string>('');
    readonly logo = input.required<string>();
    readonly folder = input<string>('');

    readonly dynamicClassesComputed = computed(() => {
        const dynamicClasses = this.dynamicClasses();
        const withBorder = this.withBorder();
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const withBorderClasses = { 'border-solid border border-malou-color-primary': withBorder };
        return dynamicClasses ? { ...dynamicClasses, ...withBorderClasses } : withBorderClasses;
    });
}
