import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, computed, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EMPTY, forkJoin, map, of, startWith, Subject, switchMap, takeUntil } from 'rxjs';

import { CreateStickerBodyDto, CreateTotemBodyDto, UpdateTotemBodyDto } from '@malou-io/package-dto';
import {
    NfcsPlatformKey,
    NfcStar,
    NfcType,
    PlatformDefinitions,
    PlatformKey,
    WHEEL_OF_FORTUNE_PLATFORM_KEY,
} from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { NfcService } from ':core/services/nfc.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectRestaurantsComponent } from ':shared/components/select-restaurants/select-restaurants.component';
import { SelectStarWithNumberComponent } from ':shared/components/select-star-with-number/select-star-with-number.component';
import { SelectTemplatesComponent } from ':shared/components/select-templates/select-templates.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { NfcDisplayMode } from ':shared/components/shared-nfc/shared-nfc.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { IFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Nfc, NfcRestaurant, NfcWithStats, Platform, PlatformComparisonWithStatus, Restaurant } from ':shared/models';
import { WheelOfFortune } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';

enum UpsertedStatus {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
}

export enum NfcUpsertFieldName {
    CHIP_NAME = 'chipName',
    RESTAURANT = 'restaurant',
    ACTIVE = 'active',
    NAME = 'name',
    PLATFORM_KEY = 'platformKey',
    STARS_REDIRECTED = 'starsRedirected',
    NOTES = 'notes',
}

interface NfcForm {
    chipName: string;
    restaurant: Restaurant;
    name?: string;
    platformKey?: NfcsPlatformKey;
    starsRedirected: NfcStar[];
    notes?: string;
}

interface PlatformKeyWithNfcLink {
    key: NfcsPlatformKey;
    nfcLink: string;
}

const DEFAULT_STARS_REDIRECTED = [NfcStar.FOUR, NfcStar.FIVE];

@Component({
    selector: 'app-upsert-nfc-modal',
    templateUrl: './upsert-nfc-modal.component.html',
    styleUrls: ['./upsert-nfc-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        TranslateModule,
        InputTextComponent,
        SlideToggleComponent,
        SelectComponent,
        SelectRestaurantsComponent,
        CloseWithoutSavingModalComponent,
        TextAreaComponent,
        SelectTemplatesComponent,
        SelectStarWithNumberComponent,
        ApplySelfPurePipe,
        IncludesPipe,
        PlatformLogoComponent,
        EnumTranslatePipe,
    ],
})
export class UpsertNfcModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject<void>();

    readonly nfc = signal<Nfc | undefined>(undefined);
    displayMode: NfcDisplayMode;
    readonly isTotem = signal(false);
    upsertableFieldNames: NfcUpsertFieldName[] = [];
    nfcForm: IFormGroup<NfcForm>;
    platformKeyWithNfcLinks: PlatformKeyWithNfcLink[];
    platformKeys: NfcsPlatformKey[] = [];
    readonly restaurants = signal<Restaurant[]>([]);
    readonly isLoadingRestaurants = signal(true);
    active = false;
    displayCloseModal = false;

    readonly STARS_REDIRECTED_VALUES = [NfcStar.ONE, NfcStar.TWO, NfcStar.THREE, NfcStar.FOUR, NfcStar.FIVE];
    readonly NfcUpsertFieldName = NfcUpsertFieldName;
    title = '';
    readonly NfcDisplayMode = NfcDisplayMode;

    previousCanBeRated: boolean | undefined;

    readonly activeWheelOfFortune: WritableSignal<WheelOfFortune | null> = signal(null);
    readonly WHEEL_OF_FORTUNE: string = WHEEL_OF_FORTUNE_PLATFORM_KEY;

    readonly isEditingSticker = computed(() => this.nfc()?.isSticker());
    readonly isCreatingSticker = computed(() => !this.nfc() && !this.isTotem());
    readonly isUpsertingSticker = computed(() => this.isEditingSticker() || this.isCreatingSticker());

    readonly allRestaurantsAlreadyHaveSticker = computed(
        () => !this.isLoadingRestaurants() && this.isCreatingSticker() && this.restaurants().length === 0
    );

    constructor(
        public dialogRef: MatDialogRef<UpsertNfcModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            nfc: NfcWithStats;
            displayMode: NfcDisplayMode;
            isTotem: boolean;
        },
        private readonly _fb: FormBuilder,
        private readonly _translate: TranslateService,
        private readonly _platformService: PlatformsService,
        private readonly _nfcService: NfcService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _dialogService: DialogService,
        private readonly _toastService: ToastService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService,
        public screenSizeService: ScreenSizeService
    ) {
        this.nfc.set(data.nfc);
        this.displayMode = data.displayMode;
        this.isTotem.set(data.isTotem);
    }

    ngOnInit(): void {
        const nfc = this.nfc();

        switch (this.displayMode) {
            case NfcDisplayMode.ADMIN:
                this.upsertableFieldNames = this.isEditingSticker()
                    ? [
                          NfcUpsertFieldName.CHIP_NAME,
                          NfcUpsertFieldName.ACTIVE,
                          NfcUpsertFieldName.PLATFORM_KEY,
                          NfcUpsertFieldName.STARS_REDIRECTED,
                      ]
                    : [
                          NfcUpsertFieldName.CHIP_NAME,
                          NfcUpsertFieldName.RESTAURANT,
                          NfcUpsertFieldName.ACTIVE,
                          NfcUpsertFieldName.PLATFORM_KEY,
                          NfcUpsertFieldName.STARS_REDIRECTED,
                      ];
                break;
            case NfcDisplayMode.BASIC:
                this.upsertableFieldNames = this.isUpsertingSticker()
                    ? [NfcUpsertFieldName.PLATFORM_KEY, NfcUpsertFieldName.STARS_REDIRECTED, NfcUpsertFieldName.NOTES]
                    : [
                          NfcUpsertFieldName.NAME,
                          NfcUpsertFieldName.PLATFORM_KEY,
                          NfcUpsertFieldName.STARS_REDIRECTED,
                          NfcUpsertFieldName.NOTES,
                      ];
                break;
        }

        const editTitleName = (this.displayMode === NfcDisplayMode.ADMIN ? nfc?.chipName : nfc?.name) ?? '';
        this.title = this.isUpsertingSticker()
            ? this.nfc()
                ? this._translate.instant('admin.nfcs.edit_sticker')
                : this._translate.instant('admin.nfcs.add_sticker')
            : this.nfc()
              ? this._translate.instant('admin.nfcs.edit_nfc_chip', { name: editTitleName })
              : this._translate.instant('admin.nfcs.add_nfc_chip');

        this.nfcForm = this._initNfcForm(this.nfc());
        this.active = this.nfc() ? (this.nfc()?.active ?? false) : true;

        if (this.isTotem()) {
            this._restaurantsService.all({ active: true }, ['name', 'type', 'address']).subscribe((result) => {
                this.restaurants.set(result.data);
                this.isLoadingRestaurants.set(false);
            });
        } else if (this.isCreatingSticker()) {
            this._restaurantsService.getRestaurantsWithoutSticker().subscribe((restaurants) => {
                this.restaurants.set(restaurants);
                this.isLoadingRestaurants.set(false);
            });
        } else {
            this.isLoadingRestaurants.set(false);
        }

        this._updatePlatformsOnRestaurantChange();

        this.previousCanBeRated = !!nfc?.platformKey && this._canBeRated(nfc.platformKey);

        this.nfcForm.get('platformKey')?.valueChanges.subscribe((platformKey: NfcsPlatformKey | undefined | null) => {
            const canBeRated = !!platformKey && this._canBeRated(platformKey);
            if (canBeRated) {
                this.nfcForm.get('starsRedirected')?.enable();
                if (this.previousCanBeRated === false) {
                    this.nfcForm
                        .get('starsRedirected')
                        ?.setValue(this.data.nfc ? (this.data.nfc.starsRedirected ?? []) : DEFAULT_STARS_REDIRECTED);
                }
            } else {
                this.nfcForm.get('starsRedirected')?.disable();
                this.nfcForm.get('starsRedirected')?.setValue([]);
            }
            this.previousCanBeRated = canBeRated;
        });
    }

    selected(restaurant: Restaurant): void {
        if (!restaurant) {
            return;
        }
        this.nfcForm.get('restaurant')?.setValue(restaurant);
    }

    toggleActive(event: any): void {
        this.active = event;
    }

    submit(): void {
        if (!this.nfcForm.valid) {
            return;
        }

        const chipName = this.nfcForm.value.chipName?.trim();
        const restaurant = this.nfcForm.value.restaurant;
        const platformKeyWithNfcLink = this.platformKeyWithNfcLinks.find((p) => p.key === this.nfcForm.value.platformKey);
        const starsRedirected = this.nfcForm.value.starsRedirected;
        const platformKey = this.nfcForm.value.platformKey;

        if (!restaurant || !platformKeyWithNfcLink || !platformKey) {
            return;
        }

        const newNfc = {
            chipName,
            restaurantId: restaurant.id,
            active: this.active,
            name: this.nfcForm.value.name ?? chipName,
            redirectionLink: platformKeyWithNfcLink.nfcLink,
            notes: this.nfcForm.value.notes,
            starsRedirected: starsRedirected ?? [],
            platformKey,
        };

        if (this.nfcForm.value.platformKey !== WHEEL_OF_FORTUNE_PLATFORM_KEY && this.nfcForm.value.platformKey) {
            newNfc.platformKey = this.nfcForm.value.platformKey;
        }

        const oldNfc = this.nfc();
        if (oldNfc) {
            this._patchNfc(oldNfc.id, newNfc);
        } else {
            if (this.isTotem()) {
                this._createTotem(newNfc as CreateTotemBodyDto);
            } else {
                this._createSticker(newNfc as CreateStickerBodyDto);
            }
        }
    }

    close({ data, shouldCheckChangesBeforeClose }: { data?: any; shouldCheckChangesBeforeClose?: boolean } = {}): void {
        const nfc = this.nfc();
        if (!nfc || !shouldCheckChangesBeforeClose) {
            this.dialogRef.close(data);
            return;
        }
        this.displayCloseModal = this._checkChangesBeforeClose(this.nfcForm.value, nfc);
        if (!this.displayCloseModal) {
            this.dialogRef.close(data);
            return;
        }
    }

    compareByRestaurantId(restaurant: NfcRestaurant): string {
        return restaurant.id;
    }

    private _updatePlatformsOnRestaurantChange(): void {
        let firstEmit = true;
        this.nfcForm
            .get('restaurant')
            ?.valueChanges.pipe(
                startWith(this.nfcForm.get('restaurant')?.value),
                switchMap((restaurant) => {
                    if (!restaurant?.id) {
                        this.nfcForm.get('platformKey')?.setValue(undefined);
                        this.nfcForm.get('platformKey')?.disable();
                        return EMPTY;
                    }
                    return this._restaurantsService.show(restaurant.id);
                }),
                switchMap(({ data: restaurantWithFullProperties }) =>
                    forkJoin([
                        of(restaurantWithFullProperties),
                        this._wheelsOfFortuneService.getActiveWheel(restaurantWithFullProperties._id),
                    ])
                ),
                switchMap(([restaurantWithFullProperties, activeWheelOfRestaurant]) =>
                    forkJoin([
                        of(restaurantWithFullProperties),
                        of(activeWheelOfRestaurant),
                        this._platformService.getPlatformsForRestaurant(restaurantWithFullProperties._id),
                    ])
                ),
                map(([restaurantWithFullProperties, activeWheelOfRestaurant, { data: platforms }]) => {
                    const connectedPlatforms = this._getConnectedPlatforms(platforms, restaurantWithFullProperties);
                    const platformKeyWithNfcLinks: PlatformKeyWithNfcLink[] = connectedPlatforms
                        .filter((platform) => !!platform.getNfcRedirectionLink())
                        .map((platform) => ({
                            key: platform.key,
                            nfcLink: platform.getNfcRedirectionLink() as string,
                        }));
                    const gmbPlatform: Platform | undefined = connectedPlatforms.find((p) => p.key === PlatformKey.GMB);
                    if (gmbPlatform?.website) {
                        platformKeyWithNfcLinks.push({
                            key: PlatformKey.WEBSITE,
                            nfcLink: gmbPlatform.website,
                        });
                    }
                    const activeWheelOfFortune = activeWheelOfRestaurant.data ? new WheelOfFortune(activeWheelOfRestaurant.data) : null;
                    this.activeWheelOfFortune.set(activeWheelOfFortune);
                    if (activeWheelOfFortune) {
                        const redirectionLink = activeWheelOfFortune.getWheelOfFortuneUrlForRestaurant({
                            restaurantId: restaurantWithFullProperties.id,
                        });
                        if (redirectionLink) {
                            platformKeyWithNfcLinks.push({
                                key: WHEEL_OF_FORTUNE_PLATFORM_KEY,
                                nfcLink: redirectionLink,
                            });
                        }
                    }
                    return platformKeyWithNfcLinks;
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe((platformKeyWithNfcLinks: PlatformKeyWithNfcLink[]) => {
                this.platformKeyWithNfcLinks = platformKeyWithNfcLinks ?? [];
                this.platformKeys = platformKeyWithNfcLinks.map((p) => p.key);
                // if first emitting and nfc has already a platformKey, we don't want to change the value
                if (firstEmit && this.nfc()?.platformKey) {
                    firstEmit = false;
                    return;
                }
                const gmbPlatformKey = this.platformKeys.find((p) => p === PlatformKey.GMB);
                this.nfcForm.get('platformKey')?.setValue(gmbPlatformKey ?? platformKeyWithNfcLinks[0]?.key);
                if (platformKeyWithNfcLinks.length === 0) {
                    this.nfcForm.get('platformKey')?.disable();
                } else {
                    this.nfcForm.get('platformKey')?.enable();
                }
            });
    }

    private _initNfcForm(nfc?: Nfc): IFormGroup<NfcForm> {
        const canBeRated =
            !!nfc?.platformKey && this._canBeRated(nfc?.isRedirectingToWheelOfFortune() ? WHEEL_OF_FORTUNE_PLATFORM_KEY : nfc?.platformKey);
        const starsRedirected = nfc ? (nfc?.starsRedirected ?? []) : DEFAULT_STARS_REDIRECTED;

        const chipNameControl = this.isTotem() ? [nfc?.chipName || '', Validators.required] : [nfc?.chipName || ''];

        // @ts-ignore
        return this._fb.group({
            chipName: chipNameControl,
            restaurant: [new Restaurant(nfc?.restaurant), Validators.required],
            name: [nfc?.name],
            platformKey: [nfc?.isRedirectingToWheelOfFortune() ? WHEEL_OF_FORTUNE_PLATFORM_KEY : nfc?.platformKey],
            starsRedirected: [
                {
                    value: canBeRated ? starsRedirected : [],
                    disabled: !canBeRated,
                },
            ],
            notes: [nfc?.notes],
        });
    }

    private _canBeRated(platformKey: NfcsPlatformKey): boolean {
        return (
            platformKey !== WHEEL_OF_FORTUNE_PLATFORM_KEY && !PlatformDefinitions.getPlatformKeysNotRatedForTotems().includes(platformKey)
        );
    }

    private _getConnectedPlatforms(platforms: Platform[], restaurantWithFullProperties: Restaurant): Platform[] {
        return platforms
            .filter((platform) => platform.hasReviewRedirectionLink())
            .map(
                (platform) =>
                    new PlatformComparisonWithStatus(restaurantWithFullProperties, platform, {
                        key: platform.key,
                        fullName: platform.key,
                    })
            )
            .filter((platformComparisonWithStatus) => platformComparisonWithStatus.isPlatformAccessActive)
            .map((platformComparisonWithStatus) => new Platform(platformComparisonWithStatus.platformData ?? undefined));
    }

    private _createTotem(create: CreateTotemBodyDto): void {
        this._nfcService.createTotem(create).subscribe({
            next: (apiResult) => {
                const nfc = Nfc.fromNfcDto(apiResult.data);
                this._handleUpsertedNfc(UpsertedStatus.CREATED, nfc);
            },
            error: (error) => {
                if (error?.error?.message?.match(/Duplicate/)) {
                    this._handleDuplicateError();
                } else {
                    this._handleDefaultError(error);
                }
            },
        });
    }

    private _createSticker(create: CreateStickerBodyDto): void {
        this._nfcService.createSticker(create).subscribe({
            next: (apiResult) => {
                const nfc = Nfc.fromNfcDto(apiResult.data);
                this._handleUpsertedNfc(UpsertedStatus.CREATED, nfc);
            },
            error: (error) => {
                if (error?.error?.message?.match(/Duplicate/)) {
                    this._handleDuplicateError();
                } else {
                    this._handleDefaultError(error);
                }
            },
        });
    }

    private _patchNfc(id: string, patch: UpdateTotemBodyDto): void {
        const nfcType = this.isTotem() ? NfcType.TOTEM : NfcType.STICKER;
        const hasRemovedTotem =
            this.nfc()?.isRedirectingToWheelOfFortune() && !Nfc.fromNfcPatchBodyDto(patch, nfcType).isRedirectingToWheelOfFortune();
        const hasAddedTotem =
            !this.nfc()?.isRedirectingToWheelOfFortune() && Nfc.fromNfcPatchBodyDto(patch, nfcType).isRedirectingToWheelOfFortune();

        const totemIds = this.activeWheelOfFortune()?.totemIds || [];
        if (hasRemovedTotem) {
            totemIds?.splice(totemIds.findIndex((totemId) => totemId === id));
        } else if (hasAddedTotem) {
            totemIds.push(id);
        }

        const activeWheel = this.activeWheelOfFortune();
        const updateWheelOfFortuneTotemRedirection$ =
            activeWheel?.id && (hasRemovedTotem || hasAddedTotem)
                ? this._wheelsOfFortuneService.updateTotemIds(activeWheel?.id, totemIds)
                : of({ data: undefined });

        updateWheelOfFortuneTotemRedirection$
            .pipe(
                switchMap(() =>
                    this.nfc()?.isTotem() ? this._nfcService.updateTotem(id, patch) : this._nfcService.updateSticker(id, patch)
                )
            )
            .subscribe({
                next: (apiResult) => {
                    const nfc = Nfc.fromNfcDto(apiResult.data);
                    this._handleUpsertedNfc(UpsertedStatus.UPDATED, nfc);
                },
                error: (error) => {
                    if (error?.error?.message?.match(/Duplicate/)) {
                        this._handleDuplicateError();
                    } else {
                        this._handleDefaultError(error);
                    }
                },
            });
    }

    private _handleUpsertedNfc(status: UpsertedStatus, nfc: Nfc): void {
        let message = '';

        if (this.isTotem()) {
            message =
                status === UpsertedStatus.CREATED
                    ? this._translate.instant('admin.nfcs.nfc_succesfully_created')
                    : this._translate.instant('admin.nfcs.nfc_succesfully_updated');
        } else {
            message =
                status === UpsertedStatus.CREATED
                    ? this._translate.instant('admin.nfcs.sticker_succesfully_created')
                    : this._translate.instant('admin.nfcs.sticker_succesfully_updated');
        }
        this._toastService.openSuccessToast(message);
        this.close({ data: nfc, shouldCheckChangesBeforeClose: false });
    }

    private _handleDuplicateError(): void {
        this._dialogService.open({
            title: this.isTotem()
                ? this._translate.instant('admin.nfcs.nfc_already_exist')
                : this._translate.instant('admin.nfcs.sticker_already_exist'),
            variant: DialogVariant.ALERT,
            primaryButton: {
                label: this._translate.instant('common.close'),
            },
        });
    }

    private _handleDefaultError(error: any): void {
        console.warn(error);
        this._dialogService.open({
            title: this._translate.instant('admin.status_not_ok'),
            variant: DialogVariant.ERROR,
            primaryButton: {
                label: this._translate.instant('common.close'),
            },
        });
    }

    private _checkChangesBeforeClose(nfcFormValue: Partial<NfcForm>, nfc: Nfc): boolean {
        return (
            nfcFormValue?.chipName !== nfc.chipName ||
            nfcFormValue?.name !== nfc.name ||
            nfcFormValue?.restaurant?.id !== nfc.restaurantId ||
            nfcFormValue?.platformKey !== nfc.platformKey ||
            this.active !== nfc.active
        );
    }
}
