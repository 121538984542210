import { z } from 'zod';

import { Locale } from '@malou-io/package-utils';

import { basicReviewValidator } from '../reviews';

const baseEmailValidator = z.object({
    locale: z.nativeEnum(Locale),
    receiver: z.string().optional(),
    trackingUrl: z.string().optional(),
    link: z.string(),
    unsubscribeLink: z.string().optional(),
});

type BaseEmailProps = z.infer<typeof baseEmailValidator>;

// ----------------------------------------------------------

export const reviewReminderEmailNotificationValidator = z.object({
    review: basicReviewValidator,
    restaurantName: z.string(),
    unansweredReviewsCount: z.number(),
    remainingUnansweredReviews: z.array(
        z.object({
            restaurantName: z.string(),
            unansweredReviewsCount: z.number(),
        })
    ),
});

export type ReviewReminderEmailNotificationProps = z.infer<typeof reviewReminderEmailNotificationValidator> & BaseEmailProps;

// ----------------------------------------------------------

export const specialHourReminderEmailNotificationValidator = z.object({
    eventStartDate: z.date(),
    eventEmoji: z.string(),
    eventName: z.string(),
});

export type SpecialHourReminderEmailNotificationProps = z.infer<typeof specialHourReminderEmailNotificationValidator> & BaseEmailProps;

// ----------------------------------------------------------

export const postEventReminderEmailNotificationValidator = z.object({
    eventEmoji: z.string(),
    eventName: z.string(),
    restaurantsNames: z.array(z.string()),
});

export type PostEventReminderEmailNotificationProps = z.infer<typeof postEventReminderEmailNotificationValidator> & BaseEmailProps;
