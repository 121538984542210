<div
    class="malou-box-shadow fixed right-0 top-0 z-10 h-full w-[25vw] max-w-[400px] bg-white transition-all duration-300 ease-in-out"
    [ngClass]="{ 'translate-x-[100%]': !notificationCenterContext.isOpen() }"
    (click)="$event.stopPropagation()">
    <div class="flex h-full flex-col">
        <div class="flex flex-1 items-center justify-between px-5 pt-3">
            <h3 class="malou-text-16--bold">
                {{ 'common.notifications' | translate }} ({{ notificationCenterContext.unreadNotificationsCount() }})
            </h3>
            <div class="flex items-center">
                <button class="malou-btn-icon" mat-icon-button [matMenuTriggerFor]="actionsMenu">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.ELLIPSIS"></mat-icon>
                </button>
                <mat-menu class="malou-mat-menu malou-box-shadow !rounded-md" #actionsMenu="matMenu">
                    <button class="flex gap-x-3" mat-menu-item (click)="notificationCenterContext.markAllAsRead()">
                        <span class="malou-text-12--semibold">{{ 'notification-center.mark_all_as_read' | translate }}</span>
                    </button>
                </mat-menu>
                <button class="malou-btn-icon" mat-icon-button [matMenuTriggerFor]="restaurantsMenu">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
                </button>
                <mat-menu class="malou-mat-menu malou-box-shadow w-[350px] !rounded-md" #restaurantsMenu="matMenu">
                    <div class="malou-text-16--bold p-3 pb-0">{{ 'app_select_restaurants.your_business' | translate }}</div>
                    <hr class="mt-2 border-malou-color-border-primary" />

                    <div class="hide-scrollbar max-h-[400px] min-h-0 w-full grow overflow-y-auto">
                        @for (restaurant of userRestaurants(); track $index) {
                            <button
                                class="w-full"
                                mat-menu-item
                                (click)="$event.stopPropagation(); toggleRestaurantFilterItem(restaurant.id)">
                                <div class="flex w-full items-center justify-between">
                                    <div class="malou-text-12--regular flex max-w-[80%] items-center">
                                        <!-- without this span element, user will lose focus on the text field on some occasions -->
                                        <span class="opacity-0">l</span>
                                        <img
                                            class="malou-avatar--small"
                                            [lazyLoad]="
                                                restaurant?.logo?.getMediaUrl('small') ?? ('Grey-burger' | illustrationPathResolver)
                                            " />
                                        <div class="ml-3 flex min-w-0 grow flex-col leading-4">
                                            <span class="text-malou-color-text-1">{{ restaurant?.internalName }}</span>
                                            <span class="malou-text-10--regular truncate font-normal italic text-malou-color-text-2">{{
                                                restaurant.isBrandBusiness()
                                                    ? ('common.brand_account' | translate)
                                                    : restaurant.getFullFormattedAddress()
                                            }}</span>
                                        </div>
                                    </div>
                                    <app-noop-mat-checkbox
                                        color="primary"
                                        classInput="malou-text-12--semibold"
                                        [checked]="
                                            isRestaurantFilterChecked
                                                | applyPure: restaurant.id : notificationFilters()?.restaurantIds ?? []
                                        ">
                                    </app-noop-mat-checkbox>
                                </div>
                            </button>
                        }
                    </div>
                </mat-menu>
                <button class="malou-btn-icon" mat-icon-button (click)="notificationCenterContext.close()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        </div>
        <div
            class="hide-scrollbar flex flex-[12] flex-col overflow-y-auto"
            infinite-scroll
            [infiniteScrollDistance]="2"
            [infiniteScrollContainer]="scrollContainer"
            (scrolled)="onScroll()"
            #scrollContainer>
            @for (roiNotification of roiNotifications(); track $index) {
                <div class="notification-item">
                    <app-roi-notification-item
                        [notification]="roiNotification.id"
                        [receptionDate]="roiNotification.date"></app-roi-notification-item>
                </div>
            }

            @for (notification of notificationCenterContext.notifications(); track notification.id) {
                @if (
                    (areAllPreviousNotificationsRead | applyPure: notification : notificationCenterContext.notifications()) &&
                    notification.isRead()
                ) {
                    <div
                        class="malou-text-10 flex w-full items-center justify-center border-b border-malou-color-border-primary px-5 py-4 text-malou-color-text-2 transition-all">
                        {{ 'notification-center.up_to_date' | translate }}
                    </div>
                }
                <div
                    class="notification-item"
                    [ngClass]="{
                        'opacity-50': notification.isRead(),
                    }">
                    <app-notification-item-actions
                        class="absolute right-5 top-5"
                        [notification]="notification"></app-notification-item-actions>
                    @switch (notification.type) {
                        @case (NotificationType.REVIEW_REPLY_REMINDER) {
                            <app-negative-review-reminder-notification-item
                                [initialNotification]="notification"></app-negative-review-reminder-notification-item>
                        }
                        @case (NotificationType.REVIEW) {
                            <app-review-notification-item [initialNotification]="notification"></app-review-notification-item>
                        }
                        @case (NotificationType.POST_SUGGESTION) {
                            <app-post-suggestion-notification-item
                                [initialNotification]="notification"></app-post-suggestion-notification-item>
                        }
                        @default {
                            <app-special-hour-notification-item [initialNotification]="notification"></app-special-hour-notification-item>
                        }
                    }
                </div>
            }

            @if (loading()) {
                <div
                    class="flex w-full items-center justify-center py-2"
                    [ngClass]="{
                        'h-full': notificationCenterContext.notifications().length === 0,
                    }">
                    <app-malou-spinner size="small"></app-malou-spinner>
                </div>
            }
        </div>
    </div>
</div>
