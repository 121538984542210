import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { ReviewNotification } from ':core/components/notification-center/models/review-notification.model';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Restaurant } from ':shared/models';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-review-notification-item',
    templateUrl: './review-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, ImagePathResolverPipe, PluralTranslatePipe, MatButtonModule],
})
export class ReviewNotificationItemComponent extends NotificationItemComponent {
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _router = inject(Router);
    private readonly _notificationsCenterContext = inject(NotificationCenterContext);

    readonly notification = computed(() => this.initialNotification() as ReviewNotification);
    readonly restaurant = computed(() =>
        this._restaurantService
            .restaurants()
            .find((restaurant: Restaurant) => restaurant.id === this.notification()?.data?.restaurantIds?.[0])
    );

    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);

    onClick(): void {
        this.markAsRead();
    }

    onNotificationClick(): void {
        this.markAsRead();
        if (this.notification().data.restaurantIds.length > 1) {
            this._navigateToAggregatedReviewsPage();
        } else {
            this._navigateToReviewsPage();
        }
        this._notificationsCenterContext.close();
    }

    private _navigateToReviewsPage(): void {
        this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'reputation', 'reviews']);
    }

    private _navigateToAggregatedReviewsPage(): void {
        this._router.navigate(['groups', 'reputation', 'reviews']);
    }
}
