import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { isNumber } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApplicationLanguage, KeywordScoreTextType } from '@malou-io/package-utils';

import { DescriptionsSizes } from ':core/constants';
import { ExperimentationService } from ':core/services/experimentation.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji, isEmojiEvent } from ':shared/helpers/text-area-emoji.helpers';
import { Description, DescriptionSize, Keyword, KeywordAnalysis, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';

export interface DescriptionUpdateData {
    descriptions?: Partial<Description>[];
}

export enum DescriptionModalTabs {
    LONG_DESCRIPTION,
    SHORT_DESCRIPTION,
}

const descriptionTypes = {
    long: {
        maxLength: DescriptionsSizes.LONG.maxLength,
        name: KeywordScoreTextType.LONG_DESCRIPTION,
    },
    short: {
        maxLength: DescriptionsSizes.SHORT.maxLength,
        name: KeywordScoreTextType.SHORT_DESCRIPTION,
    },
};

@Component({
    selector: 'app-description-modal',
    templateUrl: './description-modal.component.html',
    styleUrls: ['./description-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        TranslateModule,
        NgClass,
        NgTemplateOutlet,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        TextAreaComponent,
        KeywordsScoreGaugeComponent,
        AsyncPipe,
    ],
})
export class DescriptionModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    @ViewChild('keywordsScoreGauge') keywordsScoreGauge: KeywordsScoreGaugeComponent;

    readonly TEXT_AREA_ID = 'description-text-area';

    infoForm: UntypedFormGroup = this._fb.group({
        short: this._fb.group({
            descriptionFr: '',
            descriptionEn: '',
        }),
        long: this._fb.group({
            descriptionFr: '',
            descriptionEn: '',
        }),
    });
    maxDescriptionLength = 0;
    textType$ = new BehaviorSubject(KeywordScoreTextType.LONG_DESCRIPTION);
    restaurant$ = this._restaurantService.restaurantSelected$;
    restaurantKeywords$: Observable<Keyword[]>;
    descriptionText$: BehaviorSubject<string>;
    displayCloseModal = false;
    descriptionTypes = descriptionTypes;
    showLong = true;
    selectedTab = DescriptionModalTabs.LONG_DESCRIPTION;
    DescriptionModalTabs = DescriptionModalTabs;

    readonly isPlatformsUpdatesReleaseEnabled$ = inject(ExperimentationService).isFeatureEnabled$('release-platforms-updates');

    constructor(
        private readonly _dialogRef: MatDialogRef<DescriptionModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            selectedTab: DescriptionModalTabs;
        },
        private readonly _fb: UntypedFormBuilder,
        private readonly _store: Store,
        private readonly _restaurantService: RestaurantsService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.selectedTab = data.selectedTab;
    }

    ngOnInit(): void {
        const {
            restaurant: { descriptions },
        } = this.data;
        if (this.data.restaurant.isBrandBusiness()) {
            this.showLong = false;
            this.selectedTab = DescriptionModalTabs.SHORT_DESCRIPTION;
        }
        this.descriptionText$ = new BehaviorSubject(this.getCurrentFormControl().value);
        this.initDescriptions(descriptions);

        this.restaurantKeywords$ = this._store.select(selectCurrentKeywords);
    }

    initDescriptions(descriptions: Partial<Description>[]): void {
        this._initDescriptionWithSize(descriptions, DescriptionSize.SHORT);
        this._initDescriptionWithSize(descriptions, DescriptionSize.LONG);

        this.descriptionText$.next(this.getCurrentFormControl().value);
        const size = this._getDescriptionSizeFromDescriptionModalTabs(this.selectedTab);
        this.getSizeFormGroup(size).valueChanges.subscribe((value) => {
            const textFr = value.descriptionFr;
            const textEn = value.descriptionEn;
            this.descriptionText$.next(textEn || textFr);
        });

        this.maxDescriptionLength = descriptionTypes[size].maxLength;
        this.textType$.next(descriptionTypes[size].name);
    }

    addKeyword(keyword: string): void {
        if (this.isTextLimitReached()) {
            return;
        }
        this._addElementToText(keyword);
    }

    getSizeFormGroup(size: DescriptionSize): FormGroup {
        return this.infoForm.get(size) as FormGroup;
    }

    getCurrentFormControlName(): string {
        const size = this._getDescriptionSizeFromDescriptionModalTabs(this.selectedTab);

        return !(this.infoForm.get(size) as FormGroup).get('descriptionFr')?.value?.length &&
            (this.infoForm.get(size) as FormGroup).get('descriptionEn')?.value?.length
            ? `${size}.descriptionEn`
            : `${size}.descriptionFr`;
    }

    getCurrentDescriptionNameFromLanguage(): string {
        const size = this._getDescriptionSizeFromDescriptionModalTabs(this.selectedTab);

        return !(this.infoForm.get(size) as FormGroup).get('descriptionFr')?.value?.length &&
            (this.infoForm.get(size) as FormGroup).get('descriptionEn')?.value?.length
            ? 'descriptionEn'
            : 'descriptionFr';
    }

    getCurrentFormControl(): FormControl {
        const size = this._getDescriptionSizeFromDescriptionModalTabs(this.selectedTab);

        return (this.infoForm.get(size) as FormGroup).get(this.getCurrentDescriptionNameFromLanguage()) as FormControl;
    }

    isTextLimitReached(): boolean {
        return this.infoForm.get(this.getCurrentFormControlName())?.value?.length >= this.maxDescriptionLength;
    }

    getCurrentLength(): string {
        const currentCount = this.infoForm.get(this.getCurrentFormControlName())?.value?.length || 0;
        return currentCount + '/' + this.maxDescriptionLength;
    }

    save(): void {
        if (!this.infoForm.valid) {
            return;
        }

        const descriptions = this.getCurrentDescriptions();
        const data: DescriptionUpdateData = {
            descriptions: descriptions,
        };
        this.confirmClose(data);
    }

    getCurrentDescriptions(): Partial<Description>[] {
        const otherSize = this.selectedTab === DescriptionModalTabs.LONG_DESCRIPTION ? DescriptionSize.SHORT : DescriptionSize.LONG;
        const keywordAnalysis: KeywordAnalysis | undefined = this.keywordsScoreGauge?.bricksFound()
            ? {
                  score: this.keywordsScoreGauge?.score(),
                  keywords: this.keywordsScoreGauge?.bricksFound()?.map((brick) => brick),
                  count: this.keywordsScoreGauge?.bricksFound()?.length,
              }
            : undefined;

        const otherDescriptionFr = this.data.restaurant.descriptions.find(
            (desc) => desc.size === otherSize && desc.language === ApplicationLanguage.FR
        );
        const otherDescriptionEn = this.data.restaurant.descriptions.find(
            (desc) => desc.size === otherSize && desc.language === ApplicationLanguage.EN
        );
        const { short, long } = this.infoForm.value;
        return [
            {
                size: DescriptionSize.SHORT,
                language: ApplicationLanguage.FR,
                text: short.descriptionFr,
                keywordAnalysis: otherDescriptionFr?.keywordAnalysis,
            },
            {
                size: DescriptionSize.SHORT,
                language: ApplicationLanguage.EN,
                text: short.descriptionEn,
                keywordAnalysis: otherDescriptionEn?.keywordAnalysis,
            },
            {
                size: DescriptionSize.LONG,
                language: ApplicationLanguage.FR,
                text: long.descriptionFr,
                keywordAnalysis,
            },
            {
                size: DescriptionSize.LONG,
                language: ApplicationLanguage.EN,
                text: long.descriptionEn,
                keywordAnalysis,
            },
        ];
    }

    close(): void {
        if (this.infoForm.dirty) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: DescriptionUpdateData): void {
        this._dialogRef.close(data);
    }

    handleTabChange(event: DescriptionModalTabs): void {
        this.selectedTab = event;
        this.initDescriptions(this.getCurrentDescriptions());
    }

    private _initDescriptionWithSize(descriptions: Partial<Description>[], size: DescriptionSize): void {
        const descriptionsWithCorrectSize = descriptions.filter((desc) => desc.size === size);
        (this.infoForm.get(size) as FormGroup).setValue({
            descriptionFr: descriptionsWithCorrectSize.find((desc) => desc.language === ApplicationLanguage.FR)?.text ?? '',
            descriptionEn: descriptionsWithCorrectSize.find((desc) => desc.language === ApplicationLanguage.EN)?.text ?? '',
        });
    }

    private _getDescriptionSizeFromDescriptionModalTabs(tab: DescriptionModalTabs): DescriptionSize {
        return tab === DescriptionModalTabs.LONG_DESCRIPTION ? DescriptionSize.LONG : DescriptionSize.SHORT;
    }

    private _addElementToText(event: string | EmojiEvent): void {
        const currentForm = this.infoForm.get(this.getCurrentFormControlName());
        const currentText = currentForm?.value;
        const textarea = this._getTextArea();
        if (!textarea) {
            return;
        }
        const startPosition = textarea.selectionStart;
        const endPosition = textarea.selectionEnd;
        if (!isNumber(startPosition) || !isNumber(endPosition)) {
            return;
        }
        const cursorPosition: CursorPosition = {
            startPosition,
            endPosition,
        };
        const textToInsert = isEmojiEvent(event) ? event : this._computeTextWithSpace(currentText, cursorPosition, `${event}`);
        const textWithEmoji = createTextWithEmoji(cursorPosition, currentText, textToInsert);
        currentForm?.setValue(textWithEmoji);
        this.infoForm.markAsDirty();
        focusAfterEmoji(textarea, cursorPosition, textToInsert);
    }

    private _getTextArea(): HTMLTextAreaElement | null {
        return document.querySelector(`#${this.TEXT_AREA_ID}`);
    }

    private _computeTextWithSpace(currentText: string, { startPosition, endPosition }: CursorPosition, text: string): string {
        const hasSpaceBefore = currentText[startPosition - 1] === ' ';
        const hasSpaceAfter = currentText[endPosition] === ' ';
        const shouldAddSpaceBefore = currentText[startPosition - 1] && !hasSpaceBefore;
        const shouldAddSpaceAfter = currentText[endPosition] && !hasSpaceAfter;
        return `${shouldAddSpaceBefore ? ' ' : ''}${text}${shouldAddSpaceAfter ? ' ' : ''}`;
    }
}
