<div (click)="onNotificationClick()">
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    @if (!notification().completedAt) {
        <div class="mt-2 flex items-center gap-x-3">
            <app-platform-logo imgClasses="h-3 w-3" [logo]="notification().data.review.platformKey"></app-platform-logo>
            <div class="malou-text-11--semibold text-malou-color-text-1">
                {{ 'notification-center.review-notification-item.negative-review-title' | translate: { restaurantName: restaurantName() } }}
            </div>
        </div>
    }
    <div class="py-2">
        <app-star-gauge [stars]="notification().data.review.rating"></app-star-gauge>
    </div>

    @if (notification().data.review.text?.length) {
        <div class="flex md:!mt-2 md:!flex-col">
            <div class="malou-card__body-text">
                <div>
                    <span
                        class="font-normal"
                        [innerHTML]="
                            isFolded() ? (notification().data.review.text | shortText: SHORT_TEXT_LENGTH) : notification().data.review.text
                        "></span>
                    @if ((notification().data.review.text?.length ?? 0) > SHORT_TEXT_LENGTH) {
                        <span
                            class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5"
                            (click)="$event.stopPropagation(); toggleFolded()">
                            {{ (isFolded() ? 'common.see_more' : 'common.see_less') | translate }}
                        </span>
                    }
                </div>
            </div>
        </div>
    }
    @if (notification().data.suggestedReply && !notification().completedAt) {
        <div
            class="reply-suggestion mt-2 rounded border border-malou-color-background-dark bg-malou-color-background-light p-3 transition-all">
            <div class="malou-text-10 text-malou-color-text-2">
                {{ notification().data.suggestedReply }}
            </div>
        </div>

        <div class="mt-2 flex w-full justify-end gap-x-2">
            <button
                class="malou-btn-raised--secondary btn-xs"
                mat-raised-button
                [disabled]="answeringReview()"
                (click)="$event.stopPropagation(); onNotificationClick()">
                @if (answeringReview()) {
                    <app-malou-spinner size="xs"></app-malou-spinner>
                } @else {
                    {{ 'common.edit' | translate }}
                }
            </button>
            <button
                class="malou-btn-raised--primary btn-xs"
                mat-raised-button
                [disabled]="answeringReview()"
                (click)="$event.stopPropagation(); sendReply()">
                @if (answeringReview()) {
                    <app-malou-spinner size="xs"></app-malou-spinner>
                } @else {
                    {{ 'common.send' | translate }}
                }
            </button>
        </div>
    } @else if (!!notification().completedAt) {
        <span class="malou-text-10--semibold malou-color-primary cursor-pointer" (click)="toggleFolded()">
            {{ 'notification-center.review-notification-item.see_answer' | translate }}
        </span>
    }
</div>
