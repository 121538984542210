<form class="mt-4" [formGroup]="specialHoursForm">
    <div class="flex flex-col" [class.opacity-60]="isRestaurantClosed">
        @for (hour of formHours.controls; track hour; let index = $index) {
            <div formArrayName="formHours">
                @if (hour.value.isFutureDate || showAnteriorHours) {
                    <div class="mb-4 flex items-center justify-between md:flex-col" [formGroupName]="index">
                        <div class="flex md:my-4 md:w-full">
                            <div class="malou-border-primary flex justify-center rounded-lg md:w-full">
                                <app-input-date-picker
                                    formControlName="startDate"
                                    [placeholder]="'common.start_date' | translate"
                                    [shouldDisplayBorder]="false">
                                </app-input-date-picker>
                            </div>
                            <span class="malou-text-12--medium malou-color-text-2-light mx-2 flex items-center">
                                {{ 'common.to' | translate }}
                            </span>
                            <div class="malou-border-primary flex justify-center rounded-lg md:w-full">
                                <app-input-date-picker
                                    formControlName="endDate"
                                    [placeholder]="'common.end_date' | translate"
                                    [shouldDisplayBorder]="false">
                                </app-input-date-picker>
                            </div>
                            <div class="ml-1.5 hidden md:flex">
                                <ng-container [ngTemplateOutlet]="slideToggleTemplate" [ngTemplateOutletContext]="{ index }"></ng-container>
                            </div>
                        </div>
                        <div class="flex justify-between md:mb-2 md:w-full">
                            <div class="mx-1 flex w-full">
                                <mat-form-field
                                    class="malou-form-field--select h-[50px] grow"
                                    appearance="outline"
                                    subscriptSizing="dynamic"
                                    [class.opacity-60]="isClosed(index)">
                                    <mat-icon
                                        class="malou-color-primary !h-[16px] !w-[16px] !pl-0"
                                        matSuffix
                                        [svgIcon]="SvgIcon.CLOCK"></mat-icon>
                                    <mat-select
                                        formControlName="openTime"
                                        placeholder="----"
                                        panelClass="malou-select-panel"
                                        [hideSingleSelectionIndicator]="true"
                                        (selectionChange)="updateFullDay(index); updateToDateFormat(index)">
                                        <mat-option class="malou-text-12--medium" value="24-24">{{
                                            'common.open_24h' | translate
                                        }}</mat-option>
                                        <mat-divider></mat-divider>
                                        @for (time of times; track time) {
                                            <mat-option class="malou-text-12--medium" [value]="time">
                                                {{ time | formatTime }}
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="mx-1 flex w-full">
                                <mat-form-field
                                    class="malou-form-field--select h-[50px] grow"
                                    appearance="outline"
                                    subscriptSizing="dynamic"
                                    [class.opacity-60]="isClosed(index)">
                                    <mat-icon
                                        class="malou-color-primary !h-[16px] !w-[16px] !pl-0"
                                        matSuffix
                                        [svgIcon]="SvgIcon.CLOCK"></mat-icon>
                                    <mat-select
                                        formControlName="closeTime"
                                        panelClass="malou-select-panel"
                                        placeholder="----"
                                        [hideSingleSelectionIndicator]="true"
                                        (selectionChange)="updateToDateFormat(index)">
                                        @for (time of times; track time) {
                                            <mat-option class="malou-text-12--medium" [value]="time">
                                                {{ time | formatTime }}
                                            </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="mx-1 flex flex-col justify-center">
                                <button
                                    class="malou-btn-icon--secondary btn-xl"
                                    mat-icon-button
                                    matTooltip="{{ 'information.special_hours.delete_hour' | translate }}"
                                    [disabled]="isRestaurantClosed"
                                    (click)="deleteHourInput(index)">
                                    <mat-icon class="icon-btn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-center md:hidden">
                            <ng-container [ngTemplateOutlet]="slideToggleTemplate" [ngTemplateOutletContext]="{ index }"></ng-container>
                        </div>
                    </div>
                }
                @if (formHours.at(index).invalid) {
                    <mat-error class="malou-color-state-error malou-text-10 mt-2 italic">
                        {{ formHours.at(index).errors?.error }}
                    </mat-error>
                }
                @if (hour.value.isFutureDate || showAnteriorHours) {
                    <mat-divider class="border-color !mb-4 !mt-3"></mat-divider>
                }
            </div>
        }
    </div>
    <ng-container [ngTemplateOutlet]="addHoursTemplate"></ng-container>
</form>

<ng-template let-index="index" #slideToggleTemplate>
    <div class="flex items-center justify-end">
        <app-slide-toggle [checked]="!isClosed(index)" [disabled]="isRestaurantClosed" (onToggle)="close($event, index)">
        </app-slide-toggle>
    </div>
</ng-template>

<ng-template #addHoursTemplate>
    <div class="flex justify-start">
        <a
            class="malou-btn-flat !pl-0"
            mat-button
            matTooltip="{{ 'information.special_hours.add_hour' | translate }}"
            [class.disabled-link]="isRestaurantClosed"
            (click)="add()">
            <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
            {{ 'information.special_hours.add_hour' | translate }}
        </a>
    </div>
</ng-template>
