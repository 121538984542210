import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    effect,
    ElementRef,
    inject,
    Inject,
    OnInit,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { asapScheduler, Subject, switchMap, take, timer } from 'rxjs';

import {
    ClientSource,
    ContactMode,
    getEphemeraRestaurantIdAndSpecialGiftIdForEnv,
    MalouErrorCode,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { PHONE_CODES } from ':core/constants';
import { GiftDrawsService } from ':core/services/gift-draws.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { LocalStorage } from ':core/storage/local-storage';
import { environment } from ':environments/environment';
import { ClientsService } from ':modules/clients/clients.service';
import { WheelsOfFortuneService } from ':modules/wheels-of-fortune/wheels-of-fortune.service';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { LocalStorageKey } from ':shared/enums/local-storage-key';
import { DEFAULT_CONFETTIS_CONFIGURATION, launchConfettis } from ':shared/helpers/confettis';
import { isValidEmail } from ':shared/helpers/email.validator';
import { LightRestaurant, Phone } from ':shared/models';
import { Gift } from ':shared/models/gift';
import { PhoneCode } from ':shared/models/phone-code';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

import { WheelOfFortuneMessage } from '../../wheel-of-fortune-messages/wheel-of-fortune-messages.component';

export interface DisplayDrawDetailsModalInput {
    wheelOfFortuneId: string;
    restaurant: LightRestaurant;
    primaryColor: string;
    secondaryColor: string;
    gifts: Gift[];
}

interface ClientDetailsForm {
    name: FormControl<string | null>;
    lastname: FormControl<string | null>;
    birthday: FormControl<Date | null>;
    phone: FormGroup<{ prefix: FormControl<PhoneCode | null>; digits: FormControl<string | null> }>;
    email: FormControl<string | null>;
}

const { restaurantId: SPECIAL_RESTAURANT_ID } = getEphemeraRestaurantIdAndSpecialGiftIdForEnv(environment.environment);

@Component({
    selector: 'app-display-draw-details-modal',
    templateUrl: './display-draw-details-modal.component.html',
    styleUrls: ['./display-draw-details-modal.component.scss'],
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        NgTemplateOutlet,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        TranslateModule,
        ImagePathResolverPipe,
        InputTextComponent,
        InputDatePickerComponent,
        MalouSpinnerComponent,
        SelectComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayDrawDetailsModalComponent implements OnInit {
    readonly scrollableElement = viewChild<ElementRef<HTMLElement>>('scrollableElement');

    readonly _restaurantsService = inject(RestaurantsService);

    readonly showBoxShadow = signal(false);

    readonly hasAcceptedRules: WritableSignal<boolean> = signal(false);
    readonly hasGivenDetails: WritableSignal<boolean> = signal(false);
    readonly isSavingClientData: WritableSignal<boolean> = signal(false);

    readonly clientDetailsForm: FormGroup<ClientDetailsForm> = this._fb.group({
        name: ['', Validators.required],
        lastname: [''],
        birthday: [null as Date | null],
        email: ['', Validators.required],
        phone: this._fb.group({
            digits: [''],
            prefix: [PHONE_CODES[0]],
        }),
    });

    readonly isEphemeraRestaurant = signal(false);
    readonly today = signal(new Date());

    readonly triggerConfettis = new Subject<void>();

    readonly resentMail = signal(false);

    readonly PHONE_CODES = [...PHONE_CODES];

    readonly gift: WritableSignal<Gift | undefined> = signal(undefined);

    private readonly _existingPlayerEmails: WritableSignal<string[]> = signal([]);
    private readonly _lang: string = LocalStorage.getLang();
    private readonly _scrollableElement: WritableSignal<ElementRef<HTMLElement> | null> = signal(null);

    private _giftDrawId: string;

    constructor(
        private readonly _dialogRef: MatDialogRef<DisplayDrawDetailsModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: DisplayDrawDetailsModalInput,
        private readonly _fb: FormBuilder,
        private readonly _router: Router,
        private readonly _spinnerService: SpinnerService,
        private readonly _translateService: TranslateService,
        private readonly _clientsService: ClientsService,
        private readonly _giftDrawsService: GiftDrawsService,
        private readonly _toastService: ToastService,
        private readonly _wheelsOfFortuneService: WheelsOfFortuneService
    ) {
        this.isEphemeraRestaurant.set(this.data.restaurant?.id === SPECIAL_RESTAURANT_ID);
        effect(() => {
            if (this.hasGivenDetails()) {
                this._scrollableElement()?.nativeElement.removeAllListeners?.('scroll');
                asapScheduler.schedule(() => this.showBoxShadow.set(false));
            }
        });

        effect(
            () => {
                const scrollableElement = this.scrollableElement();
                if (!scrollableElement) {
                    return;
                }
                const hasGivenDetails = this.hasGivenDetails();
                if (hasGivenDetails) {
                    scrollableElement.nativeElement.removeAllListeners?.('scroll');
                    this.showBoxShadow.set(false);
                } else {
                    this._setShowBoxShadow(scrollableElement);

                    scrollableElement.nativeElement.addEventListener('scroll', () => this._setShowBoxShadow(scrollableElement));
                }
                this._scrollableElement.set(scrollableElement);
            },
            { allowSignalWrites: true }
        );
    }

    get email(): AbstractControl | null {
        return this.clientDetailsForm.get('email');
    }

    get phoneDigits(): AbstractControl | null {
        return this.clientDetailsForm.get('phone')?.get('digits') || null;
    }

    get phonePrefix(): AbstractControl | null {
        return this.clientDetailsForm.get('phone')?.get('prefix') || null;
    }

    get name(): AbstractControl | null {
        return this.clientDetailsForm.get('name');
    }

    get lastname(): AbstractControl | null {
        return this.clientDetailsForm.get('lastname');
    }

    get birthday(): AbstractControl | null {
        return this.clientDetailsForm.get('birthday');
    }

    ngOnInit(): void {
        this._initializePhonePrefix();
        this.clientDetailsForm.setValidators([this._customEmailValidator(), this._customPhoneValidator()]);
        timer(0, 3 * TimeInMilliseconds.SECOND)
            .pipe(take(2))
            .subscribe(() => {
                launchConfettis(DEFAULT_CONFETTIS_CONFIGURATION);
            });

        this._wheelsOfFortuneService.getWheelExistingPlayerEmails(this.data.wheelOfFortuneId, this.data.restaurant.id).subscribe({
            next: (res) => this._existingPlayerEmails.set(res.data?.map((email) => email.toLowerCase()) || []),
        });
    }

    toggleHasAcceptedRules(): void {
        this.hasAcceptedRules.update((hasAcceptedRules) => !hasAcceptedRules);
    }

    saveClientDetails(): void {
        if (!this.hasCorrectlyFilledForm() || !this.email || !this.name) {
            return;
        }

        const isEmailAlreadyUsed = this._isTrickDuplicatedEmail(this.email.value);
        if (isEmailAlreadyUsed) {
            this._redirectToAlreadyPlayed();
            return;
        }

        this.isSavingClientData.set(true);
        this._clientsService
            .updateClient(
                { email: this.email?.value, restaurantId: this.data.restaurant.id },
                {
                    restaurantId: this.data.restaurant.id,
                    firstName: this.name?.value,
                    lastName: this.lastname?.value,
                    birthday: this.birthday?.value,
                    email: this.email?.value.toLowerCase(),
                    phone:
                        this.phoneDigits?.value && this.phonePrefix?.value.code
                            ? new Phone({ digits: this.phoneDigits?.value, prefix: this.phonePrefix?.value.code })
                            : undefined,
                    language: this._lang,
                    source: ClientSource.WHEEL_OF_FORTUNE,
                    lastVisitedAt: new Date(),
                    accepts: [ContactMode.SMS, ContactMode.EMAIL],
                }
            )
            .pipe(
                switchMap((res) =>
                    this._giftDrawsService.drawGift(this.data.restaurant.id, this.data.wheelOfFortuneId, this._lang, res.data._id)
                ),
                switchMap((res) => {
                    this._giftDrawId = res.data.id;
                    this.gift.set(this.data.gifts.find((gift) => gift.id === res.data.giftId));
                    return this._giftDrawsService.sendRetrievalEmail(this._giftDrawId);
                })
            )
            .subscribe({
                next: () => {
                    this.hasGivenDetails.set(true);
                    this.isSavingClientData.set(false);
                    this._pushPlayedWheelOfFortuneInLocalStorage();
                },
                error: (err: any | null) => {
                    if (err?.error.malouErrorCode === MalouErrorCode.CLIENT_ALREADY_PLAYED) {
                        this._redirectToAlreadyPlayed();
                    } else if (err?.error.malouErrorCode === MalouErrorCode.NO_AVAILABLE_GIFTS) {
                        this.close();
                        this._router.navigate(['./wheel-of-fortune-messages'], {
                            queryParams: {
                                message: WheelOfFortuneMessage.WHEEL_OF_FORTUNE_EXPIRED,
                                color: this.data.primaryColor,
                            },
                        });
                    } else if (err?.error.duplicateRecordError && err?.error.duplicatedKey === 'phone') {
                        this._toastService.openErrorToast(
                            this._translateService.instant(
                                'play_wheel_of_fortune.display_draw_details.errors.phone_associated_with_other_email'
                            )
                        );
                    } else {
                        this._toastService.openErrorToast(
                            this._translateService.instant('play_wheel_of_fortune.display_draw_details.errors.could_not_save_data')
                        );
                    }
                    this.isSavingClientData.set(false);
                },
            });
    }

    resendMail(): void {
        this._spinnerService.show();
        this._giftDrawsService.sendRetrievalEmail(this._giftDrawId).subscribe({
            next: () => {
                this._spinnerService.hide();
                this.resentMail.set(true);
            },
            error: () => {
                this._spinnerService.hide();
                this._toastService.openErrorToast(
                    this._translateService.instant('play_wheel_of_fortune.display_draw_details.errors.email_could_not_be_sent')
                );
            },
        });
    }

    close(): void {
        this._dialogRef.close();
    }

    hasCorrectlyFilledForm(): boolean {
        // TODO EPHEMERA : delete when no longer need custom settings
        const defaultValidation = this.hasAcceptedRules() && this.name?.value && this.email?.value && !this.clientDetailsForm.invalid;
        if (this.isEphemeraRestaurant()) {
            return defaultValidation && this.lastname?.value && this.birthday?.value;
        }
        return defaultValidation;
    }

    openGameRules(): void {
        window.open(
            `${window.location.origin}/wheel-of-fortune-rules?name=${
                this.data.restaurant?.name
            }&address=${this.data.restaurant?.address?.getDisplayedValue()}`,
            '_blank'
        );
    }

    displayWithPhone(value: PhoneCode): string {
        return value.text;
    }

    private _initializePhonePrefix(): void {
        const clientLanguage =
            navigator.language ??
            (
                navigator as {
                    userLanguage?: string;
                }
            ).userLanguage;

        const clientCountry = clientLanguage.split('-')[1];
        if (!clientCountry) {
            return;
        }
        const associatedPhoneCode = PHONE_CODES.find((phone) => phone.countryCode === clientCountry);
        if (!associatedPhoneCode) {
            return;
        }
        this.clientDetailsForm.get('phone')?.get('prefix')?.setValue(associatedPhoneCode);
    }

    private _isTrickDuplicatedEmail(email: string): boolean {
        const emailWithPlusPattern = /\+(.*?)(?=@|$)/; // catch emails like solene+1@malou.io
        const cleanEmail = email.replace(emailWithPlusPattern, '');
        return this._existingPlayerEmails().includes(cleanEmail.toLowerCase());
    }

    private _redirectToAlreadyPlayed(): void {
        this._pushPlayedWheelOfFortuneInLocalStorage();
        if (this._giftDrawId) {
            this._giftDrawsService.cancelDraw(this._giftDrawId).subscribe();
        }
        this.close();
        this._router.navigate(['./wheel-of-fortune-messages'], {
            queryParams: {
                message: WheelOfFortuneMessage.CLIENT_ALREADY_PLAYED,
                color: this.data.primaryColor,
            },
        });
    }

    private _customEmailValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const email = group.get('email');
            if (!email?.value) {
                email?.setErrors(null);
                return null;
            }
            if (!isValidEmail(email)) {
                email?.setErrors({
                    error: this._translateService.instant('play_wheel_of_fortune.give_contact_details.errors.invalid_email'),
                });
                return null;
            }
            email?.setErrors(null);
            return null;
        };
    }

    private _customPhoneValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const prefix = group.get(['phone', 'prefix']);
            const digits = group.get(['phone', 'digits']);
            const phoneNumber = parsePhoneNumberFromString('+' + String(prefix?.value?.code) + String(digits?.value));
            if (!digits?.value || digits.value === '') {
                digits?.setErrors(null);
            } else if (!String(digits.value).match(/^\d{0,20}$/g)) {
                digits.setErrors({
                    error: this._translateService.instant('play_wheel_of_fortune.give_contact_details.errors.invalid_phone'),
                });
            } else if (!phoneNumber || !phoneNumber.isValid()) {
                digits.setErrors({
                    error: this._translateService.instant('play_wheel_of_fortune.give_contact_details.errors.invalid_phone'),
                });
            } else {
                digits.setErrors(null);
            }
            return null;
        };
    }

    private _pushPlayedWheelOfFortuneInLocalStorage(): void {
        const playedRestaurantWheelsOfFortune = JSON.parse(
            localStorage.getItem(LocalStorageKey.PLAYED_RESTAURANT_WHEELS_OF_FORTUNE) || '[]'
        );
        playedRestaurantWheelsOfFortune.push({ wheelOfFortuneId: this.data.wheelOfFortuneId, restaurantId: this.data.restaurant.id });
        localStorage.setItem(LocalStorageKey.PLAYED_RESTAURANT_WHEELS_OF_FORTUNE, JSON.stringify(playedRestaurantWheelsOfFortune));
    }

    private _setShowBoxShadow(element: ElementRef<HTMLElement>): void {
        const scrollPositionToBottom = Math.abs(
            element.nativeElement.scrollHeight - element.nativeElement.scrollTop - element.nativeElement.clientHeight
        );
        const isScrolledToBottom = scrollPositionToBottom < 1;
        this.showBoxShadow.set(!isScrolledToBottom);
    }
}
