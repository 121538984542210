import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { compact, uniq, uniqBy } from 'lodash';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
    GetMergedInformationUpdateBodyDto,
    GetMergedInformationUpdateResponseDto,
    MergedInformationUpdateDto,
    PlatformDto,
} from '@malou-io/package-dto';
import {
    InformationUpdatePlatformStateStatus,
    isNotNil,
    PlatformAccessStatus,
    PlatformAccessType,
    PlatformKey,
} from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { InformationUpdatesService } from ':core/services/information-update.service';
import { PlatformsService } from ':core/services/platforms.service';
import { AccessComponent } from ':modules/admin/platforms-management/access/access.component';
import { PlatformsManagementViewType } from ':modules/admin/platforms-management/platforms-management-actions-header/platforms-management-action-header.interface';
import { PlatformsManagementActionsHeaderComponent } from ':modules/admin/platforms-management/platforms-management-actions-header/platforms-management-actions-header.component';
import { MergedInformationUpdateHelper } from ':modules/admin/platforms-management/updates/updates-comparaison/merged-information-update.helper';
import {
    PlatformManagementUpdateData,
    PlatformManagementUpdateDoneData,
    UpdatesComponent,
} from ':modules/admin/platforms-management/updates/updates.component';
import { selectSelectedOption, selectShowTreated, selectUserRestaurants, selectViewType } from ':modules/admin/store/admin.reducer';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { InformationUpdateOptions, PlatformAccess, Restaurant } from ':shared/models';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { IncludesPipe } from ':shared/pipes/includes.pipe';
import { PlatformLogoPathResolverPipe } from ':shared/pipes/platform-logo-path-resolver.pipe';

interface PlatformManagementPanelContent {
    title: string;
    updatesData: PlatformManagementUpdateData[];
    accessList: PlatformAccess[];
    badge: number;
    openPanelId: string;
    imageSrc?: string;
}

interface RestaurantPlaftormManagementData {
    restaurant: Restaurant;
    platformKey: PlatformKey;
    access?: PlatformAccess;
    updateData?: PlatformManagementUpdateData;
}

@Component({
    selector: 'app-platforms-management',
    templateUrl: './platforms-management.component.html',
    styleUrls: ['./platforms-management.component.scss'],
    standalone: true,
    imports: [
        AccessComponent,
        AsyncPipe,
        FormsModule,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        IncludesPipe,
        MalouSpinnerComponent,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatExpansionModule,
        MatIconModule,
        NgClass,
        NgStyle,
        NgTemplateOutlet,
        PlatformsManagementActionsHeaderComponent,
        ReactiveFormsModule,
        SearchComponent,
        SelectComponent,
        SortByFiltersComponent,
        TranslateModule,
        UpdatesComponent,
    ],
    providers: [PlatformLogoPathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformsManagementComponent implements OnInit {
    @HostBinding('class') classes = 'h-full';

    searchText: WritableSignal<string> = signal('');

    viewType = this._store.selectSignal(selectViewType);
    isBusinessView = computed(() => this.viewType() === PlatformsManagementViewType.BUSINESS);
    selectedOption = this._store.selectSignal(selectSelectedOption);

    readonly FAILED_ACCESS_STATUSES = ['unclaimed_page', 'invalid_page', 'bad_access', 'failed'];

    showProcessed$ = this._store.select(selectShowTreated);
    showProcessed = this._store.selectSignal(selectShowTreated);

    myRestaurants$: Observable<Restaurant[]> = this._store.select(selectUserRestaurants);

    restaurantPlatformManagementData = toSignal(this._getRestaurantPlaftormManagementData$());

    panelContents = computed(() => {
        const restaurantPlaftormManagementData = this.restaurantPlatformManagementData();
        if (!restaurantPlaftormManagementData) {
            return;
        }

        const selectedOption = this.selectedOption();

        let panelContents: PlatformManagementPanelContent[] = [];
        if (this.isBusinessView()) {
            const restaurants = uniqBy(
                restaurantPlaftormManagementData.map((d) => d.restaurant),
                '_id'
            );
            panelContents = restaurants
                .map((restaurant) => {
                    const accessList = compact(
                        restaurantPlaftormManagementData.filter((d) => d.restaurant._id === restaurant._id).map((d) => d.access)
                    );
                    const updatesData = compact(
                        restaurantPlaftormManagementData.filter((d) => d.restaurant._id === restaurant._id).map((d) => d.updateData)
                    );
                    if (this._isAccessListEmpty(accessList) && updatesData.length === 0) {
                        return null;
                    }
                    return {
                        title: restaurant.name,
                        updatesData,
                        accessList,
                        badge: this._getUnprocessedUpdatesAndAccess(updatesData, accessList, selectedOption),
                        openPanelId: restaurant._id,
                        imageSrc: restaurant.logo?.urls?.original,
                    };
                })
                .filter(isNotNil)
                .sort(this._getSortPanelListContentMethod());
        } else {
            const platformKeys = uniq(restaurantPlaftormManagementData.map((d) => d.platformKey));
            panelContents = platformKeys
                .map((platformKey) => {
                    const accessList = compact(
                        restaurantPlaftormManagementData.filter((d) => d.platformKey === platformKey).map((d) => d.access)
                    );
                    const updatesData = compact(
                        restaurantPlaftormManagementData.filter((d) => d.platformKey === platformKey).map((d) => d.updateData)
                    );
                    if (this._isAccessListEmpty(accessList) && this._isUpdatesDataEmpty(updatesData)) {
                        return null;
                    }
                    return {
                        title: this._enumTranslatePipe.transform(platformKey, 'platform_key'),
                        updatesData,
                        accessList,
                        badge: this._getUnprocessedUpdatesAndAccess(updatesData, accessList, selectedOption),
                        openPanelId: platformKey,
                        imageSrc: this._platformLogoPathResolverPipe.transform(platformKey, { folder: 'platforms' }),
                    };
                })
                .filter(isNotNil)
                .sort(this._getSortPanelListContentMethod());
        }

        if (this.showProcessed()) {
            panelContents = panelContents.filter((panelContent) => panelContent.badge > 0);
        }
        return this.filterByText(this.searchText(), panelContents);
    });

    currentOpenPanelId: string | null;
    accessUpdate: WritableSignal<PlatformManagementUpdateDoneData | null> = signal(null);

    readonly InformationUpdateOptions = InformationUpdateOptions;

    constructor(
        private readonly _store: Store,
        private readonly _route: ActivatedRoute,
        private readonly _enumTranslatePipe: EnumTranslatePipe,
        private readonly _informationUpdatesService: InformationUpdatesService,
        private readonly _platformLogoPathResolverPipe: PlatformLogoPathResolverPipe,
        private readonly _platformsService: PlatformsService
    ) {}

    ngOnInit(): void {
        this._route.queryParams.subscribe((queryParams) => {
            if (queryParams.restaurant_id) {
                this.currentOpenPanelId = queryParams.restaurant_id;
            }
        });
    }

    filterByText(searchText: string, elements: PlatformManagementPanelContent[]): PlatformManagementPanelContent[] {
        return searchText
            ? elements.filter((element) => element.title.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()))
            : elements;
    }

    onSearchChange(searchText: string): void {
        this.searchText.set(searchText);
    }

    openPanel(panelContent: PlatformManagementPanelContent): void {
        this.currentOpenPanelId = panelContent.openPanelId;
    }

    closePanel(): void {
        this.currentOpenPanelId = null;
    }

    onPlatformUpdate(data: PlatformManagementUpdateDoneData): void {
        if (data.status !== InformationUpdatePlatformStateStatus.ERROR) {
            return;
        }
        this.accessUpdate.set(data);
    }

    private _getRestaurantPlaftormManagementData$(): Observable<RestaurantPlaftormManagementData[]> {
        const platforms$ = this.myRestaurants$.pipe(
            filter((myRestaurants) => !!myRestaurants.length),

            switchMap((myRestaurants) =>
                this._platformsService
                    .getPlatformsForRestaurants({ restaurantIds: myRestaurants.map((restaurant) => restaurant._id) })
                    .pipe(map((res) => ({ platforms: res.data, myRestaurants })))
            ),

            switchMap(({ myRestaurants, platforms }) =>
                this._getMergedInformationUpdateData$(myRestaurants).pipe(
                    map((mergedInfoUpdates) => ({ mergedInfoUpdates, myRestaurants, platforms }))
                )
            )
        );

        return combineLatest([platforms$, this.showProcessed$]).pipe(
            map(([{ mergedInfoUpdates, myRestaurants, platforms }, showProcessed]) => {
                const data: RestaurantPlaftormManagementData[][] = myRestaurants.map((restaurant): RestaurantPlaftormManagementData[] => {
                    const mergedInformationUpdates = mergedInfoUpdates.find(
                        (mergedInformationUpdateForRestaurantId) => mergedInformationUpdateForRestaurantId.restaurantId === restaurant._id
                    );
                    const mergedInformationUpdatesByPlatformFiltered = mergedInformationUpdates?.mergedInformationUpdatesByPlatform.filter(
                        (mergedInformationUpdateFoPlatform) =>
                            mergedInformationUpdateFoPlatform.platformState.status === InformationUpdatePlatformStateStatus.PENDING
                    );
                    const updatesData = this._buildUpdatesData(mergedInformationUpdatesByPlatformFiltered ?? [], restaurant, showProcessed);
                    const updatesDataFiltered = this._filterUpdateData(updatesData, platforms);
                    const accessList = this._buildAccessList(restaurant, showProcessed);

                    const platformKeyToShow = uniq([
                        ...updatesDataFiltered.map((updateData) => updateData.platformKey),
                        ...accessList.map((access) => access.platformKey),
                    ]);
                    return platformKeyToShow.map(
                        (platformKey): RestaurantPlaftormManagementData => ({
                            restaurant: restaurant,
                            platformKey,
                            access: accessList.find((access) => access.platformKey === platformKey),
                            updateData: updatesDataFiltered.find((updateData) => updateData.platformKey === platformKey),
                        })
                    );
                });
                const flattenedData = data.flat();
                return flattenedData;
            })
        );
    }

    private _filterUpdateData(updatesData: PlatformManagementUpdateData[], platformsDto: PlatformDto[]): any {
        return updatesData.filter((updateData) => {
            const platformDto = platformsDto.find(
                (platDto) => platDto.restaurantId === updateData.restaurant._id && platDto.key === updateData.platformKey
            );
            if (!platformDto) {
                return false;
            }
            return MergedInformationUpdateHelper.hasDiff(updateData.mergedInformationUpdate, {
                platformKey: platformDto.key,
                lockedFields: platformDto.lockedFields,
            });
        });
    }

    private _getMergedInformationUpdateData$(restaurants: Restaurant[]): Observable<GetMergedInformationUpdateResponseDto> {
        const body: GetMergedInformationUpdateBodyDto = restaurants
            .map((restaurant) => ({
                restaurantId: restaurant._id,
                platformKeys: restaurant.access
                    .filter((access) => access.active)
                    .filter((access) => access.accessType !== PlatformAccessType.AUTO)
                    .map((access) => access.platformKey),
            }))
            .filter((restaurantAndPlatforms) => restaurantAndPlatforms.platformKeys.length > 0);
        return this._informationUpdatesService.getMergedInformationUpdateData(body).pipe(map((res) => res.data));
    }

    private _buildUpdatesData(
        mergedInformationUpdates: MergedInformationUpdateDto[],
        restaurant: Restaurant,
        showProcessed: boolean = false
    ): PlatformManagementUpdateData[] {
        if (!mergedInformationUpdates.length) {
            return [];
        }
        const accessesOfConnectedNonAutoPlatform = restaurant.access
            .filter((access) => access.active)
            .filter((access) => access.accessType !== PlatformAccessType.AUTO);

        return accessesOfConnectedNonAutoPlatform
            .map((access: PlatformAccess) => ({
                platformKey: access.platformKey,
                platformAccess: access,
                mergedInformationUpdate: mergedInformationUpdates.find(
                    (mergedInformationUpdate) => mergedInformationUpdate.platformState.key === access.platformKey
                ),
                restaurant: restaurant,
            }))
            .filter((update): update is PlatformManagementUpdateData => !!update.mergedInformationUpdate)
            .filter((update) =>
                showProcessed ? true : update.mergedInformationUpdate.platformState.status !== InformationUpdatePlatformStateStatus.DONE
            );
    }

    private _buildAccessList(restaurant: Restaurant, processed: boolean = false): PlatformAccess[] {
        const accessList = restaurant.access.map((access) => ({
            ...access,
            restaurantId: restaurant._id,
            restaurantName: restaurant.name,
            restaurantLogo: restaurant.logo ?? undefined,
        }));
        return processed
            ? accessList.filter(
                  (access) =>
                      (access.active && access.accessType !== PlatformAccessType.AUTO) ||
                      this.FAILED_ACCESS_STATUSES.includes(access.status)
              )
            : accessList.filter(
                  (access) =>
                      access.active && access.accessType !== PlatformAccessType.AUTO && access.status === PlatformAccessStatus.NEED_REVIEW
              );
    }

    private _isAccessListEmpty(accessList: PlatformAccess[]): boolean {
        return (
            accessList.filter(
                (access) =>
                    (access.active && access.accessType !== PlatformAccessType.AUTO) || this.FAILED_ACCESS_STATUSES.includes(access.status)
            ).length === 0
        );
    }

    private _isUpdatesDataEmpty(updatesData: PlatformManagementUpdateData[]): boolean {
        return updatesData.length === 0;
    }

    private _getUnprocessedUpdatesAndAccess(
        updatesData: PlatformManagementUpdateData[],
        accessList: PlatformAccess[],
        selection: InformationUpdateOptions
    ): number {
        const unprocessedUpdatesCount = updatesData.filter((update) =>
            [InformationUpdatePlatformStateStatus.ERROR, InformationUpdatePlatformStateStatus.PENDING].includes(
                update.mergedInformationUpdate.platformState.status
            )
        ).length;

        const unprocessedAccessesCount = accessList.filter((al) => al.status === 'need_review')?.length;

        switch (selection) {
            case InformationUpdateOptions.BOTH:
                return unprocessedUpdatesCount + unprocessedAccessesCount;
            case InformationUpdateOptions.ACCESS:
                return unprocessedAccessesCount;
            case InformationUpdateOptions.UPDATE:
                return unprocessedUpdatesCount;
            default:
                return 0;
        }
    }

    private _getSortPanelListContentMethod(): (a: PlatformManagementPanelContent, b: PlatformManagementPanelContent) => number {
        return (panelContentA, panelContentB) => {
            const aDates = compact([
                ...panelContentA.updatesData.map((updateData) => updateData.platformAccess.lastUpdated),
                ...panelContentA.accessList.map((access) => access.lastUpdated),
            ]).map((date) => new Date(date));
            const bDates = compact([
                ...panelContentB.updatesData.map((updateData) => updateData.platformAccess.lastUpdated),
                ...panelContentB.accessList.map((access) => access.lastUpdated),
            ]).map((date) => new Date(date));

            const endDateA = aDates.sort((a, b) => a.getTime() - b.getTime())[0];
            const endDateB = bDates.sort((a, b) => a.getTime() - b.getTime())[0];
            if (endDateA === endDateB) {
                return 0;
            } else if (endDateA === undefined) {
                return 1;
            } else if (endDateB === undefined) {
                return -1;
            } else {
                return endDateA > endDateB ? 1 : -1;
            }
        };
    }
}
