<div class="flex h-full flex-col">
    <div class="px-7.5 md:px-6">
        @if (!screenSizeService.isPhoneScreen) {
            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
        }
    </div>
    <div class="min-h-0 grow">
        @if (isLoading() || firstFetchInProgress()) {
            <div class="mx-8.5 mt-2">
                <div class="grid w-full grid-cols-2 gap-x-4 md:grid-cols-1">
                    <ng-container [ngTemplateOutlet]="skeletonColumn"></ng-container>
                    @if (!screenSizeService.isPhoneScreen) {
                        <ng-container [ngTemplateOutlet]="skeletonColumn"></ng-container>
                    }
                </div>
            </div>
        } @else {
            @if (!isAggregatedView() || ((selectedRestaurants$ | async)?.length ?? 0) > 1) {
                @if (reviews().length) {
                    @if (simplerCombinedReviewsExperimentationEnabled() && isAggregatedView()) {
                        @if (answerReviewsContainerData(); as answerReviewsContainerData) {
                            <app-answer-reviews-container [data]="answerReviewsContainerData"></app-answer-reviews-container>
                        }
                    } @else {
                        <div
                            class="h-full overflow-y-auto px-7.5 md:px-6"
                            id="review-list-infinite-scroll-container"
                            infinite-scroll
                            infiniteScrollContainer="#review-list-infinite-scroll-container"
                            [infiniteScrollDistance]="2"
                            [fromRoot]="true"
                            (scrolled)="onScrollDown()"
                            (scrolledUp)="onScrollUp()">
                            @if (screenSizeService.isPhoneScreen || screenSizeService.isLargeScreen) {
                                @if (screenSizeService.isPhoneScreen) {
                                    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
                                }
                                <div class="flex w-full flex-col gap-y-4" id="smallScreenList">
                                    @for (review of reviews(); track trackByIdFn(index, review); let index = $index) {
                                        <div class="w-full" [id]="'review-' + review._id">
                                            <app-review-preview
                                                [review]="review"
                                                [restaurant]="getRestaurantFromReview | applyPure: review"
                                                [restaurantKeywords$]="
                                                    getRestaurantKeywordsFromReview | applyPure: review : restaurantsKeywords().length
                                                "
                                                [isAggregatedView]="isAggregatedView()"
                                                [isSyncReviewsFinished]="isSyncReviewsFinished()"
                                                [displaySemanticAnalysis]="shouldDisplaySemanticAnalyses()"
                                                [parentListIndex]="index"
                                                [restaurantAiSettings]="
                                                    restaurantAiSettings() | applyPure: (getRestaurantFromReview | applyPure: review)._id
                                                "
                                                (archived)="onArchivedReview($event)">
                                            </app-review-preview>
                                        </div>
                                    }
                                </div>
                            } @else {
                                <div class="flex w-full gap-x-4">
                                    <div class="flex w-[50%] flex-col gap-y-4">
                                        @for (review of reviews(); track trackByIdFn(index, review); let even = $even; let index = $index) {
                                            @if (even) {
                                                <div class="w-full" [id]="'review-' + review._id">
                                                    <app-review-preview
                                                        [review]="review"
                                                        [restaurant]="getRestaurantFromReview | applyPure: review"
                                                        [restaurantKeywords$]="
                                                            getRestaurantKeywordsFromReview
                                                                | applyPure: review : restaurantsKeywords().length
                                                        "
                                                        [isAggregatedView]="isAggregatedView()"
                                                        [isSyncReviewsFinished]="isSyncReviewsFinished()"
                                                        [displaySemanticAnalysis]="shouldDisplaySemanticAnalyses()"
                                                        [parentListIndex]="index"
                                                        [restaurantAiSettings]="
                                                            restaurantAiSettings()
                                                                | applyPure: (getRestaurantFromReview | applyPure: review)._id
                                                        "
                                                        (archived)="onArchivedReview($event)">
                                                    </app-review-preview>
                                                </div>
                                            }
                                        }
                                    </div>
                                    <div class="flex w-[50%] flex-col gap-y-4">
                                        @for (review of reviews(); track trackByIdFn(index, review); let odd = $odd; let index = $index) {
                                            @if (odd) {
                                                <div class="w-full" [id]="'review-' + review._id">
                                                    <app-review-preview
                                                        [review]="review"
                                                        [restaurant]="getRestaurantFromReview | applyPure: review"
                                                        [restaurantKeywords$]="
                                                            getRestaurantKeywordsFromReview
                                                                | applyPure: review : restaurantsKeywords().length
                                                        "
                                                        [isAggregatedView]="isAggregatedView()"
                                                        [isSyncReviewsFinished]="isSyncReviewsFinished()"
                                                        [displaySemanticAnalysis]="shouldDisplaySemanticAnalyses()"
                                                        [parentListIndex]="index"
                                                        [restaurantAiSettings]="
                                                            restaurantAiSettings()
                                                                | applyPure: (getRestaurantFromReview | applyPure: review)._id
                                                        "
                                                        (archived)="onArchivedReview($event)">
                                                    </app-review-preview>
                                                </div>
                                            }
                                        }
                                    </div>
                                </div>
                            }
                            <app-scroll-to-top
                                class="fixed right-7.5"
                                container="#review-list-infinite-scroll-container"
                                [trigger$]="scrollTopTrigger$"
                                [ngClass]="{
                                    'bottom-8': footersVisibilityCount() === 0,
                                    'bottom-24': footersVisibilityCount() >= 1,
                                }">
                            </app-scroll-to-top>
                            @if (hasLoadedAllReviews$ | async) {
                                <div class="my-9 flex justify-center">
                                    <div class="mr-1 h-4 w-4">
                                        <img alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
                                    </div>
                                    <div class="malou-text-13--semibold malou-color-text-2">
                                        {{ 'reviews.end_of_reviews' | translate }}
                                    </div>
                                </div>
                            }
                        </div>
                    }
                } @else {
                    <div class="h-full px-7.5 md:px-6">
                        @if (screenSizeService.isPhoneScreen) {
                            <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
                        }
                        <div class="grid h-full place-items-center">
                            <div class="flex h-full flex-col items-center justify-center">
                                @if (hasConnectedPlatforms$ | async) {
                                    @if (atLeastOneFilterIsActive() || hasTextSearch()) {
                                        <div class="mb-10 w-32 md:mb-5 md:w-24">
                                            <img alt="Googles illustration" [src]="'Goggles' | illustrationPathResolver" />
                                        </div>
                                        <div class="px-6 text-center">
                                            <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                                                {{ 'reviews.no_reviews.with_filters' | translate }}
                                            </h3>
                                            <p class="malou-text-10--regular">{{ 'reviews.no_reviews_due_to_search' | translate }}</p>
                                        </div>
                                    } @else {
                                        <div class="mb-10 w-32 md:mb-5 md:w-24">
                                            <img alt="Googles illustration" [src]="'Icecream' | illustrationPathResolver" />
                                        </div>
                                        <div class="px-6 text-center">
                                            <h3 class="malou-text-14--bold malou-color-text-1 mb-2">
                                                {{ 'reviews.no_reviews.without_filters' | translate }}
                                            </h3>
                                            <p class="malou-text-10--regular">{{ 'reviews.try_to_sync' | translate }}</p>
                                            <div class="mt-12 md:mt-6">
                                                <button
                                                    class="malou-btn-raised--primary"
                                                    mat-raised-button
                                                    aria-label="sync reviews"
                                                    [matTooltip]="'reviews.get_reviews_from_connected_platforms' | translate"
                                                    (click)="forceSynchronize()">
                                                    {{ 'reviews.get_reviews' | translate }}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                } @else {
                                    <app-no-connected-platforms
                                        [message]="'reviews.please_connect_platforms' | translate"></app-no-connected-platforms>
                                }
                            </div>
                        </div>
                    </div>
                }
            } @else {
                <div class="flex flex-col items-center py-6">
                    <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
                    <span class="malou-text-10--regular">{{
                        'aggregated_statistics.errors.select_at_least_2_businesses' | translate
                    }}</span>
                </div>
            }
        }
    </div>
</div>

<ng-template #filtersTemplate>
    <div
        class="mb-3 flex items-center gap-x-3 sm:mb-5 sm:flex-col"
        [ngClass]="isAggregatedView() && !simplerCombinedReviewsExperimentationEnabled() ? 'mr-8' : 'mr-0'">
        <div class="w-11/12">
            <app-reviews-header
                [isAggregatedView]="isAggregatedView()"
                [displayReviewCount]="true"
                [displaySemanticFilter]="true"
                [displayAutomaticReplyFilter]="true"
                [displayRestaurantsFilter]="true">
            </app-reviews-header>
        </div>
        <app-download-reviews-select-menu [isAggregatedView]="isAggregatedView()" />
    </div>
</ng-template>

<ng-template #skeletonColumn>
    <app-skeleton skeletonClass="!w-full !h-[180px] secondary-bg" [count]="3" [animate]="false">
        <ng-template #nestedSkeletonTemplate>
            <div class="flex h-full flex-col justify-between p-5">
                <div class="flex justify-between">
                    <app-skeleton class="!h-[35px] !w-[50%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                    <app-skeleton class="!h-[35px] !w-[20%]" skeletonClass="!w-full !h-[35px]"></app-skeleton>
                </div>
                <app-skeleton skeletonClass="!w-[100%] !h-[84px]"></app-skeleton>
            </div>
        </ng-template>
    </app-skeleton>
</ng-template>
