import { Media } from '../models';
import { VideoMediaAnalyzer } from '../models/media-analyzer';
import { retryPromise } from './retry-promise';

const DEFAULT_QUALITY = 1;

export const createVideoElement = (media: Media): HTMLVideoElement => {
    const video = document.createElement('video');
    video.setAttribute('crossOrigin', 'anonymous');
    video.setAttribute('id', getThumbnailVideoCoverIdSelector(media));
    video.style.display = 'none';
    video.width = 1;
    video.height = 1;
    video.preload = 'metadata';
    const mediaUrl = media.getMediaUrl('igFit') + '?x-request=xhr&t=' + Date.now();
    video.src = mediaUrl;

    document.body.appendChild(video);
    return video;
};

export const getThumbnailVideoCoverIdSelector = (media: Media): string => `thumbnail-video-${media._id}`;

export const getVideoCoverUrl = (video: HTMLVideoElement, offsetTimeInSeconds = 0.0): Promise<string> => {
    const promise = new Promise((resolve, reject) => {
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;

        video.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the video frame onto the canvas
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject('Could not get canvas context');
                return;
            }
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        reject('Could not get canvas blob');
                        return;
                    }
                    const thumbnailUrl = window.URL.createObjectURL(blob);
                    resolve(thumbnailUrl);
                },
                'image/jpeg',
                DEFAULT_QUALITY
            );
        });

        // Listen for the 'loadedmetadata' event to get video metadata
        video.addEventListener('loadedmetadata', () => {
            if (isNaN(video.duration)) {
                reject('Invalid video duration');
                return;
            }
            if (video.duration < offsetTimeInSeconds) {
                offsetTimeInSeconds = video.duration;
            }
        });
        video.addEventListener('error', (error) => {
            const reelError = error;
            console.warn('Error when when getting video cover', reelError);
            console.warn('Error when when getting video cover 2', (reelError as any).isTrusted);

            reject(reelError);
        });
        setTimeout(() => {
            video.currentTime = offsetTimeInSeconds;
        });
    });
    return retryPromise(promise);
};

export const pickImagesFromVideo = async (media: Media, numberOfImages: number): Promise<string[]> => {
    const video = createVideoElement(media);
    const mediaDuration = await _getMediaDuration(media);
    const step = mediaDuration / numberOfImages;
    const res: string[] = [];
    for (let i = 0; i < numberOfImages; i++) {
        const offset = (i + 1 / numberOfImages) * step;
        const videoCoverUrl = await retryPromise(getVideoCoverUrl(video, offset));
        res.push(videoCoverUrl);
    }
    document.getElementById(getThumbnailVideoCoverIdSelector(media))?.remove();
    return res;
};

const _getMediaDuration = async (media: Media): Promise<number> => {
    if (media.duration) {
        return media.duration;
    }
    const videoMediaAnalyzer = new VideoMediaAnalyzer(undefined);
    const metadata = await videoMediaAnalyzer.getMetadata(media.getMediaUrl('igFit'));
    return metadata.duration as number;
};
