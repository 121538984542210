import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

import { NfcWithRestaurantDto } from '@malou-io/package-dto';
import {
    GiftClaimDurationInDaysOption,
    GiftClaimStartDateOption,
    PlatformDefinitions,
    PlatformKey,
    WheelOfFortuneRedirectionPlatformKey,
} from '@malou-io/package-utils';

import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { InputDatePickerComponent } from ':shared/components/input-date-picker/input-date-picker.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SelectBaseComponent } from ':shared/components/select-abstract/select-base.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SimpleSelectComponent } from ':shared/components/simple-select/simple-select.component';
import { IFormGroup } from ':shared/interfaces/form-control-record.interface';
import { getNfcWithRestaurantDisplayName, RestaurantWithTotemsAndWheels } from ':shared/models';
import { WheelOfFortuneGlobalSettings } from ':shared/models/wheel-of-fortune';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';

import { AppRedirection, CloseModalSettings } from '../../upsert-wheel-of-fortune-modal.component';

@Component({
    selector: 'app-wheel-of-fortune-global-settings',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        FormsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        InputDatePickerComponent,
        SimpleSelectComponent,
        SelectComponent,
        EnumTranslatePipe,
        PlatformLogoComponent,
        SelectBaseComponent,
        ApplyPurePipe,
    ],
    templateUrl: './wheel-of-fortune-global-settings.component.html',
    styleUrls: ['./wheel-of-fortune-global-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WheelOfFortuneGlobalSettingsComponent implements OnInit {
    @Input() globalSettings: WritableSignal<WheelOfFortuneGlobalSettings>;
    @Input() selectedRestaurants: WritableSignal<RestaurantWithTotemsAndWheels[]>;
    @Input() selectedTotems: WritableSignal<NfcWithRestaurantDto[]>;
    @Input() isAggregatedView: boolean;
    @Input() isDisabled: boolean;
    @Output() onClose = new EventEmitter<CloseModalSettings>();

    readonly SvgIcon = SvgIcon;
    readonly TODAY = DateTime.now().startOf('day').toJSDate();
    readonly GIFT_CLAIM_DURATION_IN_DAYS_OPTIONS = GiftClaimDurationInDaysOption;
    readonly redirectPlatformOptions: WritableSignal<WheelOfFortuneRedirectionPlatformKey[]> = signal([PlatformKey.GMB]);
    readonly GiftClaimStartDateOption = GiftClaimStartDateOption;
    readonly WheelOfFortuneRedirectionPlatformKey = WheelOfFortuneRedirectionPlatformKey;

    totemsControl: FormControl<NfcWithRestaurantDto[]> = new FormControl([]) as FormControl<NfcWithRestaurantDto[]>;
    totems: NfcWithRestaurantDto[];
    giftClaimDurationInDaysControl: FormControl;
    redirectionPlatformControl: FormControl;
    wheelOfFortuneDatesForm: IFormGroup<{
        startDate: Date | null;
        endDate: Date | null;
    }>;

    constructor(
        private readonly _translateService: TranslateService,
        private readonly _platformsService: PlatformsService,
        private readonly _restaurantsService: RestaurantsService
    ) {}

    ngOnInit(): void {
        if (!this.isAggregatedView) {
            this._initTotemsControl();
        }
        this._initWofDatesForm();
        this._initGiftClaimDurationInDaysControl();
        const selectedRestaurantIds = this.selectedRestaurants()
            .map((restaurant) => restaurant.id)
            .filter(Boolean);
        if (!selectedRestaurantIds) {
            this._initRedirectionPlatformControl();
            return;
        }
        if (!this.isAggregatedView && this._restaurantsService.currentRestaurant?.isBrandBusiness()) {
            this.redirectPlatformOptions.set([PlatformKey.INSTAGRAM]);
            this.onRedirectionPlatformChange(PlatformKey.INSTAGRAM);
        } else if (selectedRestaurantIds.length) {
            this._platformsService
                .getPlatformsForMultipleRestaurants({
                    restaurantIds: selectedRestaurantIds,
                    keys: PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection(),
                })
                .subscribe({
                    next: (res) => {
                        this.redirectPlatformOptions.set([]);
                        const platforms = this._sortGmbFirst(PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection());
                        platforms.forEach((platformKey) => {
                            const existingPlatforms = res.data?.filter((platform) => platform.key === platformKey);
                            if (this.isAggregatedView && existingPlatforms.length > 0) {
                                this.redirectPlatformOptions.update((redirectPlatformOptions) => [
                                    ...redirectPlatformOptions,
                                    platformKey as WheelOfFortuneRedirectionPlatformKey,
                                ]);
                            }
                            if (!this.isAggregatedView && existingPlatforms.length === selectedRestaurantIds.length) {
                                this.redirectPlatformOptions.update((redirectPlatformOptions) => [
                                    ...redirectPlatformOptions,
                                    platformKey as WheelOfFortuneRedirectionPlatformKey,
                                ]);
                            }
                        });

                        this.redirectPlatformOptions.update((redirectPlatformOptions) => [
                            ...redirectPlatformOptions,
                            WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION,
                        ]);
                        if (!this.redirectPlatformOptions().includes(this.globalSettings().redirectionPlatform)) {
                            this.onRedirectionPlatformChange(this.redirectPlatformOptions()[0]);
                        }
                        this._initRedirectionPlatformControl();
                    },
                    error: () => this._initRedirectionPlatformControl(),
                });
        } else {
            const platformsOptions = this._sortGmbFirst(PlatformDefinitions.getPlatformKeysForWheelOfFortuneRedirection()).map(
                (platformKey) => platformKey as WheelOfFortuneRedirectionPlatformKey
            );
            this.redirectPlatformOptions.set([...platformsOptions, WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION]);
            this._initRedirectionPlatformControl();
        }
    }

    totemsSortBy = (a: NfcWithRestaurantDto, b: NfcWithRestaurantDto): number =>
        getNfcWithRestaurantDisplayName(a) > getNfcWithRestaurantDisplayName(b) ? 1 : -1;

    totemDisplayWith = (option: NfcWithRestaurantDto): string =>
        option.chipName
            ? [option.name || '', option.chipName].filter(Boolean).join(' - ')
            : this._translateService.instant('admin.nfcs.type.sticker');

    toggleIsEndDateMandatory(): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            isEndDateMandatory: !settings.isEndDateMandatory,
        }));
    }

    onGiftClaimStartDateOptionChange(event: MatRadioChange): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            giftClaimStartDateOption: event.value,
        }));
    }

    onGiftClaimDurationInDaysChange(giftClaimDurationInDays: number): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            giftClaimDurationInDays,
        }));
    }

    onRedirectionPlatformChange(redirectionPlatform: WheelOfFortuneRedirectionPlatformKey): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            redirectionPlatform,
        }));
    }

    displayGiftClaimDurationInDaysOptionsWith = (giftClaimDurationInDays: number): string => {
        switch (giftClaimDurationInDays) {
            // values of GiftClaimDurationInDaysOption
            case 2:
            case 7:
            case 15:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.gift_claim_duration_in_days_option',
                    { count: giftClaimDurationInDays }
                );
                break;
            case 30:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.one_month'
                );
            case 60:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.two_month'
                );
            case 182:
                return this._translateService.instant(
                    'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.six_month'
                );
                break;
        }
        return '';
    };

    getMinEndDate = (): Date | undefined => {
        const startDate = this.wheelOfFortuneDatesForm.get('startDate')?.value;
        return startDate ? DateTime.fromJSDate(startDate).startOf('day').toJSDate() : undefined;
    };

    onTotemSelectionChange(event: NfcWithRestaurantDto[]): void {
        this.totemsControl.setValue(event);
        this.selectedTotems.set(this.totemsControl.value);
    }

    close({
        shouldCheckBeforeClose = false,
        restaurantId,
        restaurantIdInAggregatedWheelOfFortune,
        redirection = AppRedirection.SETTINGS,
    }: CloseModalSettings): void {
        this.onClose.emit({ shouldCheckBeforeClose, restaurantId, restaurantIdInAggregatedWheelOfFortune, redirection });
    }

    private _initWofDatesForm(): void {
        this.wheelOfFortuneDatesForm = new FormGroup({
            startDate: new FormControl({ value: this.globalSettings().startDate, disabled: this.isDisabled }),
            endDate: new FormControl({ value: this.globalSettings().endDate, disabled: this.isDisabled }),
        });
        this.wheelOfFortuneDatesForm.valueChanges.subscribe((value) => this._onWheelOfFortuneDatesChange(value));
    }

    private _initGiftClaimDurationInDaysControl(): void {
        this.giftClaimDurationInDaysControl = new FormControl<number>({
            value: this.globalSettings().giftClaimDurationInDays,
            disabled: this.isDisabled,
        });
        this.giftClaimDurationInDaysControl.valueChanges.subscribe((value) => this.onGiftClaimDurationInDaysChange(value));
    }

    private _initRedirectionPlatformControl(): void {
        this.redirectionPlatformControl = new FormControl<WheelOfFortuneRedirectionPlatformKey>({
            value: this.globalSettings().redirectionPlatform,
            disabled: this.isDisabled,
        });
        this.redirectionPlatformControl.valueChanges.subscribe((value) => this.onRedirectionPlatformChange(value));
    }

    private _initTotemsControl(): void {
        this.totems = this.selectedRestaurants()?.[0].totems || [];
        this.totemsControl.setValue(this.selectedTotems() ?? []);
    }

    private _sortGmbFirst(platformKeys: PlatformKey[]): PlatformKey[] {
        return platformKeys.sort((a, b) => {
            if (a === PlatformKey.GMB) {
                return -1;
            } else if (b === PlatformKey.GMB) {
                return 1;
            }
            return 0;
        });
    }

    private _onWheelOfFortuneDatesChange(value: { startDate?: Date | null; endDate?: Date | null }): void {
        this.globalSettings.update((settings) => ({
            ...settings,
            startDate: value.startDate || null,
            endDate: value.endDate || null,
        }));
    }
}
