import { z } from 'zod';

import {
    AiInteractionRelatedEntityCollection,
    AiInteractionType,
    AiTextToOptimizeType,
    ApplicationLanguage,
} from '@malou-io/package-utils';

import { restaurantAiSettingsValidator } from '../restaurant-ai-settings';
import { objectIdValidator } from '../utils/validators';

export const generateReviewReplyBodyValidator = z.object({
    reviewId: objectIdValidator,
});
export type GenerateReviewReplyBodyDto = z.infer<typeof generateReviewReplyBodyValidator>;

export const generateSeoPostTextBodyValidator = z.object({
    postId: objectIdValidator,
    description: z.string(),
    lang: z.string(),
    restaurantId: objectIdValidator,
});
export type GenerateSeoPostTextBodyDto = z.infer<typeof generateSeoPostTextBodyValidator>;

export const generateSocialNetworkPostTextBodyValidator = z.object({
    postId: objectIdValidator,
    description: z.string(),
    restaurantId: objectIdValidator,
    lang: z.string(),
});
export type GenerateSocialNetworkPostTextBodyDto = z.infer<typeof generateSocialNetworkPostTextBodyValidator>;

export const choosePostHashtagsBodyValidator = z.object({
    postId: objectIdValidator,
    restaurantId: objectIdValidator,
    postText: z.string(),
    hashtags: z.array(z.string()),
});
export type ChoosePostHashtagsBodyDto = z.infer<typeof choosePostHashtagsBodyValidator>;

export const optimizeTextBodyValidator = z.object({
    relatedEntityId: objectIdValidator,
    textToOptimize: z.string(),
    restaurantId: objectIdValidator,
    textToOptimizeType: z.nativeEnum(AiTextToOptimizeType),
    lang: z.string(),
});
export type OptimizeTextBodyDto = z.infer<typeof optimizeTextBodyValidator>;

export const textTranslationBodyValidator = z.object({
    relatedEntityId: objectIdValidator,
    relatedEntityCollection: z.nativeEnum(AiInteractionRelatedEntityCollection),
    type: z.nativeEnum(AiInteractionType),
    text: z.string(),
    lang: z.string().nullish(),
    restaurantId: objectIdValidator,
});
export type TextTranslationBodyDto = z.infer<typeof textTranslationBodyValidator>;

export const keywordsBreakdownBodyValidator = z.object({
    keywords: z.array(z.string()).min(1).max(50),
    restaurantId: objectIdValidator,
    lang: z.string(),
});
export type KeywordsBreakdownBodyDto = z.infer<typeof keywordsBreakdownBodyValidator>;

// ------------------------------------------------------------------------------------------------

export const answerReviewPreviewBodyValidator = z.object({
    restaurantAiSettings: restaurantAiSettingsValidator.nullish(),
    text: z.string(),
    reviewerName: z.string(),
    lang: z.nativeEnum(ApplicationLanguage),
    sourceLanguage: z.nativeEnum(ApplicationLanguage),
    restaurantId: objectIdValidator,
});
export type AnswerReviewPreviewBodyDto = z.infer<typeof answerReviewPreviewBodyValidator>;
