import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { thirdPartyLinks } from ':core/constants';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-refer-your-friends',
    templateUrl: './refer-your-friends.component.html',
    styleUrls: ['./refer-your-friends.component.scss'],
    standalone: true,
    imports: [NgClass, MatButtonModule, TranslateModule, ImagePathResolverPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferYourFriendsComponent {
    readonly fullWidth = input<boolean>(false);

    readonly REFERRAL_LINK = thirdPartyLinks.REFERRAL_LINK;

    openLinkInNewTab(): void {
        window.open(this.REFERRAL_LINK, '_blank');
    }
}
