<div class="relative h-full w-full bg-malou-color-background-light">
    @if (isCropping() && !isMediaVideo()) {
        <div class="flex h-full w-full flex-col items-center justify-center bg-malou-color-background-light">
            <image-cropper
                class="p-0"
                id="cropped-image"
                [ngClass]="{ 'only-rotate': state() === 'rotate' }"
                [transform]="{ rotate: angle() }"
                [imageURL]="originalMedia().getMediaUrl() + '?x-request=foo'"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="state() === 'rotate'"
                [aspectRatio]="ratio()!"
                [format]="format()!"
                [imageQuality]="100"
                [alignImage]="'center'"
                [cropperMinWidth]="128"
                [onlyScaleDown]="true"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                #cropper>
            </image-cropper>

            @if (imageCropperMediaLoading()) {
                <app-malou-spinner
                    class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                    [size]="'xs'"></app-malou-spinner>
            }
        </div>
    } @else {
        <div
            class="flex {{
                aspectRatioClass()
            }} flex-col items-center justify-center transition-all absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] {{
                mediaSizeClass()
            }}">
            @if (isMediaVideo()) {
                <video
                    class="h-full w-full {{ objectFitClass() }}"
                    muted
                    loop
                    playsinline
                    [src]="media().getMediaUrl()"
                    #videoPlayer></video>
            } @else {
                <img
                    class="h-full w-full {{ objectFitClass() }}"
                    id="imageContainer"
                    [ngClass]="{ 'cursor-crosshair': tagButtonToggled() }"
                    [src]="mediaUrl()"
                    (click)="tagButtonToggled() && toggleShowTagContainerClick($event)"
                    #imageContainer />
            }
            @if (tagButtonToggled() && userTags().length) {
                @for (userTag of userTags(); track userTag) {
                    <div
                        class="fade-in absolute z-[5] translate-x-[-50%] translate-y-[-50%] transform transition-all"
                        [ngStyle]="{
                            top: (getCurrentTagPositionInMedia | applyPure: userTag.x : userTag.y).top,
                            left: (getCurrentTagPositionInMedia | applyPure: userTag.x : userTag.y).left,
                        }"
                        (click)="removeAccountClick(userTag.username)">
                        <div class="malou-box-shadow relative flex items-center gap-x-2 rounded-[8px] bg-white p-3.5">
                            <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.PROFILE"></mat-icon>
                            <span class="malou-text-12--semibold">{{ userTag.username }}</span>
                            <div
                                class="malou-box-shadow absolute left-[-9px] top-[-9px] grid h-5 w-5 cursor-pointer place-items-center rounded-full bg-white"
                                (click)="removeAccountClick(userTag.username)">
                                <mat-icon class="!h-3 !w-3" color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
        @if (isMediaVideo()) {
            <div
                class="absolute left-0 top-0 grid h-full w-full cursor-pointer place-items-center transition-all"
                [ngClass]="{ 'opacity-0 hover:opacity-100': isVideoPlaying() }"
                (click)="playVideo()">
                <div class="grid h-24 w-24 place-items-center rounded-full bg-white bg-opacity-70">
                    <mat-icon class="!h-9 !w-9" color="primary" [svgIcon]="isVideoPlaying() ? 'pause' : 'play'"></mat-icon>
                </div>
            </div>
            <div class="absolute bottom-3 left-0 flex flex-col gap-y-3">
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button (click)="muteUnmuteVideo()">
                    <mat-icon color="primary" [svgIcon]="isVideoMuted() ? 'sound-off' : 'sound-on'"></mat-icon>
                </button>
            </div>
        }

        @if (maxMedia() === 1 && isMediaDeletable()) {
            <div class="absolute bottom-3 right-3">
                <button class="malou-btn-icon--secondary btn-xl" mat-icon-button [disabled]="disabled()" (click)="removeMediaClick()">
                    <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
                </button>
            </div>
        }
        @if (media().getErrors().length) {
            <div class="absolute left-0 top-0 w-full p-3">
                <div class="flex w-full flex-col gap-y-2">
                    @for (item of media().getErrors(); track item) {
                        <div
                            class="bg-light-error malou-text-10--regular w-full rounded-[10px] px-4 py-3 italic text-malou-color-state-error">
                            {{ item }}
                        </div>
                    }
                </div>
            </div>
        }
        @if (tagButtonToggled() && showTagContainer()) {
            <div
                class="malou-box-shadow absolute left-[50%] top-[50%] z-10 w-[70%] translate-x-[-50%] translate-y-[-50%] transform rounded-[10px]"
                id="tag-account-container">
                <app-tag-account
                    [tagControl]="tagControl()"
                    [searching]="searching()"
                    [foundAccount]="foundAccount()"
                    (onAccountSelected)="onAccountSelected.emit($event)"
                    (onClose)="onClose.emit()"></app-tag-account>
            </div>
        }
    }
</div>
