<ng-container *ngTemplateOutlet="displayCloseModal() ? closeModal : page"></ng-container>

<ng-template #closeModal>
    <div class="grid h-full w-full place-items-center">
        <app-close-without-saving-modal (onConfirm)="hasTouched = false; close()" (onCancel)="displayCloseModal.set(false)">
        </app-close-without-saving-modal>
    </div>
</ng-template>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile !w-[100vw]">
        <div class="malou-dialog__header !hidden md:!flex">
            <span>
                {{ 'validation.modal.title_left' | translate }}
            </span>
            <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
        </div>
        <div class="malou-dialog__body !m-0 !p-0">
            <mat-icon
                class="close absolute right-8 top-8 !h-4 !w-4 cursor-pointer md:!hidden"
                color="primary"
                [svgIcon]="SvgIcon.CROSS"
                (click)="close()"></mat-icon>
            <div class="h-full grow">
                <ng-container *ngTemplateOutlet="isPhoneScreen() ? mobile : desktop"></ng-container>
            </div>
        </div>
        <div
            class="malou-dialog__footer !hidden md:!flex md:justify-between"
            [ngClass]="{
                'md:!bg-malou-color-background-light': currentTabIndex() === 1,
            }">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            <button class="malou-btn-raised--primary !h-11 md:grow" id="tracking_keywords_form_saving" mat-raised-button (click)="save()">
                {{ 'common.save' | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #desktop>
    <div class="flex h-full w-full">
        <ng-container *ngTemplateOutlet="leftSide"></ng-container>
        <ng-container *ngTemplateOutlet="rightSide"></ng-container>
    </div>
</ng-template>

<ng-template #mobile>
    <mat-tab-group [animationDuration]="0" (selectedIndexChange)="setCurrentTabIndex($event)">
        <mat-tab [label]="'validation.modal.propositions' | translate">
            <ng-container *ngTemplateOutlet="leftSide"></ng-container>
        </mat-tab>
        <mat-tab [label]="'validation.modal.my_kewords' | translate: { number: rightSource().data.length }">
            <ng-container *ngTemplateOutlet="rightSide"></ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #leftSide>
    <app-unselected-keywords-validation-modal
        class="w-full"
        [(dataSource)]="leftSource"
        [(selectedKeywordsDataSource)]="rightSource"
        [selection]="leftSelection()"
        [allKeywords]="allKeywords()"
        [unselectedKeywordLangs]="unselectedKeywordLangs()"
        [loading]="loading()"
        [isSaving]="isSaving()"
        [selectedLangs]="selectedLangs()"
        [updatingKeywordLangs]="updatingKeywordLangs()"
        (selectKeywords)="selectKeywords($event)"
        (updateKeywordLanguage)="updateKeywordLanguage($event.keyword, $event.lang, SideSelection.LEFT)"
        (selectedLangsChange)="selectedLangsChange($event)"
        (close)="close()"
        (save)="save()"></app-unselected-keywords-validation-modal>
</ng-template>

<ng-template #rightSide>
    <app-selected-keywords-validation-modal
        class="w-full"
        [(dataSource)]="rightSource"
        [selection]="rightSelection()"
        [allKeywords]="allKeywords()"
        [loading]="loading()"
        [isSaving]="isSaving()"
        (unselectKeywords)="unselectKeywords($event)"
        (updateKeywordLanguage)="updateKeywordLanguage($event.keyword, $event.lang, SideSelection.RIGHT)"
        (chooseKeywords)="chooseKeywordsForMe()"
        (close)="close()"
        (save)="save()"></app-selected-keywords-validation-modal>
</ng-template>

<ng-template #loadingShimmer>
    <div class="mt-5 w-full">
        <app-skeleton skeletonClass="h-[50px] w-full" [count]="7"></app-skeleton>
    </div>
</ng-template>
