import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, map, skip, switchMap } from 'rxjs';

import { generateDbId, HashtagType } from '@malou-io/package-utils';

import { HashtagsService } from ':core/services/hashtags.service';
import { KeywordsService } from ':core/services/keywords.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { Hashtag, Keyword } from ':shared/models';

import { NewSocialPostContext } from './new-social-post.context';

export enum ChooseHashtagsPanelDisplayState {
    OPEN = 'open',
    CLOSED = 'closed',
}

@Injectable({
    providedIn: 'any',
})
export class NewSocialPostHashtagsContext {
    readonly ChooseHashtagsPanelDisplayState = ChooseHashtagsPanelDisplayState;
    readonly chooseHashtagsPanelDisplayState = signal(ChooseHashtagsPanelDisplayState.CLOSED);

    readonly bookmarkedHashtags = signal<Hashtag[]>([]);
    readonly selectedHashtags = signal<Hashtag[]>([]);
    readonly generatedHashtags = signal<Hashtag[]>([]);
    readonly isGeneratingHashtags = signal(false);
    readonly mainHashtag = signal<Hashtag | null>(null);

    private readonly _newSocialPostContext = inject(NewSocialPostContext);
    private readonly _hashtagsService = inject(HashtagsService);
    private readonly _restaurantService = inject(RestaurantsService);
    private readonly _restaurantKeywords$ = inject(KeywordsService)
        .getKeywordsByRestaurantId(this._restaurantService.currentRestaurant._id)
        .pipe(map((res) => res.data.map((k) => new Keyword(k))));

    openPostHashtagsPanel(): void {
        this.chooseHashtagsPanelDisplayState.set(ChooseHashtagsPanelDisplayState.OPEN);
    }

    closePostHashtagsPanel(): void {
        this.chooseHashtagsPanelDisplayState.set(ChooseHashtagsPanelDisplayState.CLOSED);
    }

    onPostHashtagsChanges(): void {
        toObservable(this.selectedHashtags)
            .pipe(skip(2), debounceTime(100))
            .subscribe({
                next: (hashtags) => {
                    this._newSocialPostContext.postForm().get('post.hashtags')?.get('selected')?.setValue(hashtags);
                    this._newSocialPostContext.shouldAutoSave$.next({
                        field: 'hashtags',
                        value: {
                            selected: hashtags,
                            suggested: this.generatedHashtags(),
                        },
                    });
                },
            });

        toObservable(this.generatedHashtags)
            .pipe(skip(2), debounceTime(100))
            .subscribe({
                next: (hashtags) => {
                    this._newSocialPostContext.postForm().get('post.hashtags')?.get('suggested')?.setValue(hashtags);
                    this._newSocialPostContext.shouldAutoSave$.next({
                        field: 'hashtags',
                        value: {
                            selected: this.selectedHashtags(),
                            suggested: hashtags,
                        },
                    });
                },
            });
    }

    getRestaurantHashtags(): void {
        this._hashtagsService.getHashtagsForRestaurant(this._restaurantService.currentRestaurant._id).subscribe({
            next: (hashtags: Hashtag[]) => {
                let mainHashtag = hashtags.find((hashtag) => hashtag.isMain);
                if (!mainHashtag) {
                    mainHashtag = new Hashtag({
                        text: this._newSocialPostContext.currentRestaurant().getNameAsHashtag(),
                        restaurantId: this._restaurantService.currentRestaurant._id,
                        isMain: true,
                        id: generateDbId().toHexString(),
                        isCustomerInput: false,
                        type: HashtagType.RESTAURANT,
                    });

                    this._hashtagsService.saveHashtag(mainHashtag).subscribe({
                        error: (err) => console.error('err >', err),
                    });
                }
                this.mainHashtag.set(mainHashtag);

                this.generatedHashtags.update((prev) => [mainHashtag, ...prev]);

                this.bookmarkedHashtags.set([mainHashtag, ...hashtags.filter((hashtag) => hashtag.isMain === false)]);
            },
            error: (err) => console.error('err >', err),
        });
    }

    getSuggestedHashtags(): void {
        this.isGeneratingHashtags.set(true);
        this._restaurantKeywords$
            .pipe(
                switchMap((keywords) =>
                    this._hashtagsService.suggestHashtags(
                        {
                            favoriteHashtags: this.bookmarkedHashtags().map((hashtag) => hashtag.text),
                            keywords: keywords.flatMap((keyword) => keyword.bricks.map((brick) => brick.text)),
                            postDescription: this._newSocialPostContext.postForm().get('post.text')?.value ?? '',
                            restaurantCategory: [
                                this._newSocialPostContext.currentRestaurant().category?.categoryName?.[
                                    this._newSocialPostContext.currentLang()
                                ] ||
                                    this._newSocialPostContext.currentRestaurant().category?.categoryName?.backup ||
                                    '',
                            ],
                            restaurantLocality: this._newSocialPostContext.currentRestaurant().address?.locality ?? '',
                            restaurantName: this._newSocialPostContext.currentRestaurant().name,
                            restaurantPostalCode: this._newSocialPostContext.currentRestaurant().address?.postalCode ?? '',
                        },
                        this._restaurantService.currentRestaurant._id
                    )
                )
            )
            .subscribe({
                next: (res: { hashtags: Hashtag[]; preSelectedHashtags: Hashtag[] }) => {
                    this.generatedHashtags.set([this.mainHashtag()!, ...res.hashtags]);
                    this.selectedHashtags.set([this.mainHashtag()!, ...res.preSelectedHashtags]);
                    this.isGeneratingHashtags.set(false);
                },
                error: (err) => {
                    this.isGeneratingHashtags.set(false);
                    console.error('err >', err);
                },
            });
    }
}
