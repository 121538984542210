export const environment = {
    production: true,
    GOOGLE_FRONTEND_KEY: 'AIzaSyCH-RI6GzRxev_GKsNDWsZSrqP4q8oR8qc',
    APP_MALOU_API_URL: 'https://staging.api.malou.io',
    MAX_KEYWORDS_LENGTH: 10,
    S3_URI: 'https://malou-staging.s3.eu-west-3.amazonaws.com',
    environment: 'staging',
    PUSHER_KEY: '733a67ba5efb479776f1',
    experimentation_app_proxy_url: 'https://experimentation.malou.io',
    experimentation_app_client_key: 'sdk-1Opl3RBF1NxyS4a',
    APP_WEB_VERSION: 'v3',
    BASE_URL: 'http://staging.omni.malou.io/',
    JIMO_PROJECT_ID: 'd0d1083f-2824-443c-b6e8-51aaecdf7a47',
    imports: [],
};
