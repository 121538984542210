import { computed, inject, Injectable, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

import { ToastService } from ':core/services/toast.service';
import { runOptimistic } from ':shared/helpers/optimistic';

import { NotificationToastComponent } from '../components/notification-item/notification-toast/notification-toast.component';
import { Notification } from '../models/notification.model';
import { NotificationService } from '../services/notifications.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationCenterContext {
    private readonly _notificationService = inject(NotificationService);
    private readonly _toastService = inject(ToastService);
    private readonly _translate = inject(TranslateService);
    private readonly _matSnackBar = inject(MatSnackBar);

    readonly isOpen = signal(false);
    readonly notifications = signal<Notification[]>([]);

    readonly unreadNotificationsCount = computed(() => this.notifications().filter((n) => !n.isRead()).length);

    open(): void {
        this.isOpen.set(true);
    }

    close(): void {
        this.isOpen.set(false);
    }

    markAllAsRead(): void {
        const unreadNotifications = this.notifications().filter((n) => !n.isRead());
        runOptimistic({
            state: this.notifications,
            observable: this._notificationService.markAllNotificationsAsRead().pipe(
                tap((success) => {
                    if (!success) {
                        throw new Error('Failed to mark notifications as read');
                    }
                })
            ),
            optimisticUpdate: () => {
                this.notifications.update((notifications) =>
                    notifications.map((n) =>
                        unreadNotifications.some((unread) => unread.id === n.id)
                            ? n.copyWith({
                                  readAt: new Date(),
                              })
                            : n
                    )
                );
            },
            onError: (err) => this._showErrorToast(err),
        });
    }

    markNotificationAsRead(notification: Notification): void {
        runOptimistic({
            state: this.notifications,
            observable: this._notificationService
                .updateNotifications({
                    notificationIds: [notification.id],
                    update: {
                        readAt: new Date(),
                    },
                })
                .pipe(
                    tap((success) => {
                        if (!success) {
                            throw new Error('Failed to mark notification as read');
                        }
                    })
                ),
            optimisticUpdate: () => {
                this.notifications.update((notifications) =>
                    notifications.map((n) =>
                        n.id === notification.id
                            ? n.copyWith({
                                  readAt: new Date(),
                              })
                            : n
                    )
                );
            },
            onError: (err) => this._showErrorToast(err),
        });
    }

    markNotificationAsUnread(notification: Notification): void {
        runOptimistic({
            state: this.notifications,
            observable: this._notificationService
                .updateNotifications({
                    notificationIds: [notification.id],
                    update: {
                        readAt: null,
                    },
                })
                .pipe(
                    tap((success) => {
                        if (!success) {
                            throw new Error('Failed to mark notification as unread');
                        }
                    })
                ),
            optimisticUpdate: () => {
                this.notifications.update((notifications) =>
                    notifications.map((n) =>
                        n.id === notification.id
                            ? n.copyWith({
                                  readAt: null,
                              })
                            : n
                    )
                );
            },
            onError: (err) => this._showErrorToast(err),
        });
    }

    markNotificationAsCompleted(notification: Notification): void {
        runOptimistic({
            state: this.notifications,
            observable: this._notificationService
                .updateNotifications({
                    notificationIds: [notification.id],
                    update: {
                        completedAt: new Date(),
                    },
                })
                .pipe(
                    tap((success) => {
                        if (!success) {
                            throw new Error('Failed to mark notification as unread');
                        }
                    })
                ),
            optimisticUpdate: () => {
                this.notifications.update((notifications) =>
                    notifications.map((n) =>
                        n.id === notification.id
                            ? n.copyWith({
                                  readAt: null,
                              })
                            : n
                    )
                );
            },
            onError: (err) => this._showErrorToast(err),
        });
    }

    addNewNotification(notification: Notification): void {
        this.notifications.update((notifications) => [notification, ...notifications]);
    }

    removeNotification(notification: Notification): void {
        this.notifications.update((notifications) => notifications.filter((n) => n.id !== notification.id));
    }

    displayNewNotification(notification: Notification): void {
        this._matSnackBar.openFromComponent(NotificationToastComponent, {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            politeness: 'polite',
            data: notification,
            duration: 5000,
            panelClass: 'cursor-pointer',
        });
    }

    closeNotificationToast(): void {
        this._matSnackBar.dismiss();
    }

    updateNotification(notification: Notification): void {
        this.notifications.update((notifications) => notifications.map((n) => (n.id === notification.id ? notification : n)));
    }

    private _showErrorToast(err: any): void {
        const error = err.error?.message || err.message;
        this._toastService.openErrorToast(`${this._translate.instant('common.unknown_error')}  - ${error}`);
    }
}
