import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { cloneDeep, omit } from 'lodash';

import { ExperimentationService } from ':core/services/experimentation.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { OtherPeriod, Restaurant, SpecialTimePeriod, TimePeriod } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';

import { BusinessHoursFormComponent } from './business-hours-form/business-hours-form.component';
import { OtherHoursFormComponent } from './other-hours-form/other-hours-form.component';
import { SpecialHoursFormComponent } from './special-hours-form/special-hours-form.component';

export type HoursUpdateData = Partial<
    Pick<Restaurant, 'isClosedTemporarily' | 'regularHours' | 'specialHours' | 'otherHours' | 'availableHoursTypeIds'>
>;
export enum HoursModalTabs {
    REGULAR_HOURS,
    SPECIAL_HOURS,
    OTHER_HOURS,
}

@Component({
    selector: 'app-hours-modal',
    templateUrl: './hours-modal.component.html',
    styleUrls: ['./hours-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        ButtonComponent,
        CloseWithoutSavingModalComponent,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        BusinessHoursFormComponent,
        SpecialHoursFormComponent,
        TranslateModule,
        OtherHoursFormComponent,
        ApplyPurePipe,
        AsyncPipe,
    ],
})
export class HoursModalComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    readonly HoursModalTabs = HoursModalTabs;

    restaurant: Restaurant;
    displayCloseModal = false;
    specialHours: SpecialTimePeriod[];
    isClosedTemporarily: boolean;

    formErrors: Record<HoursModalTabs, boolean> = {
        [HoursModalTabs.REGULAR_HOURS]: false,
        [HoursModalTabs.SPECIAL_HOURS]: false,
        [HoursModalTabs.OTHER_HOURS]: false,
    };
    selectedTab = HoursModalTabs.REGULAR_HOURS;
    showAnteriorHours = false;

    hoursUpdate: {
        regularHours?: TimePeriod[];
        specialHours?: SpecialTimePeriod[];
        otherHours?: OtherPeriod[];
        availableHoursTypeIds?: string[];
    } = {};

    hasOneDirtyForm = false;

    readonly isPlatformsUpdatesReleaseEnabled$ = inject(ExperimentationService).isFeatureEnabled$('release-platforms-updates');

    constructor(
        private readonly _dialogRef: MatDialogRef<HoursModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            restaurant: Restaurant;
            selectedTab: HoursModalTabs;
            prefilledStartDate?: Date;
        },
        private readonly _translate: TranslateService,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.selectedTab = this.data.selectedTab;
    }

    ngOnInit(): void {
        this.restaurant = this.data.restaurant;
        this.specialHours = cloneDeep(this.restaurant.specialHours);
        this.isClosedTemporarily = this.restaurant?.isClosedTemporarily;
    }

    updateRegularHours({ regularHours }: { regularHours: TimePeriod[] }): void {
        this.hasOneDirtyForm = true;
        this.hoursUpdate = {
            ...this.hoursUpdate,
            regularHours,
        };
    }

    updateSpecialHours({ specialHours }: { specialHours: SpecialTimePeriod[] }): void {
        this.hasOneDirtyForm = true;
        this.hoursUpdate = {
            ...this.hoursUpdate,
            specialHours,
        };
    }

    updateOtherHours({ otherHours, availableHoursTypeIds }: { otherHours: OtherPeriod[]; availableHoursTypeIds: string[] }): void {
        this.hasOneDirtyForm = true;
        this.hoursUpdate = {
            ...this.hoursUpdate,
            otherHours,
            availableHoursTypeIds,
        };
    }

    handleFormErrors(hoursModalTabs: HoursModalTabs, event: boolean): void {
        this.formErrors = {
            ...this.formErrors,
            [hoursModalTabs]: event,
        };
    }

    handleTabChange(event: number): void {
        this.selectedTab = event;
    }

    getErrorText(selectedTab: HoursModalTabs): string {
        if (!this._doesOtherFormHaveErrors(selectedTab)) {
            return '';
        }
        const firstErrorIndex = Object.values(this.formErrors).findIndex((hasError) => hasError);
        return {
            [HoursModalTabs.REGULAR_HOURS]: this._translate.instant('information.hours.invalid_form_regular'),
            [HoursModalTabs.SPECIAL_HOURS]: this._translate.instant('information.hours.invalid_form_special'),
            [HoursModalTabs.OTHER_HOURS]: this._translate.instant('information.hours.invalid_form_other'),
        }[firstErrorIndex];
    }

    save(): void {
        if (this.isClosedTemporarily) {
            this.confirmClose({ isClosedTemporarily: this.isClosedTemporarily });
        } else {
            this.confirmClose({
                ...this.hoursUpdate,
                isClosedTemporarily: this.isClosedTemporarily,
            });
        }
    }

    changeOpenStatus(event: MatCheckboxChange): void {
        this.hasOneDirtyForm = true;
        this.isClosedTemporarily = event.checked;
    }

    close(): void {
        if (this.hasOneDirtyForm) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose();
        }
    }

    confirmClose(data?: HoursUpdateData): void {
        this._dialogRef.close(data);
    }

    doesFormHaveErrors(formErrors: Record<HoursModalTabs, boolean>): boolean {
        return Object.values(formErrors).reduce((acc, curr) => acc || curr, false);
    }

    private _doesOtherFormHaveErrors(hoursModalTabs: HoursModalTabs): boolean {
        const otherErrors = omit(this.formErrors, hoursModalTabs) as Record<HoursModalTabs, boolean>;
        return Object.values(otherErrors).reduce((acc, curr) => acc || curr, false);
    }
}
