<form class="relative mt-4" [formGroup]="businessHoursForm">
    <div class="flex flex-col gap-y-4 px-0 py-1">
        @for (hour of formHours.controls; track hour; let i = $index) {
            <div class="flex flex-col" data-cy="formHoursDiv" formArrayName="formHours">
                <div [ngClass]="{ 'px-5': shouldHoursHavePadding }">
                    @if (shouldHourBeVisible(hour.value.openDay, i)) {
                        <div class="mb-1.5 ml-1 flex md:!hidden">
                            <span class="malou-text-10--regular malou-color-text-2" [class.opacity-50]="isRestaurantClosed">
                                {{ hour.value.openDay && (hour.value.openDay | enumTranslate: 'days' | titlecase) }}
                            </span>
                        </div>
                    }
                    <div class="flex md:flex-col" [formGroupName]="i">
                        <div class="flex w-1/2 flex-col md:order-last md:w-full">
                            <div class="flex md:justify-between">
                                <div class="flex md:w-5/12">
                                    <mat-form-field
                                        class="custom-malou-outlined-form-field w-[110px] md:!w-full"
                                        appearance="outline"
                                        subscriptSizing="dynamic"
                                        [class.opacity-60]="isClosed(i)">
                                        <mat-select
                                            data-cy="openTimeSelect"
                                            formControlName="openTime"
                                            panelClass="malou-select-panel"
                                            placeholder="----"
                                            [hideSingleSelectionIndicator]="true"
                                            (selectionChange)="updateFullDay(i)">
                                            <mat-option value="24-24">{{ 'common.open_24h' | translate }}</mat-option>
                                            <mat-divider></mat-divider>
                                            @for (time of times; track time) {
                                                <mat-option [value]="time">
                                                    {{ time | formatTime }}
                                                </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="ml-2 flex md:w-5/12">
                                    <mat-form-field
                                        class="custom-malou-outlined-form-field w-[110px] md:!w-full"
                                        appearance="outline"
                                        subscriptSizing="dynamic"
                                        [class.opacity-60]="isClosed(i)">
                                        <mat-select
                                            data-cy="closeTimeSelect"
                                            formControlName="closeTime"
                                            panelClass="malou-select-panel"
                                            placeholder="----"
                                            [hideSingleSelectionIndicator]="true"
                                            (selectionChange)="updateCloseDay(i)">
                                            @for (time of times; track time) {
                                                <mat-option [value]="time">
                                                    {{ time | formatTime }}
                                                </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                @if (shouldDeleteBtnBeVisible(hour.value.openDay)) {
                                    <div class="relative ml-4 flex items-center md:w-2/12 md:justify-end">
                                        <button
                                            class="malou-btn-icon--secondary btn-xl"
                                            mat-icon-button
                                            matTooltip="{{ 'information.business_hours.delete' | translate }}"
                                            [disabled]="isRestaurantClosed"
                                            (click)="deleteHourInput(i)">
                                            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.TRASH"></mat-icon>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        @if (shouldHourBeVisible(hour.value.openDay, i)) {
                            <div class="flex w-1/2 items-center justify-end md:mb-4 md:!w-auto md:justify-between">
                                <div class="hidden md:flex">
                                    <span class="malou-text-10--regular malou-color-text-2" [class.disabled]="isRestaurantClosed">
                                        {{ hour.value.openDay && (hour.value.openDay | enumTranslate: 'days') }}
                                    </span>
                                </div>
                                <div class="flex justify-end">
                                    <div class="mr-2.5 mt-1 flex items-center">
                                        <app-slide-toggle
                                            [checked]="!isClosed(i)"
                                            [disabled]="isRestaurantClosed"
                                            (onToggle)="changeIsClosed($event, hour.value.openDay, i)">
                                        </app-slide-toggle>
                                    </div>
                                    <div class="relative flex items-center">
                                        <button
                                            class="malou-btn-icon--secondary btn-xl"
                                            id="copyHours"
                                            mat-icon-button
                                            matTooltip="{{ 'information.business_hours.duplicate_hour' | translate }}"
                                            [disabled]="isRestaurantClosed"
                                            (click)="copy($event, hour.value.openDay ?? null, i)">
                                            <mat-icon class="icon-btn" [svgIcon]="SvgIcon.COPY"></mat-icon>
                                        </button>
                                        @if (showCopyModal && currentCopyModalIndex === i) {
                                            <div class="malou-card duplicate-hours-modal w-full" id="duplicateModal" #duplicateModal>
                                                <ng-container *ngTemplateOutlet="copymodal"></ng-container>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    @if (formHours.at(i).invalid) {
                        <mat-error class="malou-color-state-error malou-text-10 mt-2 italic">
                            {{ formHours.at(i).errors?.error }}
                        </mat-error>
                    }
                    @if (hour.value.openDay && isLastPeriod(hour.value.openDay, i)) {
                        <div class="add-slot-btn mb-4 mt-1 flex h-10 items-center justify-start sm:!mb-6">
                            <button
                                class="malou-btn-flat !p-0"
                                mat-button
                                matTooltip="{{ 'information.business_hours.add_hour' | translate }}"
                                [disabled]="!canAddPeriod(hour.value) || isRestaurantClosed || isClosed(i)"
                                (click)="addHourInput(i, hour.value)">
                                <mat-icon class="icon-btn mr-2 h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                                {{ 'information.business_hours.add_hour' | translate }}
                            </button>
                            @if (showTimeSlotsWarning(i)) {
                                <div class="malou-text-10--regular ml-4">
                                    {{ 'information.business_hours.time_slots_warning' | translate }}
                                </div>
                            }
                        </div>
                    }
                </div>
                @if (hour.value.openDay && isLastPeriod(hour.value.openDay, i) && i !== formHours.controls.length - 1) {
                    <mat-divider class="border-color !mb-2"></mat-divider>
                }
            </div>
        }
    </div>
</form>

<ng-template #copymodal>
    <div class="malou-card__body !p-0 text-center">
        @for (day of DAYS; track day) {
            <div class="mb-2 flex justify-between" [class.opacity-50]="day === currentModalDay">
                <span class="malou-color-text-2 malou-text-12--regular">{{ day | enumTranslate: 'days' | titlecase }}</span>
                <mat-checkbox
                    color="primary"
                    [checked]="isChecked(day)"
                    [disabled]="day === currentModalDay"
                    (change)="toggleDuplicate($event, day)">
                </mat-checkbox>
            </div>
        }
    </div>
</ng-template>
