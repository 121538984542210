import { AsyncPipe, NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { DialogService } from ':core/services/dialog.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { OrganizationsService } from ':core/services/organizations.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { ManagersListModalComponent } from ':modules/admin/restaurants/managers-list-modal/managers-list-modal.component';
import {
    RestaurantUpdateForm,
    UpdateRestaurantModalComponent,
} from ':modules/admin/restaurants/update-restaurant-modal/update-restaurant-modal.component';
import * as RestaurantsActions from ':modules/restaurant-list/restaurant-list.actions';
import * as UserActions from ':modules/user/store/user.actions';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User, UserRestaurant } from ':modules/user/user';
import { UsersService } from ':modules/user/users.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { ApiResult, Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

@Component({
    selector: 'app-restaurants',
    templateUrl: './restaurants.component.html',
    styleUrls: ['./restaurants.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        NgTemplateOutlet,
        PaginatorComponent,
        SearchComponent,
        SlideToggleComponent,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTableModule,
        MatMenuModule,
        MatOptionModule,
        MatOptionModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSortModule,
        ReactiveFormsModule,
        TranslateModule,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ShortTextPipe,
        SlicePipe,
        ApplySelfPurePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantsComponent implements OnInit {
    readonly SvgIcon = SvgIcon;
    dataSource: MatTableDataSource<Restaurant> = new MatTableDataSource<Restaurant>([]);
    displayedColumns: string[] = [
        'name',
        'organization',
        'address',
        'managers',
        'functionalities',
        'active',
        'isManagedByLoggedUser',
        'actions',
    ];
    defaultSort: Sort = {
        active: 'createdAt',
        direction: 'desc',
    };
    readonly userRestaurants$: Observable<UserRestaurant[]> = this._store
        .select(selectUserInfos)
        .pipe(map((user: User) => user.restaurants));
    readonly refresh$: Subject<void> = new Subject<void>();

    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _store: Store,
        private readonly _translate: TranslateService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _dialogService: DialogService,
        private readonly _spinnerService: SpinnerService,
        private readonly _toastService: ToastService,
        private readonly _userService: UsersService,
        private readonly _organizationsService: OrganizationsService,
        private readonly _malouDialogService: DialogService
    ) {}

    @ViewChild(MatSort) set matSort(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this.refresh$
            .pipe(
                tap(() => this._spinnerService.show()),
                switchMap(() =>
                    combineLatest([
                        this._restaurantsService
                            .all({}, [
                                'address',
                                'name',
                                'active',
                                'organizationId',
                                'boosterPack',
                                'isYextActivated',
                                'roiActivated',
                                'type',
                            ])
                            .pipe(map((res) => res.data)),
                        this.userRestaurants$,
                    ])
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([allRestaurants, userRestaurants]) => {
                allRestaurants.forEach((r) => (r.isManagedByLoggedUser = !!userRestaurants.find((rest) => rest.restaurantId === r._id)));
                this.dataSource.data = allRestaurants;
                this._store.dispatch(RestaurantsActions.loadRestaurants());
                this._spinnerService.hide();
            });

        this.refresh$.next();
    }

    onSearchChange(searchValue: string): void {
        this.dataSource.filter = searchValue.trim().toLowerCase();
    }

    displayManagersListModal(managers: UserRestaurant[]): void {
        this._customDialogService.open(ManagersListModalComponent, {
            disableClose: false,
            data: {
                managers,
            },
        });
    }

    updateActive(active: boolean, restaurantId: string): void {
        this._spinnerService.show();
        const restaurant = this.dataSource.data.find((r) => r._id === restaurantId);
        if (!restaurant) {
            this._spinnerService.hide();
            return;
        }
        const { organizationId, organization } = restaurant;
        if (!organizationId || !organization) {
            this._spinnerService.hide();
            return;
        }
        this._organizationsService
            .getOrganizationRestaurantsCount(organizationId)
            .pipe(
                switchMap((restaurantsCount) => {
                    if (restaurantsCount < organization.limit || !active) {
                        return this._restaurantsService.updateActive(restaurantId, active);
                    }
                    return this._dialogService
                        .open({
                            title: this._translate.instant('common.oh'),
                            message: this._translate.instant('admin.restaurants.limit_reached'),
                            variant: DialogVariant.ALERT,
                            primaryButton: {
                                label: this._translate.instant('common.ok'),
                                action: () => {},
                            },
                        })
                        .afterClosed();
                }),
                catchError((err) => {
                    console.error('err :>> ', err);
                    this._spinnerService.hide();
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    return of(null);
                })
            )
            .subscribe({
                next: (result) => {
                    this._spinnerService.hide();
                    if (result) {
                        this.refresh$.next();
                    }
                },
            });
    }

    updateIsManagedByLoggedUser(restaurantId: string, isManagedByLoggedUser: boolean): void {
        this._spinnerService.show();
        const updateUserManagement$ = isManagedByLoggedUser
            ? this._restaurantsService.addRestaurantForUser(restaurantId)
            : this._restaurantsService.removeRestaurantForUser(restaurantId);
        updateUserManagement$.subscribe({
            next: () => {
                this._store.dispatch(UserActions.loadUser());
                this.refresh$.next();
            },
            error: (err) => {
                this._spinnerService.show();
                console.warn('err :>> ', err);
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    updateIsBoosterFeatureActivated(event: boolean, restaurantId: string): void {
        this._spinnerService.show();
        this._restaurantsService.adminUpdate(restaurantId, { boosterPack: { activated: event } }).subscribe({
            next: () => {
                this.refresh$.next();
            },
            error: (err) => {
                console.error('err :>> ', err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    updateIsYextActivated(isYextActivated: boolean, restaurantId: string): void {
        this._spinnerService.show();

        const restaurant$ = isYextActivated
            ? this._restaurantsService.activateYextForRestaurant(restaurantId)
            : this._restaurantsService.deactivateYextForRestaurant(restaurantId);

        restaurant$.subscribe({
            next: () => {
                this.refresh$.next();
            },
            error: (err) => {
                console.error('err :>> ', err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    updateIsRoiActivated(isRoiActivated: boolean, restaurantId: string): void {
        this._spinnerService.show();

        this._restaurantsService.adminUpdate(restaurantId, { roiActivated: isRoiActivated }).subscribe({
            next: () => {
                this.refresh$.next();
            },
            error: (err) => {
                console.error('err :>> ', err);
                this._spinnerService.hide();
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
            },
        });
    }

    updateRestaurant(restaurant: Restaurant): void {
        this._customDialogService
            .open(UpdateRestaurantModalComponent, {
                data: {
                    restaurant,
                },
            })
            .afterClosed()
            .subscribe((result: RestaurantUpdateForm) => {
                if (!result) {
                    return;
                }
                this._spinnerService.show();

                const updateManagers$ = this._getUpdateManagers$(restaurant, result);
                const updateOrganization$ = this._getUpdateOrganization$(restaurant, result);
                forkJoin([updateManagers$, updateOrganization$]).subscribe({
                    next: (res) => {
                        this._spinnerService.hide();
                        if (res?.filter((r) => !!r)?.length) {
                            this.refresh$.next();
                        }
                    },
                    error: (err) => {
                        console.error('err :>> ', err);
                        this._spinnerService.hide();
                        this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    },
                });
            });
    }

    openYextConfirmDeletionModal(restaurant: Restaurant, shouldDisableRestaurant: boolean): void {
        this._malouDialogService
            .open({
                variant: DialogVariant.INFO,
                title: this._translate.instant('admin.restaurants.yext.modal.delete.title'),
                primaryButton: {
                    label: this._translate.instant('common.confirm'),
                    action: () => {
                        this.updateIsYextActivated(false, restaurant._id);
                        if (shouldDisableRestaurant) {
                            this.updateActive(false, restaurant._id);
                        }
                    },
                },
                secondaryButton: {
                    label: this._translate.instant('common.cancel'),
                    action: () => {},
                },
            })
            .afterClosed()
            .subscribe();
    }

    openYextConfirmCreationModal(restaurant: Restaurant): void {
        this._malouDialogService
            .open({
                variant: DialogVariant.INFO,
                title: this._translate.instant('admin.restaurants.yext.modal.creation.title'),
                message: this._translate.instant('admin.restaurants.yext.modal.creation.description'),
                primaryButton: {
                    label: this._translate.instant('common.confirm'),
                    action: () => this.updateIsYextActivated(!restaurant.isYextActivated, restaurant._id),
                },
                secondaryButton: {
                    label: this._translate.instant('common.cancel'),
                    action: () => {},
                },
            })
            .afterClosed()
            .subscribe();
    }

    openYextConfirmModal(restaurant: Restaurant): void {
        const isYextAlreadyEnabled = restaurant.isYextActivated;

        if (isYextAlreadyEnabled) {
            this.openYextConfirmDeletionModal(restaurant, false);
        } else {
            this.openYextConfirmCreationModal(restaurant);
        }
    }

    proceedUpdateActive(restaurant: Restaurant, newActiveValue: boolean): void {
        if (!newActiveValue) {
            this._deactivateRestaurant(restaurant);
        } else {
            this._activateRestaurant(restaurant);
        }
    }

    private _getUpdateManagers$(restaurant: Restaurant, result: RestaurantUpdateForm): Observable<ApiResult | null> {
        if (
            result.users.length !== restaurant.managers.length ||
            result.users.some((u) => !restaurant.managers.map((m) => m.user._id).includes(u._id))
        ) {
            return this._restaurantsService.updateUserRestaurant(
                restaurant._id,
                result.users.map((u) => u._id)
            );
        } else {
            return of(null);
        }
    }

    private _getUpdateOrganization$(restaurant: Restaurant, result: RestaurantUpdateForm): Observable<ApiResult | null> {
        const organization = result.organization;
        if (!organization || organization._id === restaurant.organization?._id) {
            return of(null);
        }
        return this._organizationsService.getOrganizationRestaurantsCount(organization._id).pipe(
            switchMap((restaurantsCount) => {
                if (restaurantsCount < organization.limit) {
                    return this._restaurantsService.updateRestaurantOrganization(
                        { restaurantId: restaurant._id },
                        { organizationId: organization._id }
                    );
                }
                return this._dialogService
                    .open({
                        title: this._translate.instant('common.oh'),
                        message: this._translate.instant('admin.restaurants.orga_limit_reached', {
                            organization: organization.name,
                        }),
                        variant: DialogVariant.ALERT,
                        primaryButton: {
                            label: this._translate.instant('common.ok'),
                            action: () => {},
                        },
                    })
                    .afterClosed();
            })
        );
    }

    private _deactivateRestaurant(restaurant: Restaurant): void {
        if (restaurant.isYextActivated) {
            this.openYextConfirmDeletionModal(restaurant, true);
            return;
        }
        this.updateActive(false, restaurant._id);
    }

    private _activateRestaurant(restaurant: Restaurant): void {
        if (restaurant.isYextActivated) {
            this.updateIsYextActivated(false, restaurant._id);
        }
        this.updateActive(true, restaurant._id);
    }
}
