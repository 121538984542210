import { EntityConstructor, PlatformKey } from '@malou-io/package-utils';

import { Notification } from './notification.model';

export interface NegativeReviewReminderData {
    user: {
        name: string;
        email: string;
        defaultLanguage?: string;
    };
    restaurant: {
        id: string;
        name: string;
        unansweredReviewsCount: number;
    };
    review: {
        id: string;
        text?: string;
        platformKey: PlatformKey;
        socialCreatedAt: Date;
        rating: number;
        reviewerName?: string;
        reviewerProfilePictureUrl?: string;
        socialAttachmentUrls: string[];
    };
    remainingUnansweredReviews: {
        restaurantId: string;
        restaurantName: string;
        unansweredReviewsCount: number;
    }[];

    suggestedReply?: string;
}

type NegativeReviewReminderNotificationProps = EntityConstructor<NegativeReviewReminderNotification>;

export class NegativeReviewReminderNotification extends Notification {
    data: NegativeReviewReminderData;

    constructor(props: NegativeReviewReminderNotificationProps) {
        super(props);
        this.data = props.data;
    }

    copyWith(props: Partial<NegativeReviewReminderNotificationProps>): NegativeReviewReminderNotification {
        return new NegativeReviewReminderNotification({ ...this, ...props });
    }
}
