import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, signal, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KeywordScoreTextType } from '@malou-io/package-utils';

import { ratingEval } from ':core/constants';
import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { TemplatesService } from ':core/services/templates.service';
import { selectCurrentKeywords } from ':modules/keywords/store/keywords.selectors';
import {
    AVAILABLE_MESSAGE_TEMPLATE_VARIABLES,
    AVAILABLE_REVIEW_TEMPLATE_VARIABLES,
    TemplateReplacer,
    TemplateReplacerType,
} from ':modules/templates/template-replacer/template-replacer';
import { NiceDisplayTemplateService } from ':modules/templates/templates-display.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { ButtonComponent } from ':shared/components/button/button.component';
import { CloseWithoutSavingModalComponent } from ':shared/components/close-without-saving-modal/close-without-saving-modal.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { KeywordsScoreGaugeComponent } from ':shared/components/keywords-score-gauge/keywords-score-gauge.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { SelectPlatformsComponent } from ':shared/components/select-platforms/select-platforms.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { TemplateRatingFilterComponent } from ':shared/components/template-rating-filter/template-rating-filter.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { CommentOptionValue } from ':shared/enums/with-comment.enum';
import { removeNullOrEmptyField } from ':shared/helpers';
import { createTextWithEmoji, CursorPosition, focusAfterEmoji } from ':shared/helpers/text-area-emoji.helpers';
import { KillSubscriptions } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Keyword, MediumTemplate, Restaurant, Template } from ':shared/models';
import { CommentOption } from ':shared/models/comment-option';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';

interface Language {
    name: string;
    key: string;
}

enum TemplateType {
    REVIEW = 'review',
    MESSAGE = 'message',
}

interface TemplateForm {
    type: TemplateType;
    language: string;
    rating: number[];
    name: string;
    text: string;
    withComment: CommentOption;
}

@Component({
    selector: 'app-edit-create-template-modal',
    templateUrl: './edit-create-template-modal.component.html',
    styleUrls: ['./edit-create-template-modal.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        CloseWithoutSavingModalComponent,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonComponent,
        InputTextComponent,
        TemplateRatingFilterComponent,
        KeywordsScoreGaugeComponent,
        SlideToggleComponent,
        AsyncPipe,
        TranslateModule,
        SelectComponent,
        TextAreaComponent,
        SelectPlatformsComponent,
        ApplySelfPurePipe,
    ],
})
export class EditCreateTemplateModalComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    @ViewChild('keywordsScoreGauge') keywordsScoreGauge: KeywordsScoreGaugeComponent;

    readonly killSubscriptions$ = new Subject<void>();

    readonly textType$: BehaviorSubject<KeywordScoreTextType> = new BehaviorSubject(KeywordScoreTextType.HIGH_RATE_TEMPLATE);
    readonly text$ = new BehaviorSubject('');

    readonly restaurant$ = this._restaurantsService.restaurantSelected$;
    readonly restaurantKeywords$: Observable<Keyword[]> = this._store
        .select(selectCurrentKeywords)
        .pipe(takeUntil(this.killSubscriptions$));

    readonly TEMPLATE_REVIEW_TRANSLATE = this._translate.instant('templates.create');
    readonly TEXT_AREA_ID = 'template-textarea';

    TemplateType = TemplateType;

    template: Template | null;

    reviewId: string;

    templateForm: INullableFormGroup<TemplateForm>;
    restaurant: Restaurant;

    availableLanguages: Language[];
    availableCommentOptions: CommentOption[];
    ratingFilter: UntypedFormControl;

    type: TemplateType | null = null;

    hasOpenedKeywordsModal: boolean;

    chipList: string[];
    templateReplacer: TemplateReplacer;
    displayCloseModal = false;

    readonly isUpsertingTemplate = signal<boolean>(false);

    constructor(
        private readonly _dialogRef: MatDialogRef<EditCreateTemplateModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            template: MediumTemplate;
            type: TemplateType;
        },
        private readonly _formBuilder: FormBuilder,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _templatesService: TemplatesService,
        private readonly _niceDisplayTemplateService: NiceDisplayTemplateService,
        private readonly _translate: TranslateService,
        private readonly _store: Store,
        private readonly _dialogService: DialogService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.template = this.data.template ? new Template(this.data.template) : null;
        this.type = this.data.type || this.template?.type;
        this.availableCommentOptions = [
            { value: CommentOptionValue.WITH, text: this.TEMPLATE_REVIEW_TRANSLATE.with_comment },
            { value: CommentOptionValue.WITHOUT, text: this.TEMPLATE_REVIEW_TRANSLATE.without_comment },
            { value: CommentOptionValue.WITH_OR_WITHOUT, text: this.TEMPLATE_REVIEW_TRANSLATE.with_or_without_comment },
        ];
    }

    get textFormControl(): FormControl<string> {
        return this.templateForm.get('text') as FormControl<string>;
    }

    ngOnInit(): void {
        this.restaurant$.pipe(takeUntil(this.killSubscriptions$)).subscribe((restaurant: Restaurant) => {
            this.restaurant = restaurant;
        });

        this._initTemplateReplacer(this.type);
        this.chipList = this.templateReplacer.getChips();

        const textValue = this._replaceChipTextToVariableText(this.template?.text ?? '');
        this.text$.next(textValue);
        this.textType$.next(this.getTextTypeScore(this.template?.rating || []));

        this._initTemplateForm(textValue);

        this.templateForm
            .get('rating')
            ?.valueChanges.pipe(takeUntil(this.killSubscriptions$))
            .subscribe((rating) => {
                this.textType$.next(this.getTextTypeScore(rating ?? []));
            });
    }

    commentOptionDisplayWith = (commentOption: CommentOption): string => commentOption?.text;

    close(done: boolean | any = false): void {
        const actualText = this.text$.value ?? '';
        const templateText = this.template?.text ?? '';
        if (actualText !== templateText) {
            this.displayCloseModal = true;
        } else {
            this.confirmClose(done);
        }
    }

    confirmClose(done: boolean | Template = false): void {
        this._dialogRef.close(done);
    }

    getTextTypeScore(ratings: number[]): KeywordScoreTextType {
        const ratingEvaluation = this._niceDisplayTemplateService.getRatingEvaluation(ratings);
        return [ratingEval.NO_RATING, ratingEval.GOOD_RATING].includes(ratingEvaluation)
            ? KeywordScoreTextType.HIGH_RATE_TEMPLATE
            : KeywordScoreTextType.LOW_RATE_TEMPLATE;
    }

    getWithCommentValue(): CommentOption | null {
        const withComment = this.template?.withComment ?? CommentOptionValue.WITH_OR_WITHOUT;
        return this.availableCommentOptions.find((option) => option.value === withComment) ?? null;
    }

    checkLanguage(): void {
        if (
            !this.templateForm.get('language')?.value ||
            !this.availableLanguages.find((lang) => lang.name === this.templateForm.get('language')?.value)
        ) {
            this.templateForm.get('language')?.setValue('');
        }
    }

    onTextChange(text: string): void {
        text = this._replaceChipTextToVariableText(text);
        this.text$.next(text);
    }

    associatedRatingIcon(rating: number[]): string {
        return this._niceDisplayTemplateService.associatedRatingIcon(rating);
    }

    /**
     * Save template and navigate to list of templates
     */
    submit(): void {
        this._updateTextForExternalEmojis();
        const template = {
            ...this.templateForm.value,
            withComment: this.templateForm.value.withComment?.value,
        };
        const cleanData = removeNullOrEmptyField(template);
        if (this.type === TemplateType.MESSAGE) {
            delete cleanData.rating;
            delete cleanData.withComment;
        }
        cleanData.text = this._replaceChipTextToVariableText(cleanData.text);

        if (this.template) {
            this._update(cleanData);
        } else {
            this._create(cleanData);
        }
    }

    addText(inputText: string): void {
        const currentText = this.templateForm.get('text')?.value ?? '';
        const textarea = this._getTextArea();
        const cursorPosition: CursorPosition = {
            startPosition: textarea?.selectionStart,
            endPosition: textarea?.selectionEnd,
        };
        const textToInsert = this._computeTextWithSpace(currentText, cursorPosition, `${inputText}`);
        const text = createTextWithEmoji(cursorPosition, currentText, textToInsert);
        this.templateForm.get('text')?.setValue(text);
        focusAfterEmoji(textarea, cursorPosition, textToInsert);
    }

    addChipToText(chipText: string): void {
        const readableText = this._replaceVariableToChipText(chipText);
        this.addText(readableText);
    }

    getStarsText(rating: number): string {
        return rating === 1 ? this._translate.instant('reviews.reply_modal.star') : this._translate.instant('reviews.reply_modal.stars');
    }

    onSelectRatings(ratings: number[]): void {
        this.templateForm.patchValue({ rating: ratings });
    }

    private _create(cleanData: Template): void {
        this.isUpsertingTemplate.set(true);
        this.templateForm.disable();

        this._templatesService.create(cleanData, this.restaurant._id).subscribe({
            next: (res) => {
                this.confirmClose(res);
                this.isUpsertingTemplate.set(false);
                this.templateForm.enable();
            },
            error: (err) => {
                this.isUpsertingTemplate.set(false);
                this.templateForm.enable();
                this._handleUpsertError(err);
            },
        });
    }

    private _update(cleanData: Template): void {
        if (!this.template) {
            return;
        }
        this.isUpsertingTemplate.set(true);
        this.templateForm.disable();
        this._templatesService.update(this.template._id, cleanData).subscribe({
            next: (res) => {
                this.confirmClose(res);
                this.isUpsertingTemplate.set(false);
                this.templateForm.enable();
            },
            error: (err) => {
                this.isUpsertingTemplate.set(false);
                this.templateForm.enable();
                this._handleUpsertError(err);
            },
        });
    }

    private _handleUpsertError(err: HttpErrorResponse): void {
        const isDuplicate = ['Template name already exists', 'Duplicate entry'].includes(err?.error?.message);
        const message = isDuplicate ? this.TEMPLATE_REVIEW_TRANSLATE.template_already_exist : this._httpErrorPipe.transform(err);
        this._openErrorDialog(message);
    }

    private _updateTextForExternalEmojis(): void {
        const templateTextarea = this._getTextArea();
        if (templateTextarea?.value !== this.templateForm.get('text')?.value) {
            this.templateForm.get('text')?.patchValue(templateTextarea?.value);
        }
    }

    private _getTextArea(): HTMLTextAreaElement {
        return document.getElementById(this.TEXT_AREA_ID) as HTMLTextAreaElement;
    }

    private _initTemplateForm(textValue: string): void {
        this.templateForm = this._formBuilder.group(
            {
                rating: [this.template?.rating || []],
                name: [this.template?.name ?? '', Validators.required],
                text: [this._replaceVariableToChipText(textValue), Validators.required],
                withComment: [this.getWithCommentValue()],
                type: [this.type],
                language: '',
            },
            {
                validators: [this._hasNoVariableInTextValidator()],
            }
        );
        if (this.type === TemplateType.REVIEW) {
            this.templateForm.get('rating')?.setValidators(Validators.required);
        }
    }

    private _hasNoVariableInTextValidator(): ValidatorFn {
        return (group: INullableFormGroup<TemplateForm>): ValidationErrors | null => {
            const text = group.get('text')?.value ?? '';
            const variableToReplaceRegex = new RegExp(/\<\<|\>\>/);
            if (variableToReplaceRegex.test(text)) {
                return {
                    hasVariableToReplaceError: this._translate.instant('templates.edit_create_template.text_has_variable_to_replace'),
                };
            }

            return null;
        };
    }

    private _initTemplateReplacer(type: TemplateType | null): void {
        const templateVariables = type === TemplateType.REVIEW ? AVAILABLE_REVIEW_TEMPLATE_VARIABLES : AVAILABLE_MESSAGE_TEMPLATE_VARIABLES;
        this.templateReplacer = new TemplateReplacer(this._translate, templateVariables);
    }

    private _computeTextWithSpace(currentText: string, { startPosition, endPosition }: CursorPosition, text: string): string {
        const hasSpaceBefore = currentText[startPosition - 1] === ' ';
        const hasSpaceAfter = currentText[endPosition] === ' ';
        const shouldAddSpaceBefore = currentText[startPosition - 1] && !hasSpaceBefore;
        const shouldAddSpaceAfter = currentText[endPosition] && !hasSpaceAfter;
        return `${shouldAddSpaceBefore ? ' ' : ''}${text}${shouldAddSpaceAfter ? ' ' : ''}`;
    }

    private _replaceVariableToChipText(text: string): string {
        return this.templateReplacer.replaceText(text, {
            from: TemplateReplacerType.VARIABLE_TEXT,
            to: TemplateReplacerType.CHIP_TEXT,
        });
    }

    private _replaceChipTextToVariableText(text: string): string {
        return this.templateReplacer.replaceText(text, {
            from: TemplateReplacerType.CHIP_TEXT,
            to: TemplateReplacerType.VARIABLE_TEXT,
        });
    }

    private _openErrorDialog(title: string): void {
        this._dialogService.open({
            variant: DialogVariant.ERROR,
            title,
            primaryButton: {
                label: this._translate.instant('common.close'),
            },
        });
    }
}
