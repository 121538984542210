<div class="malou-dialog max-h-[80vh]">
    <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="bodyTemplate"></ng-container>
</div>

<ng-template #headerTemplate>
    <div class="malou-dialog__header">
        <span class="malou-text-section-title malou-color-text-1">
            {{ 'platforms.connection.automatically_connected_platforms' | translate }}</span
        >
        <button class="malou-btn-icon" mat-icon-button (click)="close()">
            <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
</ng-template>

<ng-template #bodyTemplate>
    <div class="malou-dialog__body malou-expansion-panel flex flex-col gap-y-2" id="automaticallyConnectedPlatforms">
        <mat-accordion>
            @for (publisher of publishers; track publisher) {
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <div class="flex w-full items-center justify-between pr-7">
                            <div class="flex items-center">
                                <div
                                    class="flex h-7 w-7 items-center"
                                    [ngClass]="{
                                        'rounded-md':
                                            yextPublishers[publisher.id].doesLogoNeedBorders ||
                                            yextPublishers[publisher.id].logoBackgroundColor,
                                        'malou-border-color-dark border': yextPublishers[publisher.id].doesLogoNeedBorders,
                                    }"
                                    [ngStyle]="{ background: yextPublishers[publisher.id].logoBackgroundColor }">
                                    <app-yext-platform-logo
                                        imgClasses="max-h-7 w-7 object-contain"
                                        [yextPublisherId]="publisher.id"></app-yext-platform-logo>
                                </div>
                                <span class="malou-text-12--semibold ml-2 text-malou-color-text-2">
                                    {{ yextPublishers[publisher.id].name }}
                                </span>
                                @if (publisher.url) {
                                    <button
                                        class="malou-btn-flat ml-2 !p-0"
                                        id="openPublisherUrlBtn"
                                        mat-button
                                        (click)="openPublisherUrl(publisher)">
                                        <mat-icon class="!ml-1 !mr-0 !h-4 !w-4" color="primary" svgIcon="eye"></mat-icon>
                                        {{ 'platforms.connection.see_page' | translate }}
                                    </button>
                                }
                            </div>
                            <div class="flex items-center gap-x-4">
                                <mat-icon
                                    class="!h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                                    [svgIcon]="SvgIcon.CHECK"></mat-icon>
                                <div class="malou-text-13 text-malou-color-text-1">
                                    {{ 'platforms.connection.successfully_connected_platforms' | pluralTranslate: 1 }}
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                        <hr class="w-full border-malou-color-background-dark" />
                        <ng-container [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ publisher }"></ng-container>
                    </ng-template>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</ng-template>

<ng-template let-publisher="publisher" #contentTemplate>
    <div class="px-6 py-5">
        <ng-container [ngTemplateOutlet]="featuresTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="informationUpdateTemplate" [ngTemplateOutletContext]="{ publisher }"></ng-container>
        <ng-container
            [ngTemplateOutlet]="updateTimeTemplate"
            [ngTemplateOutletContext]="{ updateTime: yextPublishers[publisher.id].updateTime }"></ng-container>
    </div>
</ng-template>

<ng-template #featuresTemplate>
    <div class="malou-text-14--bold mb-2 text-malou-color-text-1">
        {{ 'platforms.connection.features.available_features' | translate }}
    </div>
    <ul class="mb-6 flex flex-col gap-y-1">
        <li class="ml-5 flex items-center gap-x-2">
            <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
            <div class="malou-text-12--regular text-malou-color-text-2">
                {{ 'platforms.connection.features.update_informations' | translate }}
            </div>
        </li>
    </ul>
</ng-template>

<ng-template let-publisher="publisher" #informationUpdateTemplate>
    <div class="malou-text-14--bold mb-2 text-malou-color-text-1">
        {{ 'platforms.connection.update_information.title' | translate }}
    </div>
    <div class="mb-6 grid grid-cols-2 gap-1.5">
        @if (yextPublishers[publisher.id].updateFields.name) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.name' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.categories) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.categories' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.hours) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.hours' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.description) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.description' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.address) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.address' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.logo) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.logo' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.phone) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.phone' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.website) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.website' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.menuUrl) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.menu_url' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.temporaryClosed) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.update_information.temporary_closed' | translate }}
                </div>
            </div>
        }
        @if (yextPublishers[publisher.id].updateFields.cover) {
            <div class="ml-5 flex items-center gap-x-2">
                <div class="!h-[7px] !w-[7px] rounded-full bg-malou-color-primary"></div>
                <div class="malou-text-12--regular text-malou-color-text-2">
                    {{ 'platforms.connection.cover' | translate }}
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template let-updateTime="updateTime" #updateTimeTemplate>
    <div class="flex justify-between rounded-lg border border-malou-color-border-primary p-5">
        <div class="flex">
            <span class="mr-2">⏳</span>
            <span class="malou-text-12--bold">{{ 'platforms.connection.update_time' | translate }}</span>
        </div>
        <span class="malou-text-12--bold">{{ updateTime | enumTranslate: 'update_time' }}</span>
    </div>
</ng-template>
