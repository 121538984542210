import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, OnInit, output, signal, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLineModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { groupBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';

import {
    BusinessCategory,
    MalouErrorCode,
    PlatformAccessStatus,
    PlatformAccessType,
    PlatformKey,
    TimeInMilliseconds,
} from '@malou-io/package-utils';

import { AuthService } from ':core/auth/auth.service';
import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { notionLinks, PhotoCategory } from ':core/constants';
import { GoogleService } from ':core/services/google.service';
import { SpinnerService } from ':core/services/malou-spinner.service';
import { PlatformsService } from ':core/services/platforms.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { MediaService } from ':modules/media/media.service';
import { PlatformMedia } from ':modules/media/utils/platform-media-downloader.service';
import { RoiNotificationsContext } from ':modules/notification-center/notifications.context';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { ApiResult, Credential, PermissionsState, PlatformSearch, Restaurant } from ':shared/models';
import { Organization } from ':shared/models/organization';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AsTypePipe } from ':shared/pipes/as.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ProfileLinkPlatformPipe } from ':shared/pipes/profile-link-platform.pipe';

class ObjectWithError {
    error: string;
}

@Component({
    selector: 'app-create-restaurant-from-platform-modal',
    templateUrl: './create-restaurant-from-platform-modal.component.html',
    styleUrls: ['./create-restaurant-from-platform-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        MalouSpinnerComponent,
        PaginatorComponent,
        SearchComponent,
        SelectComponent,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatListModule,
        MatLineModule,
        ReactiveFormsModule,
        TranslateModule,
        ApplyPurePipe,
        AsTypePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ProfileLinkPlatformPipe,
    ],
})
export class CreateRestaurantFromPlatformModalComponent implements OnInit {
    readonly currentPlatformKey = input.required<string>();
    readonly selectedStep = input.required<number>();
    readonly selectedAuth = input.required<string | undefined>();
    readonly credentials = input.required<Record<string, Credential[]>>();
    readonly close = output<void>();
    readonly next = output<{
        createdRestaurantId?: string;
        picture: string;
        title: string;
        details?: string[];
        primaryButtonLabel?: string;
        secondaryButtonLabel?: string;
        onClickPrimaryButton?: () => void;
        onClickSecondaryButton?: () => void;
    }>();
    readonly setAddressGmbWarningMessage = output<string>();
    readonly validationChange = output<boolean>();
    readonly editRestaurantConnection = output<void>();

    readonly SvgIcon = SvgIcon;
    readonly PlatformKey = PlatformKey;
    readonly ObjectWithError = ObjectWithError;

    readonly FB_HELP_LINK = notionLinks.FB_PAGE;

    readonly isLoading$ = new BehaviorSubject(false);
    readonly PlatformSearchArray = Array<PlatformSearch>;

    readonly platformCredentials = computed((): Credential[] => this.credentials()[this.currentPlatformKey()] ?? []);
    readonly groupedRestaurantsFoundByAccount = signal<PlatformSearch[][]>([]);
    readonly selectedLocation = signal<PlatformSearch | undefined>(undefined);
    readonly platformCredentialForm = new UntypedFormControl();
    readonly restaurantsFound$: Observable<PlatformSearch[] | ObjectWithError> = this._initRestaurantFound();
    readonly restaurantsFound = toSignal(this.restaurantsFound$, { initialValue: [] });

    readonly selectedAccountStatus = signal<PermissionsState>({ isValid: false, missing: [] });
    selectedOption: string;

    readonly restaurantsGroupedControl = new FormControl<PlatformSearch[]>([]);

    readonly dataSource: MatTableDataSource<PlatformSearch> = new MatTableDataSource([]);

    readonly trackBySocialIdFn = TrackByFunctionFactory.get('socialId');

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    constructor(
        private readonly _platformsService: PlatformsService,
        private readonly _translate: TranslateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _googleService: GoogleService,
        private readonly _mediaService: MediaService,
        private readonly _spinnerService: SpinnerService,
        private readonly _router: Router,
        private readonly _authService: AuthService,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _roiNotificationsContext: RoiNotificationsContext
    ) {
        effect(
            () => {
                // when you click on the type of restaurant you want to create (gmb or fb), we set the credentials
                const platformCredentials = this.platformCredentials();
                const selectedAuth = this.selectedAuth();
                const selectedCredential = platformCredentials.find((cred) => cred.authId === selectedAuth) ?? platformCredentials[0];
                if (selectedCredential) {
                    this.platformCredentialForm.setValue(selectedCredential);
                }
            },
            { allowSignalWrites: true }
        );

        effect(() => {
            if (this.currentPlatformKey() === PlatformKey.FACEBOOK) {
                this.setAddressGmbWarningMessage.emit('');
            }
        });
    }

    get currentPageData(): PlatformSearch[] {
        if (!this.dataSource?.filteredData) {
            return [];
        }
        return this.dataSource._pageData(this.dataSource.filteredData);
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this.restaurantsFound$.subscribe({
            next: (restaurants) => {
                this.dataSource.data = restaurants as PlatformSearch[];
            },
        });
    }

    applyFilter(textFilter: string): void {
        if (!this.dataSource?.filteredData) {
            return;
        }
        this.dataSource.filter = textFilter?.trim()?.toLowerCase() || '';
    }

    createRestaurant(organization: Organization): void {
        const selectedLocation = this.selectedLocation();
        if (!selectedLocation) {
            return;
        }
        this._spinnerService.show();
        of(this.platformCredentialForm.value?._id)
            .pipe(
                switchMap((credentialId) => {
                    if (this.currentPlatformKey() === PlatformKey.GMB) {
                        return this._createRestaurantFromGmb$(credentialId, organization._id, selectedLocation);
                    } else if (this.currentPlatformKey() === PlatformKey.FACEBOOK) {
                        return this._createRestaurantFromFacebook$(credentialId, organization._id, selectedLocation);
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe({
                next: (res: any) => {
                    this._spinnerService.hide();
                    if (res?.data) {
                        this._restaurantsService.upsertRestaurant(res.data);
                        this._roiNotificationsContext.openNotificationCenterOnRestaurantCreation$.next(true);
                        return this.next.emit({
                            createdRestaurantId: res.data._id,
                            picture: 'Gogool_ok',
                            primaryButtonLabel: this._translate.instant('new_restaurant.choose_restaurant.connect_platforms'),
                            secondaryButtonLabel: this._translate.instant('common.later'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.congrats'),
                            details: [this._translate.instant('new_restaurant.choose_restaurant.congrats_details_1')],
                            onClickPrimaryButton: () => {
                                this._router.navigate(['restaurants', res.data._id, 'settings', 'platforms'], {
                                    queryParams: { new_restaurant: true },
                                });
                            },
                            onClickSecondaryButton: () => {},
                        });
                    } else {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('new_restaurant.choose_restaurant.connect_platforms'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.error'),
                            details: [this._translate.instant('new_restaurant.choose_restaurant.error_creating_restaurant')],
                        });
                    }
                },
                error: (err) => {
                    this._spinnerService.hide();
                    if ((err.status === 403 && err.error.casl) || err?.message === 'cancel') {
                        return;
                    } else if (err?.error?.malouErrorCode?.match(MalouErrorCode.ABOVE_RESTAURANTS_LIMITS)) {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('common.back'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.restaurant_limit_reached'),
                            details: [],
                        });
                    } else if (err.error?.message?.match(/credential_has_no_organization/)) {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('common.back'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.no_organization'),
                            details: [],
                        });
                    } else if (err.error?.malouErrorCode?.match(MalouErrorCode.BODY_VALIDATION_ERROR)) {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('common.back'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.missing_properties'),
                            details: [
                                this._translate.instant('new_restaurant.choose_restaurant.missing_property_list'),
                                this._translate.instant('new_restaurant.choose_restaurant.missing_property_list_details_link_word'),
                                JSON.stringify(err.error),
                            ],
                        });
                    } else if (err.error?.malouErrorCode === MalouErrorCode.GMB_PLACE_ID_IS_NO_LONGER_VALID) {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('common.back'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.cannot_find_latlng'),
                            details: [this._translate.instant('new_restaurant.choose_restaurant.cannot_find_latlng_details')],
                        });
                    } else {
                        return this.next.emit({
                            picture: 'Icecream',
                            primaryButtonLabel: this._translate.instant('common.back'),
                            title: this._translate.instant('new_restaurant.choose_restaurant.error_creating_restaurant'),
                            details: [err.error?.message, err?.message],
                        });
                    }
                },
            });
    }

    changeLocation(event: MatRadioChange): void {
        this.selectedLocation.set(event.value);
        if (this.currentPlatformKey() === PlatformKey.GMB && !event.value?.formattedAddress) {
            this.validationChange.emit(false);
            this.setAddressGmbWarningMessage.emit(this._translate.instant('new_restaurant.some_features_might_not_work_without_address'));
        } else {
            this.setAddressGmbWarningMessage.emit('');
            this.validationChange.emit(true);
        }
    }

    getExpirationTime(access: PermissionsState): number {
        const now = new Date().getTime();
        const expirationDate = access.dataExpiresAt ? access.dataExpiresAt * TimeInMilliseconds.SECOND : now;
        const daysLeft = (expirationDate - now) / TimeInMilliseconds.DAY;
        return Math.trunc(daysLeft);
    }

    onEditRestaurantConnection(): void {
        this.editRestaurantConnection.emit();
    }

    displayCredentialSelect(credential: Credential): string {
        return credential.name ?? credential.authId;
    }

    displayGroupedRestaurantSelect(restaurantsGrouped: PlatformSearch[]): string {
        return restaurantsGrouped[0].accountName ?? '';
    }

    private _createRestaurantFromGmb$(
        credentialId: string,
        selectedOrganizationId: string,
        selectedLocation: PlatformSearch
    ): Observable<ApiResult<Restaurant>> {
        let restaurantId: string;
        return this._googleService.getLocationAttributes(selectedLocation.locationId, credentialId).pipe(
            switchMap((res) =>
                this._restaurantsService.create({
                    ...selectedLocation,
                    type: BusinessCategory.LOCAL_BUSINESS,
                    attributes: res.data.attributes,
                    credentialId,
                    organizationId: selectedOrganizationId,
                })
            ),
            switchMap((res) => {
                restaurantId = res.data.restaurantId;
                const autoAccess = {
                    lastVerified: undefined,
                    active: true,
                    platformKey: PlatformKey.GMB,
                    accessType: PlatformAccessType.AUTO,
                    data: undefined,
                    status: PlatformAccessStatus.VERIFIED,
                    lastUpdated: new Date(),
                };
                return this._restaurantsService.updatePlatformAccess(restaurantId, autoAccess);
            }),
            switchMap(() =>
                selectedLocation.accountId?.split('/')[1]
                    ? this._googleService.listMediasByLocId(selectedLocation.accountId?.split('/')[1], selectedLocation.locationId, false)
                    : of(null)
            ),
            filter(Boolean),
            switchMap((mediasGmb: { data: PlatformMedia[] | undefined }) =>
                this._mediaService.uploadPlatformMedias(restaurantId, mediasGmb.data).pipe(catchError(() => of(null)))
            ),
            switchMap((medias) => {
                const update = {};
                const logo = medias?.find((media) => media.category === PhotoCategory.PROFILE);
                const cover = medias?.find((media) => media.category === PhotoCategory.COVER);
                if (logo) {
                    update['logo'] = logo.id;
                    update['logoChanged'] = false;
                }
                if (cover) {
                    update['cover'] = cover.id;
                    update['coverChanged'] = false;
                }
                return this._restaurantsService.update(restaurantId, update);
            })
        );
    }

    private _createRestaurantFromFacebook$(
        credentialId: string,
        selectedOrganizationId: string,
        selectedLocation: PlatformSearch
    ): Observable<ApiResult<Restaurant>> {
        let restaurantId: string;
        return this._restaurantsService
            .create({
                ...selectedLocation,
                type: BusinessCategory.BRAND,
                credentialId,
                organizationId: selectedOrganizationId,
            })
            .pipe(
                switchMap((res) => {
                    restaurantId = res.data.restaurantId;
                    const autoAccess = {
                        lastVerified: undefined,
                        active: true,
                        platformKey: PlatformKey.FACEBOOK,
                        accessType: PlatformAccessType.AUTO,
                        data: undefined,
                        status: PlatformAccessStatus.VERIFIED,
                        lastUpdated: new Date(),
                    };
                    return this._restaurantsService.updatePlatformAccess(restaurantId, autoAccess);
                }),
                switchMap(() => this._authService.logout$()), // refresh session because we add a new restaurant for current user
                switchMap(() =>
                    this._platformsService.getLogoAndCoverForPlatform(PlatformKey.FACEBOOK, selectedLocation.socialId, credentialId)
                ),
                switchMap((mediasFb) => this._mediaService.uploadPlatformMedias(restaurantId, Object.values(mediasFb.data))),
                switchMap((medias) => {
                    const update = {};
                    const logo = medias.find((media) => media.category === PhotoCategory.PROFILE);
                    const cover = medias.find((media) => media.category === PhotoCategory.COVER);
                    if (logo) {
                        update['logo'] = logo.id;
                        update['logoChanged'] = false;
                    }
                    if (cover) {
                        update['cover'] = cover.id;
                        update['coverChanged'] = false;
                    }
                    return this._restaurantsService.update(restaurantId, update);
                })
            );
    }

    private _initRestaurantFound(): Observable<PlatformSearch[] | ObjectWithError> {
        return this.platformCredentialForm.valueChanges.pipe(
            startWith(this.platformCredentialForm.value),
            filter(Boolean),
            switchMap((chosenCredential: Credential) => {
                this.validationChange.emit(false);
                this.isLoading$.next(true);
                return this._platformsService
                    .getSocialIds({
                        restaurantId: null,
                        platformKey: this.currentPlatformKey(),
                        credentialId: chosenCredential._id,
                    })
                    .pipe(
                        map(({ list }) => {
                            this.isLoading$.next(false);

                            const restaurantsFound = list; // temporary fix that show all accounts
                            this.selectedAccountStatus.set(restaurantsFound?.[0]?.access || { isValid: false, missing: [] });
                            if (this.currentPlatformKey() === PlatformKey.GMB) {
                                this.groupedRestaurantsFoundByAccount.set(Object.values(groupBy(restaurantsFound, 'accountId')));
                                this.restaurantsGroupedControl.setValue(this.groupedRestaurantsFoundByAccount()[0]);
                                return restaurantsFound;
                            }
                            this.restaurantsGroupedControl.setValue(restaurantsFound);
                            return restaurantsFound;
                        }),
                        catchError(() => {
                            this.isLoading$.next(false);
                            return of({ error: 'no_access' });
                        })
                    );
            })
        );
    }
}
