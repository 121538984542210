<button
    class="!rounded-[10px] !bg-malou-refer-your-friends-background !text-malou-color-text-white"
    mat-flat-button
    [ngClass]="{ 'malou-text-17--semibold !h-12 w-full': fullWidth(), 'malou-text-12--semibold': !fullWidth() }"
    (click)="openLinkInNewTab()">
    <div class="flex items-center justify-center gap-x-3">
        <img
            [ngClass]="{ 'h-5': !fullWidth(), 'h-8': fullWidth() }"
            [alt]=""
            [src]="'gift' | imagePathResolver: { folder: 'wheels-of-fortune' }" />
        <span>{{ 'common.refer_a_friend' | translate }}</span>
    </div>
</button>
