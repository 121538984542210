import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { compact } from 'lodash';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { filter, forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import { PlatformDefinitions } from '@malou-io/package-utils';

import { DialogService } from ':core/services/dialog.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { CampaignsService } from ':modules/campaigns/campaigns.service';
import { ClientFileImportModalComponent } from ':modules/clients/client-file-import-modal/client-file-import-modal.component';
import { ClientsService } from ':modules/clients/clients.service';
import { ManualClientImportModalComponent } from ':modules/clients/manual-client-import-modal/manual-client-import-modal.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { NoResultsComponent } from ':shared/components/no-results/no-results.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { RestaurantsSelectionModalComponent } from ':shared/components/restaurants-selection-modal/restaurants-selection-modal.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { FilterOption, SortByFiltersComponent } from ':shared/components/sort-by-filters/sort-by-filters.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { KillSubscriptions } from ':shared/interfaces';
import { ApiResult, Pagination, Restaurant } from ':shared/models';
import { Campaign } from ':shared/models/campaign';
import { CampaignStarDetails, Client, ClientsSource, ClientWithStats, ContactMode } from ':shared/models/client';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { FormatDatePipe } from ':shared/pipes/format-date.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { Illustration, IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PlatformNamePipe } from ':shared/pipes/platform-name.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

const DEFAULT_PAGINATION: Pagination = {
    pageSize: 10,
    pageNumber: 0,
    total: 0,
};
@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' },
        },
    ],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        LazyLoadImageModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatMenuModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        TranslateModule,
        NoopMatCheckboxComponent,
        NoResultsComponent,
        PaginatorComponent,
        PlatformLogoComponent,
        SearchComponent,
        SkeletonComponent,
        SortByFiltersComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ApplySelfPurePipe,
        FormatDatePipe,
        IllustrationPathResolverPipe,
        PlatformNamePipe,
        PluralTranslatePipe,
        ShortTextPipe,
        SlicePipe,
    ],
})
export class ClientsComponent implements OnInit, KillSubscriptions {
    readonly SvgIcon = SvgIcon;
    readonly killSubscriptions$: Subject<void> = new Subject();
    readonly Illustration = Illustration;

    allClientsWithStats: ClientWithStats[];
    dataSource = new MatTableDataSource<ClientWithStats>();
    selection = new SelectionModel<ClientWithStats>(true, []);
    readonly DISPLAYED_COLUMNS: string[] = [
        'select',
        'lastName',
        'firstName',
        'lastVisitedAt',
        'source',
        'mailsNb',
        'ratings',
        'isUnsubscribed',
        'edit',
    ];
    restaurantSelected$: Observable<ApiResult<Restaurant>>;
    restaurantId: string;
    pagination: Pagination = DEFAULT_PAGINATION;
    hasFetchedClients = false;
    campaigns: Campaign[];

    readonly SORT_OPTIONS: FilterOption[] = [
        { key: 'lastName', label: this.translate.instant('clients.filters.lastName') },
        { key: 'firstName', label: this.translate.instant('clients.filters.firstName') },
        { key: 'lastVisitedAt', label: this.translate.instant('clients.filters.lastVisitedAt') },
        { key: 'source', label: this.translate.instant('clients.filters.source') },
        { key: 'mailsNb', label: this.translate.instant('clients.filters.mailsNb') },
        { key: 'ratings', label: this.translate.instant('clients.filters.ratings') },
        { key: 'isUnsubscribed', label: this.translate.instant('clients.filters.isUnsubscribed') },
    ];

    readonly ClientsSource = ClientsSource;

    constructor(
        private readonly _restaurantsService: RestaurantsService,
        private readonly _clientsService: ClientsService,
        private readonly _customDialogService: CustomDialogService,
        public readonly translate: TranslateService,
        private readonly _campaignsService: CampaignsService,
        private readonly _toastService: ToastService,
        private readonly _malouDialogService: DialogService,
        private readonly _httpErrorPipe: HttpErrorPipe
    ) {}

    @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
        if (this.dataSource) {
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            this.dataSource.sortingDataAccessor = (item: ClientWithStats, property: string) => {
                switch (property) {
                    case 'mailsNb':
                        return item.sentEmails;
                    case 'ratings':
                        return item.givenStarsDetails?.[0]?.star;
                    case 'isUnsubscribed':
                        return item.accepts.includes(ContactMode.EMAIL);
                    default:
                        return item[property];
                }
            };
            this.dataSource.sort = sort;
        }
    }

    @ViewChild(PaginatorComponent) set paginator(paginatorComponent: PaginatorComponent) {
        if (paginatorComponent) {
            this.dataSource.paginator = paginatorComponent.matPaginator;
        }
    }

    ngOnInit(): void {
        this.loadClients$().subscribe({
            next: ([clientsData, campaignsData]) => {
                this.campaigns = campaignsData.data;
                this.allClientsWithStats = clientsData.data.map((client) => {
                    const { emailNumber, ratingsWithDetails } = this._getClientStats(client, campaignsData.data);
                    return new ClientWithStats({
                        ...client,
                        isUnsubscribed: !client.accepts.includes(ContactMode.EMAIL),
                        givenStarsDetails: ratingsWithDetails,
                        sentEmails: emailNumber,
                    });
                });
                this.dataSource.data = this.allClientsWithStats;
                this.hasFetchedClients = true;
            },
            error: (err) => {
                console.warn('err :>>', err);
                this._malouDialogService.open({
                    variant: DialogVariant.ERROR,
                    title: this._httpErrorPipe.transform(err),
                    message: this._httpErrorPipe.transform(err),
                    primaryButton: {
                        label: this.translate.instant('common.return'),
                    },
                });
            },
        });
    }

    loadClients$(): Observable<[ApiResult<Client[]>, ApiResult<Campaign[]>]> {
        return this._restaurantsService.restaurantSelected$.pipe(
            filter(Boolean),
            switchMap((restaurant: Restaurant) => {
                this.hasFetchedClients = false;
                this.restaurantId = restaurant._id;
                return forkJoin([
                    this._clientsService.getClientsByRestaurantId(restaurant._id),
                    this._campaignsService.getCampaignsByRestaurantId(restaurant._id),
                ]);
            }),
            takeUntil(this.killSubscriptions$)
        );
    }

    applyFilter(textFilter: string): void {
        this.dataSource.filter = textFilter.trim().toLowerCase();
    }

    editCreateManualClient(client: ClientWithStats | null = null): void {
        this._customDialogService
            .open(ManualClientImportModalComponent, {
                width: '650px',
                disableClose: false,
                data: {
                    restaurantId: this.restaurantId,
                    client,
                    upsertClient: this._upsertOneInDataSource.bind(this),
                },
            })
            .afterClosed()
            .subscribe({
                error: (err) => {
                    console.warn('err :>>', err);
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._httpErrorPipe.transform(err),
                        message: this._httpErrorPipe.transform(err),
                        primaryButton: {
                            label: this.translate.instant('common.return'),
                        },
                    });
                },
            });
    }

    importClientsFile(): void {
        this._customDialogService
            .open(ClientFileImportModalComponent, {
                width: '600px',
                height: undefined,
                maxHeight: '90vh',
                data: {
                    restaurantId: this.restaurantId,
                },
            })
            .afterClosed()
            .subscribe({
                next: ({ cancel }) => {
                    if (!cancel) {
                        this._restaurantsService.restaurantSelected$.next(this._restaurantsService.restaurantSelected$.value);
                    }
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    this._malouDialogService.open({
                        variant: DialogVariant.ERROR,
                        title: this._httpErrorPipe.transform(err),
                        message: this._httpErrorPipe.transform(err),
                        primaryButton: {
                            label: this.translate.instant('common.return'),
                        },
                    });
                },
            });
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    isAllFilteredSelected(): boolean {
        return this.dataSource.filteredData.every((client) => this.selection.selected.includes(client));
    }

    toggleAllFiltered(): void {
        if (this.isAllFilteredSelected()) {
            this.selection.deselect(...this.dataSource.filteredData);
            return;
        }
        this.selection.select(...this.dataSource.filteredData);
    }

    getCombinedActionsBtnText(): string {
        return !this.selection.selected.length
            ? this.translate.instant('clients.select')
            : this.translate.instant('clients.combined_actions', {
                  number: this.selection.selected.length,
              });
    }

    getCombinedActionsBtnTextSmall(): string {
        return `(${this.selection.selected.length})`;
    }

    getPrettyTextForSource(source: string): string {
        switch (source) {
            case ClientsSource.MANUAL:
                return this.translate.instant('clients.by_hand');
            case ClientsSource.WHEEL_OF_FORTUNE:
                return this.translate.instant('clients.wheel_of_fortune');
            case ClientsSource.LAFOURCHETTE:
                return this.translate.instant('clients.file') + this.translate.instant('clients.the_fork');
            default:
                return this.translate.instant('clients.file') + source.charAt(0).toUpperCase() + source.slice(1);
        }
    }

    deleteSelectedClients(): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this.translate.instant('common.are_you_sure'),
            illustration: Illustration.Cook,
            message: this.translate.instant('clients.details_about_deletion'),
            secondaryButton: {
                label: this.translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this.translate.instant('common.delete'),
                action: () => {
                    this._deleteManyClients();
                },
            },
        });
    }

    deleteOneClient(clientId: string): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this.translate.instant('common.are_you_sure'),
            illustration: Illustration.Cook,
            message: this.translate.instant('clients.details_about_deletion'),
            secondaryButton: {
                label: this.translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this.translate.instant('common.delete'),
                action: () => {
                    this._deleteOneClient(clientId);
                },
            },
        });
    }

    duplicateClients(singleClient: ClientWithStats | null = null): void {
        const clientsToDuplicate = singleClient ? [singleClient] : this.selection.selected;
        this._customDialogService
            .open(RestaurantsSelectionModalComponent, {
                width: '600px',
                disableClose: false,
                data: {
                    itemsLength: clientsToDuplicate.length,
                    skipOwnRestaurant: true,
                    dialogSubtitle: this.translate.instant('clients.duplicate_clients_subtitle'),
                },
            })
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result?.ids) {
                        return this._clientsService.duplicateClientsForRestaurants(this.restaurantId, clientsToDuplicate, result.ids);
                    } else {
                        this.selection.clear();
                        return of(null);
                    }
                })
            )
            .subscribe({
                next: (res) => {
                    if (!res) {
                        return;
                    }
                    this._toastService.openSuccessToast(this.translate.instant('clients.clients_already_exist'));
                    this.selection.clear();
                },
                error: (err) => {
                    console.warn('err :>>', err);
                    if (err.status === 403) {
                        return;
                    }
                    this._toastService.openErrorToast(this.translate.instant('clients.duplication_failed'));
                    this.selection.clear();
                },
            });
    }

    onSortByChange(sortBy: string): void {
        this.dataSource.sort?.sort({ id: sortBy, start: this.dataSource.sort.direction || 'asc', disableClear: true });
    }

    onSortOrderChange(): void {
        const start = this.dataSource.sort?.direction === 'asc' ? 'desc' : 'asc';
        this.dataSource.sort?.sort({ id: this.dataSource.sort.active || 'lastVisitedAt', start, disableClear: true });
    }

    private _getClientStats(client: Client, campaigns: Campaign[]): { emailNumber: number; ratingsWithDetails: CampaignStarDetails[] } {
        let emailNumber = 0;
        const ratings: CampaignStarDetails[] = [];
        for (const campaign of campaigns) {
            const clientInteraction = campaign.contactInteractions.find((c) => c.clientId === client._id);
            if (!clientInteraction) {
                continue;
            }
            emailNumber++;
            if (clientInteraction?.lastStarRating) {
                const campaignPlatform = PlatformDefinitions.getPlatformDefinition(campaign.platformKey)?.fullName;
                if (campaignPlatform) {
                    ratings.push({
                        star: clientInteraction.lastStarRating,
                        campaignName: campaign.name,
                        campaignPlatform,
                        campaignDate: campaign.startDate,
                    });
                }
            }
        }
        return { emailNumber, ratingsWithDetails: ratings.sort() };
    }

    private _upsertOneInDataSource(client: ClientWithStats): void {
        const idx = this.dataSource.data.findIndex((c) => c._id === client._id);
        if (idx > -1) {
            this.dataSource.data[idx] = client;
        } else {
            this.dataSource.data.unshift(client);
        }
        this.dataSource._updateChangeSubscription();
    }

    private _deleteByIdInDataSource(clientId: string): void {
        const idx = this.dataSource.data.findIndex((c) => c._id === clientId);
        if (idx > -1) {
            this.dataSource.data.splice(idx, 1);
            this.dataSource._updateChangeSubscription();
        }
    }

    private _deleteManyInDataSource(clientsIds: string[]): void {
        this.dataSource.data = this.dataSource.data.filter((c) => !clientsIds.includes(c._id));
    }

    private _deleteManyClients(): void {
        const clientsIds = compact(this.selection.selected.map((clientWithStats) => clientWithStats._id));
        this._clientsService.deleteManyClients(clientsIds).subscribe({
            next: () => {
                this._deleteManyInDataSource(clientsIds);
                this.selection.clear();
                this._toastService.openSuccessToast(this.translate.instant('clients.deleted_plural'));
            },
            error: (err) => {
                this.selection.clear();
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._malouDialogService.open({
                    variant: DialogVariant.ERROR,
                    title: this._httpErrorPipe.transform(err),
                    message: this._httpErrorPipe.transform(err),
                    primaryButton: {
                        label: this.translate.instant('common.return'),
                    },
                });
            },
        });
    }

    private _deleteOneClient(clientId: string): void {
        this._clientsService.deleteClient(clientId).subscribe({
            next: () => {
                this._deleteByIdInDataSource(clientId);
                this.selection.clear();
                this._toastService.openSuccessToast(this.translate.instant('clients.deleted'));
            },
            error: (err) => {
                console.warn('err :>>', err);
                if (err.status === 403) {
                    return;
                }
                this._malouDialogService.open({
                    variant: DialogVariant.ERROR,
                    title: this._httpErrorPipe.transform(err),
                    message: this._httpErrorPipe.transform(err),
                    primaryButton: {
                        label: this.translate.instant('common.return'),
                    },
                });
            },
        });
    }
}
