import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';

import { ApplicationLanguage } from '@malou-io/package-utils';

import { selectUserInfos } from ':modules/user/store/user.selectors';
import { UsersService } from ':modules/user/users.service';

enum FrontChatEvent {
    INIT = 'init',
    SHUTDOWN = 'shutdown',
    SHOW = 'show',
    HIDE = 'hide',
}
interface FrontChatOptions {
    [FrontChatEvent.INIT]: {
        chatId: string;
        useDefaultLauncher: boolean;
        email?: string;
        name?: string;
        userHash?: string;
        customFields?: Record<string, any>;
    };
    [FrontChatEvent.SHUTDOWN]: undefined;
    [FrontChatEvent.SHOW]: undefined;
    [FrontChatEvent.HIDE]: undefined;
}

type FrontChatOptionsSelector<T extends FrontChatEvent> = FrontChatOptions[T];

interface WindowExtended extends Window {
    FrontChat?: <T extends FrontChatEvent>(event: T, options: FrontChatOptionsSelector<T>) => any;
}

const CHAT_ID = 'aba3a0eb09b5dc1bdc8e1c2e1ce095f8';

@Injectable({
    providedIn: 'root',
})
export class ChatbotService {
    isChatbotInitialized = false;

    private readonly _currentUser$ = this._store.select(selectUserInfos);
    constructor(
        private readonly _store: Store,
        private readonly _usersService: UsersService
    ) {}

    initChatbot(): void {
        const windowExtended: WindowExtended = window;
        this._currentUser$
            .pipe(
                filter(Boolean),
                switchMap((user) =>
                    this._usersService
                        .getFrontChatUserEmailHash(user.email)
                        .pipe(map(({ data: { hash } }) => ({ ...user, userHash: hash })))
                ),
                filter(({ defaultLanguage }) => defaultLanguage === ApplicationLanguage.FR)
            )
            .subscribe(({ userHash, ...user }) => {
                if (windowExtended.FrontChat && !this.isChatbotInitialized && user) {
                    windowExtended.FrontChat(FrontChatEvent.INIT, {
                        chatId: CHAT_ID,
                        useDefaultLauncher: true,
                        userHash,
                        email: user.email,
                        name: user.name,
                        customFields: {
                            ...user,
                        },
                    });

                    this.isChatbotInitialized = true;
                }
            });
    }

    /**
     * Show the chatbot panel
     */
    show(): void {
        const windowExtended: WindowExtended = window;
        if (windowExtended.FrontChat && this.isChatbotInitialized) {
            windowExtended.FrontChat(FrontChatEvent.SHOW, undefined);
        }
    }

    /**
     * Hide the chatbot panel (the chatbot icon will still be displayed)
     */
    hide(): void {
        const windowExtended: WindowExtended = window;
        if (windowExtended.FrontChat && this.isChatbotInitialized) {
            windowExtended.FrontChat(FrontChatEvent.HIDE, undefined);
        }
    }

    shutdown(): void {
        const windowExtended: WindowExtended = window;
        if (windowExtended.FrontChat && this.isChatbotInitialized) {
            windowExtended.FrontChat(FrontChatEvent.SHUTDOWN, undefined);
            this.isChatbotInitialized = false;
        }
    }
}
