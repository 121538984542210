import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { NotificationType } from '@malou-io/package-utils';

import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';

import { Notification } from '../../../models/notification.model';
import { NegativeReviewReminderNotificationItemComponent } from '../negative-review-reminder-notification-item/negative-review-reminder-notification-item.component';
import { ReviewNotificationItemComponent } from '../review-notification-item/review-notification-item.component';
import { SpecialHourNotificationComponent } from '../special-hour-notification-item/special-hour-notification-item.component';

@Component({
    selector: 'app-notification-toast',
    templateUrl: './notification-toast.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NegativeReviewReminderNotificationItemComponent,
        ReviewNotificationItemComponent,
        SpecialHourNotificationComponent,
        MatIconModule,
    ],
})
export class NotificationToastComponent {
    readonly notificationCenterContext = inject(NotificationCenterContext);
    readonly notification: Notification = inject(MAT_SNACK_BAR_DATA);
    readonly NotificationType = NotificationType;

    close(): void {
        this.notificationCenterContext.closeNotificationToast();
    }
}
