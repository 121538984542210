<div
    class="edit-modal-container"
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : editModal"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #editModal>
    <div class="malou-dialog">
        <div class="malou-dialog__header">
            <span>
                {{ 'information.information.info' | translate }}
            </span>
            <button class="malou-btn-icon" mat-icon-button (click)="close()">
                <mat-icon color="primary" [svgIcon]="SvgIcon.CROSS"></mat-icon>
            </button>
        </div>

        <div class="malou-dialog__body">
            <form class="flex flex-col gap-y-6" [formGroup]="infoForm">
                <div class="relative">
                    <div class="relative self-stretch">
                        <img
                            class="malou-border-primary h-44 w-full rounded-xl object-cover object-center md:h-32"
                            alt="restaurant cover"
                            [src]="infoForm.controls['cover'].value?.urls?.smallCover ?? ('default_cover' | imagePathResolver)" />
                        <div class="absolute bottom-0 right-0 -translate-x-1/3 -translate-y-1/3">
                            <app-file-upload formControlName="coverFile" (onError)="coverErrorMessage = $event ?? ''"></app-file-upload>
                        </div>
                    </div>

                    <div class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
                        <img
                            class="malou-box-shadow h-28 w-28 rounded-full bg-white object-cover"
                            alt="restaurant logo"
                            [src]="infoForm.controls['logo'].value?.urls?.original ?? ('default_logo' | imagePathResolver)" />
                        <div class="absolute bottom-0 right-0">
                            <app-file-upload formControlName="logoFile" (onError)="logoErrorMessage = $event ?? ''"></app-file-upload>
                        </div>
                    </div>
                </div>

                <div class="malou-text-10 malou-color-state-error mt-14 italic">{{ coverErrorMessage ?? logoErrorMessage }}</div>

                <app-input-text
                    formControlName="name"
                    [title]="'information.information.business_name' | translate"
                    [placeholder]="'information.information.business_name' | translate"
                    [required]="true"></app-input-text>

                <ng-container [ngTemplateOutlet]="isLoading ? shimmerCategoriesTemplate : categoriesTemplate"></ng-container>
                @if (!restaurant.isBrandBusiness()) {
                    <ng-container [ngTemplateOutlet]="locationTemplate"></ng-container>
                }

                <!-- CONTACT -->
                <app-input-text
                    formControlName="website"
                    [title]="'information.information.website' | translate"
                    [placeholder]="'information.information.website' | translate"
                    [errorMessage]="getPatternUrlFromControlKey('website')"></app-input-text>

                @if (!restaurant.isBrandBusiness()) {
                    <app-input-text
                        formControlName="menuUrl"
                        [title]="'information.information.menu_url' | translate"
                        [placeholder]="'information.information.menu_url' | translate"
                        [errorMessage]="getPatternUrlFromControlKey('menuUrl')"></app-input-text>
                }

                <div class="flex gap-x-3 md:flex-col md:gap-y-6" formGroupName="phone">
                    <div class="w-1/3 md:w-full">
                        <app-select
                            formControlName="prefix"
                            [title]="'information.information.phone_prefix' | translate"
                            [placeholder]="'information.information.phone_prefix' | translate"
                            [values]="PHONE_CODES"
                            [displayWith]="phoneCodesDisplayWith"></app-select>
                    </div>

                    <div class="grow">
                        <app-input-text
                            formControlName="digits"
                            [title]="'information.information.phone' | translate"
                            [placeholder]="'information.information.phone' | translate"
                            [required]="!!initialPhone?.digits"
                            [errorMessage]="
                                infoForm.get(['phone', 'digits'])?.invalid ? infoForm.get(['phone', 'digits'])?.errors?.error : null
                            "></app-input-text>
                    </div>
                </div>

                <app-input-date-picker
                    formControlName="openingDate"
                    [title]="'information.information.business_opening_date' | translate"
                    [placeholder]="'information.information.business_opening_date' | translate"
                    [errorMessage]="
                        infoForm.get('openingDate')?.invalid ? infoForm.get('openingDate')?.errors?.error : null
                    "></app-input-date-picker>
            </form>
        </div>

        <div class="malou-dialog__footer md:justify-between">
            <button class="malou-btn-raised--secondary !h-10 md:grow" mat-raised-button (click)="close()">
                {{ 'common.cancel' | translate }}
            </button>
            @if (isPlatformsUpdatesReleaseEnabled$ | async) {
                <app-button
                    class="md:grow"
                    buttonClasses="!h-10 md:w-full"
                    [text]="'common.update' | translate"
                    [disabled]="isLoading || !infoForm.valid || !addressForm.valid || infoForm.pristine"
                    (buttonClick)="save()"></app-button>
            } @else {
                <button
                    class="malou-btn-raised--primary !h-10 md:grow"
                    mat-raised-button
                    [disabled]="isLoading || !infoForm.valid || !addressForm.valid || infoForm.pristine"
                    (click)="save()">
                    {{ 'common.save' | translate }}
                </button>
            }
        </div>
    </div>
</ng-template>

<ng-template #shimmerCategoriesTemplate>
    <div class="flex flex-col gap-y-6">
        <app-skeleton heightClass="h-auto" skeletonClass="!h-16" [count]="2"></app-skeleton>
    </div>
</ng-template>

<ng-template #categoriesTemplate>
    <div class="flex flex-col gap-y-6" [formGroup]="infoForm">
        <app-select
            formControlName="category"
            [title]="'information.information.main_category' | translate"
            [placeholder]="'information.information.main_category' | translate"
            [required]="true"
            [errorMessage]="infoForm.get(['category'])?.errors?.error"
            [values]="categories"
            [displayWith]="categoryDisplayWith"></app-select>

        <div class="flex flex-col">
            <app-select-chip-list
                formControlName="categoryList"
                [maxSelectableValues]="10"
                [multiSelectionElementWrap]="true"
                [title]="'information.information.secondary_categories' | translate"
                [displayWith]="categoryDisplayWith"
                [values]="categories"
                [selectedValues]="categoryList"
                [hideSelectedValues]="true">
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                        <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span> {{ categoryDisplayWith | applyPure: value }} </span>
                    </div>
                </ng-template>
            </app-select-chip-list>
        </div>
    </div>
</ng-template>

<ng-template #locationTemplate>
    <div>
        <app-input-google-maps-autocomplete
            [title]="'information.information.address' | translate"
            [placeholder]="'information.information.address' | translate"
            [formControl]="addressForm"
            [errorMessage]="
                (infoForm.get('address')?.dirty && infoForm.get('address')?.errors?.error) ||
                (addressForm.dirty && addressForm.errors?.error)
            "
            (locationSelected)="selectedAddress($event)"></app-input-google-maps-autocomplete>
    </div>
</ng-template>
