import { z } from 'zod';

import { NotificationChannel, NotificationType } from '@malou-io/package-utils';

import { objectIdValidator } from '../utils/validators';

export interface NotificationDTO {
    id: string;
    userId: string;
    restaurantId?: string;
    data: Record<string, any>;
    type: NotificationType;
    readAt?: Date | null;
    error?: string;
    channel: NotificationChannel;
    completedAt?: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export const notificationDTOValidator = z.object({
    id: objectIdValidator,
    userId: objectIdValidator,
    restaurantId: objectIdValidator.optional(),
    data: z.record(z.any()),
    type: z.nativeEnum(NotificationType),
    readAt: z
        .string()
        .nullish()
        .transform((value) => (value ? new Date(value) : null))
        .optional(),
    error: z.string().optional(),
    channel: z.nativeEnum(NotificationChannel),
    completedAt: z
        .string()
        .nullish()
        .transform((value) => (value ? new Date(value) : null))
        .optional(),
    createdAt: z.date(),
    updatedAt: z.date(),
});
