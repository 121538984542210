<div
    [ngClass]="{
        'h-[80vh]': !displayCloseModal || (displayCloseModal && screenSizeService.isPhoneScreen),
        'h-[350px]': displayCloseModal && !screenSizeService.isPhoneScreen,
    }">
    <ng-container *ngTemplateOutlet="displayCloseModal ? closeModal : page"></ng-container>
</div>

<ng-template #closeModal>
    <app-close-without-saving-modal (onConfirm)="close()" (onCancel)="displayCloseModal = false"></app-close-without-saving-modal>
</ng-template>

<ng-template #page>
    <div class="malou-dialog">
        <div class="malou-dialog__header w-full flex-col">
            <div class="flex w-full items-center justify-between">
                <span class="malou-text-18--bold text-malou-color-text-1 md:max-w-[300px]">
                    {{ 'generator.title' | translate }}
                </span>
                <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close(true)"></mat-icon>
            </div>
            @if (!loading()) {
                <div class="mt-6.5 w-full">
                    <ng-container *ngTemplateOutlet="customStepper"></ng-container>
                </div>
            }
        </div>
        <div class="malou-dialog__body overflow-x-hidden">
            <ng-container *ngTemplateOutlet="loading() ? loadingPage : dialogBody"></ng-container>
        </div>
        <div class="malou-dialog__footer md:justify-between">
            <button
                class="malou-btn-raised--secondary !h-11 md:grow"
                mat-raised-button
                (click)="currentStep === 1 ? close() : (currentStep = currentStep - 1)">
                {{ currentStep === 1 ? ('common.cancel' | translate) : ('common.back' | translate) }}
            </button>
            <button
                class="malou-btn-raised--primary !h-11 md:grow"
                data-testid="generator-next-step-btn"
                mat-raised-button
                [id]="currentStep === 3 ? 'tracking_keywords_form_confirmation' : 'tracking_keywords_form_next_step'"
                [disabled]="!hasValidatedStep(currentStep)"
                (click)="onConfirmBtnClick(currentStep)">
                {{ getStepperValidateBtnLabel | applyPure: currentStep }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #loadingPage>
    <app-skeleton skeletonClass="secondary-bg !h-24" [count]="4"></app-skeleton>
</ng-template>

<ng-template #dialogBody>
    @switch (currentStep) {
        @case (1) {
            <ng-container *ngTemplateOutlet="categorystep"></ng-container>
        }
        @case (2) {
            <ng-container *ngTemplateOutlet="locationstep"></ng-container>
        }
        @case (3) {
            <ng-container *ngTemplateOutlet="plusstep"></ng-container>
        }
        @case (4) {
            @if (shouldDisplayLanguageStep()) {
                <ng-container *ngTemplateOutlet="languageStep"></ng-container>
            }
        }
    }
</ng-template>

<ng-template #categorystep>
    <form class="form-group flex flex-col gap-y-8" [formGroup]="categoryFormGroup">
        <div
            [matTooltip]="
                categoryFormGroup.get('categoryList')?.value.length === 3 ? ('generator.max_selected' | translate: { count: 3 }) : ''
            ">
            <app-select-chip-list
                [errorMessage]="
                    categoryFormGroup.get('categoryList')?.dirty &&
                    categoryFormGroup.get('categoryList')?.invalid &&
                    ('common.required_field' | translate)
                "
                [maxSelectableValues]="3"
                [multiSelectionElementWrap]="true"
                [title]="'keywords.generator.which_category' | translate"
                [displayWith]="displayBrickWith"
                [values]="categoriesBricks"
                [selectedValues]="categoryFormGroup.get('categoryList')?.value"
                [placeholder]="'generator.categoryList' | translate"
                [hideSelectedValues]="true"
                [required]="true"
                [testId]="'generator-category-input'"
                [displayedOptionCount]="50"
                (selectChipListChange)="listChanged('categoryList', $event, categoryFormGroup)">
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                        <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span>
                            {{ displayBrickWith | applyPure: value }}
                        </span>
                    </div>
                </ng-template>
            </app-select-chip-list>
        </div>

        <div
            [matTooltip]="categoryFormGroup.get('specials')?.value.length >= 5 ? ('generator.max_selected' | translate: { count: 5 }) : ''">
            <app-select-chip-list
                [errorMessage]="
                    categoryFormGroup.get('specials')?.dirty &&
                    categoryFormGroup.get('specials')?.invalid &&
                    ('common.required_field' | translate)
                "
                [maxSelectableValues]="5"
                [multiSelectionElementWrap]="true"
                [title]="'keywords.generator.which_speciality' | translate"
                [displayWith]="displayBrickWith"
                [values]="specialBricks"
                [displayedOptionCount]="50"
                [required]="true"
                [testId]="'generator-specials-input'"
                [selectedValues]="categoryFormGroup.get('specials')?.value"
                [placeholder]="'keywords.generator.search_specialty' | translate"
                [buildValueFromText]="buildNewItemFns[BricksCategory.VENUE_SPECIAL]"
                [hideSelectedValues]="true"
                (selectChipListChange)="listChanged('specials', $event, categoryFormGroup)">
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                        <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span>
                            {{ displayBrickWith | applyPure: value }}
                        </span>
                    </div>
                </ng-template>
            </app-select-chip-list>
        </div>
    </form>
</ng-template>

<ng-template #locationstep>
    <form class="form-group" [formGroup]="geoFormGroup">
        <app-input-autocomplete
            icon="localisation"
            [id]="getLocationAutocompleteId | applyPure: restaurant._id"
            [title]="'keywords.generator.select_your_city' | translate"
            [subtitle]="'keywords.generator.select_your_city_subtitle' | translate"
            [options]="apiLocationOptions()"
            [initialValue]="initialApiLocation()"
            [filterFn]="apiLocationFilterFn"
            [writeValueFn]="apiLocationWriteValueFn"
            [displayWithFn]="apiLocationDisplayWithFn"
            [required]="true"
            [testId]="'generator-location-input'"
            [errorMessage]="apiLocationErrorMessage()"
            [messageWhenFewResults]="'keywords.generator.no_city_found' | translate"
            (valueSelected)="onApiLocationSelected($event)"
            (dirty)="onApiLocationDirty()"></app-input-autocomplete>

        @if (!(restaurant | applySelfPure: 'isBrandBusiness')) {
            <div class="malou-text-10--regular mt-3 text-malou-color-text-2">
                {{ 'keywords.generator.validate_zip_code' | translate }}
            </div>
            <div class="flex items-center gap-x-3">
                <div class="grow">
                    <app-input-text
                        formControlName="postalCode"
                        [required]="true"
                        [errorMessage]="
                            geoFormGroup.get('postalCode')?.dirty &&
                            geoFormGroup.get('postalCode')?.invalid &&
                            ('common.required_field' | translate)
                        "></app-input-text>
                </div>
            </div>
        }
        <br />
        <div class="malou-text-10--regular text-malou-color-text-2">{{ 'keywords.generator.establishment_close_places' | translate }}</div>

        <div class="malou-text-10--regular italic text-malou-color-text-2">{{ 'keywords.generator.neighborhoods' | translate }}</div>
        <div [matTooltip]="geoFormGroup.get('touristics')?.value.length >= 4 ? ('generator.max_selected' | translate: { count: 4 }) : ''">
            <app-select-chip-list
                [errorMessage]="
                    geoFormGroup.get('touristics')?.dirty &&
                    geoFormGroup.get('touristics')?.invalid &&
                    ('common.required_field' | translate)
                "
                [testId]="'generator-touristics-input'"
                [displayedOptionCount]="50"
                [maxSelectableValues]="4"
                [multiSelectionElementWrap]="true"
                [displayWith]="displayBrickWith"
                [values]="recommendedTouristics"
                [selectedValues]="geoFormGroup.get('touristics')?.value"
                [placeholder]="'keywords.generator.search_location' | translate"
                [buildValueFromText]="buildNewItemFns[BricksCategory.TOURISTIC_AREA]"
                [hideSelectedValues]="true"
                (selectChipListChange)="listChanged('touristics', $event, geoFormGroup)">
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                        <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                        <span>
                            {{ displayBrickWith | applyPure: value }}
                        </span>
                    </div>
                </ng-template>
            </app-select-chip-list>
        </div>
    </form>
</ng-template>

<ng-template #plusstep>
    <form class="form-group flex max-w-full flex-col gap-y-6" [formGroup]="furtherFormGroup">
        <!-- Offers -->
        <app-select-chip-list
            [title]="'keywords.generator.establishment_services' | translate"
            [multiSelectionElementWrap]="true"
            [displayWith]="displayBrickWith"
            [values]="offerBricks"
            [testId]="'generator-services-input'"
            [displayedOptionCount]="50"
            [selectedValues]="furtherFormGroup.get('offers')?.value"
            [placeholder]="'keywords.generator.search_offer' | translate"
            [buildValueFromText]="buildNewItemFns[BricksCategory.VENUE_OFFER]"
            [hideSelectedValues]="true"
            (selectChipListChange)="listChanged('offers', $event, furtherFormGroup)">
            <ng-template let-value="value" #optionTemplate>
                <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                    <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    <span>
                        {{ displayBrickWith | applyPure: value }}
                    </span>
                </div>
            </ng-template>
        </app-select-chip-list>
        <!-- Attributes -->
        <app-select-chip-list
            [title]="'keywords.generator.establishment_attributes' | translate"
            [multiSelectionElementWrap]="true"
            [displayWith]="displayBrickWith"
            [values]="attributeBricks"
            [displayedOptionCount]="50"
            [testId]="'generator-attributes-input'"
            [selectedValues]="furtherFormGroup.get('attributes')?.value"
            [placeholder]="'keywords.generator.search_attribute' | translate"
            [buildValueFromText]="buildNewItemFns[BricksCategory.VENUE_ATTRIBUTE]"
            [hideSelectedValues]="true"
            (selectChipListChange)="listChanged('attributes', $event, furtherFormGroup)">
            <ng-template let-value="value" #optionTemplate>
                <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                    <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    <span>
                        {{ displayBrickWith | applyPure: value }}
                    </span>
                </div>
            </ng-template>
        </app-select-chip-list>
        <!-- Audience -->
        <app-select-chip-list
            [title]="'keywords.generator.establishment_clientele' | translate"
            [multiSelectionElementWrap]="true"
            [displayWith]="displayBrickWith"
            [values]="audienceBricks"
            [testId]="'generator-audience-input'"
            [selectedValues]="furtherFormGroup.get('audience')?.value"
            [placeholder]="'keywords.generator.search_target' | translate"
            [buildValueFromText]="buildNewItemFns[BricksCategory.VENUE_AUDIENCE]"
            [hideSelectedValues]="true"
            (selectChipListChange)="listChanged('audience', $event, furtherFormGroup)">
            <ng-template let-value="value" #optionTemplate>
                <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                    <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    <span>
                        {{ displayBrickWith | applyPure: value }}
                    </span>
                </div>
            </ng-template>
        </app-select-chip-list>
        <!-- Equipement -->
        <app-select-chip-list
            [title]="'keywords.generator.physical_elements' | translate"
            [multiSelectionElementWrap]="true"
            [displayWith]="displayBrickWith"
            [testId]="'generator-equipment-input'"
            [values]="equipmentBricks"
            [selectedValues]="furtherFormGroup.get('equipment')?.value"
            [placeholder]="'keywords.generator.search_equipment' | translate"
            [buildValueFromText]="buildNewItemFns[BricksCategory.VENUE_EQUIPMENT]"
            [hideSelectedValues]="true"
            (selectChipListChange)="listChanged('equipment', $event, furtherFormGroup)">
            <ng-template let-value="value" #optionTemplate>
                <div class="malou-chip malou-chip--primary flex items-center gap-x-3">
                    <mat-icon class="malou-color-primary z-10 !h-4 !w-4" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    <span>
                        {{ displayBrickWith | applyPure: value }}
                    </span>
                </div>
            </ng-template>
        </app-select-chip-list>
    </form>
</ng-template>

<ng-template #languageStep>
    <app-select-languages
        [title]="'keywords.generator.languages' | translate"
        [values]="languageOptions"
        [testId]="'generator-languages-input'"
        [formControl]="languagesControl"
        [required]="true"
        (selectLanguagesChange)="onLanguagesChange($event)">
    </app-select-languages>
</ng-template>

<ng-template #customStepper>
    <div class="w-full rounded-xl bg-malou-color-background-dark p-6.5">
        <div class="stepper-header relative flex w-full items-center justify-between pb-2 md:justify-start md:gap-x-3">
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [class.ink-bar-mobile-before]="currentStep === 1"
                [class.!malou-text-12--bold]="currentStep === 1"
                (click)="currentStep = 1">
                <span class="mr-1">1.</span>
                <div
                    class="transition-all duration-500 md:invisible md:h-0 md:w-0"
                    [ngClass]="{ '!visible md:h-fit md:w-fit': currentStep === 1 }">
                    {{ 'keywords.generator.establishment_type_step' | translate }}
                </div>
            </div>
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [class.ink-bar-mobile-before]="currentStep === 2"
                [class.!malou-text-12--bold]="currentStep === 2"
                (click)="hasValidatedStep(1) && (currentStep = 2)">
                <span class="mr-1">2.</span>
                <div
                    class="transition-all duration-500 md:invisible md:h-0 md:w-0"
                    [ngClass]="{ '!visible md:h-fit md:w-fit': currentStep === 2 }">
                    {{ 'keywords.generator.localisation_step' | translate }}
                </div>
            </div>
            <div
                class="malou-text-12--regular relative flex cursor-pointer items-start"
                matRipple
                [class.ink-bar-mobile-before]="currentStep === 3"
                [class.!malou-text-12--bold]="currentStep === 3"
                (click)="hasValidatedStep(2) && (currentStep = 3)">
                <span class="mr-1">3.</span>
                <div
                    class="transition-all duration-500 md:invisible md:h-0 md:w-0"
                    [ngClass]="{ '!visible md:h-fit md:w-fit': currentStep === 3 }">
                    {{ 'keywords.generator.further_information_step' | translate }}
                </div>
            </div>
            @if (shouldDisplayLanguageStep()) {
                <div
                    class="malou-text-12--regular relative flex cursor-pointer items-start"
                    matRipple
                    [class.ink-bar-mobile-before]="currentStep === 4"
                    [class.!malou-text-12--bold]="currentStep === 4"
                    (click)="hasValidatedStep(3) && (currentStep = 4)">
                    <span class="mr-1">4.</span>
                    <div
                        class="transition-all duration-500 md:invisible md:h-0 md:w-0"
                        [ngClass]="{ '!visible md:h-fit md:w-fit': currentStep === 4 }">
                        {{ 'keywords.generator.langs' | translate }}
                    </div>
                </div>
            }
            @if (screenSizeService.isPhoneScreen) {
                <div
                    class="ink-bar transition-all duration-500"
                    [ngClass]="
                        shouldDisplayLanguageStep()
                            ? {
                                  '!w-1/4': currentStep === 1,
                                  '!w-2/4': currentStep === 2,
                                  '!w-3/4': currentStep === 3,
                                  '!w-full': currentStep === 4,
                              }
                            : {
                                  '!w-1/3': currentStep === 1,
                                  '!w-2/3': currentStep === 2,
                                  '!w-full': currentStep === 3,
                              }
                    "></div>
            } @else {
                <div class="ink-bar transition-all duration-500" [ngStyle]="{ width: getInkBarWidth() }"></div>
            }
        </div>
    </div>
</ng-template>
