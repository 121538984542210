<div class="flex h-screen flex-col" (click)="notificationCenterContext.close()">
    <app-header></app-header>
    <div class="min-h-0 grow">
        <router-outlet></router-outlet>
    </div>
</div>
@if (experimentationService.isFeatureEnabled('release-notification-center')) {
    <app-notification-center></app-notification-center>
    <app-notification-popins></app-notification-popins>
}
