<ng-container *ngTemplateOutlet="showCloseModal ? closeModal : pageWrapper"></ng-container>

<ng-template #closeModal>
    <div class="h-full w-[100vw]">
        <app-close-without-saving-modal (onConfirm)="confirmClose()" (onCancel)="returnToStoryEdition()"></app-close-without-saving-modal>
    </div>
</ng-template>

<ng-template #pageWrapper>
    <ng-container *ngTemplateOutlet="page"></ng-container>
</ng-template>

<ng-template #page>
    <div class="malou-dialog malou-dialog-mobile flex h-full !w-[100vw] flex-col">
        <div class="malou-dialog__header">
            <span>
                {{ 'stories.edit_story.title' | translate }}
            </span>
            <div class="flex items-center">
                <mat-icon
                    class="close cursor-pointer"
                    id="tracking_cancel_story_edition"
                    [svgIcon]="SvgIcon.CROSS"
                    (click)="openSaveBeforeClose()"></mat-icon>
            </div>
        </div>
        <hr class="border-malou-color-background-dark" />
        <div class="malou-dialog__body !m-0 flex grow flex-wrap gap-x-5 lg:pb-20">
            <ng-container *ngTemplateOutlet="picEditor"></ng-container>
            <ng-container *ngTemplateOutlet="postList"></ng-container>
            <ng-container *ngTemplateOutlet="feedback"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="stepButtons"></ng-container>
    </div>
</ng-template>

<ng-template #picEditor>
    <section class="section-size h-[445px] min-w-[290px] object-contain p-4 md:w-full min-md:flex-3" [hidden]="!(showPicEditor$ | async)">
        <div class="h-full">
            <app-story-media-editor
                [disabled]="data.isDisabled"
                [medias]="selectedMedias"
                [isTextEditable]="true"
                (fileChanged)="onEditedCurrentMedia($event)"
                (mediasSelected)="onEditedCurrentMedia($event)"
                (editing)="onEditingChange($event)"
                (imageEditionChange)="onImageEditionChange($any($event))"
                #editedImage></app-story-media-editor>
        </div>
    </section>
</ng-template>

<ng-template #postList>
    <div class="flex-7 h-full md:h-auto" [hidden]="!(showPostForm$ | async)">
        <div class="h-full w-full">
            <ng-container *ngTemplateOutlet="formBody"></ng-container>
            <ng-container *ngTemplateOutlet="formFooter"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #formBody>
    <div class="h-[85%] w-full overflow-y-auto pb-5 pr-5 pt-3 md:h-auto">
        <form>
            <ng-container>
                <span class="malou-text-10--medium mb-2 mt-2 text-malou-color-text-2">
                    {{ 'social_posts.new_social_post.platforms' | translate }}
                </span>
                <div class="mt-3 flex items-center gap-x-3">
                    @for (platform of storyPlatforms; track platform) {
                        <div>
                            @if (platform.key) {
                                <app-platform-logo
                                    imgClasses="h-7.5 w-7.5 cursor-pointer"
                                    matTooltip="{{ 'social_posts.new_social_post.platform_not_connected' | translate }}"
                                    [matTooltipDisabled]="platform.connected || data.isDisabled"
                                    [dynamicClasses]="{ 'opacity-50': !platform.connected || data.isDisabled || !platform.checked }"
                                    [logo]="platform.key"
                                    (click)="togglePlatformChecked(platform.key)"></app-platform-logo>
                            }
                        </div>
                    }
                </div>
            </ng-container>
            <ng-container>
                <div class="mb-2 mt-2">
                    <span class="malou-text-10--medium text-malou-color-text-2">
                        {{ 'stories.new_story.form_header_title' | translate }}
                    </span>
                </div>
                <div class="mb-4" cdkDropList (cdkDropListDropped)="drop($event)">
                    @for (post of posts$ | async; track post) {
                        <div cdkDrag>
                            <div
                                class="my-1 rounded-md p-2"
                                [ngClass]="{
                                    'malou-border-purple-light': post._id === selectedStory?._id,
                                    'malou-border-primary': post._id !== selectedStory?._id,
                                }"
                                (click)="clickedPost(post)">
                                <app-post-summary
                                    [post]="post"
                                    [warningText]="
                                        (postIdsWithWrongAspectRatio | includes: post._id) ? ('stories.check_background' | translate) : ''
                                    "
                                    (deletedPost)="onDeletedPost($event)"
                                    #postSummary></app-post-summary>
                            </div>
                        </div>
                    }
                </div>
                <app-media-picker-button
                    [hidden]="loadingMedia"
                    [maxVideoSize]="STORY_MAX_VIDEO_SIZE"
                    [maxImageSize]="DEFAULT_MAX_IMAGE_SIZE"
                    (fileChanged)="onEditedCurrentMedia($event, { shouldForceCreation: !!selectedStory?.attachments?.length })"
                    (startReadingFile)="loadingMedia = true"
                    (processError)="loadingMedia = false"></app-media-picker-button>
                <app-malou-spinner size="small" [hidden]="!loadingMedia"></app-malou-spinner>
            </ng-container>
            <form [formGroup]="postDateFormGroup">
                <div class="malou-text-10--regular mt-7.5 w-full">
                    <span class="text-malou-color-text-1">{{ 'social_posts.new_social_post.publication_date' | translate }}</span>
                    <mat-radio-group class="mt-4 flex gap-x-4" color="primary" [formControl]="postDateStatus" [disabled]="data.isDisabled">
                        <mat-radio-button class="malou-small-radio-button" [value]="PostDateStatus.NOW">
                            <span>{{ 'social_posts.new_social_post.now' | translate }}</span>
                        </mat-radio-button>
                        <mat-radio-button class="malou-small-radio-button" [value]="PostDateStatus.LATER">
                            <span>{{ 'social_posts.new_social_post.later' | translate }}</span>
                        </mat-radio-button>
                        <mat-radio-button class="malou-small-radio-button" [value]="PostDateStatus.DRAFT">
                            <span> {{ 'posts.new_post.draft' | translate }}</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                @if ([PostDateStatus.LATER, PostDateStatus.DRAFT] | includes: postSchedule) {
                    <div class="mt-4 flex w-full gap-x-8">
                        <div class="grow">
                            <app-input-date-picker
                                formControlName="postDate"
                                [onTimeChange$]="postDateFormGroup.get('postTime')?.valueChanges"
                                [time]="postDateFormGroup.get('postTime')?.value"
                                [min]="MIN_DATE"></app-input-date-picker>
                        </div>
                        <div class="flex flex-col">
                            <mat-select
                                class="!m-0 mt-8 !h-0 opacity-0"
                                formControlName="postTime"
                                panelClass="malou-select-panel"
                                placeholder="----"
                                [hideSingleSelectionIndicator]="true"
                                (selectionChange)="changeSelectedTime($event)">
                                @for (time of times; track time) {
                                    <mat-option
                                        [value]="time"
                                        [disabled]="isPastHour | applyPure: { hourWithMinute: time, date: postDate }">
                                        {{ time | formatTime }}
                                    </mat-option>
                                }
                            </mat-select>
                            <app-input-text
                                class="grow"
                                [svgIcon]="SvgIcon.CLOCK"
                                [errorMessage]="
                                    (isPastHour | applyPure: { hourWithMinute: postTime, date: postDate })
                                        ? ('common.invalid_time' | translate)
                                        : ''
                                "
                                [formControlName]="'postTime'"
                                [inputType]="'time'"></app-input-text>
                        </div>
                    </div>
                }
            </form>
        </form>
    </div>
</ng-template>

<ng-template #formFooter>
    <div class="malou-text-12--regular flex h-[15%] w-full items-center justify-end py-7.5 pr-5 text-malou-color-state-success md:hidden">
        <div class="flex items-center gap-x-4">
            <button class="malou-btn-raised--secondary !h-11 md:grow" mat-raised-button (click)="openSaveBeforeClose()">
                {{ 'common.cancel' | translate }}
            </button>
            <div class="wrapper relative">
                @if (formErrors.length) {
                    <ng-container [ngTemplateOutlet]="formErrorTooltip"></ng-container>
                }

                <app-button
                    class="md:grow"
                    buttonClasses="!h-11 md:w-full"
                    [id]="publishButtonTrackingId"
                    [disabled]="!canPublish()"
                    [text]="getPublishButtonText()"
                    [loading]="isUpdatingStory()"
                    (buttonClick)="save()"></app-button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #formErrorTooltip>
    <div class="form-errors-tooltip flex flex-col gap-y-3">
        @for (error of formErrors; track error) {
            <span class="text-white">- {{ error }}</span>
        }
    </div>
</ng-template>

<ng-template #feedback>
    <div class="section-size malou-color-background-light flex-3">
        <div class="flex h-full flex-col">
            <div class="flex-1 overflow-hidden px-4">
                <app-feedbacks-panel
                    [post]="selectedStory"
                    [restaurantManagers]="restaurantManagers"
                    (currentFeedbackSet)="onUpdateCurrentFeedback($event)"></app-feedbacks-panel>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #stepButtons>
    <ng-container>
        <div class="absolute bottom-0 z-10 hidden w-full items-center justify-between gap-x-2 bg-white p-4 lg:!flex">
            @if ((dialogStep$ | async) === DialogStep.ONE) {
                <button class="malou-btn-raised--secondary !h-11 lg:grow" mat-raised-button (click)="openSaveBeforeClose()">
                    {{ 'common.cancel' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.TWO) {
                <button class="malou-btn-raised--secondary !h-11 lg:grow" mat-raised-button (click)="dialogStep$.next(DialogStep.ONE)">
                    {{ 'common.previous' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.ONE) {
                <button class="malou-btn-raised--primary !h-11 lg:grow" color="primary" mat-raised-button (click)="nextDialogStep()">
                    {{ 'common.next' | translate }}
                </button>
            }
            @if ((dialogStep$ | async) === DialogStep.TWO) {
                <app-button
                    class="lg:grow"
                    buttonClasses="!h-11 md:w-full"
                    [id]="publishButtonTrackingId + '_mobile'"
                    [disabled]="!canPublish()"
                    [text]="getPublishButtonText()"
                    [loading]="isUpdatingStory()"
                    (buttonClick)="save()"></app-button>
            }
        </div>
    </ng-container>
</ng-template>
