<app-modal-structure
    [title]="'platforms.connection_new.google.step_1.step_name' | translate"
    [primaryButtonText]="'common.next' | translate"
    [primaryButtonDisabled]="apiTokenControl.invalid || loginControl.invalid"
    [secondaryButtonText]="'common.previous' | translate"
    [tertiaryButtonText]="'platforms.connection_new.shared.see_tutorial' | translate"
    [buttonStyle]="ButtonStyle.CLASSIC"
    [submitting]="isUpdatingPlatform()"
    (close)="close.emit({})"
    (primaryClick)="onPrimaryClick()"
    (secondaryClick)="goToStep.emit({ type: 'relative', value: -1 })"
    (tertiaryClick)="onTertiaryClick()">
    <div class="flex flex-col gap-y-4">
        <app-input-text
            [testId]="'zenchef-connection-api-key-input'"
            [formControl]="apiTokenControl"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.zenchef.step_1.token_api_partner' | translate"
            [placeholder]="'xxxx-xxxx-xxxx'"
            [autocapitalize]="'none'"
            [disabled]="isUpdatingPlatform()">
        </app-input-text>
        <app-input-text
            [testId]="'zenchef-connection-email-input'"
            [formControl]="loginControl"
            [showRequiredStar]="true"
            [title]="'platforms.connection_new.zenchef.step_1.email_address' | translate"
            [placeholder]="'address@email.com'"
            [errorMessage]="isEmailInError() ? ('platforms.connection_new.zenchef.step_1.invalid_email' | translate) : undefined"
            [autocapitalize]="'none'"
            [disabled]="isUpdatingPlatform()">
        </app-input-text>
        <img class="h-auto w-full" [src]="'zenchef-api-token' | imagePathResolver: { folder: 'platforms-connection/' + imageLang() }" />
    </div>
</app-modal-structure>
