import { Clipboard } from '@angular/cdk/clipboard';
import { SelectionModel } from '@angular/cdk/collections';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, debounceTime, forkJoin, of, Subject, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BusinessCategory, errorReplacer, TimeInMilliseconds, WHEEL_OF_FORTUNE_PLATFORM_KEY } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { DialogService } from ':core/services/dialog.service';
import { NfcService } from ':core/services/nfc.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScansService } from ':core/services/scans.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import { NoopMatCheckboxComponent } from ':shared/components/noop-mat-checkbox/noop-mat-checkbox.component';
import { PaginatorComponent } from ':shared/components/paginator/paginator.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { NfcActionsHeaderComponent } from ':shared/components/shared-nfc/nfc-actions-header/nfc-actions-header.component';
import { StickerComponent } from ':shared/components/shared-nfc/sticker/sticker.component';
import { NfcActions } from ':shared/components/shared-nfc/store/nfc.actions';
import { UpsertNfcModalComponent } from ':shared/components/shared-nfc/upsert-nfc-modal/upsert-nfc-modal.component';
import { StarWithTextChipComponent } from ':shared/components/star-with-text-chip/star-with-text-chip.component';
import { TypeSafeMatCellDefDirective } from ':shared/directives/type-safe-mat-cell-def.directive';
import { TypeSafeMatRowDefDirective } from ':shared/directives/type-safe-mat-row-def.directive';
import { QrCode } from ':shared/helpers/qr-code';
import { NfcWithStats } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { EnumTranslatePipe } from ':shared/pipes/enum-translate.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { PluralTranslatePipe } from ':shared/pipes/plural-translate.pipe';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { LoadNfcsComponent } from './load-nfcs/load-nfcs.component';

interface Pagination {
    page: number;
    limit: number;
}

export enum NfcTableFieldName {
    SELECT = 'select',
    TYPE = 'type',
    CHIP_NAME = 'chipName',
    RESTAURANT_NAME = 'restaurantName',
    ACTIVE = 'active',
    NAME = 'name',
    REDIRECTION = 'redirection',
    REDIRECTION_LINK = 'redirectionLink',
    STARS_REDIRECTED = 'starsRedirected',
    NOTES = 'notes',
    ACTIONS = 'actions',
}

export enum NfcDisplayMode {
    ADMIN = 'ADMIN',
    BASIC = 'BASIC',
}

@Component({
    selector: 'app-shared-nfc',
    templateUrl: './shared-nfc.component.html',
    styleUrls: ['./shared-nfc.component.scss'],
    providers: [PluralTranslatePipe],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterLink,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatTableModule,
        MatTooltipModule,
        MatMenuModule,
        MatSortModule,
        TranslateModule,
        ReactiveFormsModule,
        MalouSpinnerComponent,
        NfcActionsHeaderComponent,
        NoopMatCheckboxComponent,
        PaginatorComponent,
        StarWithTextChipComponent,
        StickerComponent,
        SlideToggleComponent,
        TypeSafeMatCellDefDirective,
        TypeSafeMatRowDefDirective,
        ApplyPurePipe,
        ApplySelfPurePipe,
        AsyncPipe,
        EnumTranslatePipe,
        IllustrationPathResolverPipe,
        PlatformLogoComponent,
        ShortTextPipe,
    ],
})
export class SharedNfcComponent implements OnInit {
    @Input() displayMode: NfcDisplayMode = NfcDisplayMode.BASIC;
    @Input() shouldReload$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    paginator: MatPaginator;
    isPaginatorInitialized = false;

    displayedColumns: NfcTableFieldName[] = [];
    readonly PAGE_SIZE_OPTIONS = [5, 10, 20];
    readonly NfcTableFieldName = NfcTableFieldName;
    readonly NfcDisplayMode = NfcDisplayMode;
    readonly dataSource = new MatTableDataSource<NfcWithStats>([]);
    readonly selection = new SelectionModel<NfcWithStats>(true, []);
    readonly textSearch$ = new BehaviorSubject<string>('');
    readonly pagination$: Subject<Pagination> = new BehaviorSubject<Pagination>({ page: 1, limit: 10 });
    readonly displayedStickerId: WritableSignal<string | null> = signal(null);
    totalCount = 0;
    isLoading: WritableSignal<boolean> = signal(true);
    isLoadingRestaurantHasTotems: WritableSignal<boolean> = signal(true);
    restaurantHasTotems: WritableSignal<boolean> = signal(false);
    WHEEL_OF_FORTUNE = WHEEL_OF_FORTUNE_PLATFORM_KEY;

    readonly DEFAULT_VALUE_DISPLAY = '-';

    readonly isPhoneScreen = toSignal(this._screenSizeService.isPhoneScreen$, { initialValue: this._screenSizeService.isPhoneScreen });

    readonly SvgIcon = SvgIcon;

    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _nfcService: NfcService,
        private readonly _scansService: ScansService,
        private readonly _translate: TranslateService,
        private readonly _pluralTranslatePipe: PluralTranslatePipe,
        private readonly _toastService: ToastService,
        private readonly _malouDialogService: DialogService,
        private readonly _store: Store,
        private readonly _customDialogService: CustomDialogService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _clipboard: Clipboard,
        private readonly _screenSizeService: ScreenSizeService
    ) {}

    @ViewChild(PaginatorComponent) set paginatorComponent(paginatorComponent: PaginatorComponent | undefined) {
        if (!paginatorComponent?.matPaginator) {
            return;
        }
        this.paginator = paginatorComponent.matPaginator;
        if (!this.isPaginatorInitialized) {
            this.pagination$.next({ page: this.paginator.pageIndex + 1, limit: this.paginator.pageSize });
            this.isPaginatorInitialized = true;
        }
    }

    ngOnInit(): void {
        switch (this.displayMode) {
            case NfcDisplayMode.ADMIN:
                this.displayedColumns = [
                    NfcTableFieldName.SELECT,
                    NfcTableFieldName.TYPE,
                    NfcTableFieldName.CHIP_NAME,
                    NfcTableFieldName.RESTAURANT_NAME,
                    NfcTableFieldName.ACTIVE,
                    NfcTableFieldName.REDIRECTION,
                    NfcTableFieldName.REDIRECTION_LINK,
                    NfcTableFieldName.STARS_REDIRECTED,
                    NfcTableFieldName.ACTIONS,
                ];
                break;
            case NfcDisplayMode.BASIC:
                this.displayedColumns = [
                    NfcTableFieldName.TYPE,
                    NfcTableFieldName.NAME,
                    NfcTableFieldName.REDIRECTION,
                    NfcTableFieldName.REDIRECTION_LINK,
                    NfcTableFieldName.STARS_REDIRECTED,
                    NfcTableFieldName.NOTES,
                    NfcTableFieldName.ACTIONS,
                ];
                break;
        }

        if (this.displayMode === NfcDisplayMode.BASIC) {
            this._restaurantsService.restaurantSelected$
                .pipe(
                    tap(() => this.isLoadingRestaurantHasTotems.set(true)),
                    switchMap((restaurant) =>
                        this._nfcService.search({
                            limit: 1,
                            restaurantId: restaurant?._id,
                        })
                    )
                )
                .subscribe((apiResult) => {
                    this.restaurantHasTotems.set(!!apiResult.metadata?.pagination && apiResult.metadata?.pagination.total > 0);
                    this.isLoadingRestaurantHasTotems.set(false);
                });
        } else {
            this.isLoadingRestaurantHasTotems.set(false);
        }

        combineLatest([this.textSearch$, this.pagination$, this._restaurantsService.restaurantSelected$, this.shouldReload$])
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.selection.clear();
                    this.isLoading.set(true);
                }),
                switchMap(([searchText, pageEvent, restaurant]) =>
                    this._nfcService.search({
                        text: searchText,
                        ...(this.displayMode === NfcDisplayMode.BASIC ? { restaurantId: restaurant?._id } : {}),
                        ...(this.displayMode === NfcDisplayMode.BASIC ? { active: true } : {}),
                        page: pageEvent.page,
                        limit: pageEvent.limit,
                    })
                ),
                switchMap((nfcApiResult) => {
                    const nfcIds = nfcApiResult.data.map((e) => e.id);
                    const scanApiResult$ = nfcIds.length ? this._scansService.search({ nfcIds }) : of({ data: [] });
                    return forkJoin([of(nfcApiResult), scanApiResult$]);
                }),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(([nfcApiResult, scanApiResult]) => {
                const nfcsWithStats: NfcWithStats[] = nfcApiResult.data.map((nfcDto) => {
                    const scanCount = scanApiResult.data.filter((scan) => scan.nfcId === nfcDto.id).length;
                    return NfcWithStats.fromNfcWithRestaurantDtoAndStats(nfcDto, { scanCount });
                });
                this.dataSource.data = nfcsWithStats;
                this.totalCount = nfcApiResult.metadata?.pagination.total ?? 0;
                this._store.dispatch(NfcActions.setNfcCount({ nfcCount: this.totalCount }));
                this.isLoading.set(false);
            });
    }

    onSearchChange(text: string): void {
        this.textSearch$.next(text);
        this.pagination$.next({
            page: 1,
            limit: this.paginator.pageSize,
        });
    }

    onPageEvent(pageEvent: PageEvent): void {
        this.pagination$.next({
            page: pageEvent.pageIndex + 1,
            limit: pageEvent.pageSize,
        });
    }

    openUpsertNfcModal(nfc: NfcWithStats | undefined, isTotem: boolean): void {
        this._customDialogService
            .open(UpsertNfcModalComponent, {
                panelClass: 'malou-dialog-panel',
                height: 'unset',
                data: {
                    nfc,
                    displayMode: this.displayMode,
                    isTotem,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this._refresh();
                }
            });
    }

    deleteNfcs(nfcs: NfcWithStats[]): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            title: this._translate.instant('settings.are_you_sure'),
            message: this._pluralTranslatePipe.transform('admin.nfcs.nfc_delete', nfcs.length, {
                chipName: nfcs.map((nfc) => nfc.chipName).join(', '),
            }),
            primaryButton: {
                label: this._translate.instant('common.delete'),
                action: () => {
                    forkJoin(nfcs.map((nfc) => this._nfcService.delete(nfc.id))).subscribe({
                        next: () => {
                            this._refresh();
                        },
                        error: (err) => {
                            const errorString: string = err?.error?.message || err?.message || String(err);
                            this._toastService.openErrorToast(this._translate.instant('common.unknown_error') + errorString);
                        },
                    });
                },
            },
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
        });
    }

    toggleActiveNfc(nfc: NfcWithStats, isActive: boolean): void {
        const updateNfc$ = nfc.isTotem()
            ? this._nfcService.updateTotem(nfc.id, { ...nfc.toUpdateTotemBodyDto(), active: isActive })
            : this._nfcService.updateSticker(nfc.id, { ...nfc.toUpdateStickerBodyDto(), active: isActive });

        updateNfc$.subscribe({
            next: () => {
                this._refresh();
                this._toastService.openSuccessToast(this._translate.instant('admin.nfcs.nfc_succesfully_updated'));
            },
            error: (err) => {
                const errorString: string = err?.error?.message || err?.message || String(err);
                this._toastService.openErrorToast(this._translate.instant('common.unknown_error') + errorString);
            },
        });
    }

    isAllSelected = (): boolean => {
        if (this.selection.isEmpty()) {
            return false;
        }
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };

    isAllSelectedIndeterminate = (): boolean => this.selection.hasValue() && !this.isAllSelected();

    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    hasSelectedNfcs = (): boolean => this.selection.hasValue();

    shouldDisplayNfcAddress(nfc: NfcWithStats): boolean {
        return !!nfc.restaurant?.address && !(nfc.restaurant?.type === BusinessCategory.BRAND);
    }

    displayNfcAddress(nfc: NfcWithStats): string {
        const address = nfc.restaurant?.address;
        return [address?.formattedAddress, address?.locality, address?.country].filter(Boolean).join(', ');
    }

    isSelected = (nfc: NfcWithStats): boolean => this.selection.isSelected(nfc);

    downloadQrCode(nfc: NfcWithStats): void {
        const nfcUrl = nfc.getNfcUrl();
        const filename = nfc.isTotem() ? (nfc.name ?? nfc.chipName) : this._translate.instant('admin.nfcs.sticker_qr_code_filename');
        const handleErrorCallback = (err: Error): void => {
            this._toastService.openErrorToast(JSON.stringify(err, errorReplacer));
            return;
        };
        const qrCodeDownloader = new QrCode();
        qrCodeDownloader.downloadQrCode(nfcUrl, filename, { errorCallback: handleErrorCallback });
    }

    downloadSticker(nfc: NfcWithStats): void {
        this.displayedStickerId.set(nfc.id);
        // We need the timeout because we have no hook to know when the sticker is correctly downloaded
        setTimeout(() => {
            this.displayedStickerId.set(null);
        }, 2 * TimeInMilliseconds.SECOND);
    }

    copyChipLink(nfc: NfcWithStats): void {
        this._clipboard.copy(nfc.getNfcUrl());
        this._toastService.openSuccessToast(this._translate.instant('common.copied'));
    }

    loadNfcsFromSheet(): void {
        this._customDialogService
            .open(LoadNfcsComponent, {
                panelClass: 'malou-dialog-panel',
                height: 'unset',
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this._refresh();
                }
            });
    }

    private _refresh(): void {
        this.pagination$.next({
            page: 1,
            limit: this.paginator.pageSize,
        });
    }
}
