@if (!isLoadingRestaurants) {
    <ng-container [ngTemplateOutlet]="restaurantsTemplate"></ng-container>
} @else {
    <div class="h-full w-full px-[30px] py-[30px]">
        <div class="w-full">
            <app-skeleton skeletonClass="w-full h-[50px] secondary-bg"></app-skeleton>
        </div>
        <div class="mt-5 grid h-full grid-cols-5 gap-5 md:grid-cols-2">
            @for (item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]; track item) {
                <app-skeleton
                    skeletonClass="h-[430px] md:h-[330px] w-full secondary-bg p-6 flex flex-col justify-between"
                    [animate]="false">
                    <div class="flex flex-col gap-4">
                        <app-skeleton skeletonClass="w-full h-[30px] mb-2"></app-skeleton>
                        <app-skeleton skeletonClass="w-full h-[170px] md:h-[150px]"></app-skeleton>
                    </div>
                    <app-skeleton skeletonClass="w-full h-[70px] mb-2"></app-skeleton>
                </app-skeleton>
            }
        </div>
    </div>
}

<ng-template #restaurantsTemplate>
    @if (dataSource.filteredData) {
        <div class="flex h-full flex-col" [ngClass]="{ 'pb-16': restaurantsList.length >= NUMBER_OF_ELEMENTS_PER_PAGE }">
            <div class="flex items-center gap-2 px-9 pb-4 pt-9 md:flex-col md:items-start md:px-6 md:pt-6">
                <p class="malou-text-section-title malou-color-text-1 whitespace-nowrap md:!text-[0.9375rem]">
                    {{ 'restaurants_list.navbar.your_business' | translate }}
                    <i class="font-medium">({{ dataSource.filteredData.length }})</i>
                </p>
                <div class="flex w-full gap-5">
                    <app-search
                        class="flex-1"
                        placeholder="{{ 'restaurants_list.business.search' | translate }}"
                        [debounceTime]="0"
                        (searchChange)="applyFilter($event)"></app-search>
                    <mat-button-toggle-group class="malou-group-toggle-btn md:!hidden">
                        <mat-button-toggle checked="true" (click)="isCardView = true">
                            <mat-icon [svgIcon]="SvgIcon.CARD"></mat-icon>
                        </mat-button-toggle>
                        <mat-button-toggle (click)="isCardView = false">
                            <mat-icon [svgIcon]="SvgIcon.LIST"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <button
                        class="malou-btn-raised--primary !h-12.5 md:!hidden"
                        data-testid="restaurants-open-add-restaurant-btn"
                        mat-raised-button
                        (click)="openConnectRestaurantModal()">
                        {{ 'restaurants_list.business.add_restaurant' | translate }}
                    </button>
                    <button
                        class="malou-btn-icon--primary btn-xl !hidden md:!flex md:!h-full"
                        mat-icon-button
                        (click)="openConnectRestaurantModal()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.ADD"></mat-icon>
                    </button>
                </div>
            </div>
            @if (dataSource.filteredData.length) {
                <div class="hide-scrollbar min-h-0 grow overflow-y-auto">
                    @if (!isCardView) {
                        @if (dataSource.filteredData.length) {
                            <mat-table class="malou-mat-table px-[30px] pb-5" matSort [dataSource]="dataSource" #table="matTable">
                                <ng-container matColumnDef="logo">
                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                    <mat-cell *matCellDef="let restaurant; table: table">
                                        <a class="mat-row-link" [routerLink]="'../' + restaurant._id + routerLink"></a>
                                        <div class="ml-[-17.5px] py-[5px]">
                                            <img
                                                class="h-11 w-14 rounded-lg object-cover"
                                                [lazyLoad]="restaurant?.logo?.urls?.small || ('Grey-burger' | illustrationPathResolver)" />
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{ 'restaurants_list.business.business' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let restaurant; table: table">
                                        <div class="flex items-center gap-x-2">
                                            @if (restaurant.type === BusinessCategory.LOCAL_BUSINESS) {
                                                <div
                                                    class="restaurant-type-logo--local-business mr-5"
                                                    matTooltip="{{ 'restaurants_list.business.business' | translate }}">
                                                    <mat-icon class="malou-color-white" [svgIcon]="SvgIcon.LOCALISATION"></mat-icon>
                                                </div>
                                            } @else {
                                                <div
                                                    class="restaurant-type-logo--brand mr-5"
                                                    matTooltip="{{ 'restaurants_list.brand_account' | translate }}">
                                                    <mat-icon class="malou-color-white" [svgIcon]="SvgIcon.HEART"></mat-icon>
                                                </div>
                                            }
                                            <p class="malou-text-14--semibold">{{ restaurant.internalName }}</p>
                                            <mat-icon
                                                class="edit-internal-name-btn"
                                                [svgIcon]="SvgIcon.EDIT"
                                                (click)="$event.stopPropagation(); openEditInternalNameModal(restaurant)">
                                            </mat-icon>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="fullFormattedAddress">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                        'restaurants_list.business.address' | translate
                                    }}</mat-header-cell>
                                    <mat-cell *matCellDef="let restaurant; table: table">
                                        <div class="mt-1">
                                            {{
                                                restaurant.type === BusinessCategory.BRAND
                                                    ? ('common.brand_account' | translate)
                                                    : restaurant.getFormattedAddressWithPostalCodeAndLocality()
                                            }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="DISPLAYED_COLUMNS"></mat-header-row>
                                <mat-row
                                    *matRowDef="let restaurant; columns: DISPLAYED_COLUMNS; table: table"
                                    [ngClass]="{ 'highlight-main': restaurant.type === BusinessCategory.BRAND }">
                                </mat-row>
                            </mat-table>
                        } @else {
                            <div class="w-full flex-1 md:px-6">
                                <app-no-results
                                    [details]="['restaurants_list.business.no_results' | translate]"
                                    [title]="'restaurants_list.no_results.funny_message' | translate"
                                    [picture]="'Goggles' | illustrationPathResolver">
                                </app-no-results>
                            </div>
                        }
                    }
                    @if (isCardView) {
                        @if (dataSource.filteredData.length) {
                            <div class="cards-container mt-2 px-9 pb-4 md:px-6">
                                @for (restaurant of currentPageData(); track trackByIdFn($index, restaurant)) {
                                    <app-restaurant-card
                                        class="md:w-full"
                                        [restaurant]="restaurant"
                                        (editInternalName)="openEditInternalNameModal($event)">
                                    </app-restaurant-card>
                                }
                            </div>
                        } @else {
                            <div class="w-full flex-1 md:px-6">
                                <app-no-results
                                    [details]="['restaurants_list.business.no_results' | translate]"
                                    [title]="'restaurants_list.no_results.funny_message' | translate"
                                    [picture]="'Goggles' | illustrationPathResolver">
                                </app-no-results>
                            </div>
                        }
                    }
                </div>
            } @else {
                <div class="w-full flex-1 md:px-6">
                    <app-no-results
                        [details]="['restaurants_list.business.no_results' | translate]"
                        [title]="'restaurants_list.no_results.funny_message' | translate"
                        [picture]="'Goggles' | illustrationPathResolver">
                    </app-no-results>
                </div>
            }
        </div>
    } @else {
        <div class="w-full md:px-6">
            <app-no-results
                [details]="['restaurants_list.business.add_first_restaurant' | translate]"
                [title]="'restaurants_list.no_restaurants.funny_message' | translate"
                [picture]="'Cutlery' | illustrationPathResolver"
                [buttonLabel]="'restaurants_list.business.add_restaurant' | translate"
                (onClickButton)="openConnectRestaurantModal()">
            </app-no-results>
        </div>
    }
</ng-template>

<app-paginator
    class="hid absolute bottom-0 w-full pb-1"
    [shouldBeHidden]="restaurantsList && restaurantsList.length < NUMBER_OF_ELEMENTS_PER_PAGE"
    [pageSize]="NUMBER_OF_ELEMENTS_PER_PAGE"
    [pageSizeOptions]="[NUMBER_OF_ELEMENTS_PER_PAGE]">
</app-paginator>
