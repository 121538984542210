import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { YextPublisherId, yextPublishers } from '@malou-io/package-utils';

import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';

@Component({
    selector: 'app-yext-platform-logo',
    templateUrl: './yext-platform-logo.component.html',
    styleUrls: ['./yext-platform-logo.component.scss'],
    standalone: true,
    imports: [PlatformLogoComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YextPlatformLogoComponent {
    readonly imgClasses = input<string>('');
    readonly dynamicClasses = input<Record<string, boolean>>({});
    readonly withLazyLoading = input<boolean>(false);
    readonly testId = input<string>('');
    readonly yextPublisherId = input.required<YextPublisherId>();

    readonly yextPublishers = yextPublishers;
}
