<div class="malou-dialog malou-dialog-mobile">
    <div class="malou-dialog__header">
        <div class="flex flex-col">
            <span>
                {{ 'informations.state_of_updates' | translate }}
            </span>
            <span class="malou-text-10--regular italic text-malou-color-text-2">
                {{ 'informations.infos_updates_state_last_time' | translate: { date: date | formatDate: 'long' } }}
            </span>
        </div>
        <mat-icon class="close cursor-pointer" [svgIcon]="SvgIcon.CROSS" (click)="close()"></mat-icon>
    </div>
    <div class="malou-dialog__body !m-0 flex h-auto flex-col gap-8 overflow-x-hidden !px-8 pb-8">
        <div class="malou-border-color-dark flex h-fit w-full flex-col rounded-[5px] border">
            <span class="malou-text-14--bold my-[22px] flex items-center pl-6 text-malou-color-text-1">
                <mat-icon
                    class="mr-4 !h-6 !w-6 rounded bg-malou-color-state-error--light bg-opacity-30 p-1 text-malou-color-state-error"
                    [svgIcon]="SvgIcon.CROSS"></mat-icon>
                {{ 'informations.nb_failed_updates' | translate: { nbUpdates: 12 } }}
            </span>
            <mat-table class="malou-mat-table" matSort [dataSource]="errorUpdatesSource" #errorUpdatesTable="matTable">
                <ng-container matColumnDef="platformKey">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small">
                        <span> {{ 'informations.platforms' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let update; table: errorUpdatesTable"
                        class="malou-mat-table-cell-small font-semibold !text-malou-color-text-1">
                        <div class="flex items-center gap-2">
                            <a href="https://malou.io" target="_blank">
                                <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                            </a>
                            <span class="malou-text-10--semibold text-malou-color-text-1"> {{ update.platformKey | platformName }} </span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-large">
                        <span> {{ 'informations.state' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let update; table: errorUpdatesTable"
                        class="malou-mat-table-cell-large font-semibold !text-malou-color-text-1 sm:ml-4 sm:!flex">
                        <app-information-update-state-error
                            [platformKey]="update.platformKey"
                            [platformAccessStatus]="update.platformAccessStatus"></app-information-update-state-error>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="details">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge sm:!hidden">
                        <span> {{ 'informations.details' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let update; table: errorUpdatesTable" class="malou-mat-table-cell-xxlarge sm:!hidden">
                        <app-information-update-detail-error
                            [platformKey]="update.platformKey"
                            [platformAccessStatus]="update.platformAccessStatus"
                            (close)="close()"></app-information-update-detail-error>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="errorUpdatesDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let update; columns: errorUpdatesDisplayedColumns; table: errorUpdatesTable; let i = index"></mat-row>
            </mat-table>
        </div>

        <div class="malou-border-color-dark flex h-fit w-full flex-col rounded-[5px] border">
            <span class="malou-text-14--bold my-[22px] flex items-center pl-6 text-malou-color-text-1">
                <mat-icon
                    class="mr-4 !h-6 !w-6 rounded bg-malou-color-chart-green bg-opacity-30 p-1 text-malou-color-state-success"
                    [svgIcon]="SvgIcon.CHECK"></mat-icon>
                {{ 'informations.nb_succeed_updates' | translate: { nbUpdates: 33 } }}
            </span>
            <mat-table class="malou-mat-table" matSort [dataSource]="otherUpdatesSource" #otherUpdatesTable>
                <ng-container matColumnDef="platformKey">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-small" mat-sort-header>
                        <span> {{ 'informations.platforms' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let update; table: otherUpdatesTable"
                        class="malou-mat-table-cell-small font-semibold !text-malou-color-text-1">
                        <div class="flex items-center gap-2">
                            <a href="https://malou.io" target="_blank">
                                <app-platform-logo imgClasses="mr-10 h-8" [logo]="update.platformKey"></app-platform-logo>
                            </a>
                            <span class="malou-text-10--semibold text-malou-color-text-1"> {{ update.platformKey | platformName }} </span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-xxlarge" mat-sort-header>
                        <span> {{ 'informations.state' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let update; table: otherUpdatesTable" class="malou-mat-table-cell-xxlarge sm:ml-4 sm:!flex">
                        <ng-container [ngTemplateOutlet]="update.state ? done : pending"></ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="infosUpdated">
                    <mat-header-cell *matHeaderCellDef class="malou-mat-table-cell-large sm:!hidden" mat-sort-header>
                        <span> {{ 'informations.details' | translate }} </span>
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let update; table: otherUpdatesTable"
                        class="malou-mat-table-cell-large !text-malou-color-text-1 sm:!hidden">
                        <span class="malou-text-10--regular text-malou-color-text-2"> {{ update.infosUpdated }} </span>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="otherDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let update; columns: otherDisplayedColumns; table: otherUpdatesTable; let i = index"></mat-row>
            </mat-table>
        </div>
    </div>
</div>

<ng-template #done>
    <span
        class="malou-text-10--regular flex items-center rounded-[5px] bg-malou-color-chart-green bg-opacity-20 p-1 pr-2 text-malou-color-state-success">
        <mat-icon class="mr-1 !h-6 !w-6 rounded p-1" [svgIcon]="SvgIcon.CHECK"></mat-icon>
        Modification Faites
    </span>
</ng-template>

<ng-template #pending>
    <span
        class="malou-text-10--regular flex items-center rounded-[5px] bg-malou-color-state-warn bg-opacity-20 p-1 pr-2 text-malou-color-state-warn">
        <mat-icon class="mr-1 !h-6 !w-6 rounded p-1" [svgIcon]="SvgIcon.CLOCK"></mat-icon>
        En cours
    </span>
</ng-template>
