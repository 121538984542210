<div class="mat-gradient flex h-full w-full">
    <div class="malou-color-bg-white flex w-3/5 flex-col rounded-r-[50px] md:w-full md:rounded-none">
        <div class="malou-sticky-logo">
            <app-platform-logo [logo]="'malou'"></app-platform-logo>
        </div>
        <div class="hide-scrollbar grid h-full place-items-center overflow-y-auto overflow-x-hidden sm:mt-3 md:px-5">
            <div class="w-8/12 md:w-full">
                <div class="icons mb-3">
                    <img alt="Burger illustration" [src]="'Burger' | illustrationPathResolver" />
                    <img alt="Karl illustration" [src]="'Karl' | illustrationPathResolver" />
                    <img alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
                    <img alt="Goggles illustration" [src]="'Goggles' | illustrationPathResolver" />
                </div>
                @if (!formSubmitted) {
                    <div>
                        <h1 class="md:!malou-text-35--semibold malou-text-heading malou-color-text-1 mb-2">
                            {{ 'login.new_password.change_password' | translate }}
                        </h1>
                        <form class="malou-form-flex" [formGroup]="passwordForm" (ngSubmit)="reset()">
                            <app-input-text
                                class="mb-4"
                                formControlName="password"
                                [title]="'login.new_password.new_password' | translate"
                                [password]="true"
                                [showEyeIcon]="true"
                                [errorMessage]="
                                    form.password.errors && form.password.errors.minlength
                                        ? ('login.new_password.password_too_short' | translate: { passwordLength: MIN_PASSWORD_LENGTH })
                                        : ''
                                ">
                            </app-input-text>
                            <app-input-text
                                class="mb-10"
                                formControlName="confirmPwd"
                                [title]="'login.new_password.confirm_password' | translate"
                                [password]="true"
                                [showEyeIcon]="true"
                                [errorMessage]="
                                    form.confirmPwd.dirty && form.confirmPwd.errors && form.confirmPwd.errors.mustMatch
                                        ? ('login.new_password.different_passwords' | translate)
                                        : ''
                                ">
                            </app-input-text>
                            <button
                                class="malou-btn-raised--primary !mb-6"
                                type="submit"
                                mat-raised-button
                                [disabled]="!passwordForm.valid">
                                {{ 'login.new_password.validate' | translate }}
                            </button>
                            <button
                                class="malou-btn-flat !mb-2"
                                class="malou-text-10--semibold malou-color-primary"
                                type="button"
                                mat-button
                                (click)="goHome()">
                                {{ 'login.forgot_password.back_to_home' | translate }}
                            </button>
                        </form>
                    </div>
                } @else if (!hasExpiredToken) {
                    <h1 class="md:!malou-text-35--semibold malou-text-heading malou-color-text-1 mb-8">
                        {{ 'login.new_password.password_reset_success' | translate }}
                    </h1>
                    <button class="malou-btn-raised--primary w-full" mat-raised-button (click)="goHome()">
                        {{ 'login.forgot_password.back_to_home' | translate }}
                    </button>
                } @else {
                    <h1 class="md:!malou-text-35--semibold malou-text-heading malou-color-text-1 mb-8">
                        {{ 'login.new_password.invalid_link' | translate }}
                    </h1>
                    <button class="malou-btn-raised--primary !mb-2 w-full" mat-raised-button (click)="goToForgotPassword()">
                        {{ 'login.forgot_password.back_to_forgot_password' | translate }}
                    </button>
                }
            </div>
        </div>
    </div>
    <div class="w-2/5 items-center justify-center md:hidden md:w-0">
        <div class="flex h-full flex-col items-center justify-around">
            <p class="malou-text-title md:malou-text-section-title malou-color-white mt-4 px-14 text-center">
                {{ 'login.digital_for_growth' | translate }}
            </p>
            <img class="px-2" alt="Screen illustration" [src]="'Screen' | illustrationPathResolver" />
            <div class="malou-color-white malou-text-20--regular mb-4">
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.time' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.visibility' | translate }}</strong
                    >,
                </div>
                <div>
                    {{ 'login.more' | translate }} <strong>{{ 'login.clients' | translate }}</strong>
                </div>
            </div>
        </div>
    </div>
</div>
