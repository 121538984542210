import { AsyncPipe, NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, NavigationSkipped, Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSize, ScreenSizeService } from ':core/services/screen-size.service';
import { selectUnansweredCommentCount, selectUnansweredMentionCount } from ':modules/comments/store/comments.reducer';
import { selectUnreadConversations } from ':modules/messages/messages.reducer';
import { selectOwnRestaurants } from ':modules/restaurant-list/restaurant-list.reducer';
import { SidenavRouteComponent } from ':modules/sidenav-router/sidenav-content/sidenav-route.component';
import * as SidenavSelectors from ':modules/sidenav-router/store/sidenav.selectors';
import { HeaderComponent } from ':shared/components/header/header.component';
import { InputTextComponent } from ':shared/components/input-text/input-text.component';
import { ReferYourFriendsComponent } from ':shared/components/refer-your-friends/refer-your-friends.component';
import { SelectComponent } from ':shared/components/select/select.component';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { AbsoluteCenteringDirective } from ':shared/directives/absolute-centering.directive';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { Restaurant } from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe, ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { AsTypePipe } from ':shared/pipes/as.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';
import { OneDigitNumberPipe } from ':shared/pipes/one-digit-number.pipe';
import { ValuesPipe } from ':shared/pipes/values.pipe';

export class SidenavStateService {
    readonly isOpened = signal<boolean>(false);
}

@Component({
    selector: 'app-sidenav-content',
    templateUrl: './sidenav-content.component.html',
    styleUrls: ['./sidenav-content.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatSidenavModule,
        NgClass,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        InfiniteScrollModule,
        MatListModule,
        MatTooltipModule,
        HeaderComponent,
        SelectComponent,
        AsyncPipe,
        ImagePathResolverPipe,
        TranslateModule,
        OneDigitNumberPipe,
        ValuesPipe,
        ApplyPurePipe,
        ReferYourFriendsComponent,
        SidenavToggleButtonComponent,
        ApplySelfPurePipe,
        InputTextComponent,
        MatMenuModule,
        SlicePipe,
        LazyLoadImageModule,
        AbsoluteCenteringDirective,
        MalouSpinnerComponent,
        AsTypePipe,
        SidenavRouteComponent,
    ],
    providers: [SidenavStateService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContentComponent implements OnInit {
    readonly restaurants$: Observable<Restaurant[]> = this._store.select(selectOwnRestaurants);
    readonly unreadConversationCount$: Observable<number> = this._store.select(selectUnreadConversations);
    readonly unreadCommentsCount$: Observable<number> = this._store.select(selectUnansweredCommentCount);
    readonly unreadMentionsCount$: Observable<number> = this._store.select(selectUnansweredMentionCount);

    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    readonly selectedRestaurant: WritableSignal<Restaurant | null> = signal(null);
    readonly restaurantFilterCtrl = new FormControl('');
    readonly interactionNotificationCount: WritableSignal<number | null> = signal(null);
    readonly isSmallScreen = signal(false);
    readonly isSidenavOpened = signal(false);
    readonly isAllRestaurantsSelected = signal(false);

    readonly currentPage = signal(1);
    readonly textSearch = signal('');
    readonly restaurants: WritableSignal<Restaurant[]> = signal([]);
    readonly Restaurant = Restaurant;
    readonly filterRestaurants = computed(() => {
        let restaurantsDisplayed: Restaurant[];

        if (!this.textSearch()) {
            restaurantsDisplayed = this.restaurants();
        } else {
            restaurantsDisplayed = this.restaurants().filter((r) =>
                r.internalName?.trim().toLowerCase().includes(this.textSearch().trim().toLowerCase())
            );
        }

        return restaurantsDisplayed.slice(0, this.currentPage() * this._pageSize);
    });
    readonly hasOneRestaurantWithBoosterPackActivated: Signal<boolean> = computed(
        () => !!this.restaurants().find((restaurant) => restaurant.boosterPack?.activated)
    );

    readonly SvgIcon = SvgIcon;

    private readonly _pageSize = 10;
    private readonly _destroyRef = inject(DestroyRef);

    constructor(
        private readonly _sidenavState: SidenavStateService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _screenSizeService: ScreenSizeService,
        private readonly _translate: TranslateService
    ) {
        this._store
            .select(SidenavSelectors.selectIsOpened)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((isOpened) => {
                this.isSidenavOpened.set(isOpened);
                this._sidenavState.isOpened.set(isOpened);
            });

        this._router.events.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
            if ((event instanceof NavigationEnd || event instanceof NavigationSkipped) && event.url.includes('/groups')) {
                this.isAllRestaurantsSelected.set(true);
                this._restaurantsService.setSelectedRestaurant(null);
            }
        });
    }

    ngOnInit(): void {
        this.isAllRestaurantsSelected.set(this._router.url.includes('/groups'));
        this.restaurantFilterCtrl.valueChanges.pipe(debounceTime(300), takeUntilDestroyed(this._destroyRef)).subscribe((textSearch) => {
            this.textSearch.set(textSearch ?? '');
            this.currentPage.set(1);
        });

        this.restaurants$.pipe().subscribe((restaurants) => {
            this.restaurants.set(
                [...restaurants.map((r) => new Restaurant(r))]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .sort((a, b) => (a.isBrandBusiness() > b.isBrandBusiness() ? -1 : 1))
            );
            this.restaurantFilterCtrl.patchValue('');
        });
        this._restaurantsService.restaurantSelected$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((restaurant: Restaurant | null) => {
                if (restaurant) {
                    this.selectedRestaurant.set(restaurant);
                    this.isAllRestaurantsSelected.set(false);
                } else {
                    this.selectedRestaurant.set(null);
                }
            });

        combineLatest([this.unreadConversationCount$, this.unreadCommentsCount$, this.unreadMentionsCount$]).subscribe(
            ([unreadConversation, unansweredComments, unansweredMentions]: number[]) => {
                this.interactionNotificationCount.set(unreadConversation + unansweredComments + unansweredMentions);
            }
        );

        this._screenSizeService.resize$.subscribe((elt) => {
            this.isSmallScreen.set(elt.size === ScreenSize.IsSmallScreen);
        });
    }

    getSubtext = (restaurant: Restaurant): string =>
        restaurant.isBrandBusiness() ? this._translate.instant('common.brand_account') : restaurant.getFullFormattedAddress();

    onRestaurantSelected(restaurant: Restaurant, mouseEvent: MouseEvent): void {
        if (!mouseEvent.ctrlKey && !mouseEvent.metaKey) {
            this.isAllRestaurantsSelected.set(false);
            this.selectedRestaurant.set(restaurant);
            this.restaurantFilterCtrl.reset('');
        }
        const cleanUrl = this._cleanUrl(this._router.url);
        const urlParams = cleanUrl.split('/').slice(3);
        if (cleanUrl?.match(/\/groups\//)) {
            if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
                const url = this._router.serializeUrl(this._router.createUrlTree(['/restaurants/' + restaurant._id]));
                window.open(url, '_blank');
                mouseEvent.stopPropagation();
                return;
            }
            this._router.navigate(['/', 'restaurants', restaurant._id]);
            return;
        }
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            const url = this._router.serializeUrl(
                this._router.createUrlTree(['/restaurants/' + restaurant._id + '/' + this._removeQueryParams(urlParams).join('/')])
            );
            window.open(url, '_blank');
            mouseEvent.stopPropagation();
            return;
        }
        this._router.navigate(['/', 'restaurants', restaurant._id, ...this._removeQueryParams(urlParams)]);
    }

    onAllRestaurantsSelected(pointerEvent: MouseEvent): void {
        if (pointerEvent.ctrlKey || pointerEvent.metaKey) {
            window.open('/groups', '_blank');
            pointerEvent.stopPropagation();
            return;
        }
        this.isAllRestaurantsSelected.set(true);
        this.selectedRestaurant.set(null);
        this._router.navigate(['/', 'groups']);
    }

    onScrollDown(): void {
        this.currentPage.update((oldValue) => oldValue + 1);
    }

    private _removeQueryParams(params: string[]): string[] {
        return params?.filter((p) => !p.includes('?'));
    }

    private _cleanUrl(url: string): string {
        if (url.match(/fromDuplicate/)) {
            return url.substring(0, url.indexOf('?'));
        }
        return url;
    }
}
