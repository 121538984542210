const Mo = 1_000_000;

export const STORY_MAX_VIDEO_SIZE = 99 * Mo; // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media#caract-ristiques-des-vid-os-des-stories

// Hotfix to 299Mo because Cloudinary support transformation for video with max 300MB
// Cloudinary can support up to 2GB with our paid plan but we need to setup an webhook and do the upload asynchronously
// todo setup the webhook and re-set 999 Mo here
export const REEL_MAX_VIDEO_SIZE = 299 * Mo; // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media#reels-specs
export const FACEBOOK_MAX_VIDEO_SIZE = 299 * Mo; // https://developers.facebook.com/docs/video-api/guides/publishing/#limites-2
export const DEFAULT_MAX_VIDEO_SIZE = 299 * Mo;

export const MESSAGE_MAX_VIDEO_SIZE = 25 * Mo; // Instagram messaging: https://developers.facebook.com/docs/messenger-platform/instagram/features/send-message
// Messenger: https://developers.facebook.com/docs/messenger-platform/reference/attachment-upload-api#payload
// Google: no video https://developers.google.com/business-communications/business-messages/reference/rest/v1/conversations.messages?hl=fr#contentinfo

export const DEFAULT_MAX_IMAGE_SIZE = 50 * Mo;

export const IG_FIT_BIGGER_SIDE_MAX_SIZE = 1500; // Max 1500px bigger side for instagram so we are under 8MB (~1500^2*1B) (1B/px png, 0.14B/px jpg)
export const SMALL_MEDIA_BIGGER_SIDE_MAX_SIZE = 300;

export const ONE_OCTET = 1024;
