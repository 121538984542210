import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { filter, forkJoin, map, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

import { isNotNil, NotificationChannel, NotificationType, PERIOD_FOR_WEB_POSTS_SUGGESTION } from '@malou-io/package-utils';

import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { KillSubscriptions } from ':shared/interfaces';
import { CustomDialogService } from ':shared/services/custom-dialog.service';

import { Notification } from '../../models/notification.model';
import { NotificationService } from '../../services/notifications.service';
import { PostSuggestionPopinComponent } from './post-suggestion-popin/post-suggestion-popin.component';

@Component({
    selector: 'app-notification-popins',
    template: '<div></div>',
    standalone: true,
    imports: [PostSuggestionPopinComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationPopinsComponent implements OnInit, KillSubscriptions {
    readonly notificationPopinByType = {
        [NotificationType.POST_SUGGESTION]: PostSuggestionPopinComponent,
    };
    readonly notificationsTypesSortedByPriority = [NotificationType.SPECIAL_HOUR, NotificationType.POST_SUGGESTION];

    readonly killSubscriptions$: Subject<void> = new Subject<void>();
    private readonly _notificationService = inject(NotificationService);
    private readonly _customDialogService = inject(CustomDialogService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _postsService = inject(PostsService);

    ngOnInit(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) =>
                    forkJoin([
                        this._notificationService.getActiveNotifications({
                            notificationTypes: this.notificationsTypesSortedByPriority,
                            channel: NotificationChannel.WEB,
                            restaurantIds: [restaurant._id],
                        }),
                        of(restaurant),
                    ])
                ),
                switchMap(([notifications, restaurant]) => {
                    const sortedNotifications = this._sortNotificationsByPriority(notifications);
                    const firstNotification = sortedNotifications[0];

                    return forkJoin({
                        shouldOpenPopin: this._shouldOpenPopin$(firstNotification, restaurant?._id),
                        notification: of(firstNotification),
                        restaurantId: of(restaurant._id),
                    });
                }),
                switchMap(({ shouldOpenPopin, notification, restaurantId }) =>
                    shouldOpenPopin ? this._openNotificationPopin$(notification, restaurantId) : of(null)
                ),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe();
    }

    private _openNotificationPopin$(notification: Notification, restaurantId): Observable<any> {
        const popinComponent = this.notificationPopinByType[notification.type];
        if (!popinComponent) {
            return of(null);
        }
        return this._customDialogService
            .open(popinComponent, {
                width: '650px',
                height: 'auto',
                data: {
                    notification,
                    restaurantId,
                },
            })
            .afterClosed()
            .pipe(
                switchMap((_res) =>
                    this._notificationService.updateNotifications({
                        notificationIds: [notification.id],
                        update: {
                            readAt: new Date(),
                        },
                    })
                )
            );
    }

    private _sortNotificationsByPriority(notifications: Notification[]): Notification[] {
        return notifications
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .sort((a, b) => {
                const aIndex = this.notificationsTypesSortedByPriority.indexOf(a.type);
                const bIndex = this.notificationsTypesSortedByPriority.indexOf(b.type);
                return aIndex - bIndex;
            });
    }

    private _shouldOpenPopin$(notification: Notification | undefined, restaurantId: string): Observable<boolean> {
        if (!notification) {
            return of(false);
        }
        if (notification.type === NotificationType.POST_SUGGESTION) {
            const startDate = DateTime.now().minus({ days: PERIOD_FOR_WEB_POSTS_SUGGESTION }).toFormat('dd-MM-yyyy');
            const endDate = DateTime.now().toFormat('dd-MM-yyyy');

            return this._postsService.getPostsBetweenDates$(restaurantId, startDate, endDate).pipe(
                map((res) => {
                    const { posts } = res.data;
                    return !posts.some((post) => post.isPublished() && !post.isStory);
                })
            );
        }

        return of(true);
    }
}
