@if (!temporaryHide()) {
    <div
        class="card-container !py-4 md:!px-5"
        [ngClass]="{
            'malou-card--secondary': review() | hasReply,
            'malou-card': !(review() | hasReply),
            'malou-color-background-light': isSelected(),
            '!border-malou-color-border-secondary': hasBorder(),
        }"
        (click)="onReviewClick()">
        <ng-container
            [ngTemplateOutlet]="reviewTemplate"
            [ngTemplateOutletContext]="{
                reviewText: review() | reviewText: { showTranslation: showTranslation(), language: currentLang },
            }"></ng-container>
    </div>
}

<ng-template let-reviewText="reviewText" #reviewTemplate>
    @if (isAggregatedView()) {
        <app-restaurant-header-for-review-preview
            [restaurant]="restaurant()"
            [review]="review()"></app-restaurant-header-for-review-preview>
    }
    <div class="malou-card__header flex flex-col !pb-0 pl-0" [ngClass]="{ 'mb-2': reviewText?.length > 0 }" #header>
        <div class="flex w-full items-center">
            <div
                class="malou-card__image-container flex"
                [matTooltip]="'reviews.orders' | pluralTranslate: (review() | getEaterTotalOrders)">
                <app-platform-logo class="z-10" imgClasses="h-8 w-8 max-w-fit !rounded-full" [logo]="review().key"></app-platform-logo>
                @if (!(review() | applySelfPure: 'hasScanId')) {
                    <img class="malou-avatar--medium -ml-4.5" alt="avatar" [src]="review() | reviewerDisplayName | avatar" />
                }
            </div>
            <div class="ml-2 flex w-[30%] grow flex-col justify-center">
                <div class="malou-text-12--bold truncate">{{ review() | reviewerDisplayName }}</div>
                <div class="malou-text__card-subtitle">{{ (review() | reviewDate) || '-' }}</div>
            </div>
            <div class="malou-card__action-button">
                <app-star-gauge class="px-4 md:!hidden" [stars]="review().rating"></app-star-gauge>
                @if (withArchiveButton()) {
                    <button
                        class="malou-btn-icon--secondary btn-xl hover:!bg-malou-color-background-light"
                        data-testid="review-preview-archive-btn"
                        type="button"
                        mat-icon-button
                        [ngClass]="{ 'malou-color-background-dark !border-malou-color-purple--light': review().archived }"
                        [matTooltip]="review() | reviewArchiveTooltip"
                        (click)="$event.stopPropagation(); toggleArchived()">
                        <mat-icon class="icon-btn" [svgIcon]="SvgIcon.BOX"></mat-icon>
                    </button>
                }
            </div>
        </div>
        <div class="h-4 w-full min-md:!hidden">
            <app-star-gauge [stars]="review().rating"></app-star-gauge>
        </div>
    </div>
    @if (reviewText?.length > 0) {
        <div class="flex md:!mt-2 md:!flex-col">
            @if (review() | applySelfPure: 'getSocialAttachments'; as socialAttachments) {
                @if (socialAttachments.length) {
                    <div class="flex gap-[5px]">
                        @for (media of socialAttachments.slice(0, MAX_MEDIA_ATTACHMENTS_SHOWN); track media; let i = $index) {
                            <div>
                                <div
                                    class="malou-card__media"
                                    [ngClass]="{
                                        'malou-card__image-suffix-overlay':
                                            socialAttachments.length > MAX_MEDIA_ATTACHMENTS_SHOWN && i === MAX_MEDIA_ATTACHMENTS_SHOWN - 1,
                                    }">
                                    @if (media.type === 'photo') {
                                        <img
                                            class="malou-border-radius-5"
                                            alt=""
                                            [lazyLoad]="media.urls.original"
                                            (click)="openCarousel(socialAttachments, i)" />
                                    }
                                    @if (media.type === 'video') {
                                        <video
                                            class="malou-border-radius-5"
                                            autoplay
                                            loop
                                            [src]="media.urls.original"
                                            [muted]="'muted'"
                                            (click)="openCarousel(socialAttachments, i)"></video>
                                    }
                                    @if (socialAttachments.length > MAX_MEDIA_ATTACHMENTS_SHOWN && i === MAX_MEDIA_ATTACHMENTS_SHOWN - 1) {
                                        <span class="malou-border-radius-5" (click)="openCarousel(socialAttachments, i)">
                                            <div class="malou-text-15--semibold">+{{ socialAttachments.length - 1 }}</div>
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            }
            <div
                class="malou-card__body-text"
                [ngClass]="{
                    'min-md:!ml-5': (review() | hasAttachments),
                    'md:!mt-3': (review() | hasAttachments),
                }">
                @if (shouldDisplaySemanticAnalysis() && highlightedReviewTextHtml() && !showTranslation()) {
                    <div class="font-normal" [innerHTML]="highlightedReviewTextHtml()"></div>
                } @else {
                    @if ('text' | reviewShortTextLength: { shouldDisplayFullText: !isFold().text }; as shortTextLength) {
                        <div>
                            <span class="font-normal" [innerHTML]="reviewText | shortText: shortTextLength"></span>
                            @if (this.withSeeMoreButton() && reviewText.length > shortTextLength) {
                                <span
                                    class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5"
                                    (click)="toggleFold('text')">
                                    {{ (isFold().text ? 'common.see_more' : 'common.see_less') | translate }}
                                </span>
                            }
                        </div>
                    }
                }
            </div>
        </div>
    }

    @if (shouldShowTranslationTemplate()) {
        <app-review-translations
            [review]="review()"
            [restaurant]="restaurant()"
            [showTranslation]="showTranslation()"
            (showTranslatedText)="showTranslation.set($event)"></app-review-translations>
    }

    @if (review() | getMenuItemReviews; as menuItems) {
        <div [ngClass]="{ 'min-md:!grid-first-wrapper': menuItems.length > MAX_MENU_ITEM_SHOWN }">
            <div class="grid-wrapper-menu-review" [class.grid-hide-rows]="isFold().menuItems" [class.gap-1.5]="!isFold().menuItems">
                @for (
                    itemReview of menuItems | displayMenuItems: { isFold: isFold().menuItems, maxMenuItemShown: MAX_MENU_ITEM_SHOWN };
                    track itemReview
                ) {
                    <div class="mt-1">
                        <div
                            class="flex items-end"
                            [matTooltip]="
                                itemReview.tags?.length || itemReview.comment?.length
                                    ? itemReview.tags?.join(' ● ') || itemReview.comment
                                    : ''
                            ">
                            <mat-icon
                                class="smaller-icon mr-2 !h-[16px] !w-[16px]"
                                svgIcon="{{ itemReview.rating ? 'thumb-up' : 'thumb-down' }}"
                                [ngClass]="itemReview.rating ? 'thumb-up' : 'thumb-down'">
                            </mat-icon>
                            <span class="malou-text-10--regular flex">
                                {{ itemReview.name }}
                            </span>
                        </div>
                    </div>
                }
                @if (menuItems.length > MAX_MENU_ITEM_SHOWN) {
                    <span
                        class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5 pt-1 min-md:!hidden"
                        (click)="toggleFold('menuItems')">
                        {{ (isFold().menuItems ? 'common.see_more' : 'common.see_less') | translate }}
                    </span>
                }
            </div>
            @if (menuItems.length > MAX_MENU_ITEM_SHOWN) {
                <span class="flex items-end">
                    <span
                        class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5 md:!hidden"
                        (click)="toggleFold('menuItems')">
                        {{ (isFold().menuItems ? 'common.see_more' : 'common.see_less') | translate }}
                    </span>
                </span>
            }
        </div>
    }
    @if (shouldDisplaySemanticAnalysis()) {
        <div class="mt-3.5 flex">
            <app-review-semantic-analysis
                [review]="review()"
                [showOriginalTextWarning]="showOriginalTextWarning()"
                (hoveredChip)="onHoveredChip($event)"
                (highlightAllSegments)="highlightAllSegments()"></app-review-semantic-analysis>
        </div>
    }
    @if (review().comments.length) {
        <div class="mt-2.5 flex">
            @if (restaurantKeywords$() | async) {
                <div>
                    <div class="flex items-center">
                        <label class="malou-text-12--semibold malou-color-text-1">{{ 'keywords_score.score' | translate }}</label>
                        @if (review() | applySelfPure: 'getWasAnsweredAutomatically') {
                            <mat-icon class="malou-color-primary h-4 w-4" [svgIcon]="SvgIcon.FLASH"></mat-icon>
                        }
                    </div>
                    <app-keywords-score-gauge
                        [text$]="commentText$"
                        [restaurant$]="restaurant$"
                        [textType$]="gaugeTextType$"
                        [lang$]="lang$"
                        [keywords$]="restaurantKeywords$()"
                        [reviewerName$]="reviewerName$"
                        [responseTime$]="responseTime$"
                        [restaurantAiSettings$]="restaurantAiSettings$"
                        [shouldOnlyDisplayScore]="true"
                        #keywordsScoreGauge>
                    </app-keywords-score-gauge>
                </div>
            }
            <div class="malou-card__vertical-separator"></div>
            @if ('comment' | reviewShortTextLength: { shouldDisplayFullText: !isFold().comment }; as shortTextLength) {
                <div class="malou-card__body-text">
                    <span class="italic" [innerHTML]="review().comments[0].text | shortText: shortTextLength"></span>
                    @if (this.withSeeMoreButton() && review().comments[0].text && review().comments[0].text.length > shortTextLength) {
                        <span class="malou-text-10--semibold malou-color-primary cursor-pointer pl-0.5" (click)="toggleFold('comment')">
                            {{ (isFold().comment ? 'common.see_more' : 'common.see_less') | translate }}
                        </span>
                    }
                    <div class="mt-1">
                        @if (review() | canBeEdited) {
                            <span
                                class="malou-text-10--semibold malou-color-primary cursor-pointer"
                                data-testid="review-preview-edit-reply-btn"
                                [matTooltip]="!isSyncReviewsFinished() ? ('reviews.cant_edit_review_while_sync' | translate) : ''"
                                (click)="openReplyModal(review())">
                                {{ 'common.edit' | translate }}
                            </span>
                        }
                        @if (review().comments.length > 1) {
                            <span>
                                ({{ 'reviews.other_replies' | pluralTranslate: review().comments.length - 1 }}
                                <span
                                    class="malou-text-10--semibold malou-color-primary cursor-pointer"
                                    [matTooltip]="!isSyncReviewsFinished() ? ('reviews.cant_edit_review_while_sync' | translate) : ''"
                                    (click)="openReplyModal(review())">
                                    {{ 'common.see' | translate }}
                                </span>
                                )
                            </span>
                        }
                    </div>
                </div>
            }
        </div>
    }
    @if (withReplyButton()) {
        <div>
            @if (review() | canBeReplied) {
                <div class="mt-2.5">
                    @if (review() | isPrivate) {
                        <button
                            class="malou-btn-raised--secondary malou-text-weight-medium tracking_review_preview_reply_button !h-11 w-full"
                            data-testid="review-preview-reply-btn"
                            data-testid="review-preview-reply-btn"
                            mat-raised-button
                            appCheckPermissions
                            [disabled]="!isSyncReviewsFinished()"
                            [context]="{ concernedPlatforms: [PlatformKey.FACEBOOK], action: 'reply_review', platformKey: review().key }"
                            (myClick)="openReplyModal(review())">
                            <span [matTooltip]="!isSyncReviewsFinished() ? ('reviews.cant_reply_review_while_sync' | translate) : ''">
                                {{ 'common.reply' | translate }}
                            </span>
                        </button>
                    } @else {
                        @if (review().comments.length === 0 || (review().comments.length && (review() | canHaveMultipleReplies))) {
                            <button
                                class="malou-btn-raised--secondary malou-text-weight-medium tracking_review_preview_reply_button !h-11 w-full"
                                data-testid="review-preview-reply-btn"
                                mat-raised-button
                                appCheckPermissions
                                [disabled]="!isSyncReviewsFinished()"
                                [context]="{
                                    concernedPlatforms: [PlatformKey.FACEBOOK],
                                    action: 'reply_review',
                                    platformKey: review().key,
                                }"
                                (myClick)="openReplyModal(review())">
                                <span data-cy="replyReviewSpan" [matTooltip]="review() | reviewAnswerTooltip: isSyncReviewsFinished()">
                                    {{ 'reviews.reply' | translate }}
                                    @if (review() | applySelfPure: 'getNbDaysLeftToReply'; as nbDaysLeftToReply) {
                                        @if (nbDaysLeftToReply > 0) {
                                            <span class="malou-text-13 italic">
                                                {{
                                                    nbDaysLeftToReply > 1
                                                        ? ('reviews.days_left_to_reply' | translate: { days: nbDaysLeftToReply })
                                                        : ('reviews.day_left_to_reply' | translate)
                                                }}
                                            </span>
                                        }
                                    }
                                </span>
                            </button>
                        }
                    }
                </div>
            } @else {
                @if (isSyncReviewsFinished() && (review() | getNbDaysUntilCantBeAnswered) !== null && review().comments.length === 0) {
                    <button
                        class="malou-btn-raised--secondary--alt malou-text-weight-medium !mt-2.5 !h-11 w-full"
                        mat-raised-button
                        appCheckPermissions
                        [disabled]="true">
                        <span class="malou-text-12">{{
                            isPhoneScreen()
                                ? ('reviews.cant_reply_because_date_short' | translate)
                                : ('reviews.cant_reply_because_date' | translate)
                        }}</span>
                    </button>
                }
            }
        </div>
    }
</ng-template>
