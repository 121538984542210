@if (!isPhoneScreen()) {
    <div class="flex h-full gap-x-4">
        <div class="flex-2 pl-7.5 md:px-7.5">
            <ng-container *ngTemplateOutlet="postsList"></ng-container>
        </div>
        <div class="max-w-1/3 flex-1 pb-3.5 pr-7">
            <ng-container *ngTemplateOutlet="feed"></ng-container>
        </div>
    </div>
} @else {
    <mat-tab-group class="p-3" preserveContent [selectedIndex]="selectedIndex()">
        <mat-tab>
            <ng-container *ngTemplateOutlet="loading() ? loader : posts().length === 0 ? noPosts : postsList"></ng-container>
        </mat-tab>
        <mat-tab>
            <div class="px-7">
                <ng-container *ngTemplateOutlet="actionsBar"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="feed"></ng-container>
        </mat-tab>
    </mat-tab-group>
}

<ng-template #actionsBar>
    <div class="flex items-center gap-x-4 pb-3 md:w-full md:justify-between">
        <div class="grow md:hidden">
            <app-search
                [testId]="'social-post-list-search-input'"
                [placeholder]="'common.search' | translate"
                (searchChange)="editTextFilter($event)"></app-search>
        </div>
        <div [matTooltip]="selectedPostsLength() === 0 ? ('social-posts-list.select_posts_to_duplicate' | translate) : ''">
            <button
                class="malou-btn-icon--secondary btn-xl"
                data-testid="social-post-duplicate-posts-btn"
                mat-icon-button
                [matMenuTriggerFor]="duplicateActions"
                [disabled]="selectedPostsLength() === 0">
                <mat-icon [svgIcon]="SvgIcon.DUPLICATE"></mat-icon>
            </button>
        </div>
        <div [matTooltip]="selectedPostsLength() === 0 ? ('social-posts-list.select_posts_to_delete' | translate) : ''">
            <button
                class="malou-btn-icon--secondary btn-xl"
                data-testid="social-post-trash-btn"
                mat-icon-button
                [matTooltip]="'common.delete' | translate"
                [disabled]="selectedPostsLength() === 0"
                (click)="deleteSelectedPosts()">
                <mat-icon [svgIcon]="SvgIcon.TRASH"></mat-icon>
            </button>
        </div>
        <button
            class="malou-btn-icon--secondary btn-xl"
            data-testid="social-post-filters-btn"
            mat-icon-button
            [matMenuTriggerFor]="filtersMenu">
            @if (activeFiltersCount() > 0) {
                <span class="malou-status--count malou-text-10--medium absolute right-1.5 top-1.5">
                    {{ activeFiltersCount() }}
                </span>
            }
            <mat-icon [svgIcon]="SvgIcon.SETTINGS"></mat-icon>
        </button>
        <button
            class="malou-btn-raised--primary btn-xl !h-12.5 !min-w-max md:!hidden"
            data-testid="social-post-open-create-post-modal-btn"
            mat-raised-button
            [matMenuTriggerFor]="createPostMenu">
            {{ 'social_posts.create_post' | translate }}
        </button>
        <button class="malou-btn-icon--primary btn-xl !hidden md:!flex" mat-icon-button [matMenuTriggerFor]="createPostMenu">
            <mat-icon [svgIcon]="SvgIcon.ADD"></mat-icon>
        </button>
        <button
            class="malou-btn-icon--secondary btn-xl"
            data-testid="social-post-sync-btn"
            mat-icon-button
            [matTooltip]="'common.synchronize' | translate"
            [disabled]="!isAnyPlatformConnected()"
            (click)="synchronize()">
            <mat-icon [svgIcon]="SvgIcon.SYNC"></mat-icon>
        </button>
    </div>

    @if (selectedIndex() === 0) {
        <div class="hidden py-3 md:!block">
            <button class="malou-btn-flat flex items-start gap-x-3" (click)="toggleMoreOptionsToggled()">
                <span> {{ 'common.advanced_options' | translate }} </span>
                <mat-icon
                    class="!h-4 !w-4 transition-all"
                    color="primary"
                    [svgIcon]="SvgIcon.CHEVRON_DOWN"
                    [ngClass]="{ 'rotate-180': moreOptionsToggled() }"></mat-icon>
            </button>
            @if (moreOptionsToggled()) {
                <div class="mt-3 grow">
                    <app-search [placeholder]="'common.search' | translate" (searchChange)="editTextFilter($event)"></app-search>
                </div>
            }
        </div>
    }
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #createPostMenu="matMenu">
    <button data-testid="social-post-menu-open-create-post-modal-btn" mat-menu-item (click)="openPost()">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.IMAGE"></mat-icon>
        {{ 'social_posts.publication' | translate }}
    </button>
    <button data-testid="social-post-menu-reel-open-create-post-modal-btn" mat-menu-item (click)="openPost(PostType.REEL)">
        <mat-icon class="!h-4 !w-4" color="primary" [svgIcon]="SvgIcon.VIDEO"></mat-icon>
        {{ 'social_posts.Reel' | translate }}
    </button>
</mat-menu>

<ng-template #postsList>
    <div class="flex h-full flex-col">
        <ng-container *ngTemplateOutlet="actionsBar"></ng-container>
        <div class="min-h-0 grow">
            <ng-container *ngTemplateOutlet="loading() ? loader : posts().length === 0 ? noPosts : list"></ng-container>
        </div>
    </div>
    <ng-template #list>
        <div
            class="hide-scrollbar h-full w-full overflow-y-auto pb-[100px] md:pb-[280px]"
            infinite-scroll
            [infiniteScrollContainer]="scrollContainer"
            (scrolled)="onScrollDown()"
            #scrollContainer>
            <div class="flex flex-col items-center gap-y-3" data-testid="social-post-list-container">
                @for (post of posts(); track post; let index = $index) {
                    <div class="w-full overflow-hidden">
                        <app-social-post
                            [isChecked]="isChecked | applyPure: post : selectedPostsLength()"
                            [post]="post"
                            (duplicate)="duplicateSinglePost($event)"
                            (postChecked)="postChecked($event, post)"
                            (editPostEvent)="onEditPost($event)"
                            (deletePostEvent)="onDeletePost($event)"
                            (refreshPostData)="onRefreshPostData($event)"></app-social-post>
                    </div>
                }

                @if (shouldDisplayNoMoreResultsText()) {
                    <div class="no-more-results malou-text-12--regular mt-4 flex items-end gap-x-3 italic text-malou-color-text-2">
                        <span>
                            {{ 'social_posts.no_more_results' | translate }}
                        </span>
                        <span> <img height="30" width="30" [src]="'Goggles' | illustrationPathResolver" /></span>
                    </div>
                }
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #feed>
    <div class="h-full rounded-[10px] border border-malou-color-background-dark bg-white md:rounded-none">
        <app-social-posts-feed></app-social-posts-feed>
    </div>
</ng-template>

<ng-template #loader>
    <div class="hide-scrollbar h-full overflow-y-auto pt-5">
        @for (item of [1, 2, 3, 4, 5]; track item) {
            <div>
                <div class="mb-4 h-[200px] w-full">
                    <app-post-skeleton></app-post-skeleton>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #noPosts>
    <div class="grid h-full min-h-[70vh] w-full place-items-center">
        <div class="flex w-full flex-col items-center gap-y-6">
            <img height="100" width="100" [src]="'Icecream' | illustrationPathResolver" />
            <div class="malou-text-14--bold text-center">
                {{
                    ((textFilter$ | async)?.length ?? 0) > 0 || activeFiltersCount() > 0
                        ? ('social_posts.no_posts_found' | translate)
                        : ('social_posts.no_posts' | translate)
                }}
                <div class="malou-text-10--regular mt-3 text-center">
                    {{
                        ((textFilter$ | async)?.length ?? 0) > 0 || activeFiltersCount() > 0
                            ? ('social_posts.no_posts_found_description' | translate)
                            : ('social_posts.no_posts_description' | translate)
                    }}
                </div>
            </div>
            @if ((textFilter$ | async)?.length === 0 && activeFiltersCount() === 0) {
                <button class="malou-btn-raised--primary" mat-raised-button [matMenuTriggerFor]="createPostMenu">
                    {{ 'social_posts.create_post' | translate }}
                </button>
            }
        </div>
    </div>
</ng-template>

@if (isPhoneScreen()) {
    <div class="fixed bottom-7 z-[4] w-full px-7.5">
        <mat-button-toggle-group class="malou-group-toggle-mobile-btn">
            <mat-button-toggle [checked]="selectedIndex() === 0" (click)="selectedIndex.set(0)">{{
                'social_posts.list_view' | translate
            }}</mat-button-toggle>
            <mat-button-toggle [checked]="selectedIndex() === 1" (click)="selectedIndex.set(1)">{{
                'social_posts.feed_view.title' | translate
            }}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
}

<mat-menu class="malou-mat-menu malou-box-shadow !min-w-fit !rounded-[10px] md:w-screen md:!max-w-none" #filtersMenu="matMenu">
    <div class="w-[440px] p-4 md:w-full" (click)="$event.stopPropagation()">
        <div class="flex w-full justify-between">
            <div class="malou-text-18--bold text-malou-color-text-1">
                {{ 'common.filters' | translate }}
            </div>

            <button class="malou-btn-flat" mat-button [disabled]="activeFiltersCount() === 0" (click)="resetFilters()">
                {{ 'common.reset' | translate }}
            </button>
        </div>

        <div class="mt-4 flex flex-col gap-y-5 md:h-d-70">
            <ng-container [ngTemplateOutlet]="periodFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="platformsFilterTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
        </div>
    </div>
</mat-menu>

<ng-template #periodFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.period' | translate }}
        </div>
        <app-grouped-date-filters
            [startDate]="(start$ | async)!"
            [endDate]="(end$ | async)!"
            [periodOptions]="PERIOD_OPTIONS"
            [defaultPeriod]="(period$ | async)!"
            [withLabel]="false"
            (chooseBoundaryDate)="editPostsFilters($event)">
        </app-grouped-date-filters>
    </div>
</ng-template>

<ng-template #statusFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.status' | translate }}
        </div>
        <app-is-published-filters [initialValue$]="postFilterStatus$" (changeOption)="editPostsFilters({ publicationStatus: $event })">
        </app-is-published-filters>
    </div>
</ng-template>

<ng-template #platformsFilterTemplate>
    <div>
        <div class="malou-text-10--medium mb-2 text-malou-color-text-2">
            {{ 'reviews.filters.platforms' | translate }}
        </div>
        <div class="flex flex-wrap gap-4">
            @for (platform of availablePlatforms(); track trackByKeyFn($index, platform)) {
                <div [matTooltip]="!platform.connected ? ('common.platform_disconnected' | translate) : ''">
                    <button
                        matRipple
                        [disabled]="!platform.connected"
                        (click)="toggleSelectedPlatforms(platform.key, availablePlatforms())">
                        <app-platform-logo
                            imgClasses="h-[29px] w-[29px]"
                            [dynamicClasses]="{ 'opacity-50': !platform.checked || !platform.connected }"
                            [logo]="platform.key"></app-platform-logo>
                    </button>
                </div>
            }
        </div>
    </div>
</ng-template>

<mat-menu class="malou-mat-menu malou-box-shadow !rounded-[10px]" #duplicateActions="matMenu">
    <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.HERE, socialPlatformKeys)">
        <span>{{ 'common.here' | translate }}</span>
    </button>
    @if (!restaurant().isBrandBusiness() && !(hasVideoInSelection | applyPure: selectedPostsLength())) {
        <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.HERE, [PlatformKey.GMB])">
            <div matTooltip="{{ 'posts.not_for_video' | translate }}">
                <span>{{ 'posts.duplicate_in_seo' | translate }}</span>
            </div>
        </button>
    }
    <button mat-menu-item (click)="duplicateSelectedPosts(DuplicationDestination.OUT, socialPlatformKeys)">
        <span>{{ 'common.to_other_venues' | translate }}</span>
    </button>
</mat-menu>
