import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Role } from '@malou-io/package-utils';

import { AuthService } from ':core/auth/auth.service';
import { NotificationCenterContext } from ':core/components/notification-center/context/notification-center.context';
import { ExperimentationService } from ':core/services/experimentation.service';
import { LOGOUT_ACTION } from ':core/storage/storage.metareducer';
import { NotificationsComponent } from ':modules/notification-center/notifications.component';
import { selectUserInfos } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { ReferYourFriendsComponent } from ':shared/components/refer-your-friends/refer-your-friends.component';
import { SidenavToggleButtonComponent } from ':shared/components/sidenav-toggle-button/sidenav-toggle-button.component';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { ImagePathResolverPipe } from ':shared/pipes/image-path-resolver.pipe';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        RouterLink,
        MatIconModule,
        MatMenuModule,
        TranslateModule,
        ReferYourFriendsComponent,
        SidenavToggleButtonComponent,
        AsyncPipe,
        IllustrationPathResolverPipe,
        ImagePathResolverPipe,
        NotificationsComponent,
        MatBadgeModule,
    ],
})
export class HeaderComponent {
    @Input() showLogo = true;
    @Input() showRouting = true;
    @Input() showProfile = true;
    @Input() showSidenavToggle = false;
    @Input() background = 'bg-malou-color-background-light';

    readonly SvgIcon = SvgIcon;
    readonly Role = Role;

    extensionJobs = 0;
    readonly userInfos$: Observable<User | null> = this._store.select(selectUserInfos);

    constructor(
        private readonly _auth: AuthService,
        private readonly _store: Store,
        private readonly _router: Router,
        public readonly experimentationService: ExperimentationService,
        public readonly notificationCenterContext: NotificationCenterContext
    ) {}

    logout(): void {
        this._auth.logout$().subscribe(() => {
            localStorage.removeItem('jwtToken');
            window.sessionStorage.clear();
            this._store.dispatch({ type: LOGOUT_ACTION });
            void this._router.navigate(['auth/login']);
        });
    }

    navigateTo(url: string): void {
        this._router.navigate([url]);
    }
}
