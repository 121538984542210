<div class="px-8.5 py-4" #topOfComponent>
    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    <div class="mb-4 flex gap-6 md:flex-col">
        <div class="min-w-0 flex-1">
            @if (experimentationService.isFeatureEnabled('fast-keyword-stats-v3')) {
                <app-statistics-seo-keywords-v3
                    (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.KEYWORDS, $event)"
                    (isLoadingEvent)="isKeywordsLoading.set($event)">
                </app-statistics-seo-keywords-v3>
            } @else {
                <app-statistics-seo-keywords
                    (tableSortOptionsChange)="onTableSortOptionsChange(InsightsChart.KEYWORDS, $event)"
                    (isLoadingEvent)="isKeywordsLoading.set($event)">
                </app-statistics-seo-keywords>
            }
        </div>
    </div>

    <div class="flex items-end gap-6 xl:flex-col xl:items-stretch">
        <div class="min-w-0 flex-1">
            <app-statistics-seo-gmb-discoveries
                (viewByChange)="onViewByChange(InsightsChart.APPARITIONS, $event)"
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.APPARITIONS, $event)"
                (isLoadingEvent)="isImpressionsLoading.set($event)">
            </app-statistics-seo-gmb-discoveries>
        </div>
        <div class="min-w-0 flex-1">
            <app-statistics-seo-gmb-actions
                (viewByChange)="onViewByChange(InsightsChart.ACTIONS, $event)"
                (hiddenDatasetIndexesChange)="onHiddenDatasetIndexesChange(InsightsChart.ACTIONS, $event)"
                (isLoadingEvent)="isActionsLoading.set($event)">
            </app-statistics-seo-gmb-actions>
        </div>
    </div>
</div>

<ng-template #filtersTemplate>
    <div class="mb-6 flex items-center justify-between sm:flex-col sm:items-center">
        <app-statistics-filters></app-statistics-filters>
        <button
            class="malou-btn-raised--primary !h-12 sm:mt-2"
            mat-raised-button
            [disabled]="isLoading()"
            (click)="openStatisticsDownload()">
            {{ 'statistics.common.download_statistics' | translate }}
        </button>
    </div>
</ng-template>
