@if ((isFooterVisible$ | async) && !isAggregatedView()) {
    <div class="information-update-footer__container malou-box-shadow flex items-center justify-end px-6" id="footer-update">
        <!-- Suggest Update infos -->
        @if (!loadingStatus) {
            <ng-container>
                <div class="flex items-center">
                    <mat-icon class="malou-spin mr-4 !h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.SPINNER"></mat-icon>
                    <span class="malou-text-12--bold w-fit">
                        {{ 'informations_update_footer.suggest_update' | translate }}
                        <button class="malou-btn-flat !p-0" mat-button (click)="startUpdate()">
                            {{ 'informations_update_footer.start_update' | translate }}
                        </button>
                    </span>
                </div>
            </ng-container>
        } @else {
            <!-- Platforms updating view -->
            <ng-container>
                <ng-container *ngTemplateOutlet="platforms"></ng-container>
            </ng-container>
        }

        <!-- Close icon button -->
        <button class="malou-btn-icon" color="primary" mat-icon-button (click)="close()">
            <mat-icon [svgIcon]="SvgIcon.CROSS"></mat-icon>
        </button>
    </div>
}

<ng-template #platforms>
    <div class="flex items-center justify-between">
        <div class="mr-2 flex items-center">
            @switch (loadingStatus) {
                @case (InformationsUpdateStatus.LOADED) {
                    <div class="malou-bg-state-success mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                        <mat-icon
                            class="!h-[9px] !w-[9px] text-white"
                            matTooltip="{{ 'information.update_modal.updated' | translate }}"
                            [svgIcon]="SvgIcon.CHECK">
                        </mat-icon>
                    </div>
                    <span class="malou-text-12--bold w-fit">
                        {{ 'information.update_modal.updated' | translate }}
                    </span>
                }
                @case (InformationsUpdateStatus.ERROR) {
                    <div class="malou-bg-state-error mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                        <mat-icon
                            class="!h-[9px] !w-[9px] text-white"
                            matTooltip="{{ 'information.update_modal.failed' | translate }}"
                            [svgIcon]="SvgIcon.CROSS">
                        </mat-icon>
                    </div>
                    <span class="malou-text-12--bold w-fit md:hidden">
                        {{ 'information.update_modal.failed' | translate }}
                    </span>
                    <span class="malou-text-12--bold hidden w-fit md:block">
                        {{ 'information.update_modal.failed_small' | translate }}
                    </span>
                }
                @default {
                    <mat-icon class="malou-spin mr-2 !h-[16px] !w-[16px]" color="primary" [svgIcon]="SvgIcon.SPINNER"></mat-icon>
                    <span class="malou-text-12--bold w-fit">
                        {{ 'informations_update_footer.updating' | translate }}
                    </span>
                }
            }
        </div>
        <div class="flex justify-evenly">
            @for (platform of platformsWithStatus; track $index) {
                <div class="relative mx-1">
                    <div class="icon-img">
                        <app-platform-logo imgClasses="h-8 w-8" [logo]="platform.key"></app-platform-logo>
                        <div class="svg-container">
                            <svg class="circle">
                                <g>
                                    <ellipse
                                        class="foreground"
                                        ry="17"
                                        rx="17"
                                        stroke-width="35"
                                        [ngClass]="{
                                            loading: loadingStatus === InformationsUpdateStatus.LOADING,
                                            loaded:
                                                [InformationsUpdateStatus.LOADED, InformationsUpdateStatus.ERROR] | includes: loadingStatus,
                                        }" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div
                        class="absolute bottom-0 right-0"
                        [ngStyle]="{ visibility: (platform | applySelfPure: 'isConnected') ? 'visible' : 'invisible' }">
                        @if ((platform | applySelfPure: 'isConnected') && loadingStatus === InformationsUpdateStatus.LOADED) {
                            <div>
                                @if (!(isOauthPlatform | applyPure: platform.key)) {
                                    <div class="malou-bg-state-warn grid h-[15px] w-[15px] place-items-center rounded-full">
                                        <mat-icon
                                            class="!h-[9px] !w-[9px] text-white"
                                            matTooltip="{{ 'information.update_modal.update_time' | translate }}"
                                            [svgIcon]="SvgIcon.ALARM">
                                        </mat-icon>
                                    </div>
                                } @else {
                                    <ng-container [ngTemplateOutlet]="oauthPlatform"></ng-container>
                                }
                                <ng-template #oauthPlatform>
                                    @switch (platformConnectedToApi[platform.key]?.status) {
                                        @case ('updated') {
                                            <div class="malou-bg-state-success grid h-[15px] w-[15px] place-items-center rounded-full">
                                                <mat-icon
                                                    class="!h-[9px] !w-[9px] text-white"
                                                    matTooltip="{{ 'information.update_modal.updated' | translate }}"
                                                    [svgIcon]="SvgIcon.CHECK">
                                                </mat-icon>
                                            </div>
                                        }
                                        @case ('warning') {
                                            <div class="malou-bg-state-warn grid h-[15px] w-[15px] place-items-center rounded-full">
                                                <mat-icon
                                                    class="!h-[9px] !w-[9px] text-white"
                                                    matTooltip="{{ platformConnectedToApi[platform.key].info }}"
                                                    [svgIcon]="SvgIcon.WARNING">
                                                </mat-icon>
                                            </div>
                                        }
                                        @case ('error') {
                                            <div class="malou-bg-state-error grid h-[15px] w-[15px] place-items-center rounded-full">
                                                <mat-icon
                                                    class="!h-[9px] !w-[9px] text-white"
                                                    matTooltip="{{ platformConnectedToApi[platform.key].info }}"
                                                    [svgIcon]="SvgIcon.CROSS">
                                                </mat-icon>
                                            </div>
                                        }
                                    }
                                </ng-template>
                            </div>
                        }
                    </div>
                </div>
            }
            @if (isYextEnabled() && availablePublishers().length) {
                <div class="relative flex items-center justify-center">
                    <div class="malou-text-14--bold mx-4 text-malou-color-primary" [matTooltip]="readablePublishersNames()">
                        +{{ availablePublishers().length }}
                    </div>
                    @if (yextUpdateStatus) {
                        <div class="absolute bottom-0 right-[-4px]">
                            @switch (yextUpdateStatus) {
                                @case (InformationsUpdateStatus.LOADED) {
                                    <div class="malou-bg-state-success mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                                        <mat-icon
                                            class="!h-[9px] !w-[9px] text-white"
                                            matTooltip="{{ 'information.update_modal.updated' | translate }}"
                                            [svgIcon]="SvgIcon.CHECK">
                                        </mat-icon>
                                    </div>
                                }
                                @case (InformationsUpdateStatus.ERROR) {
                                    <div class="malou-bg-state-error mr-2 grid h-[15px] w-[15px] place-items-center rounded-full">
                                        <mat-icon
                                            class="!h-[9px] !w-[9px] text-white"
                                            matTooltip="{{ 'information.update_modal.failed' | translate }}"
                                            [svgIcon]="SvgIcon.CROSS">
                                        </mat-icon>
                                    </div>
                                }
                            }
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</ng-template>
