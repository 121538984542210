import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, of, skip, switchMap, throwError } from 'rxjs';

import { isApiResultSuccess, LaunchState, PlatformKey } from '@malou-io/package-utils';

import { MalouSpinnerComponent } from ':core/components/spinner/spinner/malou-spinner.component';
import { GoogleBusinessCommunicationsService } from ':core/services/google-communications.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { SlideToggleComponent } from ':shared/components-v3/slide-toggle/slide-toggle.component';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { TextAreaComponent } from ':shared/components/text-area/text-area.component';
import { GoogleCommunicationsAgent } from ':shared/models';

interface MessagingConfig {
    key: PlatformKey;
    agent: GoogleCommunicationsAgent | null;
}

@Component({
    selector: 'app-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.scss'],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule,
        SlideToggleComponent,
        MalouSpinnerComponent,
        TextAreaComponent,
        FormsModule,
        ReactiveFormsModule,
        PlatformLogoComponent,
        TranslateModule,
    ],
})
export class SettingsModalComponent implements OnInit {
    readonly gmbMessagingEnabled$ = new BehaviorSubject<boolean>(false);
    readonly gmbMessagingEnabled = toSignal(this.gmbMessagingEnabled$, { initialValue: false });
    readonly gmbMessagingConfig: WritableSignal<MessagingConfig> = signal({
        key: PlatformKey.GMB,
        agent: null,
    });

    launchFailed = false;
    launchOrUnlaunchPending = false;
    welcomeMessageForm = new UntypedFormControl('', [Validators.required]);
    loading = false;

    showInfoBannerAfterUpdate = false;

    constructor(
        private readonly _dialogRef: MatDialogRef<SettingsModalComponent>,
        private readonly _translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: {
            agent: GoogleCommunicationsAgent;
        },
        private readonly _googleCommunicationsService: GoogleBusinessCommunicationsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.gmbMessagingConfig.set({
            key: PlatformKey.GMB,
            agent: new GoogleCommunicationsAgent({
                businessMessagesAgent: this.data.agent?.businessMessagesAgent,
                launchState: this.data.agent?.launchState,
                name: this.data.agent?.name,
            }),
        });
        if (this.gmbMessagingConfig().agent?.launchState === LaunchState.LAUNCH_STATE_LAUNCHED) {
            this.gmbMessagingEnabled$.next(true);
        } else {
            this.gmbMessagingEnabled$.next(false);
        }

        this.gmbMessagingEnabled$.pipe(skip(1), distinctUntilChanged(), debounceTime(1000)).subscribe((enabled) => {
            if (enabled) {
                this._toggleEnableMessaging();
            } else {
                this._toggleDisableMessaging();
            }
        });

        this.launchFailed = [
            LaunchState.LAUNCH_STATE_REJECTED,
            LaunchState.LAUNCH_STATE_SUSPENDED,
            LaunchState.LAUNCH_STATE_INVALID_IN_GMB,
        ].includes(this.data.agent?.launchState);

        this.launchOrUnlaunchPending = [LaunchState.LAUNCH_STATE_PENDING, LaunchState.LAUNCH_STATE_PENDING_UNLAUNCH].includes(
            this.data.agent?.launchState
        );

        this.welcomeMessageForm.setValue(
            this.gmbMessagingConfig().agent?.businessMessagesAgent?.conversationalSettings[this._translate.currentLang]?.welcomeMessage.text
        );
        if (!this.gmbMessagingEnabled()) {
            this.welcomeMessageForm.disable();
        }
    }

    close(): void {
        this._dialogRef.close(this.gmbMessagingConfig().agent);
    }

    toggleEnableMessagingForPlatform(): void {
        const enabled = !this.gmbMessagingEnabled();
        this.gmbMessagingEnabled$.next(enabled);
        if (enabled) {
            this.welcomeMessageForm.enable();
        } else {
            this.welcomeMessageForm.disable();
        }
    }

    hasErrors(): boolean {
        return this.gmbMessagingEnabled() && (!!this.welcomeMessageForm.errors || this.loading);
    }

    validateMessage(): void {
        if (!this.gmbMessagingEnabled()) {
            this.close();
            return;
        }
        this.loading = true;

        const welcomeMessage: string = this.welcomeMessageForm.value;
        const gmbAgent = this.gmbMessagingConfig().agent;

        if (!gmbAgent) {
            return;
        }

        gmbAgent.setWelcomeMessage(welcomeMessage, this._translate.currentLang);

        const updateAgentPayload = gmbAgent.toDto();

        this._googleCommunicationsService.updateAgent(this._restaurantsService.currentRestaurant._id, updateAgentPayload).subscribe({
            error: (err) => {
                console.error(err);
                this._toastService.openErrorToast(err?.error?.message || err?.message || String(err));
            },
            complete: () => {
                this.loading = false;
                this.close();
            },
        });
    }

    private _toggleEnableMessaging(): void {
        const restaurantId = this._restaurantsService.currentRestaurant._id;
        this._googleCommunicationsService
            .verifyAgent(restaurantId)
            .pipe(
                switchMap(() => this._googleCommunicationsService.verifyLocation(restaurantId)),
                switchMap(() => this._googleCommunicationsService.launchAgent(restaurantId)),
                switchMap(() => this._googleCommunicationsService.launchLocation(restaurantId)),
                catchError((err) => {
                    // if already verified, continue
                    if (err?.error?.message?.match(/already verified/)) {
                        return of(null);
                    }
                    return throwError(err);
                })
            )
            .subscribe({
                next: (launchLocationResponse) => {
                    if (!launchLocationResponse || !isApiResultSuccess(launchLocationResponse)) {
                        return;
                    }
                    const launchLocationData = launchLocationResponse.data;
                    this.gmbMessagingConfig.update((value) => {
                        const config = {
                            ...value,
                            agent: value.agent!.copyWith({
                                launchState: launchLocationData.launchState,
                            }),
                        };
                        return config;
                    });
                    this.showInfoBannerAfterUpdate = true;
                    this._toastService.openSuccessToast(this._translate.instant('messages.success_snackbar'));
                },
                error: (err) => {
                    this.loading = false;
                    this.welcomeMessageForm.disable();
                    const errText = err?.error?.message || err?.message || String(err);
                    if (errText.match(/Location is already launched/) || errText.match(/locations with same/)) {
                        this.showInfoBannerAfterUpdate = true;
                        this.welcomeMessageForm.enable();
                        this._toastService.openSuccessToast(this._translate.instant('messages.success_snackbar'));
                        if (this.gmbMessagingConfig().agent) {
                            this.gmbMessagingConfig.update((value) => ({
                                ...value,
                                agent: value.agent!.copyWith({
                                    launchState: LaunchState.LAUNCH_STATE_LAUNCHED,
                                }),
                            }));
                        } else {
                            this.gmbMessagingConfig.update((value) => ({
                                ...value,
                                agent: new GoogleCommunicationsAgent({
                                    businessMessagesAgent: { conversationalSettings: {} },
                                    name: '',
                                    launchState: LaunchState.LAUNCH_STATE_LAUNCHED,
                                }),
                            }));
                        }
                    } else if (errText.match(/Forbidden/)) {
                        this._toastService.openErrorToast(this._translate.instant('messages.settings.forbidden_error'));
                    } else {
                        this._toastService.openErrorToast(errText);
                    }
                },
                complete: () => (this.loading = false),
            });
    }

    private _toggleDisableMessaging(): void {
        const agentName = this.gmbMessagingConfig().agent?.name;
        if (!agentName) {
            const error = this._translate.instant('messages.settings.modal.google_agent_name_not_found');
            console.error(error);
            this.welcomeMessageForm.enable();
            this.loading = false;
            this._toastService.openErrorToast(error);
            return;
        }
        this._googleCommunicationsService.unlaunchAgent(this._restaurantsService.currentRestaurant._id, agentName).subscribe({
            next: (agent) => {
                this.gmbMessagingConfig.update((value) => ({
                    ...value,
                    agent: new GoogleCommunicationsAgent({
                        ...agent.data,
                        launchState: LaunchState.LAUNCH_STATE_UNLAUNCHED,
                    }),
                }));
                this.showInfoBannerAfterUpdate = true;
                this.loading = false;
                this._toastService.openSuccessToast(this._translate.instant('messages.settings.toasts.disabled'));
            },
            error: (err) => {
                console.error(err);
                this.welcomeMessageForm.enable();
                this.loading = false;
                this._toastService.openErrorToast(err?.error?.message || err?.message || String(err));
            },
        });
    }
}
