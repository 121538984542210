import { AsyncPipe, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { DragToSelectModule, SelectContainerComponent } from 'ngx-drag-to-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    skip,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';

import { PlatformDefinition, PlatformDefinitions, PlatformKey, PostPublicationStatus, PostSource } from '@malou-io/package-utils';

import { periodOptions } from ':core/constants';
import { PostCategory } from ':core/constants/post-category';
import { DialogService } from ':core/services/dialog.service';
import { DuplicatePostModalService } from ':core/services/duplicate-post-modal.service';
import { KeywordsService } from ':core/services/keywords.service';
import { getPostCategoryFromKeys, PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ScreenSizeService } from ':core/services/screen-size.service';
import { ToastService } from ':core/services/toast.service';
import * as fromPlatformsStore from ':modules/platforms/store/platforms.reducer';
import { PostDateStatus } from ':modules/social-posts/new-social-post-modal/context/types';
import { selectUserState } from ':modules/user/store/user.selectors';
import { User } from ':modules/user/user';
import { ScrollToTopComponent } from ':shared/components-v3/scroll-to-top/scroll-to-top.component';
import { GroupedDateFiltersComponent } from ':shared/components/grouped-date-filters/grouped-date-filters.component';
import { IsPublishedFiltersComponent } from ':shared/components/is-published-filters/is-published-filters.component';
import { DialogVariant } from ':shared/components/malou-dialog/malou-dialog.component';
import {
    PersonalizePostDuplicationComponent,
    PersonalizePostDuplicationData,
} from ':shared/components/personalize-post-duplication/personalize-post-duplication.component';
import {
    RestaurantsSelectionComponent,
    RestaurantsSelectionData,
} from ':shared/components/restaurants-selection/restaurants-selection.component';
import { SearchComponent } from ':shared/components/search/search.component';
import { SinglePostComponent, SinglePostComponent as SinglePostComponent_1 } from ':shared/components/single-post/single-post.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { StepperModalComponent } from ':shared/components/stepper-modal/stepper-modal.component';
import { AutoUnsubscribeOnDestroy } from ':shared/decorators/auto-unsubscribe-on-destroy.decorator';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { addMinutes, getTimeStringFromDate, isControlValueInTimeFormat } from ':shared/helpers';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { KillSubscriptions } from ':shared/interfaces';
import { INullableFormGroup } from ':shared/interfaces/form-control-record.interface';
import { Step } from ':shared/interfaces/step.interface';
import {
    ActionsAfterEditPostClosed,
    AgendaJob,
    ApiResult,
    Keyword,
    MalouPeriod,
    Pagination,
    Platform,
    Post,
    PostsFilters,
    PostWithJob,
    Restaurant,
    SeoPost,
} from ':shared/models';
import { SvgIcon } from ':shared/modules/svg-icon.enum';
import { ApplyPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';
import { CustomDialogService, DialogScreenSize } from ':shared/services/custom-dialog.service';

import { NewPostModalComponent } from '../new-post-modal/new-post-modal.component';
import * as PostsActions from '../posts.actions';
import { defaultPublicationStatus } from '../posts.reducer';
import { selectCurrentlyPublishingPosts } from '../posts.selectors';

export interface PostDuplication {
    isSinglePost: boolean;
    to: string;
    postsToDuplicate: PostWithJob[];
    platformsKeys: PlatformKey[];
    inBackground: boolean;
}

interface PlatformsStore {
    platformsData: {
        [key: string]: Platform[];
    };
    platformsKeys: {
        [key: string]: PlatformDefinition;
    };
}

interface UserRestaurant {
    restaurantId: string;
    userId: string;
    gmbCredentialId?: string;
    apiEndpoint: string;
}

interface AppState {
    posts: {
        filters: PostsFilters;
    };
    platforms: PlatformsStore;
    user: {
        infos: {
            restaurants: UserRestaurant[];
        };
    };
}

export function isPost(res: ApiResult | Post): res is Post {
    return (res as Post)._id !== undefined;
}

const DEFAULT_PAGINATION: Pagination = { pageSize: 20, pageNumber: 0, total: 0, skip: 0 };
const DEFAULT_PERIOD = MalouPeriod.LAST_AND_COMING_SIX_MONTH;

@AutoUnsubscribeOnDestroy()
@Component({
    selector: 'app-posts-list',
    templateUrl: './posts-list.component.html',
    styleUrls: ['./posts-list.component.scss'],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NgStyle,
        GroupedDateFiltersComponent,
        IsPublishedFiltersComponent,
        ScrollToTopComponent,
        SearchComponent,
        SinglePostComponent_1,
        SkeletonComponent,
        DragToSelectModule,
        InfiniteScrollModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TranslateModule,
        ApplyPurePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
    ],
})
export class PostsListComponent implements OnInit, KillSubscriptions {
    @ViewChildren(SinglePostComponent) singlePostComponents!: QueryList<SinglePostComponent>;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(SelectContainerComponent) dtsContainer: SelectContainerComponent;

    readonly SvgIcon = SvgIcon;
    readonly trackByIdFn = TrackByFunctionFactory.get('_id');

    restaurant: Restaurant;
    posts: PostWithJob[] = [];
    pagination: Pagination = { ...DEFAULT_PAGINATION };
    jobs: AgendaJob[];
    childrenSubscription: Subscription;
    filters$: Observable<PostsFilters> = this._store
        .select((state) => state.posts.filters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));
    restaurant$: Observable<Restaurant | null> = this._restaurantsService.restaurantSelected$;
    publishedOptions = [
        {
            value: 'all',
            text: this._translate.instant('posts.all'),
        },
        {
            value: 'not_published',
            text: this._translate.instant('posts.not_published'),
        },
        {
            value: 'published',
            text: this._translate.instant('posts.published'),
        },
    ];
    periodOptionKeys = periodOptions.map((period) => period.key);
    period$: Observable<MalouPeriod | undefined> = this._store.select((state) => state.posts.filters.period);
    start$: Observable<Date | undefined> = this._store.select((state) => state.posts.filters.startDate ?? undefined);
    end$: Observable<Date | undefined> = this._store.select((state) => state.posts.filters.endDate ?? undefined);
    postFilterStatus$: Observable<string[]> = this._store.select((state) => state.posts.filters.publicationStatus ?? []);
    postFilterStatus: string[];
    textFilterInput = '';
    textFilter$ = new BehaviorSubject('');
    isGmbConnected$ = of(true);
    hasSynced = false;
    reload$ = new BehaviorSubject<boolean>(true);
    selectedPosts: PostWithJob[] = [];
    loading = true;
    loadingKeywords = true;
    hideDraftPost = false;
    showFilters = false;
    innerWidth: number;
    totalPosts = 0;
    restaurantKeywords: Keyword[];
    DuplicationDestination = DuplicationDestination;
    filterCount = 0;
    PlatformKey = PlatformKey;
    MalouPeriod = MalouPeriod;
    killSubscriptions$ = new Subject<void>();
    currentUser: User;

    readonly platformsKeysWithSeoPost = PlatformDefinitions.getSeoPlatformKeysWithDuplicablePosts();
    readonly platformsKeysWithSocialPost = PlatformDefinitions.getSocialPlatformKeysWithDuplicablePosts();

    constructor(
        private readonly _postsService: PostsService,
        private readonly _restaurantsService: RestaurantsService,
        private readonly _store: Store<AppState>,
        private readonly _translate: TranslateService,
        private readonly _router: Router,
        private readonly _activatedRoute: ActivatedRoute,
        private readonly _duplicatePostModalService: DuplicatePostModalService,
        private readonly _toastService: ToastService,
        private readonly _httpErrorPipe: HttpErrorPipe,
        private readonly _malouDialogService: DialogService,
        private readonly _keywordsService: KeywordsService,
        private readonly _customDialogService: CustomDialogService,
        private readonly _formBuilder: FormBuilder,
        public readonly screenSizeService: ScreenSizeService
    ) {
        this.postFilterStatus$.pipe(filter(Boolean), takeUntilDestroyed()).subscribe((val) => (this.postFilterStatus = val));
    }

    get duplicationTooltip(): string {
        return !this.selectedPosts.length
            ? this._translate.instant('posts.seo.list.select_items_before_duplicate')
            : this._translate.instant('common.duplicate');
    }

    ngOnInit(): void {
        this.restaurant$
            .pipe(
                filter(Boolean),
                tap(() => (this.loadingKeywords = true)),
                switchMap((restaurant) => {
                    this.restaurant = restaurant;
                    return combineLatest([
                        this._keywordsService
                            .getKeywordsByRestaurantId(restaurant._id)
                            .pipe(map((res) => res.data.map((k) => new Keyword(k)))),
                        this._restaurantsService.show(this.restaurant._id).pipe(map((res) => res.data)),
                        this._store.select(selectUserState),
                    ]);
                }),
                switchMap(([keywords, restaurantWithManagers, user]) => {
                    if (!user?.infos) {
                        return throwError(() => new Error('User not found'));
                    }
                    this.currentUser = user.infos;
                    this.restaurantKeywords = keywords;
                    this.loadingKeywords = false;
                    const restaurantManagers = restaurantWithManagers?.managers.map((ru) => new User(ru.user));
                    return this._activatedRoute.queryParams.pipe(
                        filter((params) => !!params.postId),
                        map((params) => params.postId),
                        switchMap((postId) =>
                            this._postsService.getPost(postId).pipe(
                                catchError((err) => {
                                    if (err.status === 404) {
                                        this._toastService.openErrorToast(this._translate.instant('posts.posts_list.post_not_found'));
                                        this._router.navigate(['./'], { relativeTo: this._activatedRoute, queryParams: null });
                                    }
                                    return of(null);
                                }),
                                filter(Boolean),
                                map((res) => new Post(res.data))
                            )
                        ),
                        switchMap((post) => {
                            if (post) {
                                this.selectedPosts = [];
                                post.filterOutFeedbackMessageDependingOnRole(this.currentUser.role);
                                return this._customDialogService
                                    .open<
                                        NewPostModalComponent,
                                        {
                                            post: Post;
                                            restaurant: Restaurant;
                                            restaurantKeywords: Keyword[];
                                            restaurantManagers: User[];
                                        },
                                        { next: ActionsAfterEditPostClosed }
                                    >(
                                        NewPostModalComponent,
                                        {
                                            width: '100%',
                                            panelClass: 'malou-dialog-panel--full',
                                            height: undefined,
                                            data: {
                                                post,
                                                restaurant: this.restaurant,
                                                restaurantKeywords: this.restaurantKeywords,
                                                restaurantManagers,
                                            },
                                            disableClose: true,
                                        },
                                        { animateScreenSize: DialogScreenSize.ALL }
                                    )
                                    .afterClosed()
                                    .pipe(
                                        filter(Boolean),
                                        switchMap(({ next: { reload, duplicatedPostId, shouldDeleteAfterClose } }) => {
                                            this._router.navigate(['.'], { relativeTo: this._activatedRoute });
                                            if (shouldDeleteAfterClose) {
                                                return this._postsService.deletePost(post._id).pipe(
                                                    tap(() => {
                                                        this.resetFilters();
                                                        this.resetPosts();
                                                        this.reload();
                                                    })
                                                );
                                            }
                                            if (duplicatedPostId) {
                                                this._duplicatePostModalService.openDuplicateModal(duplicatedPostId, PostCategory.SOCIAL);
                                                return of(null);
                                            }
                                            if (reload) {
                                                this.resetFilters();
                                                this.resetPosts();
                                                this.reload();
                                                return of(null);
                                            }
                                            return this._postsService.getPost(post._id, true).pipe(map((res) => res.data));
                                        })
                                    );
                            }
                            return of(null);
                        }),
                        takeUntil(this.killSubscriptions$)
                    );
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (post) => {
                    if (post && isPost(post)) {
                        const index = this.posts.findIndex((p) => p._id === post._id);
                        const freshPost = new PostWithJob(post);
                        if (index === -1) {
                            this.posts.unshift(freshPost);
                            this.totalPosts += 1;
                        } else {
                            this.posts[index] = freshPost;
                        }
                    }
                },
            });

        this.postFilterStatus$
            .pipe(filter(Boolean), takeUntil(this.killSubscriptions$))
            .subscribe((filters) => (this.postFilterStatus = filters));

        this.isGmbConnected$ = this._store.select(fromPlatformsStore.selectCurrentPlatform({ platformKey: PlatformKey.GMB })).pipe(
            take(1),
            map((platform) => (platform?.credentials?.length ?? 0) > 0)
        );

        this.restaurant$.pipe(takeUntil(this.killSubscriptions$)).subscribe({
            next: () => {
                this.posts = [];
                this.resetPagination();
                this.resetFilters();
                this.reload();
            },
            error: (err) => console.warn('err :>> ', err),
        });

        this.reload$
            .pipe(
                switchMap(() => this.filters$),
                switchMap((postsFilters) => {
                    this.loading = true;
                    this.hideDraftPost = !postsFilters.publicationStatus?.some(
                        (publicationStatus) => publicationStatus === PostPublicationStatus.DRAFT
                    );
                    this.filterCount = this._computeFilterCount(postsFilters);
                    return this._postsService
                        .getRestaurantPostsPaginated(this.restaurant._id, this.pagination, {
                            ...postsFilters,
                            text: this.textFilterInput,
                            source: PostSource.SEO,
                        })
                        .pipe(map((res) => res.data));
                }),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: ({ posts, pagination, jobs }) => {
                    this.posts = this.posts.concat(
                        posts.map((p) => {
                            p.initWorkingPic('small');
                            return new PostWithJob({
                                ...p,
                                job: (jobs || []).filter(
                                    (j) =>
                                        j.data?.postId === p._id &&
                                        (p.published === PostPublicationStatus.PUBLISHED ? j.name === 'publish post' : true)
                                )[0],
                            });
                        })
                    );
                    this.loading = false;
                    this.totalPosts = pagination.total;
                    this.incrementPagination();
                    if (this.posts.length <= 0 && !this.hasSynced) {
                        this.hasSynced = true;
                        this.synchronize();
                    }
                },
                error: (err) => {
                    console.warn(err);
                    this.loading = false;
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                },
            });

        this.textFilter$.pipe(skip(1), distinctUntilChanged(), debounceTime(500), takeUntil(this.killSubscriptions$)).subscribe((text) => {
            this.setTextFilter(text);
        });

        this._store
            .select(selectCurrentlyPublishingPosts)
            .pipe(
                distinctUntilChanged((a, b) => isEqual(a, b)),
                takeUntil(this.killSubscriptions$)
            )
            .subscribe({
                next: (newPosts) => {
                    newPosts.forEach((newPost) => {
                        const foundIndex = this.posts.findIndex((p) => p.bindingId === newPost.bindingId);
                        if (foundIndex >= 0) {
                            this.posts[foundIndex] = new SeoPost(newPost);
                        }
                    });
                },
            });
    }

    createPost(): void {
        this._createEmptyDraft$().subscribe({
            next: ({ data: post }) => {
                this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { postId: post._id } });
            },
        });
    }

    openPost(postId: string): void {
        if (postId) {
            this._router.navigate(['.'], { relativeTo: this._activatedRoute, queryParams: { postId } });
            return;
        }
    }

    checkPost(postId: string): void {
        const post = this.posts.find((p) => p._id === postId);
        if (!post) {
            return;
        }
        this.selectPosts([post]);
    }

    editPostsFilters($event: { [key: string]: any }): void {
        this._store.dispatch({ type: PostsActions.editPostsFilters.type, filters: $event });
        this.resetPagination();
        this.resetPosts();
        this.reload();
    }

    resetFilters(): void {
        this._store.dispatch({ type: PostsActions.resetPostsFiltersDates.type });
        this._store.dispatch({ type: PostsActions.resetPostsFiltersStatus.type });
        this.resetPosts();
        this.resetPagination();
    }

    reload(): void {
        this.reload$.next(true);
    }

    resetPagination(): void {
        this.pagination = { ...DEFAULT_PAGINATION };
    }

    resetPosts(): void {
        this.posts = [];
    }

    incrementPagination(): void {
        this.pagination.pageNumber = this.pagination.pageNumber + 1;
        this.pagination.skip = (this.pagination.skip || 0) + this.pagination.pageSize;
    }

    editTextFilter(text: string): void {
        this.textFilter$.next(text);
    }

    setTextFilter(text: string): void {
        this.textFilterInput = text;
        this.resetPagination();
        this.resetPosts();
        this.reload();
    }

    onScrollDown(): void {
        this.reload();
    }

    isForLater(post: PostWithJob): boolean {
        return !!post.job && !post.job?.lastRunAt;
    }

    openSocialLink(post: Post): void {
        if (post?.socialLink) {
            window.open(post.socialLink, '_blank');
        }
    }

    isChecked = (post: Post): boolean => this.selectedPosts.some((p) => p._id === post._id);

    duplicateSelectedPosts(to: string, platformsKeys: PlatformKey[]): void {
        return this._prepareDuplicatePosts({
            isSinglePost: false,
            to,
            postsToDuplicate: this.selectedPosts,
            platformsKeys,
            inBackground: false,
        });
    }

    duplicateSinglePost({
        to,
        post: fromPost,
        platformsKeys,
        inBackground,
    }: {
        to: string;
        post: PostWithJob;
        platformsKeys: PlatformKey[];
        inBackground: boolean;
    }): void {
        this._prepareDuplicatePosts({
            isSinglePost: true,
            to,
            postsToDuplicate: [fromPost],
            platformsKeys,
            inBackground,
        });
    }

    synchronize(): void {
        this.posts = [];
        this.loading = true;
        this._postsService
            .synchronizePosts(this.restaurant._id, PlatformDefinitions.getSeoPlatformKeysWithPost())
            .pipe(
                switchMap(() =>
                    this._restaurantsService.update(this.restaurant._id, {
                        currentState: { ...this.restaurant.currentState, posts: { lastSync: new Date() } },
                    })
                )
            )
            .subscribe({
                next: () => {
                    this._restaurantsService.reloadSelectedRestaurant();
                },
                error: (err) => {
                    console.warn('err :>> ', err);
                    this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                    this._restaurantsService.reloadSelectedRestaurant();
                },
            });
    }

    clarifyError(error: any): string | null {
        if (!error) {
            return null;
        }
        if (error.error?.config) {
            return this._translate.instant('posts.gmb_not_connected');
        }
        if (error.error?.message?.includes('Missing credentialId')) {
            return this._translate.instant('posts.management_mode');
        }
        if (typeof error === 'string') {
            if (error.match(/Forbidden/)) {
                return this._translate.instant('posts.operation_failed');
            }
            return error;
        }
        return String(error?.error?.message ?? error?.message ?? error);
    }

    emptySelectedPosts(): void {
        this.selectedPosts = [];
    }

    getCombinedActionsBtnText(): string {
        return !this.selectedPosts.length
            ? this._translate.instant('posts.select')
            : this._translate.instant('posts.combined_actions', { number: this.selectedPosts.length });
    }

    getCombinedActionsBtnTextSmall(): string {
        return `(${this.selectedPosts.length})`;
    }

    deletePost(id: string): void {
        this.selectedPosts = this.posts.filter((p) => p._id === id);
        this.deleteSelectedPosts();
    }

    deleteSelectedPosts(): void {
        this._malouDialogService.open({
            variant: DialogVariant.INFO,
            maxWidth: '100vw',
            title: this._translate.instant('posts.delete_posts'),
            message: this._translate.instant('posts.want_delete_posts'),
            secondaryButton: {
                label: this._translate.instant('common.cancel'),
            },
            primaryButton: {
                label: this._translate.instant('posts.yes_delete'),
                action: () => {
                    this._postsService.deletePosts(this.selectedPosts.map((p) => p._id)).subscribe({
                        next: () => {
                            this.posts = this.posts.filter((post) => !this.selectedPosts.map((p) => p._id).includes(post._id));
                            this.pagination.skip = (this.pagination.skip || 0) - this.selectedPosts.length;
                            this.totalPosts -= this.selectedPosts.length;
                            this.selectedPosts = [];
                        },
                        error: (err) => {
                            if (err.status === 403) {
                                return;
                            }
                            this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                        },
                    });
                },
            },
        });
    }

    hideDraft = (post: Post): boolean => post.published === PostPublicationStatus.DRAFT && this.hideDraftPost;

    goToPlatforms(): void {
        this._router.navigate([`/restaurants/${this.restaurant._id}/settings/platforms/connection`]);
    }

    selectPosts(posts: PostWithJob[]): void {
        if (!posts.length) {
            this.selectedPosts = [];
            return;
        }

        if (posts.length === 1) {
            const post = posts[0];
            if (!!this.selectedPosts.find((el) => el._id === post._id)) {
                this.selectedPosts = this.selectedPosts.filter((it) => it._id !== post._id);
            } else {
                this.selectedPosts.push(post);
            }
            return;
        }

        posts.forEach((el) => {
            if (!this.selectedPosts.find((it) => el._id === it._id)) {
                this.selectedPosts.push(el);
            }
        });
    }

    private _prepareDuplicatePosts({ isSinglePost, to, postsToDuplicate, platformsKeys, inBackground }: PostDuplication): void {
        if (to === DuplicationDestination.HERE) {
            return this._duplicatePosts(postsToDuplicate, platformsKeys, inBackground, isSinglePost);
        }

        const steps = this._getStepsForDuplication(postsToDuplicate, platformsKeys, isSinglePost, inBackground);

        const initialData: RestaurantsSelectionData | undefined = isSinglePost
            ? {
                  skipOwnRestaurant: false,
                  withoutBrandBusiness: false,
                  selectedRestaurants: [],
                  hasPlatform: platformsKeys,
              }
            : undefined;

        this._customDialogService.open(StepperModalComponent, {
            width: isSinglePost ? '900px' : '600px',
            height: 'unset',
            data: {
                steps,
                initialData,
                title: this._translate.instant('duplicate_to_restaurants_dialog.title'),
                onSuccess: (posts: Post[]) => {
                    this._onDuplicationSuccess({
                        posts,
                        inBackground,
                        duplicateOutside: true,
                    });
                },
                onError: (error: unknown) => {
                    this._onDuplicationError(error, inBackground);
                },
                shouldDisplayConfirmationCloseModalAfterClosed: true,
            },
        });
    }

    private _onDuplicationSuccess = ({
        posts,
        inBackground,
        duplicateOutside,
    }: {
        posts: Post[];
        inBackground: boolean;
        duplicateOutside: boolean;
    }): void => {
        if (!inBackground) {
            this.loading = false;
        }
        if (posts.length) {
            if (duplicateOutside) {
                this._toastService.openSuccessToast(this._translate.instant('social_posts.posts_duplicated'));
            }
        }
        this.selectedPosts = [];

        this.reload();
        this._customDialogService.closeAll();
    };

    private _onDuplicationError = (error: unknown, inBackground: boolean): void => {
        console.warn('err :>>', error);
        this.selectedPosts = [];
        if (!inBackground) {
            this.loading = false;
        }
        if (error && error['status'] === 403 && !!error['error']?.casl) {
            return;
        }
        if (error?.['message']?.match(/Unsupported file/)) {
            this._toastService.openErrorToast(this._translate.instant('social_posts.format_not_supported'));
        } else {
            this._toastService.openErrorToast(this._translate.instant('social_posts.duplication_failed'));
        }
    };

    private _duplicatePosts(
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        inBackground: boolean,
        isSinglePost: boolean,
        duplicateOutside = false
    ): void {
        if (!inBackground) {
            this.loading = true;
        }
        forkJoin(postsToDuplicate.map((postToDuplicate) => this._postsService.duplicatePost$(postToDuplicate, platformsKeys))).subscribe({
            next: (posts) => {
                if (!inBackground) {
                    this.loading = false;
                }
                if (posts.length) {
                    const isSeoPosts = getPostCategoryFromKeys(platformsKeys) === PostSource.SEO;
                    if (duplicateOutside) {
                        this._toastService.openSuccessToast(this._translate.instant('posts.posts_duplicated'));
                        return;
                    } else if (!isSeoPosts) {
                        const postId = isSinglePost ? posts[0]._id : null;
                        this._duplicatePostModalService.openDuplicateModal(postId, PostCategory.SOCIAL, !isSinglePost);
                        return;
                    }
                    if (isSeoPosts) {
                        this.posts.unshift(
                            ...posts.map((p) => {
                                p.initWorkingPic('small');
                                return new PostWithJob({
                                    ...p,
                                });
                            })
                        );
                        this.pagination.skip = (this.pagination.skip || 0) + posts.length;
                        this.totalPosts += posts.length;
                        this._toastService.openSuccessToast(this._translate.instant('posts.duplication_success'));
                    }
                }
                this.selectedPosts = [];
            },
            error: (err) => {
                console.warn('err :>>', err);
                this.selectedPosts = [];
                if (err.status === 403) {
                    return;
                }
                this._toastService.openErrorToast(this._httpErrorPipe.transform(err));
                if (!inBackground) {
                    this.loading = false;
                }
            },
        });
    }

    private _computeFilterCount(postsFilters: PostsFilters): number {
        const isDefaultPeriod = postsFilters.period === DEFAULT_PERIOD;
        const isDefaultStatuses = postsFilters.publicationStatus?.length === defaultPublicationStatus.length;
        return [!isDefaultPeriod, !isDefaultStatuses].filter(Boolean).length;
    }

    private _createEmptyDraft$(): Observable<ApiResult<Post>> {
        return this._postsService.createEmptySeoDraft$(this.restaurant._id);
    }

    private _duplicatePostOutside(
        restaurantSelectionData: RestaurantsSelectionData,
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        inBackground: boolean,
        isDraft = false
    ): Observable<Post[]> {
        if (restaurantSelectionData?.selectedRestaurants?.length) {
            type CustomPostWithJob = PostWithJob & { forceDraft: boolean; platformKeys: PlatformKey[] };
            const allPosts: CustomPostWithJob[] = [];

            restaurantSelectionData.selectedRestaurants?.forEach(({ _id, platformKeys }) => {
                // separate valid and invalid platform keys for the restaurant if duplication is not as draft
                const validPlatforms = isDraft ? platformsKeys : platformsKeys.filter((key) => platformKeys.includes(key));
                const invalidPlatforms = isDraft ? [] : platformsKeys.filter((key) => !platformKeys.includes(key));

                postsToDuplicate.forEach((post) => {
                    // if post to duplicate is draft than duplicate it in all platforms
                    if (post.published === PostPublicationStatus.DRAFT) {
                        const newPost = new Post(post) as CustomPostWithJob;
                        newPost.restaurantId = _id;
                        newPost.forceDraft = false;
                        newPost.platformKeys = platformsKeys;
                        delete newPost.attachmentsName;

                        allPosts.push(newPost);
                    } else {
                        // else duplicate it as draft in invalid platforms
                        if (invalidPlatforms.length > 0) {
                            const newPost = new Post(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.published = PostPublicationStatus.DRAFT;
                            newPost.forceDraft = true;
                            newPost.platformKeys = invalidPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }

                        if (validPlatforms.length > 0) {
                            const newPost = new Post(post) as CustomPostWithJob;
                            newPost.restaurantId = _id;
                            newPost.forceDraft = false;
                            newPost.platformKeys = validPlatforms;
                            delete newPost.attachmentsName;

                            allPosts.push(newPost);
                        }
                    }
                });
            });
            if (!inBackground) {
                this.loading = true;
            }
            return forkJoin(
                allPosts.map((postToDuplicate) =>
                    this._postsService.duplicatePost$(
                        postToDuplicate,
                        postToDuplicate.platformKeys,
                        isDraft || postToDuplicate.forceDraft || postToDuplicate.published === PostPublicationStatus.DRAFT
                    )
                )
            );
        } else {
            this.selectedPosts = [];
            return of([]);
        }
    }

    private _populateSelectedRestaurantsWithPost(
        restaurantSelectionData: RestaurantsSelectionData,
        postToDuplicate: PostWithJob
    ): PersonalizePostDuplicationData[] {
        return (
            restaurantSelectionData.selectedRestaurants?.map((restaurant) => {
                const date = new Date(
                    postToDuplicate.job?.nextRunAt || postToDuplicate.plannedPublicationDate || addMinutes(2, new Date())
                );

                const post = this._formBuilder.group({
                    status: [
                        postToDuplicate.published === PostPublicationStatus.DRAFT ? PostDateStatus.DRAFT : PostDateStatus.LATER,
                        Validators.required,
                    ],
                    text: [postToDuplicate.text, Validators.required],
                    date: [date],
                    time: [getTimeStringFromDate(date), isControlValueInTimeFormat(this._translate)],
                });
                return {
                    restaurant,
                    post,
                };
            }) || []
        );
    }

    private _duplicatePersonalizedPostOutside(
        postDuplicationDataPerRestaurant: PersonalizePostDuplicationData[],
        initialPost: PostWithJob,
        platformsKeys: PlatformKey[],
        inBackground: boolean
    ): Observable<Post[]> {
        return forkJoin(
            postDuplicationDataPerRestaurant.map(({ restaurant, post }) => {
                const postToDuplicate = initialPost;
                const date = this._getPostDuplicationDate(post);

                postToDuplicate.text = post.value.text || '';
                postToDuplicate.plannedPublicationDate = date;
                postToDuplicate.published =
                    post.value.status === PostDateStatus.DRAFT ? PostPublicationStatus.DRAFT : PostPublicationStatus.PENDING;

                return this._duplicatePostOutside(
                    { selectedRestaurants: [restaurant], skipOwnRestaurant: false, withoutBrandBusiness: false },
                    [postToDuplicate],
                    platformsKeys,
                    inBackground
                );
            })
        ).pipe(mergeMap((results) => results));
    }

    private _getPostDuplicationDate(
        post: INullableFormGroup<{
            status: PostDateStatus;
            date: Date;
            text: string;
            time: string;
        }>
    ): Date {
        switch (post.value.status) {
            case PostDateStatus.DRAFT:
            case PostDateStatus.LATER:
                const date = post.value.date ?? new Date();
                if (date && post.value.time) {
                    date.setHours(parseInt(post.value.time.substring(0, 2), 10));
                    date.setMinutes(parseInt(post.value.time.substring(3), 10));
                }
                return date;

            case PostDateStatus.NOW:
            default:
                return new Date();
        }
    }

    private _getStepsForDuplication(
        postsToDuplicate: PostWithJob[],
        platformsKeys: PlatformKey[],
        isSinglePost: boolean,
        inBackground: boolean
    ): Step[] {
        return isSinglePost
            ? [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translate.instant('common.next'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<PersonalizePostDuplicationData[]> =>
                          of(this._populateSelectedRestaurantsWithPost(data, postsToDuplicate[0])),
                  },
                  {
                      component: PersonalizePostDuplicationComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.personalize_post_data.subtitle'),
                      primaryButtonText: this._translate.instant('common.duplicate'),
                      nextFunction$: (data: PersonalizePostDuplicationData[]): Observable<Post[]> =>
                          this._duplicatePersonalizedPostOutside(data, postsToDuplicate[0], platformsKeys, inBackground),
                  },
              ]
            : [
                  {
                      component: RestaurantsSelectionComponent,
                      subtitle: this._translate.instant('duplicate_to_restaurants_dialog.subtitle'),
                      primaryButtonText: this._translate.instant('common.duplicate'),
                      nextFunction$: (data: RestaurantsSelectionData): Observable<Post[]> => {
                          const isDraft = true;
                          return this._duplicatePostOutside(data, postsToDuplicate, platformsKeys, inBackground, isDraft);
                      },
                  },
              ];
    }
}
