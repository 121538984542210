<div class="flex h-full flex-col">
    <div class="flex flex-1 flex-col gap-5 overflow-auto px-9 py-6">
        <div class="malou-text-18--bold text-malou-color-text-1">{{ 'admin.profile.alerts.title' | translate }}</div>
        <div class="malou-simple-card flex flex-col gap-y-10 p-6.5">
            <ng-container>
                <div class="flex gap-x-2">
                    <div class="flex min-w-0 grow flex-col">
                        <div class="flex justify-between">
                            <div class="malou-text-13--semibold text-malou-color-text-1">
                                {{ 'admin.profile.alerts.untreated_notifications' | translate }}
                            </div>
                            <div>
                                <app-slide-toggle [checked]="wantsFeedbackNotifications" (onToggle)="toggleReceiveFeedback()">
                                </app-slide-toggle>
                            </div>
                        </div>
                        <div class="malou-text-13--regular text-malou-color-text-2">
                            {{ 'admin.profile.alerts.untreated_notifications_description' | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        @if (isEmailNotificationsEnabled()) {
            <div class="malou-simple-card flex flex-col gap-y-10 p-6.5">
                <div class="malou-text-13--semibold text-malou-color-text-1">
                    {{ 'admin.profile.alerts.reminders' | translate }}
                </div>
                @if (isNegativeReviewsEmailNotificationsEnabled()) {
                    <app-notification-reminder-item
                        [checked]="wantsReviewReplyReminderNotification()"
                        [notificationType]="NotificationType.REVIEW_REPLY_REMINDER"
                        (onToggle)="
                            toggleReceiveReminderNotifications(
                                NotificationType.REVIEW_REPLY_REMINDER,
                                wantsReviewReplyReminderNotification()
                            )
                        ">
                    </app-notification-reminder-item>
                }

                @if (isSpecialHourEmailNotificationsEnabled()) {
                    <app-notification-reminder-item
                        [checked]="wantsSpecialHoursReminderNotification()"
                        [notificationType]="NotificationType.SPECIAL_HOUR"
                        (onToggle)="
                            toggleReceiveReminderNotifications(NotificationType.SPECIAL_HOUR, wantsSpecialHoursReminderNotification())
                        ">
                    </app-notification-reminder-item>
                }

                @if (isEmailPostSuggestionsNotificationsEnabled()) {
                    <app-notification-reminder-item
                        [checked]="wantsSpecialHoursReminderNotification()"
                        [notificationType]="NotificationType.POST_SUGGESTION"
                        (onToggle)="
                            toggleReceiveReminderNotifications(NotificationType.POST_SUGGESTION, wantsPostSuggestionReminderNotification())
                        ">
                    </app-notification-reminder-item>
                }
            </div>
        }

        @if (user?.isAdmin()) {
            <div class="malou-simple-card flex flex-col gap-y-10 p-6.5">
                <div class="malou-text-13--semibold text-malou-color-text-1">Notifications Testing</div>
                <ng-container [ngTemplateOutlet]="notificationsTesting"></ng-container>
            </div>
        }
    </div>
</div>

<ng-template #notificationsTesting>
    <div class="flex items-center gap-x-4">
        @if (!sendingTestNotification()) {
            <button class="malou-btn-raised--primary" mat-raised-button (click)="sendTestNotification(NotificationType.REVIEW)">
                Send new reviews notifcation
            </button>
            <button
                class="malou-btn-raised--primary"
                mat-raised-button
                (click)="sendTestNotification(NotificationType.REVIEW_REPLY_REMINDER)">
                Send negative review reminder notifcation
            </button>
            <button class="malou-btn-raised--primary" mat-raised-button (click)="sendTestNotification(NotificationType.SPECIAL_HOUR)">
                Send new special hours notifcation
            </button>
            <button
                class="malou-btn-raised--primary"
                mat-raised-button
                [disabled]="sendingTestNotification()"
                (click)="sendTestNotification(NotificationType.POST_SUGGESTION)">
                Send Post suggestion notification
            </button>
        } @else {
            <mat-spinner class="stroke-white" [diameter]="16"></mat-spinner>
        }
    </div>
</ng-template>
