<div class="flex flex-col gap-y-6">
    <ng-container [ngTemplateOutlet]="wheelOfFortuneDatesTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="giftClaimStartDateOptionTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="giftClaimDurationTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="redirectionPlatformTemplate"></ng-container>
    @if (!isAggregatedView && totems.length) {
        <ng-container [ngTemplateOutlet]="totemSelectionTemplate"></ng-container>
    }
</div>

<ng-template #wheelOfFortuneDatesTemplate>
    <form [formGroup]="wheelOfFortuneDatesForm">
        <div class="malou-text-12--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.title' | translate }}
        </div>
        <div class="mb-4 flex items-center gap-x-2">
            <mat-checkbox
                color="primary"
                [disabled]="isDisabled"
                [checked]="!globalSettings().isEndDateMandatory"
                (change)="toggleIsEndDateMandatory()"></mat-checkbox>
            <span class="malou-text-10--regular malou-color-text-1">
                {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.no_end_date' | translate }}
            </span>
        </div>
        <div class="flex">
            <div class="flex w-[47%]">
                <app-input-date-picker
                    class="w-full"
                    formControlName="startDate"
                    [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.start_date' | translate"
                    [min]="TODAY"
                    [max]="wheelOfFortuneDatesForm.get('endDate')?.value ?? undefined"
                    [shouldDisplayBorder]="true">
                </app-input-date-picker>
            </div>
            @if (globalSettings().isEndDateMandatory) {
                <span class="malou-text-10--regular malou-color-text-2 flex w-[6%] items-center justify-center">
                    {{ 'common.to' | translate }}
                </span>
                <div class="flex w-[47%]">
                    <app-input-date-picker
                        class="w-full"
                        formControlName="endDate"
                        [min]="getMinEndDate()"
                        [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.global_settings.wheel_of_fortune_dates.end_date' | translate"
                        [shouldDisplayBorder]="true">
                    </app-input-date-picker>
                </div>
            }
        </div>
    </form>
</ng-template>

<ng-template #giftClaimStartDateOptionTemplate>
    <div>
        <div class="malou-text-12--semibold malou-color-text-1 mb-3">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_start_date_option.title' | translate }}
        </div>

        <mat-radio-group
            class="flex gap-x-5"
            color="primary"
            [disabled]="isDisabled"
            [value]="globalSettings().giftClaimStartDateOption"
            (change)="onGiftClaimStartDateOptionChange($event)">
            <mat-radio-button class="malou-small-radio-button" [value]="GiftClaimStartDateOption.NOW">
                <span class="malou-text-10--regular">
                    {{ GiftClaimStartDateOption.NOW | enumTranslate: 'gift_claim_start_date_option' }}
                </span>
            </mat-radio-button>
            <mat-radio-button class="malou-small-radio-button" [value]="GiftClaimStartDateOption.TOMORROW">
                <span class="malou-text-10--regular">
                    {{ GiftClaimStartDateOption.TOMORROW | enumTranslate: 'gift_claim_start_date_option' }}
                </span>
                <span class="malou-text-10--regular italic">
                    {{
                        'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_start_date_option.make_your_clients_come_back'
                            | translate
                    }}
                </span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</ng-template>

<ng-template #giftClaimDurationTemplate>
    <div>
        <div class="malou-text-12--semibold malou-color-text-1">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.title' | translate }}
        </div>
        <div class="malou-text-10--regular malou-color-text-2 mb-4 italic">
            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.gift_claim_duration_in_days.subtitle' | translate }}
        </div>
        <app-simple-select
            [formControl]="giftClaimDurationInDaysControl"
            [values]="GIFT_CLAIM_DURATION_IN_DAYS_OPTIONS"
            [displayWith]="displayGiftClaimDurationInDaysOptionsWith"></app-simple-select>
    </div>
</ng-template>

<ng-template #redirectionPlatformTemplate>
    <div>
        <div class="malou-text-12--semibold malou-color-text-1 mb-4 flex flex-col">
            {{
                (redirectPlatformOptions().length > 1
                    ? 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_platform.choose_your_redirection'
                    : 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.redirection_platform.your_redirection'
                ) | translate
            }}
            @if (
                redirectPlatformOptions().length === 1 &&
                redirectPlatformOptions()[0] === WheelOfFortuneRedirectionPlatformKey.NO_REDIRECTION
            ) {
                <div class="malou-text-10--regular malou-color-text-2 italic">
                    @if (isAggregatedView) {
                        <span>
                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms' | translate }}
                        </span>
                    } @else {
                        <span>
                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_1' | translate }}
                            <span
                                class="malou-btn-flat !malou-text-10--bold cursor-pointer"
                                (click)="close({ shouldCheckBeforeClose: true, restaurantId: selectedRestaurants()[0]._id })"
                                >{{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_cta' | translate }}</span
                            >
                            {{ 'wheel_of_fortune.new_wheel_modal.tabs.global_settings.connect_more_platforms_2' | translate }}
                        </span>
                    }
                </div>
            }
        </div>
        @if (redirectPlatformOptions().length > 1) {
            <app-select [formControl]="redirectionPlatformControl" [values]="redirectPlatformOptions()">
                <ng-template let-value="value" #simpleSelectedValueTemplate>
                    <div class="malou-text-12--medium malou-color-text-1 flex h-full items-center gap-x-2 pl-1">
                        <app-platform-logo imgClasses="h-8 w-8" [logo]="value"></app-platform-logo>
                        <span>{{ value | enumTranslate: 'platform_key' }}</span>
                    </div>
                </ng-template>
                <ng-template let-value="value" #optionTemplate>
                    <div class="malou-text-12--regular flex items-center gap-x-2">
                        <app-platform-logo imgClasses="h-8 w-8" [logo]="value"></app-platform-logo>
                        <span>{{ value | enumTranslate: 'platform_key' }}</span>
                    </div>
                </ng-template>
            </app-select>
        } @else {
            <div class="malou-text-12--medium malou-color-text-1 flex h-full items-center gap-x-2 pl-2">
                <app-platform-logo imgClasses="h-8 w-8" [logo]="redirectPlatformOptions()[0]"></app-platform-logo>
                <span>{{ redirectPlatformOptions()[0] | enumTranslate: 'platform_key' }}</span>
            </div>
        }
    </div>
</ng-template>

<ng-template #totemSelectionTemplate>
    <div class="flex flex-col">
        <span class="malou-text-12--semibold malou-color-text-1">{{
            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.which_totems' | translate
        }}</span>
        <span class="malou-text-10--medium italic">{{
            'wheel_of_fortune.new_wheel_modal.tabs.restaurants.will_redirect' | translate
        }}</span>
        <app-select-base
            class="mr-5 mt-4 max-h-[50px] max-w-[98%] overflow-x-hidden"
            [values]="totems"
            [selectedValues]="totemsControl.value || []"
            [displayWith]="totemDisplayWith"
            [sortBy]="totemsSortBy"
            [formControl]="totemsControl"
            [defaultEmptyValueMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem' | translate"
            [placeholder]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.no_totem_selected' | translate"
            [selectAllCheckboxMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.all_totems' | translate"
            [multiSelection]="true"
            [checkboxOption]="true"
            [showSelectAllCheckbox]="true"
            [showValuesSelectedCount]="true"
            [groupSelectedValuesAtTop]="true"
            [multiSelectionElementWrap]="false"
            [shouldSwitchToWrapModeOnClick]="false"
            [shouldUpdateValuesToDisplayAfterSelection]="true"
            [valuesSelectedCountMessage]="'wheel_of_fortune.new_wheel_modal.tabs.restaurants.totems' | translate"
            (selectBaseChange)="$event ? onTotemSelectionChange($event) : null">
            <ng-template let-value="value" let-index="index" let-deleteValueAt="deleteValueAt" #selectedValueTemplate>
                <div class="malou-chip malou-chip--primary">
                    <span>{{ totemDisplayWith | applyPure: value }}</span>
                    <mat-icon
                        class="malou-chip-icon--right malou-color-primary"
                        [svgIcon]="SvgIcon.REMOVE"
                        (click)="deleteValueAt(index)"></mat-icon>
                </div>
            </ng-template>
            <ng-template let-value="value" let-isValueSelected="isValueSelected" #optionTemplate>
                <div class="malou-text-12--semibold" [class.malou-color-text-1]="isValueSelected">
                    {{ totemDisplayWith | applyPure: value }}
                </div>
            </ng-template>
        </app-select-base>
    </div>
</ng-template>
