export const environment = {
    production: true,
    GOOGLE_FRONTEND_KEY: 'AIzaSyCH-RI6GzRxev_GKsNDWsZSrqP4q8oR8qc',
    APP_MALOU_API_URL: 'https://app.api.malou.io',
    MAX_KEYWORDS_LENGTH: 10,
    S3_URI: 'https://malou-production.s3.eu-west-3.amazonaws.com',
    environment: 'production',
    PUSHER_KEY: 'fefb73f5df41cc7faa1d',
    experimentation_app_proxy_url: 'https://experimentation.malou.io',
    experimentation_app_client_key: 'sdk-si1NwbCfb8xdbd2',
    APP_WEB_VERSION: 'v3',
    BASE_URL: 'http://app.malou.io',
    JIMO_PROJECT_ID: 'd0d1083f-2824-443c-b6e8-51aaecdf7a47',
    imports: [],
};
