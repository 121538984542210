import { DateTime } from 'luxon';

import { EntityConstructor } from '@malou-io/package-utils';

import { CalendarEvent } from ':shared/models/calendar-event';

import { Notification } from './notification.model';

export interface PostSuggestioNotificationData {
    restaurantIds: string[];
    event: CalendarEvent;
}

type PostSuggestionotificationProps = EntityConstructor<PostSuggestioNotification>;

export class PostSuggestioNotification extends Notification {
    data: PostSuggestioNotificationData;

    constructor(props: PostSuggestionotificationProps) {
        super(props);
        this.data = props.data;
        this.data.event = new CalendarEvent(props.data.event);
    }

    copyWith(props: Partial<PostSuggestionotificationProps>): PostSuggestioNotification {
        return new PostSuggestioNotification({ ...this, ...props });
    }

    getFormattedEventDate(): string {
        return DateTime.fromISO(this.data.event.startDate).toFormat('dd MMMM');
    }

    getNotificationName = (): string => this.data.event.getNameForLang(localStorage.getItem('lang') ?? 'fr');

    getNotificationDescription(): string {
        return this.data.event.getIdeasForLang(localStorage.getItem('lang') ?? 'fr');
    }

    getNotificationEmoji = (): string => this.data.event.emoji ?? '🎉';
}
