import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList, CdkDropListGroup, DragRef, moveItemInArray } from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
    BehaviorSubject,
    catchError,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    forkJoin,
    map,
    Observable,
    switchMap,
    take,
    tap,
} from 'rxjs';

import { isNotNil, PlatformDefinitions, PlatformKey, PostPublicationStatus, PostSource } from '@malou-io/package-utils';

import { ExtendedPostPublicationStatus } from ':core/constants';
import { PlatformsService } from ':core/services/platforms.service';
import { PostsService } from ':core/services/posts.service';
import { RestaurantsService } from ':core/services/restaurants.service';
import { ToastService } from ':core/services/toast.service';
import { updateRefreshDates } from ':modules/posts/posts.actions';
import { selectRefreshDates } from ':modules/posts/posts.selectors';
import { PlatformLogoComponent } from ':shared/components/platform-logo/platform-logo.component';
import { SkeletonComponent } from ':shared/components/skeleton/skeleton.component';
import { SocialPostMediaComponent } from ':shared/components/social-post-media/social-post-media.component';
import { DuplicationDestination } from ':shared/enums/duplication-destination.enum';
import { shouldRefreshPost } from ':shared/helpers/should-refresh-post';
import { TrackByFunctionFactory } from ':shared/helpers/track-by-functions';
import { AvailablePlatform, Pagination, Platform, Post, PostsFilters, Restaurant, SocialPost } from ':shared/models';
import { ApplySelfPurePipe } from ':shared/pipes/apply-fn.pipe';
import { HttpErrorPipe } from ':shared/pipes/http-error.pipe';
import { IllustrationPathResolverPipe } from ':shared/pipes/illustration-path-resolver.pipe';

import { SocialPostsService } from '../social-posts.service';

const DEFAULT_PAGINATION = { pageSize: 50, pageNumber: 0, total: 0 };

interface PlatformsStore {
    platformsData: {
        [key: string]: Platform[];
    };
}

@Component({
    selector: 'app-social-posts-feed',
    templateUrl: './social-posts-feed.component.html',
    styleUrls: ['./social-posts-feed.component.scss'],
    standalone: true,
    imports: [
        CdkDrag,
        CdkDropList,
        CdkDropListGroup,
        NgClass,
        NgTemplateOutlet,
        InfiniteScrollModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        TranslateModule,
        SkeletonComponent,
        SocialPostMediaComponent,
        ApplySelfPurePipe,
        AsyncPipe,
        IllustrationPathResolverPipe,
        PlatformLogoComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialPostsFeedComponent implements OnInit {
    @ViewChild(CdkDropList) placeholder: CdkDropList;

    private readonly _platformsService = inject(PlatformsService);
    private readonly _postsService = inject(PostsService);
    private readonly _restaurantsService = inject(RestaurantsService);
    private readonly _socialPostsService = inject(SocialPostsService);
    private readonly _toastService = inject(ToastService);
    private readonly _translateService = inject(TranslateService);
    private readonly _activatedRoute = inject(ActivatedRoute);
    private readonly _destroyRef = inject(DestroyRef);
    private readonly _router = inject(Router);
    private readonly _store = inject(Store);
    private readonly _httpErrorPipe = inject(HttpErrorPipe);

    readonly trackByIdFn = TrackByFunctionFactory.get('id');

    readonly PlatformKey = PlatformKey;
    readonly ExtendedPostPublicationStatus = ExtendedPostPublicationStatus;
    readonly PostPublicationStatus = PostPublicationStatus;

    readonly platformsStore$: Observable<PlatformsStore> = this._store.select((state) => state.platforms);
    readonly availablePlatforms$ = this._getAvailablePlatforms$();
    readonly availablePlatforms = toSignal(this.availablePlatforms$, { initialValue: [] });

    readonly igPosts: WritableSignal<SocialPost[]> = signal([]);
    readonly loading = signal(true);
    readonly pagination$ = new BehaviorSubject<Pagination>(DEFAULT_PAGINATION);
    readonly filters$: Observable<PostsFilters> = this._store
        .select((state) => state.socialposts.socialFilters)
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)));

    readonly isIgConnected = computed(() => this.availablePlatforms().find((p) => p.key === PlatformKey.INSTAGRAM)?.connected ?? false);
    readonly igUsername = signal('');
    readonly hideInstagramFeed = signal(false);

    readonly showOldDrafts = signal(false);
    readonly filteredPosts = computed(() =>
        this.showOldDrafts()
            ? this.igPosts()
            : this.igPosts().filter(
                  (p) =>
                      p.published !== PostPublicationStatus.DRAFT ||
                      (p.published === PostPublicationStatus.DRAFT && new Date(p.plannedPublicationDate).getTime() > Date.now())
              )
    );

    private _target: CdkDropList | null = null;
    private _targetIndex: number;
    private _source: CdkDropList | null = null;
    private _sourceIndex: number;
    private _dragRef: DragRef | null = null;

    private readonly _BOX_WIDTH = '200px';
    private readonly _BOX_HEIGHT = '200px';

    ngOnInit(): void {
        this._setIgUserName();
        this._setIgPostsPaginated();

        // effects
        this._onPostDeleted();
        this._onPostDuplicated();
        this._onPostEdited();
    }

    onScroll(): void {
        this.pagination$.next({ ...this.pagination$.value, pageNumber: this.pagination$.value.pageNumber + 1 });
    }

    refreshPost(post: SocialPost): void {
        this._store
            .select(selectRefreshDates)
            .pipe(
                take(1),
                map((dates) => dates[post.id]),
                switchMap((date) => {
                    if (shouldRefreshPost(date)) {
                        return this._postsService.refresh(post.id).pipe(
                            map((res) => res.data),
                            tap((p) => {
                                this.igPosts.update((currentIgPosts) =>
                                    currentIgPosts.map((igPost) => {
                                        if (igPost.id === post.id) {
                                            igPost.refreshData({ ...p });
                                        }
                                        return igPost;
                                    })
                                );
                                this._store.dispatch(updateRefreshDates({ postId: post.id }));
                            })
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    navigateToPlatforms(): void {
        this._router.navigate([`../restaurants/${this._restaurantsService.currentRestaurant._id}/settings/platforms`]);
    }

    cdkDropListEnterPredicate(drag: CdkDrag, drop: CdkDropList): boolean {
        return (
            drop.data.published !== PostPublicationStatus.PUBLISHED &&
            new Post(drop.data).getPostDate() > new Date() &&
            new Date(drag.data.plannedPublicationDate).getTime() > Date.now()
        );
    }

    onDropListDropped(event: CdkDragDrop<SocialPost>): void {
        const movingPost = event?.item?.data;
        if (movingPost.published === PostPublicationStatus.DRAFT && movingPost.getPostDate().getTime() < Date.now()) {
            this._toastService.openErrorToast(this._translateService.instant('social-posts.old_drafts_cannot_be_dragged'));
        }

        if (!event.item.data) {
            return;
        }
        try {
            if (!this._target || event?.container?.data?.published === PostPublicationStatus.PUBLISHED) {
                event.item.reset();
                return;
            }

            const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
            const placeholderParentElement: HTMLElement = placeholderElement.parentElement as HTMLElement;

            placeholderElement.style.display = 'none';

            placeholderParentElement.removeChild(placeholderElement);
            placeholderParentElement.appendChild(placeholderElement);
            if (this._source) {
                placeholderParentElement.insertBefore(
                    this._source?.element?.nativeElement,
                    placeholderParentElement.children[this._sourceIndex]
                );
            }

            if (this.placeholder._dropListRef.isDragging() && this._dragRef) {
                this.placeholder._dropListRef.exit(this._dragRef);
            }

            this._target = null;
            this._source = null;
            this._dragRef = null;

            if (this._sourceIndex !== this._targetIndex) {
                // creating a copy of the array to be able to compare the old and new items
                const oldItems = [...this.igPosts().map((p) => new SocialPost({ ...p }))];
                // creating a copy of the source and target posts to be able to compare the old and new dates
                const sourcePost = new SocialPost({ ...this.igPosts()[this._sourceIndex] });
                const targetPost = new SocialPost({ ...this.igPosts()[this._targetIndex] });
                // updating the posts array positions
                moveItemInArray(this.igPosts(), this._sourceIndex, this._targetIndex);
                // updating the posts dates
                this._updatePostsDatesBetweenIndexes(oldItems, this._sourceIndex, this._targetIndex, sourcePost, targetPost);
            }
        } catch (error) {
            event.item.reset();
        }
    }

    onDropListEntered({ item, container }: CdkDragEnter<SocialPost>): void {
        try {
            if (container === this.placeholder || container.data.published === PostPublicationStatus.PUBLISHED) {
                item.reset();
                return;
            }

            const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
            const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
            const dropElement: HTMLElement = container.element.nativeElement;
            const dragIndex: number = Array.prototype.indexOf.call(
                dropElement.parentElement?.children,
                this._source ? placeholderElement : sourceElement
            );
            const dropIndex: number = Array.prototype.indexOf.call(dropElement.parentElement?.children, dropElement);

            if (!this._source) {
                this._sourceIndex = dragIndex;
                this._source = item.dropContainer;

                placeholderElement.style.width = this._BOX_WIDTH + 'px';
                placeholderElement.style.height = this._BOX_HEIGHT + 40 + 'px';
                placeholderElement.style.aspectRatio = '1/1';

                sourceElement.parentElement?.removeChild(sourceElement);
            }

            this._targetIndex = dropIndex;
            this._target = container;
            this._dragRef = item._dragRef;

            placeholderElement.style.display = '';

            dropElement.parentElement?.insertBefore(placeholderElement, dropIndex > dragIndex ? dropElement.nextSibling : dropElement);

            this.placeholder._dropListRef.enter(item._dragRef, item.element.nativeElement.offsetLeft, item.element.nativeElement.offsetTop);
        } catch (e) {
            item.reset();
        }
    }

    editPost(postId: string): void {
        const post = this.igPosts().find((p) => p.id === postId);
        if (post?.published !== PostPublicationStatus.PUBLISHED) {
            this._router.navigate(['./'], { queryParams: { postId }, relativeTo: this._activatedRoute });
        }
    }

    toggleShouldShowDrafts(): void {
        this.showOldDrafts.update((currentShowOldDrafts) => !currentShowOldDrafts);
    }

    private _onPostDeleted(): void {
        this._socialPostsService.deletedPost$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((deletedPosts) => {
            deletedPosts.forEach((deletedPost) => {
                this.igPosts.update((currentIgPosts) => {
                    const index = currentIgPosts.findIndex((post) => post.id === deletedPost.id);
                    if (index >= 0) {
                        currentIgPosts.splice(index, 1);
                    }
                    return [...currentIgPosts];
                });
            });
        });
    }

    private _onPostDuplicated(): void {
        this._socialPostsService.duplicatePosts$
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(({ posts: duplicatedPosts, destination }) => {
                if (destination === DuplicationDestination.HERE) {
                    duplicatedPosts
                        .filter((p) => p.keys.includes(PlatformKey.INSTAGRAM))
                        .forEach((duplicate) => {
                            this.igPosts.update((currentIgPosts) => [new SocialPost(duplicate), ...currentIgPosts]);
                        });
                }
            });
    }

    private _onPostEdited(): void {
        this._socialPostsService.editedPosts$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((posts: SocialPost[]) => {
            this.igPosts.update((currentIgPosts) => {
                posts.forEach((post) => {
                    const index = currentIgPosts.findIndex((p) => p.id === post.id);
                    if (index >= 0) {
                        currentIgPosts[index] = post;
                    } else {
                        if (post.keys.includes(PlatformKey.INSTAGRAM)) {
                            currentIgPosts.unshift(post);
                        }
                    }
                });
                currentIgPosts.sort((a, b) => b.getPostDate().getTime() - a.getPostDate().getTime());
                return [...currentIgPosts];
            });
        });
    }

    private _setIgPostsPaginated(): void {
        combineLatest([
            this.pagination$,
            this.filters$.pipe(
                tap(() => this._emptyPostsAndShowLoader()),
                map((filters: PostsFilters) => {
                    if (filters.platforms?.includes(PlatformKey.INSTAGRAM)) {
                        this.hideInstagramFeed.set(false);
                        return filters;
                    }
                    this.hideInstagramFeed.set(true);
                    if (!filters.platforms) {
                        filters.platforms = [];
                    }
                    return {
                        ...filters,
                        platforms: [...filters.platforms, PlatformKey.INSTAGRAM],
                    };
                })
            ),
            this._restaurantsService.restaurantSelected$.pipe(tap(() => this._emptyPostsAndShowLoader())),
            this._store
                .select((state) => state.socialposts.postsSync)
                .pipe(
                    filter((sync) => !sync.loading),
                    tap(() => {
                        this._emptyPostsAndShowLoader();
                    })
                ),
            this._socialPostsService.reload$.pipe(tap(() => this._emptyPostsAndShowLoader())),
        ])
            .pipe(
                filter(([_pagination, _filters, restaurant]) => isNotNil(restaurant)),
                switchMap(([pagination, filters, restaurant]: [Pagination, PostsFilters, Restaurant, any, any]) =>
                    this._postsService
                        .getRestaurantPostsPaginated(restaurant._id, pagination, {
                            ...filters,
                            platforms: [PlatformKey.INSTAGRAM],
                            category: PostSource.SOCIAL,
                            source: PostSource.SOCIAL,
                            isStory: false,
                            reelsInFeed: true,
                        })
                        .pipe(map((res) => res.data.posts.map((post) => new SocialPost(post))))
                ),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe((posts) => {
                const postsInFeed = posts.filter((post) => post.isReelDisplayedInFeed === true);
                this.igPosts.update((currentIgPosts) => [...currentIgPosts, ...postsInFeed]);
                this.loading.set(false);

                setTimeout(() => {
                    const placeholderElement = this.placeholder?.element?.nativeElement;
                    if (placeholderElement) {
                        placeholderElement.style.display = 'none';
                        placeholderElement?.parentNode?.removeChild(placeholderElement);
                    }
                }, 100);
            });
    }

    private _setIgUserName(): void {
        this._restaurantsService.restaurantSelected$
            .pipe(
                filter(isNotNil),
                switchMap((restaurant) => this._platformsService.getPlatformSocialLink(restaurant._id, PlatformKey.INSTAGRAM))
            )
            .subscribe((res) => {
                this.igUsername.set(res.data?.socialLink?.match(/^https:\/\/www.instagram.com\/(.*)/)?.[1] || '');
            });
    }

    private _emptyPostsAndShowLoader(): void {
        this.igPosts.set([]);
        this.loading.set(true);
        this.pagination$.next(DEFAULT_PAGINATION);
    }

    private _updatePostsDatesBetweenIndexes(
        oldItems: SocialPost[],
        _sourceIndex: number,
        _targetIndex: number,
        sourcePost: SocialPost,
        targetPost: SocialPost
    ): void {
        // set the source post date to the target post date
        sourcePost.plannedPublicationDate = targetPost.plannedPublicationDate;
        let [sourceIdx, targetIdx] = [_sourceIndex, _targetIndex];
        // if the source post is below the target post, we need to update the dates in the opposite direction
        if (_sourceIndex > _targetIndex) {
            [sourceIdx, targetIdx] = [_targetIndex, _sourceIndex];
        }
        // create copies of the posts to update, so we can update the dates without affecting the original posts
        const oldPostsToUpdate = [...oldItems.slice(sourceIdx, targetIdx + 1)];
        const postsToUpdateFromIgPosts = this.igPosts()
            .slice(sourceIdx, targetIdx + 1)
            .map((post, index) => {
                post.plannedPublicationDate = oldPostsToUpdate[index].plannedPublicationDate;
                return new SocialPost(post);
            });

        // build the queries to update the posts
        const updatesQueries = [...postsToUpdateFromIgPosts].map((post) =>
            this._postsService.preparePost(
                post.id,
                {
                    post: { plannedPublicationDate: post.plannedPublicationDate },
                    draft: post.published === PostPublicationStatus.DRAFT,
                    keys: post.keys,
                },
                this._restaurantsService.currentRestaurant._id
            )
        );

        // send edit posts event to update each post state in the list
        this._socialPostsService.editPosts(postsToUpdateFromIgPosts);

        // send the queries to update the posts
        forkJoin(updatesQueries).subscribe({
            error: (err) => {
                this._toastService.openErrorToast(
                    this._translateService.instant('common.unknown_error') + this._httpErrorPipe.transform(err)
                );
            },
        });
    }

    private _getAvailablePlatforms$(): Observable<AvailablePlatform[]> {
        return combineLatest([this.platformsStore$, this._restaurantsService.restaurantSelected$]).pipe(
            filter(([platforms, restaurant]) => !!restaurant && !!platforms.platformsData[restaurant._id]),
            map(([platforms, restaurant]: [PlatformsStore, Restaurant]) => {
                const socialPostsPlatforms = PlatformDefinitions.getPlatformKeysWithFeed();
                const connectedPlatforms = platforms.platformsData[restaurant._id].map((plat) => plat.key);
                return socialPostsPlatforms.map((p) => ({
                    key: p,
                    connected: connectedPlatforms.includes(p),
                    checked: true,
                }));
            }),
            catchError((err) => {
                console.warn('err :>> ', err);
                return [];
            }),
            takeUntilDestroyed(this._destroyRef)
        );
    }
}
