<div class="flex flex-col gap-y-4 px-8.5 py-4">
    <ng-container [ngTemplateOutlet]="filtersTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="aggregatedBoostersScanCountTemplate"></ng-container>
    @if (atLeastOneBoosterPackActivated()) {
        <ng-container [ngTemplateOutlet]="wheelOfFortuneStatisticsTemplate"></ng-container>
    }
</div>

<ng-template #filtersTemplate>
    <div class="mb-6 flex items-end gap-4 sm:flex-col sm:items-center">
        <div class="flex-1">
            <app-statistics-filters
                [page]="PlatformFilterPage.BOOSTERS"
                [showPlatformsFilter]="false"
                [showTotemsFilter]="!!restaurantsTotems().length"
                [totems]="restaurantsTotems()"></app-statistics-filters>
        </div>
        <app-button
            buttonClasses="!h-12"
            [disabled]="((restaurants$ | async)?.length ?? 0) < 2 || isLoading()"
            [text]="'aggregated_statistics.common.download_statistics' | translate"
            (buttonClick)="openDownloadStatisticsModal()"></app-button>
    </div>
</ng-template>

<ng-template #aggregatedBoostersScanCountTemplate>
    @if (((restaurants$ | async)?.length ?? 0) > 1) {
        <app-aggregated-boosters-scan-count
            [data$]="boostersData$"
            [restaurants$]="restaurants$"
            [isParentLoading]="isLoadingBoosters()"
            [isParentError]="isErrorBoosters()"
            (chartSortByChange)="onSortByChange(InsightsChart.AGGREGATED_BOOSTERS_SCAN_COUNT, $event)"></app-aggregated-boosters-scan-count>
    } @else {
        <div class="flex flex-col items-center py-6">
            <img class="mb-6 h-20 w-20" alt="Taster illustration" [src]="'Taster' | illustrationPathResolver" />
            <span class="malou-text-14--bold mb-2">{{ 'common.no_data' | translate }}</span>
            <span class="malou-text-10--regular">{{ 'aggregated_statistics.errors.select_at_least_2_businesses' | translate }}</span>
        </div>
    }
</ng-template>

<ng-template #wheelOfFortuneStatisticsTemplate>
    <div class="malou-text-18--bold text-malou-color-text-1">{{ 'aggregated_statistics.boosters.scans.wheel_of_fortune' | translate }}</div>
    <app-aggregated-wheel-of-fortune-gifts-kpis
        [data$]="giftsData$"
        [isParentLoading]="isLoadingGifts()"
        [isParentError]="isErrorGifts()"></app-aggregated-wheel-of-fortune-gifts-kpis>

    <div class="flex gap-4 md:flex-col">
        <div class="min-w-0 flex-1">
            <app-aggregated-wheel-of-fortune-gifts-distribution
                class="flex h-[500px]"
                [data$]="giftsData$"
                [restaurants$]="restaurantsWithBoosterPackActivated$"
                [isParentLoading]="isLoadingGifts()"
                [isParentError]="isErrorGifts()"
                (tableSortByChange)="
                    onTableSortOptionsChange(InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_GIFTS_DISTRIBUTION, $event)
                "></app-aggregated-wheel-of-fortune-gifts-distribution>
        </div>
        <div class="min-w-0 flex-1">
            <app-aggregated-wheel-of-fortune-estimated-review-count
                class="flex h-[500px]"
                [data$]="wheelOfFortuneData$"
                [restaurants$]="restaurantsWithBoosterPackActivated$"
                [isParentLoading]="isLoadingBoosters()"
                [isParentError]="isErrorBoosters()"
                (tableSortByChange)="
                    onTableSortOptionsChange(InsightsChart.AGGREGATED_BOOSTERS_WHEEL_OF_FORTUNE_ESTIMATED_REVIEWS_COUNT, $event)
                ">
            </app-aggregated-wheel-of-fortune-estimated-review-count>
        </div>
    </div>
</ng-template>
