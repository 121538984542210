import { CountriesWithEvents } from ':core/constants';

export class CalendarEvent {
    _id: string;
    id: string;
    startDate: string;
    endDate?: string;
    emoji: string;
    key: string;
    name: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    ideas?: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    example?: {
        fr?: string;
        en?: string;
        it?: string;
        es?: string;
    };
    isBankHoliday?: boolean;
    restaurantsIds: string[];
    country: CountriesWithEvents;
    byDefault: boolean;

    public constructor(init?: Partial<CalendarEvent>) {
        Object.assign(this, init);
    }

    getNameForLang(lang: string): string {
        return this.name[lang];
    }

    getIdeasForLang(lang: string): string {
        return this.ideas?.[lang];
    }

    getExampleForLang(lang: string): string {
        return this.example?.[lang];
    }
}
