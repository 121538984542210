export enum HeapEventName {
    MANUALLY_CREATE_KEYWORD = 'tracking_keywords_manually_entered',
    NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
    REPORT_EMAIL_OPENED = 'report_email_opened',
    REPORT_EMAIL_SENT = 'report_email_sent',
    REPORT_EMAIL_UNSUBSCRIBE = 'report_email_unsubscribe',
    UPDATE_KEYWORD_LANGUAGE_FR = 'tracking_update_keyword_language_fr',
    UPDATE_KEYWORD_LANGUAGE_EN = 'tracking_update_keyword_language_en',
    UPDATE_KEYWORD_LANGUAGE_ES = 'tracking_update_keyword_language_es',
    UPDATE_KEYWORD_LANGUAGE_IT = 'tracking_update_keyword_language_it',
    WHEEL_OF_FORTUNE_GIFT_EXPIRES_SOON = 'tracking_wof_gift_expires_soon',
    WHEEL_OF_FORTUNE_RETRIEVE_GIFT = 'tracking_wof_retrieve_gift',
    TRACKING_ROI_TIP = 'tracking_roi_tip',
    TRACKING_FIRST_TIME_SETTINGS = 'tracking_first_time_settings',
    TRACKING_EDIT_SETTINGS = 'tracking_edit_settings',
    TRACKING_CHECK_PERFORMANCE_SCORE = 'tracking_check_performance_score',
    TRACKING_CHECK_EARNINGS_DETAILS_SINGLE_RESTAURANT = 'tracking_check_earnings_details_single_restaurant',
    TRACKING_CHECK_EARNINGS_DETAILS_MULTI_RESTAURANTS = 'tracking_check_earnings_details_multi_restaurants',
    TRACKING_CHECK_GAINED_TIME_DETAILS_SINGLE_RESTAURANT = 'tracking_check_gained_time_details_single_restaurant',
    TRACKING_CHECK_GAINED_TIME_DETAILS_MULTI_RESTAURANTS = 'tracking_check_gained_time_details_multi_restaurants',
    MALOUPE_TRACKING_OPENING_LANDING_PAGE = 'maloupe_tracking_opening_landing_page',
    MALOUPE_TRACKING_GET_DIAGNOSTIC_ACTION = 'maloupe_tracking_get_diagnostic_action',
    MALOUPE_TRACKING_GET_DIAGNOSTIC_SNIPPET = 'maloupe_tracking_get_diagnostic_snippet',
    MALOUPE_TRACKING_SUBMIT_HUBSPOT_FORM = 'maloupe_tracking_submit_hubspot_form',
    MALOUPE_TRACKING_CLICK_ON_DIAGNOSTIC_EMAIL_LINK = 'maloupe_tracking_click_on_diagnostic_email_link',
    MALOUPE_TRACKING_CLICK_ON_CHECK_KEYWORDS_COMPETITORS = 'maloupe_tracking_click_on_check_keywords_competitors',
    NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_SENT = 'notification_review_reminder_tracking_email_sent',
    NOTIFICATION_REVIEW_REMINDER_TRACKING_EMAIL_OPENED = 'notification_review_reminder_tracking_email_opened',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_SENT = 'notification_special_hour_tracking_email_sent',
    NOTIFICATION_SPECIAL_HOUR_TRACKING_EMAIL_OPENED = 'notification_special_hour_tracking_email_opened',
    NOTIFICATION_POST_SUGGESTION_TRACKING_EMAIL_SENT = 'notification_post_suggestion_tracking_email_sent',
    NOTIFICATION_POST_SUGGESTION_TRACKING_EMAIL_OPENED = 'notification_post_suggestion_tracking_email_opened',
}
