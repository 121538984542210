import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { PostSuggestioNotification } from ':core/components/notification-center/models/post-suggestion-notification.model';
import { ShortTextPipe } from ':shared/pipes/short-text.pipe';

import { NotificationItemComponent } from '../notification-item.component';

@Component({
    selector: 'app-post-suggestion-notification-item',
    templateUrl: './post-suggestion-notification-item.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, TranslateModule, DatePipe, MatButtonModule, ShortTextPipe],
})
export class PostSuggestionNotificationItemComponent extends NotificationItemComponent {
    private readonly _router = inject(Router);
    readonly notification = computed(() => this.initialNotification() as PostSuggestioNotification);
    readonly hasMultipleRestaurantsInNotification = computed(() => this.notification().data.restaurantIds.length > 1);

    readonly isFolded = signal(true);
    readonly SHORT_TEXT_LENGTH = 100;

    onNotificationClick(): void {
        this.markAsRead();
        this._navigateToPostsPage();
        this.notificationCenterContext.close();
    }

    toggleFolded(): void {
        this.isFolded.update((old) => !old);
    }

    private _navigateToPostsPage(): void {
        this._router.navigate(['restaurants', this.notification().data.restaurantIds[0], 'social', 'socialposts']);
    }
}
