export enum DiagnosticRating {
    GOOD = 'good',
    AVERAGE = 'average',
    BAD = 'bad',
    VERY_BAD = 'very_bad',
}

export const SCORE_RATING = {
    MINIMUM_AVERAGE_THRESHOLD: 60,
    MINIMUM_GOOD_THRESHOLD: 80,
};

export const MAX_INCONSISTENCIES_BY_PLATFORM = 3;
export const MINIMUM_GOOGLE_RATING_FOR_GOOD_SCORE = 4.6;

export const TOTAL_SCORE_WITHOUT_INSTAGRAM = 60;

// All the score values are based on this
// https://www.notion.so/welcomehomemalou/Calcul-du-score-59b3915d00bc4d329b3ed6ab1d031c61#49f4dfd325e34c199e7f14f3bbf98704

export const INCONSISTENCIES_RATING_THRESHOLD = {
    MAXIMUM_GOOD_THRESHOLD: 0.15, // Less than 15% of the platforms are inconsistent
    MAXIMUM_AVERAGE_THRESHOLD: 0.5, // Between 50% and 15% of the platforms are inconsistent
    GOOD_SCORE: 7,
    AVERAGE_SCORE: 3,
    BAD_SCORE: 1,
};

export const KEYWORDS_RATING_THRESHOLD = {
    MAXIMUM_GOOD_THRESHOLD: 5,
    MAXIMUM_AVERAGE_THRESHOLD: 15,
    GOOD_SCORE: 20,
    AVERAGE_SCORE: 10,
    BAD_SCORE: 3,
};

export const GOOGLE_SCORE_RATING_THRESHOLD = {
    MINIMUM_AVERAGE_THRESHOLD: 0.85,
    MINIMUM_GOOD_THRESHOLD: 1,
    GOOD_SCORE: 10,
    AVERAGE_SCORE: 5,
    BAD_SCORE: 2,
};

export const BETTER_LOCAL_COMPETITORS_COUNT = {
    MINIMUM_COMPETITOR_COUNT_FOR_BAD_RATING: 5,
    MAXIMUM_COMPETITOR_COUNT_FOR_AVERAGE_RATING: 10,
    MAXIMUM_COMPETITOR_COUNT_FOR_GOOD_RATING: 5,
};

export const INSTAGRAM_VISIBILITY_RATING = {
    MINIMUM_AVERAGE_THRESHOLD: 10,
    MINIMUM_GOOD_THRESHOLD: 30,
};

export const INSTAGRAM_SCORE_THRESHOLD = {
    MINIMUM_AVERAGE_THRESHOLD: 0.6,
    MINIMUM_GOOD_THRESHOLD: 0.8,
    GOOD_SCORE: 13,
    AVERAGE_SCORE: 6,
    BAD_SCORE: 2,
};

export const PHOTOS_RATING_THRESHOLD = {
    MINIMUM_GOOD_THRESHOLD: 10,
    MINIMUM_AVERAGE_THRESHOLD: 5,
    GOOD_SCORE: 3,
    AVERAGE_SCORE: 1,
    BAD_SCORE: 0,
};

export const SERVICES_RATING_THRESHOLD = {
    MINIMUM_GOOD_THRESHOLD: 80,
    MINIMUM_AVERAGE_THRESHOLD: 50,
    GOOD_SCORE: 10,
    AVERAGE_SCORE: 5,
    BAD_SCORE: 2,
};

export const REVIEW_COUNT_RATING_THRESHOLD = {
    MINIMUM_GOOD_THRESHOLD: 100,
    MINIMUM_AVERAGE_THRESHOLD: 85,
    GOOD_SCORE: 10,
    AVERAGE_SCORE: 5,
    BAD_SCORE: 2,
};
