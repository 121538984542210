<div>
    <div class="malou-text-9 italic text-malou-color-text-2--light">
        {{
            'notifications.at'
                | translate: { date: notification().createdAt | date: 'dd.MM.yyyy', time: notification().createdAt | date: 'HH:mm' }
        }}
    </div>
    <div class="mt-2 flex w-full items-center gap-x-3">
        <div>🌱</div>
        <div class="flex flex-col">
            <div
                class="malou-text-11 flex max-w-[90%] items-center gap-x-2 text-malou-color-text-1"
                [innerHTML]="
                    hasMultipleRestaurantsInNotification()
                        ? ('notification-center.special-hour-notification-item.are_your_restaurants_open'
                          | translate
                              : {
                                    formattedDate: notification().getFormattedEventDate(),
                                })
                        : ('notification-center.special-hour-notification-item.are_you_open'
                          | translate
                              : {
                                    formattedDate: notification().getFormattedEventDate(),
                                })
                "></div>
        </div>
    </div>
</div>
