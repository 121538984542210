import { NotificationType } from '@malou-io/package-utils';

import { NegativeReviewReminderNotification } from './negative-review-reminder-notification.model';
import { Notification, NotificationProps } from './notification.model';
import { PostSuggestioNotification } from './post-suggestion-notification.model';
import { ReviewNotification } from './review-notification.model';
import { SpecialHourNotification } from './special-hour-notification.model';

export class NotificationFactory {
    static create(props: NotificationProps): Notification {
        if (props.type === NotificationType.REVIEW_REPLY_REMINDER) {
            return new NegativeReviewReminderNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.REVIEW) {
            return new ReviewNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.SPECIAL_HOUR) {
            return new SpecialHourNotification({ ...props, data: props.data });
        }

        if (props.type === NotificationType.POST_SUGGESTION) {
            return new PostSuggestioNotification({ ...props, data: props.data });
        }

        return new Notification(props);
    }
}
